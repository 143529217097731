import { useMemo, useEffect } from 'react';
import { uniq } from 'lodash';
import { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch } from 'react-redux';

import { FinancialDataSourceType, IMultyYear } from '@/types';
import { actions } from '@/slices/company-profile';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { BRIGHT_QUERY_DATA_SOURCE } from '@/Utils/text';

export const getPreferredDataSource = (revenueMetadata?: string): string | null => {
  // Unfortunately backend will have to execute same regular expressions.
  // Proper fix is to convert metadata to semi-structured format
  // (variant in Snowflake, JSONB in RDS, nested object in ElasticSearch)
  const metadata1 = revenueMetadata?.match(/(\S+) multi year financials prioritized/);

  if (metadata1) {
    return metadata1[1] as string;
  }

  const metadata2 = revenueMetadata?.match(/Top quality source (\S+) used/);

  if (metadata2) {
    return metadata2[1] as string;
  }

  return null;
};

export const useFinancialDataSource = (multiYear?: IMultyYear, revenueMetadata?: string) => {
  const dispatch = useDispatch();
  const currentDataSource = useShallowSelector((state) => state.companyProfile.financialDataSource);

  const dataSourcesAvailable = useMemo(
    () =>
      multiYear
        ? ([
            ...uniq(
              Object.values(multiYear)
                .map((data) => data?.data_source)
                .map((dataSource) => Object.values(dataSource))
                .flat(),
            ),
          ] as string[])
        : ([] as string[]),

    [multiYear],
  );

  useEffect(() => {
    const preferredDataSource = getPreferredDataSource(revenueMetadata);

    if (!currentDataSource) {
      if (preferredDataSource) {
        dispatch(actions.setFinancialDataSource(preferredDataSource));
      } else {
        dispatch(actions.setFinancialDataSource(dataSourcesAvailable ? dataSourcesAvailable[0] : null));
      }
    }
  }, [dispatch, dataSourcesAvailable, multiYear, revenueMetadata, currentDataSource]);

  const handleDataSourceChange = (event: SelectChangeEvent<string | null>) => {
    dispatch(actions.setFinancialDataSource(event.target.value as FinancialDataSourceType));
  };

  return {
    isFinancialDataEstimated: currentDataSource === BRIGHT_QUERY_DATA_SOURCE,
    currentDataSource,
    dataSourcesAvailable,
    handleDataSourceChange,
  };
};
