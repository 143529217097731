import { http, HttpResponse } from 'msw';
import { getUuidFromStringEnd } from '@/Utils/url-utils';

export const MOCKED_COMPANIES_LIST_TITLE = 'Test';
export const MOCKED_COMPANIES_LIST_PINNED_COMPANIES = ['23617408'];
export const MOCKED_AVAILABLE_COMPANIES_LIST_ID = 'available-test-id-123e4567-e89b-12d3-a456-426614174000';
export const MOCKED_NOT_AVAILABLE_COMPANIES_LIST_ID = 'not-available-test-id-839617d5-be13-4f09-96be-de6d4a6da802';

export const companiesListMock = {
  list_id: getUuidFromStringEnd(MOCKED_AVAILABLE_COMPANIES_LIST_ID),
  list_type: 'static',
  title: MOCKED_COMPANIES_LIST_TITLE,
  description: 'test description',
  currency: 'USD',
  company_search: '',
  lucene_query: true,
  filters: {
    treeFilters: {},
    includedNullList: [],
    otherFilters: {},
  },
  pinned_company_ids: MOCKED_COMPANIES_LIST_PINNED_COMPANIES,
  excluded_company_ids: [],
  sort_key: null,
  sort_order: null,
  creator: '040f572c-af65-448a-b39e-85787a9d8643',
  is_sharable_link: false,
  created_at: '2023-03-15T09:12:49.596917',
  updated_at: '2023-03-15T09:12:49.596932',
};

const companiesListsDataMock = {
  search_lists: [companiesListMock],
};

export const companiesListsController = [
  http.get(`${process.env.REACT_APP_API_URL}/search_lists`, () => HttpResponse.json(companiesListsDataMock)),

  http.get(`${process.env.REACT_APP_API_URL}/search_list/:id`, ({ params: { id } }) => {
    if (id === getUuidFromStringEnd(MOCKED_NOT_AVAILABLE_COMPANIES_LIST_ID)) {
      return HttpResponse.json({
        message: `Not found SearchList with id: ${MOCKED_NOT_AVAILABLE_COMPANIES_LIST_ID}`,
      }, { status: 404 });
    }

    return HttpResponse.json({ ...companiesListMock, list_id: id });
  }),

  http.patch(`${process.env.REACT_APP_API_URL}/search_list/:id`, async ({ request }) => {
    const requestBody = await request.json();

    return HttpResponse.json({ companiesListMock, ...requestBody });
  }),

  http.post(`${process.env.REACT_APP_API_URL}/search_list`, async ({ request }) => {
    const requestBody = await request.json();

    return HttpResponse.json({ companiesListMock, ...requestBody });
  }),
];

export const companiesListsErrorsController = [
  http.get(`${process.env.REACT_APP_API_URL}/search_lists`, () => {
    return HttpResponse.json({ message: 'error' }, { status: 500 });
  }),
];

export const LIST_WITH_COLLABORATORS_ID = 'test-list-with-collaborators';
export const LIST_WITHOUT_COLLABORATORS_ID = 'test-list-without-collaborators';
export const COLLABORATOR_EMAIL = 'test-random@test.com';
export const COLLABORATOR_ID = 'test-id';

export const collaboratorsCompaniesListController = [
  http.get(`${process.env.REACT_APP_API_URL}/search_list/:id/collaborators`, ({ params: { id } }) => {
    if (id === LIST_WITH_COLLABORATORS_ID) {
      return HttpResponse.json({
        collaborators: [
          {
            sub: COLLABORATOR_ID,
            email: COLLABORATOR_EMAIL,
          },
        ],
      });
    }

    return HttpResponse.json({
      collaborators: [],
    });
  }),

  http.delete(`${process.env.REACT_APP_API_URL}/search_list/:id/collaborators/:userId`, async () => {
    return HttpResponse.json({});
  }),

  http.put(`${process.env.REACT_APP_API_URL}/search_list/:id/collaborators`, async () => {
    return HttpResponse.json({});
  }),
];

export const collaboratorsCompaniesListErrorController = [
  http.put(`${process.env.REACT_APP_API_URL}/search_list/:id/collaborators`, () => {
    return HttpResponse.json({ message: 'error' }, { status: 500 });
  }),
];
