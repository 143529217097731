import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompaniesListSnippet, ISavedSearchSnippet, Nullish } from '@/types';

export const initialState = {
  currentCompaniesList: null as Nullish<ICompaniesListSnippet>,
  currentSavedSearch: null as Nullish<ISavedSearchSnippet>,
};

export const slice = createSlice({
  name: 'navigation-tabs',
  initialState,
  reducers: {
    setCurrentCompaniesList: (state, { payload }: PayloadAction<Nullish<ICompaniesListSnippet>>) => {
      state.currentCompaniesList = payload;
    },
    setCurrentSavedSearch: (state, { payload }: PayloadAction<Nullish<ISavedSearchSnippet>>) => {
      state.currentSavedSearch = payload;
    },
  },
});

export const { reducer, actions } = slice;

export type TState = typeof initialState;
