import { unionBy } from 'lodash';
import { useLocalStorage } from 'usehooks-ts';

import { LOCAL_STORAGE_KEYS } from '@/constants';
import { useQuerySavedSearches } from '@/hooks/queries/saved-search/use-query-saved-searches';
import { ISavedSearch } from '@/types';

export const useSavedSearches = () => {
  const {
    data: savedSearchesData,
    isFetching: isSavedSearchesFetching,
    isError: isSavedSearchesError,
  } = useQuerySavedSearches();
  const userSavedSearches = savedSearchesData ?? [];
  const [savedSearchesSharedWithUser] = useLocalStorage<ISavedSearch[]>(
    LOCAL_STORAGE_KEYS.SEARCHES_SHARED_WITH_USER,
    [],
  );
  const allSavedSearches = unionBy(userSavedSearches, savedSearchesSharedWithUser, 'list_id').sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  return {
    allSavedSearches,
    userSavedSearches,
    isSavedSearchesFetching,
    isSavedSearchesError,
  };
};
