import React from 'react';

export const IconDoubleCheck = ({ className, style }) => {
  return (
    <svg
      style={style}
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-double-check"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6501 8.0001C1.6501 4.49309 4.49309 1.6501 8.0001 1.6501C9.15779 1.6501 10.2413 1.95931 11.1746 2.49922C11.4854 2.67897 11.883 2.57278 12.0627 2.26204C12.2425 1.95131 12.1363 1.55368 11.8256 1.37393C10.6997 0.722646 9.39242 0.350098 8.0001 0.350098C3.77512 0.350098 0.350098 3.77512 0.350098 8.0001C0.350098 12.2251 3.77512 15.6501 8.0001 15.6501C11.8546 15.6501 15.0423 12.8 15.5727 9.09214C15.6235 8.73677 15.3766 8.40748 15.0212 8.35665C14.6659 8.30582 14.3366 8.55269 14.2858 8.90806C13.8457 11.9847 11.1986 14.3501 8.0001 14.3501C4.49309 14.3501 1.6501 11.5071 1.6501 8.0001ZM11.1422 4.25597C11.3696 3.96597 11.7882 3.91397 12.0776 4.14197C12.3682 4.36864 12.4189 4.78797 12.1916 5.0773L6.97089 11.744C6.84555 11.9046 6.65355 11.9986 6.45089 12H6.44622C6.24422 12 6.05355 11.9086 5.92622 11.7513L3.14689 8.2993C2.91689 8.0133 2.96155 7.5933 3.24889 7.36197C3.53555 7.13064 3.95422 7.1773 4.18622 7.4633L6.43889 10.2613L11.1422 4.25597ZM14.4756 4.25597C14.7029 3.96597 15.1209 3.91397 15.4109 4.14197C15.7016 4.36864 15.7522 4.78797 15.5249 5.0773L10.3042 11.744C10.1789 11.9046 9.98689 11.9986 9.78422 12H9.77955C9.57755 12 9.38689 11.9086 9.25955 11.7513L8.52622 10.84L9.36955 9.76197L9.77222 10.2613L14.4756 4.25597ZM6.80869 8.70704L7.65335 7.62971L7.51935 7.46371C7.28869 7.17704 6.86935 7.13104 6.58202 7.36171C6.29469 7.59304 6.25002 8.01304 6.48069 8.29971L6.80869 8.70704Z"
      />
    </svg>
  );
};
