import React, { useMemo, useState } from 'react';

import { CircularProgress, Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { FilterHeader } from '../FilterHeader';
import { Loader } from '../../Loader/Loader';
import { KeywordsAccordion } from '../KaywordsAccordion';
import { FiltersAvailableData, FiltersCustomAvailableConfigItem } from '@/types';
import { useQueryCustomAvailableFiltersConfig } from '@/hooks/queries/available-filters/use-query-custom-available-filters-config';
import { useQueryCustomAvailableFiltersById } from '@/hooks/queries/available-filters/use-query-custom-available-filters-by-id';
import { getFilteredFilters, getFlatAvailableFilters } from '@/Utils/filters';

const DEFAULT_TAB: FiltersCustomAvailableConfigItem = {
  title: 'All',
  id: 0,
  slug: 'All',
};

interface IFiltersTabs {
  allFiltersToRender: FiltersAvailableData[];
  searchValue: string;
  blockStatsCall: boolean;
  isSemanticSearch?: boolean;
}

export const FiltersTabs = ({ allFiltersToRender, searchValue, blockStatsCall, isSemanticSearch }: IFiltersTabs) => {
  const [currentTabId, setCurrentTabId] = useState(DEFAULT_TAB.id);
  const { data: customTabsConfigData, isLoading: isCustomTabsConfigDataLoading } =
    useQueryCustomAvailableFiltersConfig();

  const tabs: FiltersCustomAvailableConfigItem[] = [DEFAULT_TAB, ...(customTabsConfigData ?? [])];

  const currentTabIndex = tabs.findIndex((tab) => currentTabId === tab.id);
  const currentTab = tabs[currentTabIndex];
  const isDefaultTab = currentTabIndex === 0;

  const { data: customTabData, isLoading: isCustomTabDataLoading } = useQueryCustomAvailableFiltersById(
    isDefaultTab ? undefined : String(currentTab.id),
  );

  const handleChange = (_: React.SyntheticEvent<Element, Event>, newIndex: number) =>
    setCurrentTabId(tabs[newIndex].id);

  const filtersToRender = useMemo(() => {
    if (isDefaultTab) return allFiltersToRender;

    const flatAllFilters = getFlatAvailableFilters(allFiltersToRender);

    if (!customTabData) return [];

    return (getFilteredFilters(
      customTabData.map(({ items, displayHeader }) => ({
        displayHeader,
        items: items.map((item) => flatAllFilters[item.backendName]).filter((item) => !isNil(item)),
      })),
      searchValue,
    ) ?? []) as FiltersAvailableData[];
  }, [allFiltersToRender, customTabData, isDefaultTab, searchValue]);

  return (
    <>
      <Tabs
        value={currentTabIndex}
        onChange={handleChange}
        className="min-h-[28px] mb-6 px-6"
        classes={{
          indicator: 'bg-[#2E3F4C]',
        }}
      >
        {tabs.map(({ title, id, slug }, index) => (
          <Tab
            key={id}
            label={title}
            id={`FilterPanel${slug || id}`}
            className={classNames(
              'text-sm text-[#2E3F4C] p-0 justify-end mb-2 min-w-[auto] min-h-[auto] mr-6 tracking-wide',
              {
                'font-medium': index === currentTabIndex,
                'font-normal': index !== currentTabIndex,
              },
            )}
          />
        ))}
        {isCustomTabsConfigDataLoading ? <Loader /> : null}
      </Tabs>

      {isDefaultTab ? null : (
        <p className="px-6 text-xs uppercase bg-[#666] h-8 min-h-8 text-white font-medium flex items-center">
          {`${currentTab.title} filters`}
        </p>
      )}

      {tabs.map(({ id }, index) => (
        <div
          key={id}
          role="tabpanel"
          hidden={currentTabIndex !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          <div className="flex flex-col">
            {isCustomTabDataLoading && !isDefaultTab ? (
              <div className="flex justify-center mt-4">
                <CircularProgress />
              </div>
            ) : (
              <>
                <KeywordsAccordion isSemanticSearch={isSemanticSearch} />
                {filtersToRender?.map((filter) => {
                  if (!filter) return null;

                  return (
                    <FilterHeader
                      key={filter.displayHeader}
                      filter={filter}
                      searchValue={searchValue}
                      blockStatsCall={blockStatsCall}
                    />
                  );
                })}

                {!filtersToRender?.length ? <p className="text-center mt-4">No results match your search...</p> : null}
              </>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
