import React from 'react';

export const IconCompareCompanies = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={60} height={60} viewBox="0 0 60 60">
    <rect width="60" height="60" fill="#F5CDCC" rx="20"/>
    <g fill="#C00" clipPath="url(#icc)">
      <path d="m14.153 31.308.813 13.224c.053.854.648 1.443 1.502 1.502 2.215.153 4.43.116 6.64-.11.744-.077 1.588-.63 1.502-1.502-.463-4.67-.84-9.347-1.13-14.03-.048-.763-.651-1.564-1.502-1.501l-6.476.481c-.81.06-1.502.647-1.502 1.502 0 .766.688 1.562 1.502 1.502l6.476-.482-1.502-1.502c.291 4.683.668 9.36 1.13 14.03l1.502-1.502a38.795 38.795 0 0 1-6.64.11l1.501 1.502-.812-13.224c-.05-.81-.655-1.502-1.502-1.502-.774 0-1.552.688-1.502 1.502Zm10.18-7.273.813 20.443c.035.877.665 1.413 1.502 1.502 2.36.251 4.698.146 7.04-.224.608-.097 1.14-.848 1.102-1.448a906.561 906.561 0 0 1-1.13-21.689c-.03-.74-.668-1.597-1.502-1.502l-6.476.744c-.806.093-1.502.624-1.502 1.502 0 .74.69 1.595 1.502 1.502l6.476-.744-1.502-1.502c.29 7.234.667 14.464 1.13 21.689l1.103-1.448c-2.064.326-4.166.338-6.241.117l1.501 1.501-.812-20.444c-.077-1.926-3.08-1.935-3.004 0Zm11.209-8.557.813 28.937c.017.595.45 1.35 1.103 1.448 2.504.378 4.963.313 7.438-.241.616-.138 1.132-.817 1.103-1.448-.464-10.23-.84-20.463-1.13-30.7-.03-1.055-.931-1.605-1.901-1.447-2.159.35-4.318.702-6.476 1.053-.801.13-1.247 1.126-1.05 1.847.235.852 1.045 1.18 1.848 1.049l6.476-1.053-1.9-1.448c.29 10.236.666 20.469 1.13 30.699l1.102-1.449c-1.913.43-3.899.535-5.842.242l1.103 1.448-.813-28.937c-.054-1.928-3.058-1.936-3.004 0Z"/>
    </g>
    <defs>
      <clipPath id="icc">
        <path fill="#fff" d="M14 12h32v34.126H14z"/>
      </clipPath>
    </defs>
  </svg>
);
