import { createSlice } from '@reduxjs/toolkit';
import _, { isEmpty } from 'lodash';

export type TNumericalFilter = {
  min?: number | null;
  max?: number | null;
};

export type TFilter = string[] | TNumericalFilter;

export interface Filters {
  treeFilters: Record<string, string>;
  otherFilters: Record<string, TFilter>;
  includedNullList: string[];
  customAggregationString?: string;
}

const initialState: Filters = {
  treeFilters: {},
  otherFilters: {},
  includedNullList: [],
  customAggregationString: undefined,
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilter: (state, { payload: { id, data } }) => {
      const { customAggregationString } = state;

      if (customAggregationString && !customAggregationString.includes(id)) {
        state.customAggregationString = `( ${customAggregationString} ) AND ${id}`;
      }

      state.otherFilters[id] = data;
    },
    setFilterTree: (state, { payload: { id, data } }) => {
      const { customAggregationString } = state;

      if (customAggregationString && !customAggregationString.includes(id)) {
        state.customAggregationString = `( ${customAggregationString} ) AND ${id}`;
      }

      state.treeFilters[id] = data;
    },
    setCustomAggregationString: (state, { payload }) => {
      state.customAggregationString = payload;
    },
    setFilters: (__, { payload }) => {
      return payload;
    },
    setOtherFilters: (state, { payload }) => {
      return {
        treeFilters: state.treeFilters,
        includedNullList: [],
        otherFilters: payload,
        customAggregationString: state.customAggregationString,
      };
    },
    setIdToNullList: (state, { payload }) => {
      state.includedNullList.includes(payload)
        ? (state.includedNullList = _.without(state.includedNullList, payload))
        : state.includedNullList.push(payload);
    },
    addIdToNullList: (state, { payload }) => {
      state.includedNullList = _.uniq([...state.includedNullList, payload]);
    },
    removeIdFromNullList: (state, { payload }) => {
      state.includedNullList = _.without(state.includedNullList, payload);
    },
    setIdsToNullList: (state, { payload }) => {
      state.includedNullList = _.uniq([...state.includedNullList, ...payload]);
    },
    removeFilter: (state, { payload: { filter, skipCustomAggClear } }) => {
      _.unset(state.otherFilters, filter);

      if (isEmpty(state.otherFilters) && isEmpty(state.treeFilters) && !skipCustomAggClear) {
        state.customAggregationString = undefined;
      }
    },
    removeFilterTree: (state, { payload: { filter, skipCustomAggClear } }) => {
      _.unset(state.treeFilters, filter);

      if (isEmpty(state.otherFilters) && isEmpty(state.treeFilters) && !skipCustomAggClear) {
        state.customAggregationString = undefined;
      }
    },
    resetFilters: () => {
      return initialState;
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

export type TState = typeof initialState;
