import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import { replaceArrayItem } from './utils';
import {
  ELEMENT_ROLES,
  IAggregationElement,
  KEY_CHARS,
} from '@/Components/CompanyResearch/SavedFilters/use-filters-to-display';
import { FilterChip } from '@/Components/CompanyResearch/SavedFilters/FilterChip';
import { useMenu } from '@/hooks/use-menu';

interface IFilterChipWithDropdown {
  filtersToDisplay: IAggregationElement[];
  label: string;
  backendName: string;
  setCurrentAggregation: React.Dispatch<React.SetStateAction<IAggregationElement[] | null>>;
  index: number;
}

export const FilterChipWithDropdown = ({
  filtersToDisplay,
  label,
  backendName,
  setCurrentAggregation,
  index,
}: IFilterChipWithDropdown) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  const plusButtonClassName =
    'transition delay-150 group-hover:opacity-90 text-[#fff] opacity-0 bg-[#2E3F4C] w-1/4 flex absolute items-center justify-center h-full top-0 max-w-10';

  const onAddBracket = (side: 'left' | 'right') => {
    const isLeft = side === 'left';
    const bracketValue = isLeft ? KEY_CHARS.OPENING_BRACKET : KEY_CHARS.CLOSING_BRACKET;

    setCurrentAggregation((prevState) => {
      if (!prevState) return null;

      const arrayCopy = cloneDeep(prevState);

      arrayCopy.splice(isLeft ? index : index + 1, 0, {
        value: bracketValue,
        role: ELEMENT_ROLES.keychar,
      });

      return arrayCopy;
    });
  };

  return (
    <>
      <div className="relative group cursor-pointer">
        <FilterChip
          label={label}
          onClick={handleClick}
        />

        <button
          onClick={() => onAddBracket('left')}
          className={classnames(plusButtonClassName, 'rounded-tl rounded-bl left-0')}
        >
          <span className="text-base flex items-center text-center mb-[2px]">+</span>
        </button>

        <button
          onClick={() => onAddBracket('right')}
          className={classnames(plusButtonClassName, 'rounded-tr rounded-br right-0')}
        >
          <span className="text-base flex items-center text-center mb-[2px]">+</span>
        </button>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] max-h-[500px]',
        }}
      >
        {filtersToDisplay.map(({ value: filterValue, backendName: filterBackendName }) => {
          const isCurrentKeyword = filterBackendName === backendName;

          return (
            <MenuItem
              key={filterBackendName}
              value={filterBackendName}
              classes={{ root: classnames('p-0 h-5 ', { 'bg-[#0000000a]': isCurrentKeyword }) }}
              onClick={handleClose}
            >
              <button
                onClick={() =>
                  setCurrentAggregation((prevState) => {
                    if (!prevState) return null;

                    return replaceArrayItem(prevState, index, {
                      value: filterValue,
                      role: ELEMENT_ROLES.filter,
                      backendName: filterBackendName,
                    });
                  })
                }
                className="h-full flex items-center px-4 w-[500px]"
              >
                <span className=" truncate">{filterValue}</span>
              </button>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
