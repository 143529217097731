import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isArray, isEmpty, isNil, without } from 'lodash';

import { Autocomplete, Chip, MenuItem, TextField } from '@mui/material';
import { HIDE_FILTERS_COUNT } from '../../../../../../constants';
import { useShallowSelector } from '../../../../../../hooks/use-shallow-selector';
import { actions } from '../../../../../../slices/filters';
import { getHighlightedText } from '../get-highlighted-text';
import { getFilterValueLabel } from '@/Utils/filters';
import { IFilterMultipleSelectData } from '@/types/state/filter-multiple-select';

interface IFilterMultipleSelectChip {
  itemId: string;
  values: IFilterMultipleSelectData[];
  isFetching: boolean;
  searchValue: string;
  withoutCounts?: boolean;
}

export const getLabel = (
  value: string,
  searchValue: string,
  active: number,
  isFetching: boolean,
  withoutCounts?: boolean,
) => {
  if (HIDE_FILTERS_COUNT || withoutCounts) {
    const { text } = getHighlightedText({
      text: getFilterValueLabel(value),
      textToHighlight: searchValue,
    });

    return text;
  }

  const count = isNil(active) ? '(0)' : `(${active?.toLocaleString('en')})`;

  const { text } = getHighlightedText({
    text: `${getFilterValueLabel(value)} ${isFetching ? '' : count}`,
    textToHighlight: searchValue,
    isFetching,
  });

  return text;
};

export const FilterMultipleSelectChip = ({
  itemId,
  values,
  isFetching,
  searchValue,
  withoutCounts,
}: IFilterMultipleSelectChip) => {
  const dispatch = useDispatch();
  const filterData = useShallowSelector((state) => state.filters.otherFilters?.[itemId] ?? []);

  useEffect(() => {
    if (isEmpty(filterData)) {
      dispatch(actions.removeFilter({ filter: itemId }));
    }
  }, [dispatch, filterData, itemId]);

  const handleAutocompleteChange = (_: React.SyntheticEvent<Element, Event>, newValue: IFilterMultipleSelectData[]) => {
    dispatch(
      actions.setFilter({
        id: itemId,
        data: newValue.map(({ value }) => value),
      }),
    );
  };

  const handleDelete = (value: string) => {
    dispatch(
      actions.setFilter({
        id: itemId,
        data: isArray(filterData) ? without(filterData, value) : [],
      }),
    );
  };

  const filteredValues = values.filter((item) => {
    if (!isArray(filterData)) return true;

    return !filterData.includes(item.value);
  });

  return (
    <Autocomplete
      disableCloseOnSelect
      className="mt-4 mb-3"
      renderTags={(tags) =>
        tags.map(({ value, active }) => (
          <Chip
            key={value}
            label={getLabel(value, searchValue, active, isFetching, true)}
            className="text-[#484848] m-0.5 mr-2"
            onDelete={() => handleDelete(value)}
          />
        ))
      }
      multiple
      options={filteredValues}
      getOptionLabel={({ value, active }) => {
        if (HIDE_FILTERS_COUNT) {
          return getFilterValueLabel(value);
        }

        const count = isNil(active) ? '(0)' : `(${active?.toLocaleString('en')})`;

        return `${getFilterValueLabel(value)} ${isFetching ? '' : count}`;
      }}
      value={
        isArray(filterData)
          ? (filterData.map((value) => ({
              value,
              _backend: itemId,
              active: values.find((option) => option.value === value)?.active ?? 0,
            })) as IFilterMultipleSelectData[])
          : undefined
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Please select…"
        />
      )}
      onChange={handleAutocompleteChange}
      renderOption={(props, { value, active }) => {
        return (
          <MenuItem
            key={value}
            value={value}
            {...props}
          >
            {getLabel(value, searchValue, active, isFetching, withoutCounts)}
          </MenuItem>
        );
      }}
    />
  );
};
