import { identity, pickBy } from 'lodash';
import { TFormData, TFormDataApi } from '@/hooks/queries/use-mutation-log-case-code';

export const prepareCaseCodeData = (caseCodeFormData: TFormData, clearEmptyValues = true): TFormDataApi => {
  const caseCodeData = {
    case_code: caseCodeFormData?.caseCode,
    partner_email: caseCodeFormData?.partnerEmail,
    project_type: caseCodeFormData?.projectType,
    project_name: caseCodeFormData?.projectName,
    other_info: caseCodeFormData?.otherInfo,
    other_email: caseCodeFormData?.otherEmail,
  };

  const cleanedCaseCodeData = pickBy(caseCodeData, identity);

  return clearEmptyValues ? cleanedCaseCodeData : caseCodeData;
};
