import React from 'react';

interface IKeycharChip {
  label: string;
  onClick: () => void;
}

export const KeycharChip = ({ label, onClick }: IKeycharChip) => (
  <div className="bg-[#e7cede] rounded px-1.5 h-6 relative flex items-center cursor-pointer group">
    <div className="flex items-center mb-[2px]">{label}</div>
    <button
      onClick={onClick}
      className={
        'w-full transition group-hover:opacity-100 text-[#fff] opacity-0 bg-[#2E3F4C] flex absolute items-center justify-center h-full top-0 left-0 rounded '
      }
    >
      <span className="flex items-center text-center mb-1 text-xs">x</span>
    </button>
  </div>
);
