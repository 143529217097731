import React, { useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

import { Tooltip } from '@mui/material';

interface FilterChipProps {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  onClose?: React.MouseEventHandler<SVGSVGElement>;
  tooltipTitle?: string;
}

export const FilterChip = ({ label, onClick, disabled, onClose, tooltipTitle }: FilterChipProps) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth ?? 0);
  }, []);

  const shouldShowTooltip = width >= 292;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.defaultPrevented) return;
    onClick?.(e);
  };

  const content = (
    <button
      ref={ref}
      onClick={disabled ? undefined : handleClick}
      className={classnames('w-fit max-w-[300px] flex items-center p-1 rounded text-xs bg-[#ECF6FD]', {
        'cursor-pointer': !!onClick,
        'cursor-auto': !onClick,
        'opacity-50': disabled,
      })}
      disabled={disabled}
    >
      <p className="text-left truncate">{label}</p>

      {onClose ? (
        <CloseIcon
          className="ml-1 w-4 h-4 opacity-50 hover:opacity-80 transition"
          onClick={onClose}
        />
      ) : null}
    </button>
  );

  if (shouldShowTooltip || tooltipTitle) {
    return (
      <Tooltip
        classes={{ tooltip: 'max-w-[320px] bg-[#484848] p-2', arrow: 'text-[#484848]' }}
        arrow
        title={tooltipTitle ?? label}
        enterDelay={300}
        enterNextDelay={300}
      >
        <span>{content}</span>
      </Tooltip>
    );
  }

  return content;
};
