import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FinancialDataSourceType } from '@/types';

const initialState = {
  financialDataSource: null as FinancialDataSourceType,
};

export const { reducer, actions } = createSlice({
  name: 'companyProfile',
  initialState,
  reducers: {
    setFinancialDataSource: (state, { payload }: PayloadAction<FinancialDataSourceType>) => {
      state.financialDataSource = payload;
    },
  },
});

export type TState = typeof initialState;
