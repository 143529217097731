import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useShallowSelector } from '../../use-shallow-selector';
import { getCompanyMultiyearFinancials } from '@/services/api/company-profile';

export const useQueryCompanyMultiyearFinancials = (bainId: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.config.currency);
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    ['company-multiyear-financials', bainId, currency],
    () => getCompanyMultiyearFinancials(bainId, currency),
    {
      enabled: isAuth && !!bainId,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      onError: () =>
        enqueueSnackbar('Failed to fetch company multiyear financials data. Please try again or contact support.', {
          variant: 'error',
        }),
    },
  );
};
