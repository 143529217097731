import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Search from '@/Components/CompanyResearch/Search/Search';
import { actions, QueryType } from '@/slices/search';

const CompanyFinder = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setQueryType(QueryType.MATCH));
  }, [dispatch]);

  return (
    <div className="w-1/2 flex flex-col justify-center mt-24 m-auto">
      <div className="mb-12 text-2xl font-medium">What company are you looking for?</div>

      <div>
        <Search isCompanyFinder />
      </div>
    </div>
  )
};

export default CompanyFinder;
