import { useQuery } from '@tanstack/react-query';
import { post } from 'aws-amplify/api';

import { pick } from 'lodash';
import { useObligatoryElasticBody } from './table/use-obligatory-elastic-body';
import { TFilterStats } from '@/types/api/stats';

interface IProps {
  ids: string[];
  enabled: boolean;
  body?: {
    company_search: string;
    is_lucene: boolean;
  };
}

export const useQueryFilterTree = ({ ids, enabled, body }: IProps) => {
  const { currency, body: obligatoryElasticBody } = useObligatoryElasticBody();

  const effectiveBody = {
    ...(body ?? pick(obligatoryElasticBody, ['company_search', 'is_lucene'])),
    include_filters: ids,
  };

  return useQuery(
    ['filter-tree', effectiveBody, currency],
    (): Promise<TFilterStats> =>
      // @ts-ignore
      post({ apiName: 'CDPAPI', path: `/search/stats?currency=${currency}`, options: { body: effectiveBody } }).response.then(res => res.body.json()),
    {
      enabled,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: 1,
    },
  );
};
