import { post } from 'aws-amplify/api';

import {
  ITriggerCompanyOverviewExportBody,
  ITriggerAllSearchResultsDataExportBody,
  ITriggerSelectedCompaniesDataExportBody,
} from '@/types/api/export';

export const triggerCompanyOverviewExport = (body: ITriggerCompanyOverviewExportBody): Promise<void> =>
 // @ts-ignore
  post({ apiName: 'CDPAPI', path: '/company_overview_slides', options: { body } }).response.then(res => res.body.json());

export const triggerSelectedCompaniesDataExport = ({
  currency,
  body,
}: ITriggerSelectedCompaniesDataExportBody): Promise<void> =>
 // @ts-ignore
  post({ apiName: 'CDPAPI', path: `/search/export?currency=${currency}`, options: { body: { ...body, company_profile: true } } }).response.then(res => res.body.json());

export const triggerAllSearchResultsDataExport = ({
  currency,
  body,
}: ITriggerAllSearchResultsDataExportBody): Promise<void> =>
  // @ts-ignore
  post({ apiName: 'CDPAPI', path: `/search/export?currency=${currency}`, options: { body } }).response.then(res => res.body.json());
