import React from 'react';

import { ButtonWithIcon } from '../../Buttons/ButtonWithIcon';
import { IconCopy } from '@/Components/Icons/IconCopy';

interface IProps {
  id: string;
  dataTestId: string;
  onClick: () => void;
  disabled?: boolean;
}

export const DownloadChartImageButton = ({ id, dataTestId, onClick, disabled }: IProps) => (
  <ButtonWithIcon
    tooltipTitle="Download Image"
    id={id}
    onClick={onClick}
    disabled={disabled}
    dataTestId={dataTestId}
  >
    <IconCopy />
  </ButtonWithIcon>
);
