import React from 'react';

export const IconFindCompany = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={60} height={60} viewBox="0 0 60 60">
    <rect width="60" height="60" fill="#B3F5FF" rx="20" />
    <g clipPath="url(#ifc)">
      <path fill="#00A3BF" d="m22.751 50.714-.667-38.735-3.486 1.443 27.88 24.207 1.987-3.412c-3.282-.85-6.567-1.689-9.855-2.515-2.898-.729-6.36-1.963-8.61.78-.964 1.176-1.617 2.617-2.355 3.937l-2.584 4.62-4.97 8.884c-1.284 2.296 2.242 4.357 3.527 2.061 2.981-5.33 5.927-10.682 8.944-15.992.464-.816.656-1.284 1.653-1.154 1.374.18 2.756.662 4.098 1 3.025.762 6.047 1.535 9.066 2.317 1.872.484 3.588-2.023 1.987-3.413l-27.88-24.207c-1.28-1.11-3.518-.425-3.486 1.444l.667 38.735c.045 2.625 4.13 2.634 4.084 0Z" />
    </g>
    <defs>
      <clipPath id="ifc">
        <path fill="#fff" d="M18 10h32v42.986H18z" />
      </clipPath>
    </defs>
  </svg>
);
