import { StarterPackWizardChoices, StarterPackWizardStepItem } from '@/types';

export const itemShouldBeSkipped = (item: StarterPackWizardStepItem, choices: StarterPackWizardChoices) => {
  // Potentially in the future conditions here may refer to filters as well as choices.
  if (item.condition && choices[item.condition?.id]) {
    switch (item.condition.op) {
      // all conditions are inverted to "return early"
      case 'eq': {
        if (choices[item.condition.id] !== item.condition.value) {
          return true;
        }

        break;
      }

      case 'ne': {
        if (choices[item.condition.id] === item.condition.value) {
          return true;
        }

        break;
      }

      default: {
        // allow script to continue
      }
    }
  }

  return false;
};
