import React from 'react';
import { DATA_SOURCE_ID } from '@/constants';

export default function SourceLogo({ idColumn }: { idColumn: DATA_SOURCE_ID }) {
  return (
    <div
      className="w-[24px] h-[24px] bg-no-repeat bg-cover bg-center rounded"
      style={{ backgroundImage: `url(/data-sources/${idColumn}.png)` }}
    />
  );
}
