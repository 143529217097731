import { post } from 'aws-amplify/api';

import { IReqCompanySearch, IResponseCompanySearchItem } from '@/types';

export const autocompleteCompanySearch = ({
  company,
  currency,
}: IReqCompanySearch): Promise<IResponseCompanySearchItem[]> =>
  // @ts-ignore
  post({
    apiName: 'CDPAPI',
    path: `/search/autocomplete?currency=${currency}&granular=true`,
    options: { body: { company_search: company } },
    // @ts-ignore
  }).response.then(res => res.body.json()).then((res) => res?.data);
