import { isNil, range, map, remove, includes } from 'lodash';

import { IColumnMapper, IElasticTableData } from '@/types';

export const getRows = (table: { rows: IElasticTableData[]; isLoading: boolean; placeholderRows?: number }) =>
  table.isLoading
    ? range(table.placeholderRows || 20).map((__, id) => ({ id: -id, bain_id: -id }))
    : table?.rows.reduce<IElasticTableData[]>((acc, { bain_id, ...other }) => {
        // this condition is dubious, maybe we should clean output propertly in tanstack-query
        if (bain_id) {
          acc.push({
            bain_id,
            id: bain_id,
            ...other,
          });
        }

        return acc;
      }, []);

const formatNumber = (num: number) => Intl.NumberFormat('en-US').format(num);

export const getCountText = (totalBainIds?: number) => {
  if (isNil(totalBainIds)) return '';

  return `${formatNumber(totalBainIds)}`;
};

export const prepareColumns = (columnMapper: IColumnMapper[], columnSortOrder: string[]) => [
  ...columnSortOrder,
  ...remove(map(columnMapper, 'Backend Name') ?? [], (col) => !includes(columnSortOrder, col)),
];
