import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { xor } from 'lodash';

import { DEFAULT_PAGE_SIZE, DEFAULT_VISIBLE_COLUMNS, EXTENDED_DEFAULT_COLUMNS } from '@/constants';

const initialState = {
  pageSize: DEFAULT_PAGE_SIZE,
  sortModel: [] as GridSortModel,
  defaultColumns: DEFAULT_VISIBLE_COLUMNS,
  visible: EXTENDED_DEFAULT_COLUMNS,
  filterColumns: [] as string[],
  customColumnsOrder: {} as Record<string, number>,
  isConfidenceLevelChecked: false,
};

export const { reducer, actions } = createSlice({
  name: 'table',
  initialState,
  reducers: {
    toggleConfidenceLevel: (state) => {
      state.isConfidenceLevelChecked = !state.isConfidenceLevelChecked;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSortModel: (state, action: PayloadAction<GridSortModel>) => {
      state.sortModel = action.payload;
    },
    setVisibleColumns: (state, action: PayloadAction<string[]>) => {
      state.visible = action.payload;
    },
    setFilterColumns: (state, action: PayloadAction<string[]>) => {
      state.filterColumns = action.payload;
    },
    toggleFilterColumns: (state, action: PayloadAction<string[]>) => {
      state.filterColumns = xor(state.filterColumns, action.payload);
    },
    setCustomColumnsOrder: (state, action: PayloadAction<Record<string, number>>) => {
      state.customColumnsOrder = action.payload;
    },
    setDefaultColumns: (state, action: PayloadAction<string[]>) => {
      state.defaultColumns = action.payload;
    },
    resetTable: () => initialState,
  },
});

export type TState = typeof initialState;
