import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { useModal } from '@/hooks/use-modal';
import { MODALS } from '@/constants';
import { createSavedSearch } from '@/services/api/saved-search';
import { TSharedElementsMutationPayload } from '@/types/shared-elements';
import { createSavedSearchPath } from '@/Components/Shared/SavedSearch/ShareSavedSearchButton';
import { useSearchSettings } from '@/hooks/saved-search/use-search-settings';

export const useMutationCreateSavedSearch = (resetCallback?: () => void) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useModal(MODALS.CREATE_SAVED_SEARCH);

  const body = useSearchSettings();

  return useMutation({
    mutationKey: ['create-saved-search'],
    mutationFn: (payload: TSharedElementsMutationPayload) => createSavedSearch({ ...payload, ...body }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['saved-searches'] });

      resetCallback?.();
      handleClose();

      enqueueSnackbar(
        <NotificationMessage
          title={'Current search has been saved'}
          href={{
            to: createSavedSearchPath(response.title, response.list_id),
            name: 'Go to the saved search',
          }}
        />,
        { variant: 'success' },
      );
    },
    onError: () => {
      enqueueSnackbar(
        <NotificationMessage title="Failed to save current search. Please try again or contact support." />,
        { variant: 'error' },
      );
    },
  });
};
