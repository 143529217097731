import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { EstimatedSign } from '@/Components/CompanyResearch/CompanyProfile/common/EstimatedSign';
import { BRIGHT_QUERY_DATA_SOURCE } from '@/Utils/text';

interface IProps {
  currentDataSource: string;
  dataSourcesAvailable: string[];
  inputLabelId: string;
  selectLabelId: string;
  selectId: string;
  onChange: (event: SelectChangeEvent<string | null>) => void;
}

export const DataSourceMenu = ({
  currentDataSource,
  dataSourcesAvailable,
  inputLabelId,
  selectLabelId,
  selectId,
  onChange,
}: IProps) => {
  return (
    <div className="flex justify-end">
      <FormControl
        sx={{ minWidth: 150 }}
        size="small"
      >
        <InputLabel id={inputLabelId}>Data Source</InputLabel>
        <Select
          labelId={selectLabelId}
          id={selectId}
          value={currentDataSource}
          label="Data Source"
          onChange={onChange}
          disabled={!dataSourcesAvailable || dataSourcesAvailable.length < 2}
        >
          {dataSourcesAvailable.map((dataSource) => (
            <MenuItem
              value={dataSource}
              key={dataSource}
            >
              <EstimatedSign isEstimated={dataSource === BRIGHT_QUERY_DATA_SOURCE}>{dataSource}</EstimatedSign>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
