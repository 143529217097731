import React from 'react';

export const IconFilters = ({ fill = '#484848' }) => (
  <svg
    width="21"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m9.564 14.547 1.689 1.278v-3.794a.84.84 0 0 1 .1-.396L14.936 5h-8.9l3.435 6.648c.062.119.093.25.093.383v2.516Zm2.522 3.786a.828.828 0 0 1-.503-.169l-3.355-2.537a.833.833 0 0 1-.33-.665v-2.728L3.927 4.55a.833.833 0 0 1 .74-1.216h11.666a.834.834 0 0 1 .734 1.23l-4.148 7.679V17.5a.833.833 0 0 1-.833.833Z"
      fill={fill}
    />
  </svg>
);
