import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { updateCompaniesListCollaborator } from '@/services/api/companies-list';
import { IUpdateCompaniesListCollaboratorVariables } from '@/types';

export const useMutationUpdateCompaniesListCollaborators = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['update-companies-list-collaborators'],
    mutationFn: (payload: IUpdateCompaniesListCollaboratorVariables) => updateCompaniesListCollaborator(payload),
    onSuccess: (_, payload) => {
      enqueueSnackbar(<NotificationMessage title={`${payload.email} has been added as collaborator`} />, {
        variant: 'success',
      });

      queryClient.invalidateQueries({ queryKey: ['companies-list-collaborators'] });
    },
    onError: (_, payload) => {
      enqueueSnackbar(
        <NotificationMessage
          title={`Failed to share list with ${payload.email}. Please try again or contact support.`}
        />,
        { variant: 'error' },
      );
    },
  });
};
