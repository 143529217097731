import React, { PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Accordion from '@mui/material/Accordion';

import { AccordionDetails, AccordionSummary } from '@mui/material';

interface IFilterDrawerAccordion {
  accordionSummaryContent: ReactNode;
  displayHeader: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilterDrawerAccordion = ({
  displayHeader,
  accordionSummaryContent,
  isOpen,
  children,
  setIsOpen,
}: PropsWithChildren<IFilterDrawerAccordion>) => {
  const handleChange = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Accordion
      disableGutters
      TransitionProps={{ unmountOnExit: true }}
      classes={{
        root: 'shadow-[0px_1px_3px_rgba(46,48,48,0.14)] border-t-[1px] rounded-none',
      }}
      onChange={handleChange}
      expanded={isOpen}
    >
      <AccordionSummary
        id={displayHeader}
        aria-controls={displayHeader}
        expandIcon={<ExpandMoreIcon />}
        classes={{
          content: 'my-4',
        }}
        className={classnames('p-0 mx-6', {
          'border-b border-[#ddd] border-solid': isOpen,
        })}
      >
        {accordionSummaryContent}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: 'p-0 px-6 pb-2',
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
