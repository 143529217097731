import { Dispatch, SetStateAction } from 'react';

export const CreateListWorkflowSteps = {
  PICK_CREATE_LIST_OPTION: 'pick-create-list-option',
  PROVIDE_SEMANTIC_SEARCH_DESCRIPTION: 'provide-semantic-search-description',
  LOADING_SCREEN: 'loading-screen',
  SEMANTIC_FILTERS_AND_KEYS: 'semantic-filters-and-keys',
} as const;

export type SemanticSearchWorkflowStep = typeof CreateListWorkflowSteps[keyof typeof CreateListWorkflowSteps];

export type StepProps = {
  isFirstStep: boolean;
  progress: number;
  setStep: Dispatch<SetStateAction<number>>;
  setStepsSet: Dispatch<SetStateAction<Partial<SemanticSearchWorkflowStep>[]>>;
  handleClose: () => void;
};

export const SEMANTIC_OPTION_STEPS = [
  CreateListWorkflowSteps.PICK_CREATE_LIST_OPTION,
  CreateListWorkflowSteps.PROVIDE_SEMANTIC_SEARCH_DESCRIPTION,
];
