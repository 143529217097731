import { IMultyYear } from '@/types';

export const COMPANY_PROFILE_MULTIYEAR: IMultyYear = {
    'Annual_BS': {
        '2011': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 180782000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2012': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 193406000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2013': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 203105000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2014': {
            '0': 7281000000,
            '1': 7286000000,
            '5': 6677000000,
            '9': 6677000000,
            '11': 44858000000,
            '13': 61185000000,
            '16': 117907000000,
            '18': -13115000000,
            '19': 204751000000,
            '20': 204751000000,
            '23': 37415000000,
            '24': 38,
            '26': 1,
            '29': 7670000000,
            '30': 49357000000,
            '31': 56643000000,
            '32': 69345000000,
            '33': 52576000000,
            '34': 121921000000,
            '35': 139473000000,
            '36': 76255000000,
            '37': 82830000000,
            '38': 204751000000,
        },
        '2015': {
            '0': 9135000000,
            '1': 9135000000,
            '5': 6778000000,
            '9': 6778000000,
            '11': 45141000000,
            '13': 63278000000,
            '16': 116655000000,
            '18': -12174000000,
            '19': 203490000000,
            '20': 203490000000,
            '23': 38410000000,
            '24': 38,
            '26': 1,
            '29': 1592000000,
            '30': 41641000000,
            '31': 50776000000,
            '32': 65253000000,
            '33': 52300000000,
            '34': 117553000000,
            '35': 136713000000,
            '36': 81394000000,
            '37': 85937000000,
            '38': 203490000000,
        },
        '2016': {
            '0': 8705000000,
            '1': 8705000000,
            '5': 5624000000,
            '9': 5624000000,
            '11': 44469000000,
            '13': 60239000000,
            '16': 116516000000,
            '18': -11477000000,
            '19': 199581000000,
            '20': 199581000000,
            '23': 38487000000,
            '24': 39,
            '26': 1,
            '29': 2708000000,
            '30': 42067000000,
            '31': 50772000000,
            '32': 64619000000,
            '33': 51351000000,
            '34': 115970000000,
            '35': 134383000000,
            '36': 80546000000,
            '37': 83611000000,
            '38': 199581000000,
        },
        '2017': {
            '0': 6867000000,
            '1': 6867000000,
            '5': 5835000000,
            '9': 5835000000,
            '11': 43046000000,
            '13': 57689000000,
            '16': 114178000000,
            '18': -10619000000,
            '19': 198825000000,
            '20': 198825000000,
            '23': 41433000000,
            '24': 41,
            '26': 1,
            '29': 1099000000,
            '30': 39701000000,
            '31': 46568000000,
            '32': 66928000000,
            '33': 51362000000,
            '34': 118290000000,
            '35': 127103000000,
            '36': 77798000000,
            '37': 80535000000,
            '38': 198825000000,
        },
        '2018': {
            '0': 6756000000,
            '1': 6756000000,
            '5': 5614000000,
            '9': 5614000000,
            '11': 43783000000,
            '13': 59664000000,
            '16': 114818000000,
            '18': -10051000000,
            '19': 204522000000,
            '20': 204522000000,
            '23': 46092000000,
            '24': 43,
            '26': 1,
            '29': 5257000000,
            '30': 39917000000,
            '31': 46673000000,
            '32': 78521000000,
            '33': 45179000000,
            '34': 123700000000,
            '35': 127495000000,
            '36': 77869000000,
            '37': 80822000000,
            '38': 204522000000,
        },
        '2019': {
            '0': 7722000000,
            '1': 7722000000,
            '5': 6283000000,
            '9': 6283000000,
            '11': 44269000000,
            '13': 61897000000,
            '16': 111395000000,
            '18': -10344000000,
            '19': 219295000000,
            '20': 219295000000,
            '23': 47060000000,
            '24': 44,
            '26': 1,
            '29': 5225000000,
            '30': 50739000000,
            '31': 58461000000,
            '32': 77477000000,
            '33': 62184000000,
            '34': 139661000000,
            '35': 138095000000,
            '36': 72496000000,
            '37': 79634000000,
            '38': 219295000000,
        },
        '2020': {
            '0': 9465000000,
            '1': 9465000000,
            '5': 6284000000,
            '9': 6284000000,
            '11': 44435000000,
            '13': 61806000000,
            '16': 127049000000,
            '18': -10705000000,
            '19': 236495000000,
            '20': 236495000000,
            '23': 46973000000,
            '24': 43,
            '26': 1,
            '29': 575000000,
            '30': 63664000000,
            '31': 73129000000,
            '32': 77790000000,
            '33': 77153000000,
            '34': 154943000000,
            '35': 154681000000,
            '36': 74669000000,
            '37': 81552000000,
            '38': 236495000000,
        },
        '2021': {
            '0': 17741000000,
            '1': 17741000000,
            '5': 6516000000,
            '9': 6516000000,
            '11': 44949000000,
            '13': 90067000000,
            '16': 109848000000,
            '18': -10264000000,
            '19': 252496000000,
            '20': 252496000000,
            '23': 49141000000,
            '24': 42,
            '26': 1,
            '29': 224000000,
            '30': 45899000000,
            '31': 63640000000,
            '32': 92645000000,
            '33': 72320000000,
            '34': 164965000000,
            '35': 151171000000,
            '36': 80925000000,
            '37': 87531000000,
            '38': 252496000000,
        },
        '2022': {
            '0': 14760000000,
            '1': 14760000000,
            '5': 8280000000,
            '9': 8280000000,
            '11': 56511000000,
            '13': 81070000000,
            '16': 112624000000,
            '18': -13106000000,
            '19': 244860000000,
            '20': 244860000000,
            '23': 55261000000,
            '24': 43,
            '26': 1,
            '29': 410000000,
            '30': 43658000000,
            '31': 58418000000,
            '32': 87379000000,
            '33': 65590000000,
            '34': 152969000000,
            '35': 150309000000,
            '36': 83253000000,
            '37': 91891000000,
            '38': 244860000000,
        },
        '2023': {
            '0': 8625000000,
            '1': 8625000000,
            '5': 7933000000,
            '9': 7933000000,
            '11': 56576000000,
            '13': 75655000000,
            '16': 119234000000,
            '18': -16857000000,
            '19': 243197000000,
            '20': 243197000000,
            '23': 53742000000,
            '24': 43,
            '26': 1,
            '29': 372000000,
            '30': 52410000000,
            '31': 61035000000,
            '32': 92198000000,
            '33': 67008000000,
            '34': 159206000000,
            '35': 145026000000,
            '36': 76693000000,
            '37': 83991000000,
            '38': 243197000000,
        },
        'statement': {
            '0': 'B/S',
            '1': 'B/S',
            '5': 'B/S',
            '9': 'B/S',
            '11': 'B/S',
            '13': 'B/S',
            '16': 'B/S',
            '18': 'B/S',
            '19': 'B/S',
            '20': 'B/S',
            '23': 'B/S',
            '24': 'B/S',
            '26': 'B/S',
            '29': 'B/S',
            '30': 'B/S',
            '31': 'B/S',
            '32': 'B/S',
            '33': 'B/S',
            '34': 'B/S',
            '35': 'B/S',
            '36': 'B/S',
            '37': 'B/S',
            '38': 'B/S',
        },
        'metric': {
            '0': 'Cash And Equivalents',
            '1': 'Total Cash And Short Term Investments',
            '5': 'Accounts Receivable, Total',
            '9': 'Total Receivables',
            '11': 'Inventory',
            '13': 'Total Current Assets',
            '16': 'Net Property Plant And Equipment',
            '18': 'Capital Expenditure',
            '19': 'Total Assets',
            '20': 'Total Assets',
            '23': 'Accounts Payable, Total',
            '24': 'Average Days Payable Outstanding',
            '26': 'Current Ratio',
            '29': 'Short-term Borrowings',
            '30': 'Net Debt',
            '31': 'Total Debt',
            '32': 'Total Current Liabilities',
            '33': 'Total Long-Term Liabilities',
            '34': 'Total Liabilities - (Standard / Utility Template)',
            '35': 'Total Capital',
            '36': 'Total Common Equity',
            '37': 'Total Equity',
            '38': 'Total Liabilities And Equity',
        },
        'units': {
            '0': 'USD',
            '1': 'USD',
            '5': 'USD',
            '9': 'USD',
            '11': 'USD',
            '13': 'USD',
            '16': 'USD',
            '18': 'USD',
            '19': 'USD',
            '20': 'USD',
            '23': 'USD',
            '24': 'Days',
            '26': 'Ratio',
            '29': 'USD',
            '30': 'USD',
            '31': 'USD',
            '32': 'USD',
            '33': 'USD',
            '34': 'USD',
            '35': 'USD',
            '36': 'USD',
            '37': 'USD',
            '38': 'USD',
        },
        'data_source': {
            '0': 'CapitalIQ',
            '1': 'CapitalIQ',
            '5': 'CapitalIQ',
            '9': 'CapitalIQ',
            '11': 'CapitalIQ',
            '13': 'CapitalIQ',
            '16': 'CapitalIQ',
            '18': 'CapitalIQ',
            '19': 'BrightQuery',
            '20': 'CapitalIQ',
            '23': 'CapitalIQ',
            '24': 'CapitalIQ',
            '26': 'CapitalIQ',
            '29': 'CapitalIQ',
            '30': 'CapitalIQ',
            '31': 'CapitalIQ',
            '32': 'CapitalIQ',
            '33': 'CapitalIQ',
            '34': 'CapitalIQ',
            '35': 'CapitalIQ',
            '36': 'CapitalIQ',
            '37': 'CapitalIQ',
            '38': 'CapitalIQ',
        },
    },
    'Annual_IS': {
        '2011': {
            '2': 421849000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 106903000000,
            '15': '',
            '17': 81361000000,
            '21': 33814000000,
            '22': '',
            '25': '',
            '27': 16389000000,
            '28': '',
        },
        '2012': {
            '2': 446509000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 111516000000,
            '15': '',
            '17': 85025000000,
            '21': 34049000000,
            '22': '',
            '25': '',
            '27': 15699000000,
            '28': '',
        },
        '2013': {
            '2': 468868000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 116506000000,
            '15': '',
            '17': 88748000000,
            '21': 35695000000,
            '22': '',
            '25': '',
            '27': 16999000000,
            '28': '',
        },
        '2014': {
            '2': 476294000000,
            '3': 476294000000,
            '6': 476294000000,
            '10': 2,
            '12': 4,
            '14': 118225000000,
            '15': 118225000000,
            '17': 91353000000,
            '21': 35332000000,
            '22': 35742000000,
            '25': 26872000000,
            '27': 16022000000,
            '28': 16022000000,
        },
        '2015': {
            '2': 485651000000,
            '3': 485651000000,
            '6': 485651000000,
            '10': 2,
            '12': 3,
            '14': 120565000000,
            '15': 120565000000,
            '17': 93418000000,
            '21': 35982000000,
            '22': 36320000000,
            '25': 27147000000,
            '27': 16363000000,
            '28': 16363000000,
        },
        '2016': {
            '2': 482130000000,
            '3': 482130000000,
            '6': 482130000000,
            '10': -1,
            '12': 1,
            '14': 121146000000,
            '15': 121146000000,
            '17': 97041000000,
            '21': 33254000000,
            '22': 33559000000,
            '25': 24105000000,
            '27': 14694000000,
            '28': 14694000000,
        },
        '2017': {
            '2': 485873000000,
            '3': 485873000000,
            '6': 485873000000,
            '10': 1,
            '12': 1,
            '14': 124617000000,
            '15': 124617000000,
            '17': 101853000000,
            '21': 32294000000,
            '22': 32844000000,
            '25': 22764000000,
            '27': 13643000000,
            '28': 13643000000,
        },
        '2018': {
            '2': 500343000000,
            '3': 500343000000,
            '6': 500343000000,
            '10': 3,
            '12': 1,
            '14': 126947000000,
            '15': 126947000000,
            '17': 106510000000,
            '21': 27321000000,
            '22': 31466000000,
            '25': 20937000000,
            '27': 9862000000,
            '28': 9862000000,
        },
        '2019': {
            '2': 514405000000,
            '3': 514405000000,
            '6': 514405000000,
            '10': 3,
            '12': 2,
            '14': 129104000000,
            '15': 129104000000,
            '17': 107147000000,
            '21': 23975000000,
            '22': 32635000000,
            '25': 21957000000,
            '27': 6670000000,
            '28': 6670000000,
        },
        '2020': {
            '2': 523964000000,
            '3': 523964000000,
            '6': 523964000000,
            '10': 2,
            '12': 3,
            '14': 129359000000,
            '15': 129359000000,
            '17': 108791000000,
            '21': 33382000000,
            '22': 32455000000,
            '25': 21468000000,
            '27': 14881000000,
            '28': 14881000000,
        },
        '2021': {
            '2': 559151000000,
            '3': 559151000000,
            '6': 559151000000,
            '10': 7,
            '12': 4,
            '14': 138836000000,
            '15': 138836000000,
            '17': 116288000000,
            '21': 33835000000,
            '22': 33700000000,
            '25': 22548000000,
            '27': 13510000000,
            '28': 13510000000,
        },
        '2022': {
            '2': 572754000000,
            '3': 572754000000,
            '6': 572754000000,
            '10': 2,
            '12': 4,
            '14': 143754000000,
            '15': 143754000000,
            '17': 117812000000,
            '21': 31081000000,
            '22': 39100000000,
            '25': 28442000000,
            '27': 13673000000,
            '28': 13673000000,
        },
        '2023': {
            '2': 611289000000,
            '3': 611289000000,
            '6': 611289000000,
            '10': 7,
            '12': 5,
            '14': 147568000000,
            '15': 147568000000,
            '17': 127140000000,
            '21': 30477000000,
            '22': 35473000000,
            '25': 24528000000,
            '27': 11680000000,
            '28': 11680000000,
        },
        'statement': {
            '2': 'I/S',
            '3': 'I/S',
            '6': 'I/S',
            '10': 'I/S',
            '12': 'I/S',
            '14': 'I/S',
            '15': 'I/S',
            '17': 'I/S',
            '21': 'I/S',
            '22': 'I/S',
            '25': 'I/S',
            '27': 'I/S',
            '28': 'I/S',
        },
        'metric': {
            '2': 'Revenues',
            '3': 'Revenues',
            '6': 'Total Revenues',
            '10': 'Total Revenues, 1 Yr. Growth %',
            '12': 'Total Revenues, 3 Yr. CAGR %',
            '14': 'Gross Profit',
            '15': 'Gross Profit',
            '17': 'Other Operating Expenses',
            '21': 'EBITDA',
            '22': 'EBITDA',
            '25': 'EBIT',
            '27': 'Net Income - (IS)',
            '28': 'Net Income - (IS)',
        },
        'units': {
            '2': 'USD',
            '3': 'USD',
            '6': 'USD',
            '10': '%',
            '12': '%',
            '14': 'USD',
            '15': 'USD',
            '17': 'USD',
            '21': 'USD',
            '22': 'USD',
            '25': 'USD',
            '27': 'USD',
            '28': 'USD',
        },
        'data_source': {
            '2': 'BrightQuery',
            '3': 'CapitalIQ',
            '6': 'CapitalIQ',
            '10': 'CapitalIQ',
            '12': 'CapitalIQ',
            '14': 'BrightQuery',
            '15': 'CapitalIQ',
            '17': 'BrightQuery',
            '21': 'BrightQuery',
            '22': 'CapitalIQ',
            '25': 'CapitalIQ',
            '27': 'BrightQuery',
            '28': 'CapitalIQ',
        },
    },
    'Annual_OTHER': {
        '2011': {
            '4': '',
            '7': 1422122,
            '8': '',
        },
        '2012': {
            '4': '',
            '7': 1448347,
            '8': '',
        },
        '2013': {
            '4': '',
            '7': 1405687,
            '8': '',
        },
        '2014': {
            '4': 2200000,
            '7': 1440034,
            '8': 2200000,
        },
        '2015': {
            '4': 2200000,
            '7': 1442869,
            '8': 2200000,
        },
        '2016': {
            '4': 2300000,
            '7': 1564005,
            '8': 2300000,
        },
        '2017': {
            '4': 2300000,
            '7': 1687608,
            '8': 2300000,
        },
        '2018': {
            '4': 2300000,
            '7': 1526961,
            '8': 2300000,
        },
        '2019': {
            '4': 2200000,
            '7': 1567015,
            '8': 2200000,
        },
        '2020': {
            '4': 2200000,
            '7': 1541877,
            '8': 2200000,
        },
        '2021': {
            '4': 2300000,
            '7': 1736829,
            '8': 2300000,
        },
        '2022': {
            '4': 2300000,
            '7': 2012343,
            '8': 2300000,
        },
        '2023': {
            '4': 2100000,
            '7': 2135172,
            '8': 2100000,
        },
        'statement': {
            '4': 'Other',
            '7': 'Other',
            '8': 'Other',
        },
        'metric': {
            '4': 'Full Time Employees',
            '7': 'Total Employees',
            '8': 'Total Employees',
        },
        'units': {
            '4': '#',
            '7': '#',
            '8': '#',
        },
        'data_source': {
            '4': 'CapitalIQ',
            '7': 'BrightQuery',
            '8': 'CapitalIQ',
        },
    },
    'Quarterly_BS': {
        'statement': {
            '0': 'B/S',
            '1': 'B/S',
            '5': 'B/S',
            '9': 'B/S',
            '11': 'B/S',
            '13': 'B/S',
            '16': 'B/S',
            '18': 'B/S',
            '19': 'B/S',
            '20': 'B/S',
            '23': 'B/S',
            '24': 'B/S',
            '26': 'B/S',
            '29': 'B/S',
            '30': 'B/S',
            '31': 'B/S',
            '32': 'B/S',
            '33': 'B/S',
            '34': 'B/S',
            '35': 'B/S',
            '36': 'B/S',
            '37': 'B/S',
            '38': 'B/S',
        },
        'metric': {
            '0': 'Cash And Equivalents',
            '1': 'Total Cash And Short Term Investments',
            '5': 'Accounts Receivable, Total',
            '9': 'Total Receivables',
            '11': 'Inventory',
            '13': 'Total Current Assets',
            '16': 'Net Property Plant And Equipment',
            '18': 'Capital Expenditure',
            '19': 'Total Assets',
            '20': 'Total Assets',
            '23': 'Accounts Payable, Total',
            '24': 'Average Days Payable Outstanding',
            '26': 'Current Ratio',
            '29': 'Short-term Borrowings',
            '30': 'Net Debt',
            '31': 'Total Debt',
            '32': 'Total Current Liabilities',
            '33': 'Total Long-Term Liabilities',
            '34': 'Total Liabilities - (Standard / Utility Template)',
            '35': 'Total Capital',
            '36': 'Total Common Equity',
            '37': 'Total Equity',
            '38': 'Total Liabilities And Equity',
        },
        'units': {
            '0': 'USD',
            '1': 'USD',
            '5': 'USD',
            '9': 'USD',
            '11': 'USD',
            '13': 'USD',
            '16': 'USD',
            '18': 'USD',
            '19': 'USD',
            '20': 'USD',
            '23': 'USD',
            '24': 'Days',
            '26': 'Ratio',
            '29': 'USD',
            '30': 'USD',
            '31': 'USD',
            '32': 'USD',
            '33': 'USD',
            '34': 'USD',
            '35': 'USD',
            '36': 'USD',
            '37': 'USD',
            '38': 'USD',
        },
        'data_source': {
            '0': 'CapitalIQ',
            '1': 'CapitalIQ',
            '5': 'CapitalIQ',
            '9': 'CapitalIQ',
            '11': 'CapitalIQ',
            '13': 'CapitalIQ',
            '16': 'CapitalIQ',
            '18': 'CapitalIQ',
            '19': 'BrightQuery',
            '20': 'CapitalIQ',
            '23': 'CapitalIQ',
            '24': 'CapitalIQ',
            '26': 'CapitalIQ',
            '29': 'CapitalIQ',
            '30': 'CapitalIQ',
            '31': 'CapitalIQ',
            '32': 'CapitalIQ',
            '33': 'CapitalIQ',
            '34': 'CapitalIQ',
            '35': 'CapitalIQ',
            '36': 'CapitalIQ',
            '37': 'CapitalIQ',
            '38': 'CapitalIQ',
        },
        '2011Q1': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 174043000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2011Q2': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 176944000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2011Q3': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 186890000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2011Q4': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 180782000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2012Q1': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 186225000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2012Q2': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 193872000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2012Q3': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 195244000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2012Q4': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 193406000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2013Q1': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 197100000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2013Q2': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 195661000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2013Q3': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 205818000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2013Q4': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 203105000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2014Q1': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 202187000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2014Q2': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 200969000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2014Q3': {
            '0': '',
            '1': '',
            '5': '',
            '9': '',
            '11': '',
            '13': '',
            '16': '',
            '18': '',
            '19': 209876000000,
            '20': '',
            '23': '',
            '24': '',
            '26': '',
            '29': '',
            '30': '',
            '31': '',
            '32': '',
            '33': '',
            '34': '',
            '35': '',
            '36': '',
            '37': '',
            '38': '',
        },
        '2014Q4': {
            '0': 7281000000,
            '1': 7286000000,
            '5': 6677000000,
            '9': 6677000000,
            '11': 44858000000,
            '13': 61185000000,
            '16': 117907000000,
            '18': -3609000000,
            '19': 204751000000,
            '20': 204751000000,
            '23': 37415000000,
            '24': 38,
            '26': 1,
            '29': 7670000000,
            '30': 49357000000,
            '31': 56643000000,
            '32': 69345000000,
            '33': 52576000000,
            '34': 121921000000,
            '35': 139473000000,
            '36': 76255000000,
            '37': 82830000000,
            '38': 204751000000,
        },
        '2015Q1': {
            '0': 6012000000,
            '1': 6012000000,
            '5': 6096000000,
            '9': 6096000000,
            '11': 45315000000,
            '13': 59687000000,
            '16': 117643000000,
            '18': -2157000000,
            '19': 202517000000,
            '20': 202746000000,
            '23': 36347000000,
            '24': 38,
            '26': 1,
            '29': 3517000000,
            '30': 49554000000,
            '31': 55566000000,
            '32': 67942000000,
            '33': 56605000000,
            '34': 124547000000,
            '35': 133765000000,
            '36': 73088000000,
            '37': 78199000000,
            '38': 202746000000,
        },
        '2015Q2': {
            '0': 6184000000,
            '1': 6184000000,
            '5': 6146000000,
            '9': 6146000000,
            '11': 45451000000,
            '13': 59632000000,
            '16': 118723000000,
            '18': -2956000000,
            '19': 203762000000,
            '20': 203985000000,
            '23': 36828000000,
            '24': 37,
            '26': 1,
            '29': 3516000000,
            '30': 48027000000,
            '31': 54211000000,
            '32': 67152000000,
            '33': 54010000000,
            '34': 121162000000,
            '35': 137034000000,
            '36': 77746000000,
            '37': 82823000000,
            '38': 203985000000,
        },
        '2015Q3': {
            '0': 6718000000,
            '1': 6718000000,
            '5': 6091000000,
            '9': 6091000000,
            '11': 51501000000,
            '13': 65841000000,
            '16': 117492000000,
            '18': -3130000000,
            '19': 207668000000,
            '20': 207889000000,
            '23': 39656000000,
            '24': 37,
            '26': 1,
            '29': 6019000000,
            '30': 49112000000,
            '31': 55830000000,
            '32': 71560000000,
            '33': 52276000000,
            '34': 123836000000,
            '35': 139883000000,
            '36': 79109000000,
            '37': 84053000000,
            '38': 207889000000,
        },
        '2015Q4': {
            '0': 9135000000,
            '1': 9135000000,
            '5': 6778000000,
            '9': 6778000000,
            '11': 45141000000,
            '13': 63278000000,
            '16': 116655000000,
            '18': -3931000000,
            '19': 203490000000,
            '20': 203490000000,
            '23': 38410000000,
            '24': 39,
            '26': 1,
            '29': 1592000000,
            '30': 41641000000,
            '31': 50776000000,
            '32': 65253000000,
            '33': 52300000000,
            '34': 117553000000,
            '35': 136713000000,
            '36': 81394000000,
            '37': 85937000000,
            '38': 203490000000,
        },
        '2016Q1': {
            '0': 7759000000,
            '1': 7759000000,
            '5': 5813000000,
            '9': 5813000000,
            '11': 46310000000,
            '13': 62133000000,
            '16': 115685000000,
            '18': -2203000000,
            '19': 200747000000,
            '20': 200747000000,
            '23': 37224000000,
            '24': 38,
            '26': 1,
            '29': 799000000,
            '30': 42934000000,
            '31': 50693000000,
            '32': 69624000000,
            '33': 51133000000,
            '34': 120757000000,
            '35': 130683000000,
            '36': 76574000000,
            '37': 79990000000,
            '38': 200747000000,
        },
        '2016Q2': {
            '0': 5751000000,
            '1': 5751000000,
            '5': 5275000000,
            '9': 5275000000,
            '11': 45007000000,
            '13': 58132000000,
            '16': 116511000000,
            '18': -2841000000,
            '19': 198620000000,
            '20': 198620000000,
            '23': 37225000000,
            '24': 39,
            '26': 1,
            '29': 1725000000,
            '30': 43940000000,
            '31': 49691000000,
            '32': 65262000000,
            '33': 51234000000,
            '34': 116496000000,
            '35': 131815000000,
            '36': 78871000000,
            '37': 82124000000,
            '38': 198620000000,
        },
        '2016Q3': {
            '0': 6990000000,
            '1': 6990000000,
            '5': 5012000000,
            '9': 5012000000,
            '11': 50706000000,
            '13': 65112000000,
            '16': 116956000000,
            '18': -3179000000,
            '19': 205144000000,
            '20': 205144000000,
            '23': 40553000000,
            '24': 38,
            '26': 1,
            '29': 4960000000,
            '30': 46134000000,
            '31': 53124000000,
            '32': 70492000000,
            '33': 52022000000,
            '34': 122514000000,
            '35': 135754000000,
            '36': 79571000000,
            '37': 82630000000,
            '38': 205144000000,
        },
        '2016Q4': {
            '0': 8705000000,
            '1': 8705000000,
            '5': 5624000000,
            '9': 5624000000,
            '11': 44469000000,
            '13': 60239000000,
            '16': 116516000000,
            '18': -3254000000,
            '19': 199581000000,
            '20': 199581000000,
            '23': 38487000000,
            '24': 40,
            '26': 1,
            '29': 2708000000,
            '30': 42067000000,
            '31': 50772000000,
            '32': 64619000000,
            '33': 51351000000,
            '34': 115970000000,
            '35': 134383000000,
            '36': 80546000000,
            '37': 83611000000,
            '38': 199581000000,
        },
        '2017Q1': {
            '0': 7597000000,
            '1': 7597000000,
            '5': 5187000000,
            '9': 5187000000,
            '11': 44513000000,
            '13': 59097000000,
            '16': 116494000000,
            '18': -2209000000,
            '19': 198705000000,
            '20': 198705000000,
            '23': 37997000000,
            '24': 40,
            '26': 1,
            '29': 3813000000,
            '30': 42952000000,
            '31': 50549000000,
            '32': 70282000000,
            '33': 50665000000,
            '34': 120947000000,
            '35': 128307000000,
            '36': 75183000000,
            '37': 77758000000,
            '38': 198705000000,
        },
        '2017Q2': {
            '0': 7676000000,
            '1': 7676000000,
            '5': 5275000000,
            '9': 5275000000,
            '11': 43453000000,
            '13': 58232000000,
            '16': 115410000000,
            '18': -2410000000,
            '19': 197886000000,
            '20': 197886000000,
            '23': 39902000000,
            '24': 41,
            '26': 1,
            '29': 1932000000,
            '30': 40432000000,
            '31': 48108000000,
            '32': 68122000000,
            '33': 50620000000,
            '34': 118742000000,
            '35': 127252000000,
            '36': 76525000000,
            '37': 79144000000,
            '38': 197886000000,
        },
        '2017Q3': {
            '0': 5939000000,
            '1': 5939000000,
            '5': 5344000000,
            '9': 5344000000,
            '11': 49822000000,
            '13': 63401000000,
            '16': 115088000000,
            '18': -2840000000,
            '19': 206857000000,
            '20': 206857000000,
            '23': 42990000000,
            '24': 41,
            '26': 1,
            '29': 5082000000,
            '30': 44903000000,
            '31': 50842000000,
            '32': 74130000000,
            '33': 52252000000,
            '34': 126382000000,
            '35': 131317000000,
            '36': 77693000000,
            '37': 80475000000,
            '38': 206857000000,
        },
        '2017Q4': {
            '0': 6867000000,
            '1': 6867000000,
            '5': 5835000000,
            '9': 5835000000,
            '11': 43046000000,
            '13': 57689000000,
            '16': 114178000000,
            '18': -3160000000,
            '19': 198825000000,
            '20': 198825000000,
            '23': 41433000000,
            '24': 43,
            '26': 1,
            '29': 1099000000,
            '30': 39701000000,
            '31': 46568000000,
            '32': 66928000000,
            '33': 51362000000,
            '34': 118290000000,
            '35': 127103000000,
            '36': 77798000000,
            '37': 80535000000,
            '38': 198825000000,
        },
        '2018Q1': {
            '0': 6545000000,
            '1': 6545000000,
            '5': 5252000000,
            '9': 5252000000,
            '11': 43361000000,
            '13': 57336000000,
            '16': 114169000000,
            '18': -1990000000,
            '19': 199718000000,
            '20': 199718000000,
            '23': 41367000000,
            '24': 42,
            '26': 1,
            '29': 2617000000,
            '30': 40564000000,
            '31': 47109000000,
            '32': 74193000000,
            '33': 49411000000,
            '34': 123604000000,
            '35': 123223000000,
            '36': 73691000000,
            '37': 76114000000,
            '38': 199718000000,
        },
        '2018Q2': {
            '0': 6469000000,
            '1': 6469000000,
            '5': 5395000000,
            '9': 5395000000,
            '11': 43442000000,
            '13': 56763000000,
            '16': 115353000000,
            '18': -2433000000,
            '19': 201566000000,
            '20': 201566000000,
            '23': 42389000000,
            '24': 42,
            '26': 1,
            '29': 3262000000,
            '30': 41525000000,
            '31': 47994000000,
            '32': 72811000000,
            '33': 49709000000,
            '34': 122520000000,
            '35': 127040000000,
            '36': 76389000000,
            '37': 79046000000,
            '38': 201566000000,
        },
        '2018Q3': {
            '0': 7026000000,
            '1': 7026000000,
            '5': 5865000000,
            '9': 5865000000,
            '11': 50147000000,
            '13': 65368000000,
            '16': 115299000000,
            '18': -2485000000,
            '19': 209414000000,
            '20': 209414000000,
            '23': 47587000000,
            '24': 42,
            '26': 1,
            '29': 5114000000,
            '30': 43254000000,
            '31': 50280000000,
            '32': 80435000000,
            '33': 50073000000,
            '34': 130508000000,
            '35': 129186000000,
            '36': 76145000000,
            '37': 78906000000,
            '38': 209414000000,
        },
        '2018Q4': {
            '0': 6756000000,
            '1': 6756000000,
            '5': 5614000000,
            '9': 5614000000,
            '11': 43783000000,
            '13': 59664000000,
            '16': 114818000000,
            '18': -3143000000,
            '19': 204522000000,
            '20': 204522000000,
            '23': 46092000000,
            '24': 45,
            '26': 1,
            '29': 5257000000,
            '30': 39917000000,
            '31': 46673000000,
            '32': 78521000000,
            '33': 45179000000,
            '34': 123700000000,
            '35': 127495000000,
            '36': 77869000000,
            '37': 80822000000,
            '38': 204522000000,
        },
        '2019Q1': {
            '0': 7885000000,
            '1': 7885000000,
            '5': 4568000000,
            '9': 4568000000,
            '11': 43303000000,
            '13': 59242000000,
            '16': 114800000000,
            '18': -1818000000,
            '19': 204927000000,
            '20': 204927000000,
            '23': 44612000000,
            '24': 44,
            '26': 1,
            '29': 7762000000,
            '30': 38724000000,
            '31': 46609000000,
            '32': 80757000000,
            '33': 45846000000,
            '34': 126603000000,
            '35': 124933000000,
            '36': 75552000000,
            '37': 78324000000,
            '38': 204927000000,
        },
        '2019Q2': {
            '0': 15840000000,
            '1': 15840000000,
            '5': 5002000000,
            '9': 5002000000,
            '11': 41985000000,
            '13': 66370000000,
            '16': 111017000000,
            '18': -2464000000,
            '19': 206062000000,
            '20': 206062000000,
            '23': 43128000000,
            '24': 43,
            '26': 1,
            '29': 444000000,
            '30': 38324000000,
            '31': 54164000000,
            '32': 71683000000,
            '33': 60567000000,
            '34': 132250000000,
            '35': 127976000000,
            '36': 71185000000,
            '37': 73812000000,
            '38': 206062000000,
        },
        '2019Q3': {
            '0': 9174000000,
            '1': 9174000000,
            '5': 5785000000,
            '9': 5785000000,
            '11': 50380000000,
            '13': 69446000000,
            '16': 111349000000,
            '18': -2732000000,
            '19': 226583000000,
            '20': 226583000000,
            '23': 49729000000,
            '24': 42,
            '26': 1,
            '29': 7795000000,
            '30': 52287000000,
            '31': 61461000000,
            '32': 85751000000,
            '33': 61363000000,
            '34': 147114000000,
            '35': 140930000000,
            '36': 71996000000,
            '37': 79469000000,
            '38': 226583000000,
        },
        '2019Q4': {
            '0': 7722000000,
            '1': 7722000000,
            '5': 6283000000,
            '9': 6283000000,
            '11': 44269000000,
            '13': 61897000000,
            '16': 111395000000,
            '18': -3330000000,
            '19': 219295000000,
            '20': 219295000000,
            '23': 47060000000,
            '24': 45,
            '26': 1,
            '29': 5225000000,
            '30': 50739000000,
            '31': 58461000000,
            '32': 77477000000,
            '33': 62184000000,
            '34': 139661000000,
            '35': 138095000000,
            '36': 72496000000,
            '37': 79634000000,
            '38': 219295000000,
        },
        '2020Q1': {
            '0': 9255000000,
            '1': 9255000000,
            '5': 5342000000,
            '9': 5342000000,
            '11': 44751000000,
            '13': 61739000000,
            '16': 125241000000,
            '18': -2205000000,
            '19': 234544000000,
            '20': 234544000000,
            '23': 45110000000,
            '24': 44,
            '26': 1,
            '29': 4828000000,
            '30': 66665000000,
            '31': 75920000000,
            '32': 79888000000,
            '33': 79746000000,
            '34': 159634000000,
            '35': 150830000000,
            '36': 68205000000,
            '37': 74910000000,
            '38': 234544000000,
        },
        '2020Q2': {
            '0': 9283000000,
            '1': 9283000000,
            '5': 5382000000,
            '9': 5382000000,
            '11': 44134000000,
            '13': 61371000000,
            '16': 125862000000,
            '18': -2666000000,
            '19': 234861000000,
            '20': 234861000000,
            '23': 45871000000,
            '24': 43,
            '26': 1,
            '29': 3681000000,
            '30': 66046000000,
            '31': 75329000000,
            '32': 80283000000,
            '33': 77447000000,
            '34': 157730000000,
            '35': 152460000000,
            '36': 70327000000,
            '37': 77131000000,
            '38': 234861000000,
        },
        '2020Q3': {
            '0': 8606000000,
            '1': 8606000000,
            '5': 5612000000,
            '9': 5612000000,
            '11': 51546000000,
            '13': 67912000000,
            '16': 125425000000,
            '18': -2894000000,
            '19': 239830000000,
            '20': 239830000000,
            '23': 49750000000,
            '24': 43,
            '26': 1,
            '29': 4926000000,
            '30': 68001000000,
            '31': 76607000000,
            '32': 83784000000,
            '33': 77739000000,
            '34': 161523000000,
            '35': 154914000000,
            '36': 71649000000,
            '37': 78307000000,
            '38': 239830000000,
        },
        '2020Q4': {
            '0': 9465000000,
            '1': 9465000000,
            '5': 6284000000,
            '9': 6284000000,
            '11': 44435000000,
            '13': 61806000000,
            '16': 127049000000,
            '18': -2940000000,
            '19': 236495000000,
            '20': 236495000000,
            '23': 46973000000,
            '24': 44,
            '26': 1,
            '29': 575000000,
            '30': 63664000000,
            '31': 73129000000,
            '32': 77790000000,
            '33': 77153000000,
            '34': 154943000000,
            '35': 154681000000,
            '36': 74669000000,
            '37': 81552000000,
            '38': 236495000000,
        },
        '2021Q1': {
            '0': 14930000000,
            '1': 14930000000,
            '5': 5029000000,
            '9': 5029000000,
            '11': 41217000000,
            '13': 63328000000,
            '16': 123378000000,
            '18': -1752000000,
            '19': 232892000000,
            '20': 232892000000,
            '23': 44096000000,
            '24': 41,
            '26': 1,
            '29': 4048000000,
            '30': 61657000000,
            '31': 76587000000,
            '32': 82647000000,
            '33': 76135000000,
            '34': 158782000000,
            '35': 150697000000,
            '36': 68240000000,
            '37': 74110000000,
            '38': 232892000000,
        },
        '2021Q2': {
            '0': 16906000000,
            '1': 16906000000,
            '5': 5111000000,
            '9': 5111000000,
            '11': 41084000000,
            '13': 64996000000,
            '16': 122894000000,
            '18': -1817000000,
            '19': 237382000000,
            '20': 237382000000,
            '23': 46326000000,
            '24': 41,
            '26': 1,
            '29': 357000000,
            '30': 53336000000,
            '31': 70242000000,
            '32': 81957000000,
            '33': 74228000000,
            '34': 156185000000,
            '35': 151439000000,
            '36': 75310000000,
            '37': 81197000000,
            '38': 237382000000,
        },
        '2021Q3': {
            '0': 14325000000,
            '1': 14325000000,
            '5': 5770000000,
            '9': 5770000000,
            '11': 51842000000,
            '13': 73602000000,
            '16': 124289000000,
            '18': -2869000000,
            '19': 250863000000,
            '20': 250863000000,
            '23': 54152000000,
            '24': 42,
            '26': 1,
            '29': 240000000,
            '30': 54875000000,
            '31': 69200000000,
            '32': 88121000000,
            '33': 75238000000,
            '34': 163359000000,
            '35': 156704000000,
            '36': 81431000000,
            '37': 87504000000,
            '38': 250863000000,
        },
        '2021Q4': {
            '0': 17741000000,
            '1': 17741000000,
            '5': 6516000000,
            '9': 6516000000,
            '11': 44949000000,
            '13': 90067000000,
            '16': 109848000000,
            '18': -3826000000,
            '19': 252496000000,
            '20': 252496000000,
            '23': 49141000000,
            '24': 44,
            '26': 1,
            '29': 224000000,
            '30': 45899000000,
            '31': 63640000000,
            '32': 92645000000,
            '33': 72320000000,
            '34': 164965000000,
            '35': 151171000000,
            '36': 80925000000,
            '37': 87531000000,
            '38': 252496000000,
        },
        '2022Q1': {
            '0': 22846000000,
            '1': 22846000000,
            '5': 5797000000,
            '9': 5797000000,
            '11': 46383000000,
            '13': 76591000000,
            '16': 108625000000,
            '18': -2214000000,
            '19': 236581000000,
            '20': 236581000000,
            '23': 48151000000,
            '24': 41,
            '26': 1,
            '29': 362000000,
            '30': 40381000000,
            '31': 63227000000,
            '32': 80841000000,
            '33': 71148000000,
            '34': 151989000000,
            '35': 147819000000,
            '36': 78335000000,
            '37': 84592000000,
            '38': 236581000000,
        },
        '2022Q2': {
            '0': 22831000000,
            '1': 22831000000,
            '5': 6103000000,
            '9': 6103000000,
            '11': 47754000000,
            '13': 78243000000,
            '16': 109598000000,
            '18': -2805000000,
            '19': 238552000000,
            '20': 238552000000,
            '23': 49601000000,
            '24': 42,
            '26': 1,
            '29': 671000000,
            '30': 38664000000,
            '31': 61495000000,
            '32': 81122000000,
            '33': 70303000000,
            '34': 151425000000,
            '35': 148622000000,
            '36': 80529000000,
            '37': 87127000000,
            '38': 238552000000,
        },
        '2022Q3': {
            '0': 16111000000,
            '1': 16111000000,
            '5': 7349000000,
            '9': 7349000000,
            '11': 57484000000,
            '13': 82964000000,
            '16': 110331000000,
            '18': -3569000000,
            '19': 244851000000,
            '20': 244851000000,
            '23': 57156000000,
            '24': 43,
            '26': 1,
            '29': 447000000,
            '30': 42367000000,
            '31': 58478000000,
            '32': 87620000000,
            '33': 66474000000,
            '34': 154094000000,
            '35': 149235000000,
            '36': 82274000000,
            '37': 90757000000,
            '38': 244851000000,
        },
        '2022Q4': {
            '0': 14760000000,
            '1': 14760000000,
            '5': 8280000000,
            '9': 8280000000,
            '11': 56511000000,
            '13': 81070000000,
            '16': 112624000000,
            '18': -4518000000,
            '19': 244860000000,
            '20': 244860000000,
            '23': 55261000000,
            '24': 45,
            '26': 1,
            '29': 410000000,
            '30': 43658000000,
            '31': 58418000000,
            '32': 87379000000,
            '33': 65590000000,
            '34': 152969000000,
            '35': 150309000000,
            '36': 83253000000,
            '37': 91891000000,
            '38': 244860000000,
        },
        '2023Q1': {
            '0': 11817000000,
            '1': 11817000000,
            '5': 7674000000,
            '9': 7674000000,
            '11': 61229000000,
            '13': 83220000000,
            '16': 113217000000,
            '18': -3539000000,
            '19': 246142000000,
            '20': 246142000000,
            '23': 52926000000,
            '24': 43,
            '26': 1,
            '29': 11432000000,
            '30': 56660000000,
            '31': 68477000000,
            '32': 96530000000,
            '33': 63752000000,
            '34': 160282000000,
            '35': 154337000000,
            '36': 76896000000,
            '37': 85860000000,
            '38': 246142000000,
        },
        '2023Q2': {
            '0': 13923000000,
            '1': 13923000000,
            '5': 7522000000,
            '9': 7522000000,
            '11': 59921000000,
            '13': 84164000000,
            '16': 114392000000,
            '18': -3953000000,
            '19': 247199000000,
            '20': 247199000000,
            '23': 54191000000,
            '24': 43,
            '26': 1,
            '29': 10634000000,
            '30': 53285000000,
            '31': 67208000000,
            '32': 99899000000,
            '33': 61453000000,
            '34': 161352000000,
            '35': 153055000000,
            '36': 77569000000,
            '37': 85847000000,
            '38': 247199000000,
        },
        '2023Q3': {
            '0': 11587000000,
            '1': 11587000000,
            '5': 8218000000,
            '9': 8218000000,
            '11': 64706000000,
            '13': 87680000000,
            '16': 115544000000,
            '18': -4569000000,
            '19': 247656000000,
            '20': 247656000000,
            '23': 57263000000,
            '24': 43,
            '26': 1,
            '29': 6811000000,
            '30': 56425000000,
            '31': 68012000000,
            '32': 101408000000,
            '33': 65865000000,
            '34': 167273000000,
            '35': 148395000000,
            '36': 72253000000,
            '37': 80383000000,
            '38': 247656000000,
        },
        '2023Q4': {
            '0': 8625000000,
            '1': 8625000000,
            '5': 7933000000,
            '9': 7933000000,
            '11': 56576000000,
            '13': 75655000000,
            '16': 119234000000,
            '18': -4796000000,
            '19': 243197000000,
            '20': 243197000000,
            '23': 53742000000,
            '24': 44,
            '26': 1,
            '29': 372000000,
            '30': 52410000000,
            '31': 61035000000,
            '32': 92198000000,
            '33': 67008000000,
            '34': 159206000000,
            '35': 145026000000,
            '36': 76693000000,
            '37': 83991000000,
            '38': 243197000000,
        },
        '2024Q1': {
            '0': 10575000000,
            '1': 10575000000,
            '5': 7647000000,
            '9': 7647000000,
            '11': 56932000000,
            '13': 78511000000,
            '16': 121138000000,
            '18': -4429000000,
            '19': '',
            '20': 245053000000,
            '23': 54268000000,
            '24': 42,
            '26': 1,
            '29': 1711000000,
            '30': 55483000000,
            '31': 66058000000,
            '32': 95505000000,
            '33': 70083000000,
            '34': 165588000000,
            '35': 145523000000,
            '36': 72405000000,
            '37': 79465000000,
            '38': 245053000000,
        },
        '2024Q2': {
            '0': 13888000000,
            '1': 13888000000,
            '5': 7891000000,
            '9': 7891000000,
            '11': 56722000000,
            '13': 82032000000,
            '16': 123995000000,
            '18': -4787000000,
            '19': '',
            '20': 255121000000,
            '23': 56576000000,
            '24': 42,
            '26': 1,
            '29': 4546000000,
            '30': 52973000000,
            '31': 66861000000,
            '32': 99220000000,
            '33': 70342000000,
            '34': 169562000000,
            '35': 152420000000,
            '36': 79556000000,
            '37': 85559000000,
            '38': 255121000000,
        },
        '2024Q3': {
            '0': 12154000000,
            '1': 12154000000,
            '5': 8625000000,
            '9': 8625000000,
            '11': 63951000000,
            '13': 88391000000,
            '16': 126824000000,
            '18': -5458000000,
            '19': '',
            '20': 259174000000,
            '23': 61049000000,
            '24': 42,
            '26': 1,
            '29': 9942000000,
            '30': 59826000000,
            '31': 71980000000,
            '32': 104230000000,
            '33': 69133000000,
            '34': 173363000000,
            '35': 157791000000,
            '36': 79456000000,
            '37': 85811000000,
            '38': 259174000000,
        },
    },
    'Quarterly_IS': {
        'statement': {
            '2': 'I/S',
            '3': 'I/S',
            '6': 'I/S',
            '10': 'I/S',
            '12': 'I/S',
            '14': 'I/S',
            '15': 'I/S',
            '17': 'I/S',
            '21': 'I/S',
            '22': 'I/S',
            '25': 'I/S',
            '27': 'I/S',
            '28': 'I/S',
        },
        'metric': {
            '2': 'Revenues',
            '3': 'Revenues',
            '6': 'Total Revenues',
            '10': 'Total Revenues, 1 Yr. Growth %',
            '12': 'Total Revenues, 3 Yr. CAGR %',
            '14': 'Gross Profit',
            '15': 'Gross Profit',
            '17': 'Other Operating Expenses',
            '21': 'EBITDA',
            '22': 'EBITDA',
            '25': 'EBIT',
            '27': 'Net Income - (IS)',
            '28': 'Net Income - (IS)',
        },
        'units': {
            '2': 'USD',
            '3': 'USD',
            '6': 'USD',
            '10': '%',
            '12': '%',
            '14': 'USD',
            '15': 'USD',
            '17': 'USD',
            '21': 'USD',
            '22': 'USD',
            '25': 'USD',
            '27': 'USD',
            '28': 'USD',
        },
        'data_source': {
            '2': 'BrightQuery',
            '3': 'CapitalIQ',
            '6': 'CapitalIQ',
            '10': 'CapitalIQ',
            '12': 'CapitalIQ',
            '14': 'BrightQuery',
            '15': 'CapitalIQ',
            '17': 'BrightQuery',
            '21': 'BrightQuery',
            '22': 'CapitalIQ',
            '25': 'CapitalIQ',
            '27': 'BrightQuery',
            '28': 'CapitalIQ',
        },
        '2011Q1': {
            '2': 99811000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 25193000000,
            '15': '',
            '17': 19456000000,
            '21': 7509000000,
            '22': '',
            '25': '',
            '27': 3301000000,
            '28': '',
        },
        '2011Q2': {
            '2': 103726000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 26288000000,
            '15': '',
            '17': 20098000000,
            '21': 7980000000,
            '22': '',
            '25': '',
            '27': 3596000000,
            '28': '',
        },
        '2011Q3': {
            '2': 101952000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 26133000000,
            '15': '',
            '17': 20522000000,
            '21': 7397000000,
            '22': '',
            '25': '',
            '27': 3436000000,
            '28': '',
        },
        '2011Q4': {
            '2': 116360000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 29289000000,
            '15': '',
            '17': 21285000000,
            '21': 10928000000,
            '22': '',
            '25': '',
            '27': 6056000000,
            '28': '',
        },
        '2012Q1': {
            '2': 104189000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 26012000000,
            '15': '',
            '17': 20116000000,
            '21': 7746000000,
            '22': '',
            '25': '',
            '27': 3399000000,
            '28': '',
        },
        '2012Q2': {
            '2': 109366000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 27596000000,
            '15': '',
            '17': 21213000000,
            '21': 8311000000,
            '22': '',
            '25': '',
            '27': 3801000000,
            '28': '',
        },
        '2012Q3': {
            '2': 110226000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 27635000000,
            '15': '',
            '17': 21757000000,
            '21': 7818000000,
            '22': '',
            '25': '',
            '27': 3336000000,
            '28': '',
        },
        '2012Q4': {
            '2': 122728000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 30273000000,
            '15': '',
            '17': 21939000000,
            '21': 10174000000,
            '22': '',
            '25': '',
            '27': 5163000000,
            '28': '',
        },
        '2013Q1': {
            '2': 113010000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 27832000000,
            '15': '',
            '17': 21445000000,
            '21': 8379000000,
            '22': '',
            '25': '',
            '27': 3742000000,
            '28': '',
        },
        '2013Q2': {
            '2': 114282000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 28639000000,
            '15': '',
            '17': 21941000000,
            '21': 8730000000,
            '22': '',
            '25': '',
            '27': 4016000000,
            '28': '',
        },
        '2013Q3': {
            '2': 113800000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 28330000000,
            '15': '',
            '17': 22237000000,
            '21': 8034000000,
            '22': '',
            '25': '',
            '27': 3635000000,
            '28': '',
        },
        '2013Q4': {
            '2': 127776000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 31705000000,
            '15': '',
            '17': 23125000000,
            '21': 10552000000,
            '22': '',
            '25': '',
            '27': 5606000000,
            '28': '',
        },
        '2014Q1': {
            '2': 114070000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 28079000000,
            '15': '',
            '17': 21641000000,
            '21': 8520000000,
            '22': '',
            '25': '',
            '27': 3784000000,
            '28': '',
        },
        '2014Q2': {
            '2': 116830000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 29410000000,
            '15': '',
            '17': 22633000000,
            '21': 8893000000,
            '22': '',
            '25': '',
            '27': 4069000000,
            '28': '',
        },
        '2014Q3': {
            '2': 115688000000,
            '3': '',
            '6': '',
            '10': '',
            '12': '',
            '14': 29001000000,
            '15': '',
            '17': 22691000000,
            '21': 8388000000,
            '22': '',
            '25': '',
            '27': 3738000000,
            '28': '',
        },
        '2014Q4': {
            '2': 129706000000,
            '3': 129706000000,
            '6': 129706000000,
            '10': 2,
            '12': '',
            '14': 31735000000,
            '15': 31735000000,
            '17': 24388000000,
            '21': 9531000000,
            '22': 9617000000,
            '25': 7347000000,
            '27': 4431000000,
            '28': 4431000000,
        },
        '2015Q1': {
            '2': 114960000000,
            '3': 114960000000,
            '6': 114960000000,
            '10': 1,
            '12': 3,
            '14': 28246000000,
            '15': 28246000000,
            '17': 22053000000,
            '21': 8349000000,
            '22': 8443000000,
            '25': 6193000000,
            '27': 3593000000,
            '28': 3593000000,
        },
        '2015Q2': {
            '2': 120125000000,
            '3': 120125000000,
            '6': 120125000000,
            '10': 3,
            '12': 3,
            '14': 30115000000,
            '15': 30115000000,
            '17': 23375000000,
            '21': 9053000000,
            '22': 9017000000,
            '25': 6740000000,
            '27': 4093000000,
            '28': 4093000000,
        },
        '2015Q3': {
            '2': 119001000000,
            '3': 119001000000,
            '6': 119001000000,
            '10': 3,
            '12': 3,
            '14': 29754000000,
            '15': 29754000000,
            '17': 23489000000,
            '21': 8524000000,
            '22': 8619000000,
            '25': 6265000000,
            '27': 3711000000,
            '28': 3711000000,
        },
        '2015Q4': {
            '2': 131565000000,
            '3': 131565000000,
            '6': 131565000000,
            '10': 1,
            '12': 2,
            '14': 32450000000,
            '15': 32450000000,
            '17': 24501000000,
            '21': 10056000000,
            '22': 10490000000,
            '25': 8198000000,
            '27': 4966000000,
            '28': 4966000000,
        },
        '2016Q1': {
            '2': 114826000000,
            '3': 114826000000,
            '6': 114826000000,
            '10': 0,
            '12': 1,
            '14': 28343000000,
            '15': 28343000000,
            '17': 22663000000,
            '21': 8076000000,
            '22': 7999000000,
            '25': 5680000000,
            '27': 3341000000,
            '28': 3341000000,
        },
        '2016Q2': {
            '2': 120229000000,
            '3': 120229000000,
            '6': 120229000000,
            '10': 0,
            '12': '',
            '14': 30173000000,
            '15': 30173000000,
            '17': 24104000000,
            '21': 8272000000,
            '22': 8408000000,
            '25': 6069000000,
            '27': 3475000000,
            '28': 3475000000,
        },
        '2016Q3': {
            '2': 117408000000,
            '3': 117408000000,
            '6': 117408000000,
            '10': -1,
            '12': 1,
            '14': 29962000000,
            '15': 29962000000,
            '17': 24248000000,
            '21': 7990000000,
            '22': 8079000000,
            '25': 5714000000,
            '27': 3304000000,
            '28': 3304000000,
        },
        '2016Q4': {
            '2': 129667000000,
            '3': 129667000000,
            '6': 129667000000,
            '10': -1,
            '12': 0,
            '14': 32668000000,
            '15': 32668000000,
            '17': 26026000000,
            '21': 8916000000,
            '22': 9073000000,
            '25': 6642000000,
            '27': 4574000000,
            '28': 4574000000,
        },
        '2017Q1': {
            '2': 115904000000,
            '3': 115904000000,
            '6': 115904000000,
            '10': 1,
            '12': 1,
            '14': 29360000000,
            '15': 29360000000,
            '17': 24085000000,
            '21': 7550000000,
            '22': 7663000000,
            '25': 5275000000,
            '27': 3079000000,
            '28': 3079000000,
        },
        '2017Q2': {
            '2': 120854000000,
            '3': 120854000000,
            '6': 120854000000,
            '10': 1,
            '12': 1,
            '14': 31369000000,
            '15': 31369000000,
            '17': 25204000000,
            '21': 8588000000,
            '22': 8682000000,
            '25': 6165000000,
            '27': 3773000000,
            '28': 3773000000,
        },
        '2017Q3': {
            '2': 118179000000,
            '3': 118179000000,
            '6': 118179000000,
            '10': 1,
            '12': 1,
            '14': 30695000000,
            '15': 30695000000,
            '17': 25576000000,
            '21': 7444000000,
            '22': 7588000000,
            '25': 5119000000,
            '27': 3034000000,
            '28': 3034000000,
        },
        '2017Q4': {
            '2': 130936000000,
            '3': 130936000000,
            '6': 130936000000,
            '10': 1,
            '12': 0,
            '14': 33193000000,
            '15': 33193000000,
            '17': 26988000000,
            '21': 8712000000,
            '22': 8911000000,
            '25': 6205000000,
            '27': 3757000000,
            '28': 3757000000,
        },
        '2018Q1': {
            '2': 117542000000,
            '3': 117542000000,
            '6': 117542000000,
            '10': 1,
            '12': 1,
            '14': 29854000000,
            '15': 29854000000,
            '17': 24617000000,
            '21': 7710000000,
            '22': 7788000000,
            '25': 5237000000,
            '27': 3039000000,
            '28': 3039000000,
        },
        '2018Q2': {
            '2': 123355000000,
            '3': 123355000000,
            '6': 123355000000,
            '10': 2,
            '12': 1,
            '14': 31834000000,
            '15': 31834000000,
            '17': 25865000000,
            '21': 7632000000,
            '22': 8587000000,
            '25': 5969000000,
            '27': 2899000000,
            '28': 2899000000,
        },
        '2018Q3': {
            '2': 123179000000,
            '3': 123179000000,
            '6': 123179000000,
            '10': 4,
            '12': 1,
            '14': 31632000000,
            '15': 31632000000,
            '17': 26868000000,
            '21': 5965000000,
            '22': 7572000000,
            '25': 4914000000,
            '27': 1749000000,
            '28': 1749000000,
        },
        '2018Q4': {
            '2': 136267000000,
            '3': 136267000000,
            '6': 136267000000,
            '10': 4,
            '12': 1,
            '14': 33627000000,
            '15': 33627000000,
            '17': 29160000000,
            '21': 6014000000,
            '22': 8831000000,
            '25': 6129000000,
            '27': 2175000000,
            '28': 2175000000,
        },
        '2019Q1': {
            '2': 122690000000,
            '3': 122690000000,
            '6': 122690000000,
            '10': 4,
            '12': 2,
            '14': 30983000000,
            '15': 30983000000,
            '17': 25829000000,
            '21': 5888000000,
            '22': 7832000000,
            '25': 5154000000,
            '27': 2134000000,
            '28': 2134000000,
        },
        '2019Q2': {
            '2': 128028000000,
            '3': 128028000000,
            '6': 128028000000,
            '10': 4,
            '12': 2,
            '14': 32457000000,
            '15': 32457000000,
            '17': 26707000000,
            '21': 3472000000,
            '22': 8404000000,
            '25': 5750000000,
            '27': -861000000,
            '28': -861000000,
        },
        '2019Q3': {
            '2': 124894000000,
            '3': 124894000000,
            '6': 124894000000,
            '10': 1,
            '12': 2,
            '14': 31778000000,
            '15': 31778000000,
            '17': 26792000000,
            '21': 5677000000,
            '22': 7601000000,
            '25': 4986000000,
            '27': 1710000000,
            '28': 1710000000,
        },
        '2019Q4': {
            '2': 138793000000,
            '3': 138793000000,
            '6': 138793000000,
            '10': 2,
            '12': 2,
            '14': 33886000000,
            '15': 33886000000,
            '17': 27819000000,
            '21': 8938000000,
            '22': 8958000000,
            '25': 6227000000,
            '27': 3687000000,
            '28': 3687000000,
        },
        '2020Q1': {
            '2': 123925000000,
            '3': 123925000000,
            '6': 123925000000,
            '10': 1,
            '12': 2,
            '14': 30891000000,
            '15': 30891000000,
            '17': 25946000000,
            '21': 8480000000,
            '22': 7659000000,
            '25': 4945000000,
            '27': 3842000000,
            '28': 3842000000,
        },
        '2020Q2': {
            '2': 130377000000,
            '3': 130377000000,
            '6': 130377000000,
            '10': 2,
            '12': 3,
            '14': 32454000000,
            '15': 32454000000,
            '17': 26871000000,
            '21': 8206000000,
            '22': 8305000000,
            '25': 5583000000,
            '27': 3610000000,
            '28': 3610000000,
        },
        '2020Q3': {
            '2': 127991000000,
            '3': 127991000000,
            '6': 127991000000,
            '10': 2,
            '12': 3,
            '14': 32091000000,
            '15': 32091000000,
            '17': 27373000000,
            '21': 7696000000,
            '22': 7741000000,
            '25': 5018000000,
            '27': 3288000000,
            '28': 3288000000,
        },
        '2020Q4': {
            '2': 141671000000,
            '3': 141671000000,
            '6': 141671000000,
            '10': 2,
            '12': 3,
            '14': 33923000000,
            '15': 33923000000,
            '17': 28601000000,
            '21': 9000000000,
            '22': 8750000000,
            '25': 5922000000,
            '27': 4141000000,
            '28': 4141000000,
        },
        '2021Q1': {
            '2': 134622000000,
            '3': 134622000000,
            '6': 134622000000,
            '10': 9,
            '12': 5,
            '14': 32596000000,
            '15': 32596000000,
            '17': 27372000000,
            '21': 8695000000,
            '22': 8015000000,
            '25': 5224000000,
            '27': 3990000000,
            '28': 3990000000,
        },
        '2021Q2': {
            '2': 137742000000,
            '3': 137742000000,
            '6': 137742000000,
            '10': 6,
            '12': 4,
            '14': 35053000000,
            '15': 35053000000,
            '17': 28994000000,
            '21': 12112000000,
            '22': 9230000000,
            '25': 6459000000,
            '27': 6476000000,
            '28': 6476000000,
        },
        '2021Q3': {
            '2': 134708000000,
            '3': 134708000000,
            '6': 134708000000,
            '10': 5,
            '12': 3,
            '14': 34369000000,
            '15': 34369000000,
            '17': 28591000000,
            '21': 10361000000,
            '22': 8549000000,
            '25': 5778000000,
            '27': 5135000000,
            '28': 5135000000,
        },
        '2021Q4': {
            '2': 152079000000,
            '3': 152079000000,
            '6': 152079000000,
            '10': 7,
            '12': 4,
            '14': 36818000000,
            '15': 36818000000,
            '17': 31331000000,
            '21': 2667000000,
            '22': 12306000000,
            '25': 9487000000,
            '27': -2091000000,
            '28': -2091000000,
        },
        '2022Q1': {
            '2': 138310000000,
            '3': 138310000000,
            '6': 138310000000,
            '10': 3,
            '12': 4,
            '14': 35038000000,
            '15': 35038000000,
            '17': 28129000000,
            '21': 6990000000,
            '22': 9570000000,
            '25': 6909000000,
            '27': 2730000000,
            '28': 2730000000,
        },
        '2022Q2': {
            '2': 141048000000,
            '3': 141048000000,
            '6': 141048000000,
            '10': 2,
            '12': 3,
            '14': 35865000000,
            '15': 35865000000,
            '17': 28511000000,
            '21': 8991000000,
            '22': 9995000000,
            '25': 7354000000,
            '27': 4276000000,
            '28': 4276000000,
        },
        '2022Q3': {
            '2': 140525000000,
            '3': 140525000000,
            '6': 140525000000,
            '10': 4,
            '12': 4,
            '14': 35502000000,
            '15': 35502000000,
            '17': 29710000000,
            '21': 7256000000,
            '22': 8442000000,
            '25': 5792000000,
            '27': 3105000000,
            '28': 3105000000,
        },
        '2022Q4': {
            '2': 152871000000,
            '3': 152871000000,
            '6': 152871000000,
            '10': 1,
            '12': 3,
            '14': 37349000000,
            '15': 37349000000,
            '17': 31462000000,
            '21': 7844000000,
            '22': 11093000000,
            '25': 8387000000,
            '27': 3562000000,
            '28': 3562000000,
        },
        '2023Q1': {
            '2': 141569000000,
            '3': 141569000000,
            '6': 141569000000,
            '10': 2,
            '12': 5,
            '14': 34722000000,
            '15': 34722000000,
            '17': 29404000000,
            '21': 5987000000,
            '22': 7998000000,
            '25': 5318000000,
            '27': 2054000000,
            '28': 2054000000,
        },
        '2023Q2': {
            '2': 152859000000,
            '3': 152859000000,
            '6': 152859000000,
            '10': 8,
            '12': 5,
            '14': 37021000000,
            '15': 37021000000,
            '17': 30167000000,
            '21': 9824000000,
            '22': 9553000000,
            '25': 6854000000,
            '27': 5149000000,
            '28': 5149000000,
        },
        '2023Q3': {
            '2': 152813000000,
            '3': 152813000000,
            '6': 152813000000,
            '10': 9,
            '12': 6,
            '14': 37200000000,
            '15': 37200000000,
            '17': 34505000000,
            '21': 1877000000,
            '22': 8750000000,
            '25': 5995000000,
            '27': -1798000000,
            '28': -1798000000,
        },
        '2023Q4': {
            '2': 164048000000,
            '3': 164048000000,
            '6': 164048000000,
            '10': 7,
            '12': 5,
            '14': 38625000000,
            '15': 38625000000,
            '17': 33064000000,
            '21': 12789000000,
            '22': 9172000000,
            '25': 6361000000,
            '27': 6275000000,
            '28': 6275000000,
        },
        '2024Q1': {
            '2': '',
            '3': 152301000000,
            '6': 152301000000,
            '10': 8,
            '12': 4,
            '14': '',
            '15': 37017000000,
            '17': '',
            '21': '',
            '22': 9085000000,
            '25': 6240000000,
            '27': '',
            '28': 1673000000,
        },
        '2024Q2': {
            '2': '',
            '3': 161632000000,
            '6': 161632000000,
            '10': 6,
            '12': 5,
            '14': '',
            '15': 39782000000,
            '17': '',
            '21': '',
            '22': 10221000000,
            '25': 7316000000,
            '27': '',
            '28': 7891000000,
        },
        '2024Q3': {
            '2': '',
            '3': 160804000000,
            '6': 160804000000,
            '10': 5,
            '12': 6,
            '14': '',
            '15': 39621000000,
            '17': '',
            '21': '',
            '22': 9188000000,
            '25': 6202000000,
            '27': '',
            '28': 453000000,
        },
    },
    'Quarterly_OTHER': {
        'statement': {
            '4': 'Other',
            '7': 'Other',
            '8': 'Other',
        },
        'metric': {
            '4': 'Full Time Employees',
            '7': 'Total Employees',
            '8': 'Total Employees',
        },
        'units': {
            '4': '#',
            '7': '#',
            '8': '#',
        },
        'data_source': {
            '4': 'CapitalIQ',
            '7': 'BrightQuery',
            '8': 'CapitalIQ',
        },
        '2011Q1': {
            '4': '',
            '7': 1392160,
            '8': '',
        },
        '2011Q2': {
            '4': '',
            '7': 1400470,
            '8': '',
        },
        '2011Q3': {
            '4': '',
            '7': 1416245,
            '8': '',
        },
        '2011Q4': {
            '4': '',
            '7': 1422122,
            '8': '',
        },
        '2012Q1': {
            '4': '',
            '7': 1398187,
            '8': '',
        },
        '2012Q2': {
            '4': '',
            '7': 1415569,
            '8': '',
        },
        '2012Q3': {
            '4': '',
            '7': 1432529,
            '8': '',
        },
        '2012Q4': {
            '4': '',
            '7': 1448347,
            '8': '',
        },
        '2013Q1': {
            '4': '',
            '7': 1400470,
            '8': '',
        },
        '2013Q2': {
            '4': '',
            '7': 1397880,
            '8': '',
        },
        '2013Q3': {
            '4': '',
            '7': 1396982,
            '8': '',
        },
        '2013Q4': {
            '4': '',
            '7': 1405687,
            '8': '',
        },
        '2014Q1': {
            '4': '',
            '7': 1346347,
            '8': '',
        },
        '2014Q2': {
            '4': '',
            '7': 1374713,
            '8': '',
        },
        '2014Q3': {
            '4': '',
            '7': 1408945,
            '8': '',
        },
        '2014Q4': {
            '4': 2200000,
            '7': 1440034,
            '8': 2200000,
        },
        '2015Q1': {
            '4': 2000000,
            '7': 1406236,
            '8': 2000000,
        },
        '2015Q2': {
            '4': '',
            '7': 1417142,
            '8': '',
        },
        '2015Q3': {
            '4': 2000000,
            '7': 1428912,
            '8': 2000000,
        },
        '2015Q4': {
            '4': 2200000,
            '7': 1442869,
            '8': 2200000,
        },
        '2016Q1': {
            '4': '',
            '7': 1444391,
            '8': '',
        },
        '2016Q2': {
            '4': '',
            '7': 1484936,
            '8': '',
        },
        '2016Q3': {
            '4': 2200000,
            '7': 1521107,
            '8': 2200000,
        },
        '2016Q4': {
            '4': 2300000,
            '7': 1564005,
            '8': 2300000,
        },
        '2017Q1': {
            '4': 2300000,
            '7': 1544115,
            '8': 2300000,
        },
        '2017Q2': {
            '4': 2300000,
            '7': 1588782,
            '8': 2300000,
        },
        '2017Q3': {
            '4': 2400000,
            '7': 1636099,
            '8': 2400000,
        },
        '2017Q4': {
            '4': 2300000,
            '7': 1687608,
            '8': 2300000,
        },
        '2018Q1': {
            '4': 2300000,
            '7': 1555816,
            '8': 2300000,
        },
        '2018Q2': {
            '4': 2300000,
            '7': 1542404,
            '8': 2300000,
        },
        '2018Q3': {
            '4': 2300000,
            '7': 1536995,
            '8': 2300000,
        },
        '2018Q4': {
            '4': 2300000,
            '7': 1526961,
            '8': 2300000,
        },
        '2019Q1': {
            '4': 2300000,
            '7': 1541476,
            '8': 2300000,
        },
        '2019Q2': {
            '4': 2200000,
            '7': 1546301,
            '8': 2200000,
        },
        '2019Q3': {
            '4': 2200000,
            '7': 1557870,
            '8': 2200000,
        },
        '2019Q4': {
            '4': 2200000,
            '7': 1567015,
            '8': 2200000,
        },
        '2020Q1': {
            '4': 2200000,
            '7': 1529037,
            '8': 2200000,
        },
        '2020Q2': {
            '4': 2200000,
            '7': 1531460,
            '8': 2200000,
        },
        '2020Q3': {
            '4': 2200000,
            '7': 1541569,
            '8': 2200000,
        },
        '2020Q4': {
            '4': 2200000,
            '7': 1541877,
            '8': 2200000,
        },
        '2021Q1': {
            '4': 2200000,
            '7': 1541045,
            '8': 2200000,
        },
        '2021Q2': {
            '4': 2200000,
            '7': 1646412,
            '8': 2200000,
        },
        '2021Q3': {
            '4': 2200000,
            '7': 1716091,
            '8': 2200000,
        },
        '2021Q4': {
            '4': 2300000,
            '7': 1736829,
            '8': 2300000,
        },
        '2022Q1': {
            '4': 2200000,
            '7': 1694476,
            '8': 2200000,
        },
        '2022Q2': {
            '4': '',
            '7': 1761448,
            '8': '',
        },
        '2022Q3': {
            '4': 2200000,
            '7': 1854462,
            '8': 2200000,
        },
        '2022Q4': {
            '4': 2300000,
            '7': 2012343,
            '8': 2300000,
        },
        '2023Q1': {
            '4': 2300000,
            '7': 2235927,
            '8': 2300000,
        },
        '2023Q2': {
            '4': 2300000,
            '7': 2200237,
            '8': 2300000,
        },
        '2023Q3': {
            '4': 2300000,
            '7': 2154677,
            '8': 2300000,
        },
        '2023Q4': {
            '4': 2100000,
            '7': 2135172,
            '8': 2100000,
        },
        '2024Q1': {
            '4': 2100000,
            '7': '',
            '8': 2100000,
        },
        '2024Q2': {
            '4': 2100000,
            '7': '',
            '8': 2100000,
        },
        '2024Q3': {
            '4': 2100000,
            '7': '',
            '8': 2100000,
        },
    },
};