import { useMemo } from 'react';
import { isEqual, omit, pick, sortBy } from 'lodash';
import { useSearchSettings } from './use-search-settings';
import { ISavedSearch } from '@/types';
import { QueryType } from '@/slices/search';
import { fixTreeFilters } from '@/Utils/filters';

export const useHasChangedSearchSettings = (savedSearch?: ISavedSearch) => {
  const currentSettings = useSearchSettings();

  return useMemo(() => {
    const isSmartSearch = savedSearch?.query_type === QueryType.SMART;
    const semanticDescription = savedSearch?.semantic_description ? savedSearch?.semantic_description : null;

    const savedSearchSettingsWithoutVisibility = pick(
      { ...savedSearch, filters: fixTreeFilters(savedSearch?.filters) },
      [
        'company_search',
        'query_type',
        'filters',
        'currency',
        'sort_key',
        'sort_order',
        'columns_order',
        'is_unique_company',
        ...(semanticDescription ? ['semantic_description'] : []),
        ...(isSmartSearch ? [] : ['search_query']),
      ],
    );

    const savedSearchColumnsVisibility = savedSearch?.visible_columns ? [...savedSearch?.visible_columns] : [];

    const currentSettingsWithoutVisibility = omit(currentSettings, [
      'visible_columns',
      ...(isSmartSearch ? ['search_query'] : []),
      ...(semanticDescription ? [] : ['semantic_description']),
    ]);

    const currentSettingsColumnsVisibility = currentSettings?.visible_columns
      ? [...currentSettings?.visible_columns]
      : [];

    return (
      !isEqual(savedSearchSettingsWithoutVisibility, currentSettingsWithoutVisibility) ||
      !isEqual(sortBy(savedSearchColumnsVisibility), sortBy(currentSettingsColumnsVisibility))
    );
  }, [currentSettings, savedSearch]);
};
