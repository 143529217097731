import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import hash from 'object-hash';
import { hasIn, isEqual } from 'lodash';
import { ApiError } from 'aws-amplify/api';

import { useObligatoryElasticBody } from './use-obligatory-elastic-body';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { QueryType, actions as searchActions } from '@/slices/search';
import { actions as tableNotPersistActions } from '@/slices/table/table-not-persist';
import { getElasticData } from '@/services/api/elastic-search';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_PAGE_SIZE } from '@/constants';

const INITIAL_BODY = {
  company_search: '',
  is_lucene: true,
  filters: {},
  page_size: DEFAULT_PAGE_SIZE,
};

export const useQueryTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { currency, body: obligatoryBody } = useObligatoryElasticBody();
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const {
    isElasticEnabled,
    queryType,
    isFiltersDrawerOpen,
    pinnedCompanyIds,
    isUniqueCompanySearch,
    backupSearchText,
    isInitialSearchPerformed,
  } = useShallowSelector((state) => state.search);
  const { pageSize } = useShallowSelector((state) => state.table);

  const handle504Error = () => {
    enqueueSnackbar('Service Unavailable. We apologize for the inconvenience. Please try again later.', {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const isKeywordQuery = queryType === QueryType.KEYWORD;

  const body = {
    ...obligatoryBody,
    ...(isUniqueCompanySearch && { unique_company: isUniqueCompanySearch }),
    page_size: pageSize,
  };

  const isInitialCall = isEqual(body, INITIAL_BODY);

  const hashedBody = hash({ currency, body, isFiltersDrawerOpen, pinnedCompanyIds });

  return useInfiniteQuery({
    enabled: isElasticEnabled && isAuth && !isFiltersDrawerOpen,
    keepPreviousData: true,
    queryKey: ['table-elastic', hashedBody],
    queryFn: ({ pageParam }) => {
      if (isInitialCall && !isInitialSearchPerformed) {
        dispatch(searchActions.setIsInitialSearchPerformed(true));
      }

      if (pageParam === undefined) {
        dispatch(tableNotPersistActions.setPageNumber(0));
      }

      return getElasticData({ currency, body, pinnedCompanyIds, pageParam });
    },
    getNextPageParam: (lastPage) => lastPage.page_after,
    retry: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess: () => {
      dispatch(searchActions.setIsElasticEnabled(false));
      dispatch(searchActions.setIsUniqueCompanySearch(false));
    },
    onSettled: () => {
      dispatch(searchActions.setIsElasticEnabled(false));
      dispatch(searchActions.setIsUniqueCompanySearch(false));
    },
    onError: (error: ApiError) => {
      const status = error.response?.statusCode ?? null;

      dispatch(searchActions.setIsElasticEnabled(false));

      if (isKeywordQuery) {
        dispatch(searchActions.setSearchText(backupSearchText));
      }

      if (status === 504) {
        handle504Error();

        return;
      }

      const bodyErr = JSON.parse(error.response?.body ?? '');
      const hasMessage = hasIn(error, ['error', 0]);
      const responseMessage = bodyErr.error;
      const message = hasMessage ? responseMessage : DEFAULT_ERROR_MESSAGE;

      dispatch(searchActions.setSearchQuery(''));

      if (status === 400) {
        const isQueryProblem = message !== DEFAULT_ERROR_MESSAGE;

        enqueueSnackbar(
          <NotificationMessage
            title={isQueryProblem ? 'Your query is not valid' : DEFAULT_ERROR_MESSAGE}
            description={isQueryProblem ? message : undefined}
          />,
          { variant: 'error' },
        );

        return;
      }

      enqueueSnackbar(<NotificationMessage title="Error. Please try again or contact support." />, {
        variant: 'error',
      });
    },
  });
};
