import { Menu, MenuItem } from '@mui/material';
import classnames from 'classnames';
import React from 'react';
import { useMenu } from '@/hooks/use-menu';
import { KEY_WORDS } from '@/Components/CompanyResearch/SavedFilters/use-filters-to-display';

interface IKeywordChip {
  label: string;
  value: string;
  onSelect: (val: string) => void;
}

export const KeywordChip = ({ label, value, onSelect }: IKeywordChip) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  return (
    <>
      <button
        onClick={handleClick}
        className="bg-[#eae6ff] rounded px-1.5 h-6"
      >
        {label}
      </button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        {Object.entries(KEY_WORDS).map(([key, val]) => {
          const isCurrentKeyword = val === value;

          return (
            <MenuItem
              key={val}
              value={val}
              classes={{ root: classnames('p-0 h-5 ', { 'bg-[#0000000a]': isCurrentKeyword }) }}
              onClick={handleClose}
            >
              <button
                onClick={() => onSelect(val)}
                className="w-full h-full flex items-center	px-4	"
              >
                {key}
              </button>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
