import React, { useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import classnames from 'classnames';
import { uniq } from 'lodash';
import { isStringPositiveNumber } from '@/Utils/text';
import { MAX_COMPANIES_ON_THE_LIST } from '@/constants';

export const PredefinedCompaniesTextField = ({ register, disabled, predefinedCompanies, formState }) => {
  const [numberOfRecognizedCompanies, setNumberOfRecognizedCompanies] = useState(0);

  const validate = (value) => {
    if (!value) {
      setNumberOfRecognizedCompanies(0);

      return null;
    }

    const wrongValues = [];

    if (value?.length > MAX_COMPANIES_ON_THE_LIST) {
      return `Adding more than ${MAX_COMPANIES_ON_THE_LIST.toLocaleString('en-US')} Bain ids is not allowed`;
    }

    value.forEach((company) => {
      if (!isStringPositiveNumber(company)) {
        wrongValues.push(company);
      }
    });

    setNumberOfRecognizedCompanies(value.length);

    return wrongValues.length ? `Wrong values: ${wrongValues.join(', ')}` : null;
  };

  const setValueAs = (value) => {
    const trimmedValue = value?.trim?.();

    if (!trimmedValue) return undefined;

    const arrayOfCompanies = trimmedValue
      .replace(/,/g, ' ')
      .replace(/\s+/g, ' ')
      .split(' ')
      .map((company) => company?.trim())
      .filter(Boolean);

    return arrayOfCompanies.length ? uniq(arrayOfCompanies) : undefined;
  };

  const error = formState?.errors?.pinned_company_ids;

  const message = useMemo(() => {
    if (error?.message) return error?.message;

    if (numberOfRecognizedCompanies > 0) return `Recognized ids: ${numberOfRecognizedCompanies}`;

    return '';
  }, [error?.message, numberOfRecognizedCompanies]);

  return (
    <div className="flex flex-col gap-2">
      <span className="font-semibold">Include companies</span>
      <TextField
        placeholder="Optional - provide comma or space separated Bain ids to include companies in your list"
        {...register('pinned_company_ids', {
          required: false,
          shouldUnregister: !predefinedCompanies,
          validate,
          setValueAs,
        })}
        variant="outlined"
        size="small"
        multiline
        rows={5}
        disabled={disabled}
        inputProps={{
          'data-testid': 'data-pinned_company_ids',
        }}
        error={!!error}
      />
      {message ? (
        <span
          className={classnames('text-xs max-h-24 overflow-y-auto', {
            'text-[#D63333]': !!error,
          })}
        >
          {message}
        </span>
      ) : (
        <div className="h-4" />
      )}
    </div>
  );
};
