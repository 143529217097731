import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import { createEditor, Transforms, Editor } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';

import { Keywords } from './Keywords';
import { ActiveFilters } from './ActiveFilters';
import { useSearchCompanies } from '@/hooks/use-search-companies';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { decorate, getSlateText } from '@/Utils/slate';
import { actions, QueryType } from '@/slices/search';
import { actions as filtersActions } from '@/slices/filters';

import { MODALS } from '@/constants';
import { validateSingleQuotationMarks } from '@/Components/CompanyResearch/Search/utils/validate-single-quotation-marks';
import CheckSingleQuotesNotification from '@/Components/CompanyResearch/Search/CheckSingleQuotesNotification/CheckSingleQuotesNotification';
import {
  validateAggregation,
  getAggregationString,
} from '@/Components/CompanyResearch/SavedFilters/use-filters-to-display';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { useSimplifyCustomAggregationString } from '@/hooks/queries/custom-aggregation/use-simplify-custom-aggregation-string';

const getInitialSlateValue = (searchQuery, searchText) => [
  {
    type: 'paragraph',
    children: [{ text: getSlateText(searchQuery, searchText) }],
  },
];

export const AdvancedSearchDialog = () => {
  const searchCompanies = useSearchCompanies();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isOpen, handleClose } = useModal(MODALS.ADVANCED_SEARCH);
  const [editor] = useState(() => withReact(createEditor()));
  const { advancedSearchText, searchQuery, hasSearchError, hasLuceneGrammarError } = useShallowSelector(
    (state) => state.search,
  );

  const initialAggregationString = useShallowSelector((store) => store.filters.customAggregationString);

  const { simplifyCustomAggregationString, isSimplifying } = useSimplifyCustomAggregationString();

  const [currentAggregation, setCurrentAggregation] = useState([]);

  const hasError = hasSearchError || hasLuceneGrammarError;

  const initialSlateValue = getInitialSlateValue(searchQuery, advancedSearchText);

  const dispatch = useDispatch();

  const clearErrors = () => {
    dispatch(actions.setHasLuceneGrammarError(false));
    dispatch(actions.setHasSearchError(false));
  };

  const handleCloseDialog = () => {
    dispatch(actions.setAdvancedSearchText(''));
    clearErrors();

    handleClose();
  };

  useEffect(() => {
    const offset = initialSlateValue[0].children[0].text.length;

    /** Move the cursor to the last editor character  */
    Transforms.select(editor, { offset, path: [0, 0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, searchQuery]);

  useEffect(() => {
    if (isOpen) {
      dispatch(actions.setAdvancedSearchText(initialSlateValue[0].children[0].text));
      clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleChange = (data) => {
    const result = data.reduce((previous, current) => previous + current.children[0].text, '');

    dispatch(actions.setAdvancedSearchText(result.replace(/\s\s+/g, ' ')));
  };

  const proceedSearch = (searchString) => {
    Transforms.insertText(editor, searchString, {
      at: [0, 0],
    });

    searchCompanies(
      { searchText: searchString, queryType: QueryType.KEYWORD },
      { useStateFilters: false, concatPrevQuery: false },
    );
  };

  const afterCheckSearch = (searchString) => {
    closeSnackbar();

    dispatch(actions.setAdvancedSearchText(searchString));

    const aggregationString = getAggregationString(currentAggregation);

    if (!aggregationString) {
      dispatch(filtersActions.resetFilters());
      proceedSearch(searchString);

      return;
    }

    if (initialAggregationString === aggregationString) {
      proceedSearch(searchString);

      return;
    }

    simplifyCustomAggregationString({
      customAggregationStringToSimplify: aggregationString,
      onSuccess: () => proceedSearch(searchString),
    });
  };

  const handleApplyClick = () => {
    const { valid, wrongTextParts, standardizedText } = validateSingleQuotationMarks(advancedSearchText);

    closeSnackbar();

    const validateAggregationMessage = validateAggregation(currentAggregation);

    if (validateAggregationMessage) {
      enqueueSnackbar(<NotificationMessage title={validateAggregationMessage} />, { variant: 'error' });

      return;
    }

    if (!valid && wrongTextParts?.length) {
      enqueueSnackbar(
        <CheckSingleQuotesNotification
          standardizedText={standardizedText}
          wrongTextParts={wrongTextParts}
          onYes={() => afterCheckSearch(standardizedText)}
          onNo={() => afterCheckSearch(standardizedText.replaceAll(/'/g, '"'))}
        />,
        {
          variant: 'info',
          persist: true,
        },
      );

      return;
    }

    afterCheckSearch(standardizedText);
  };

  const handleClearClick = () => {
    clearErrors();

    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    });

    ReactEditor.focus(editor);

    setCurrentAggregation([]);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      classes={{ paper: 'w-9/12 rounded-lg' }}
    >
      <DialogTitle className="text-sm">
        <p className="mb-2 text-base font-medium">Advanced</p>
        <p className="font-normal">
          Arrange your advanced query, switch out operators for filters and keywords or paste in an existing query. Add
          more filters or adjust their values in the Filters panel.
        </p>
      </DialogTitle>

      <CloseIcon
        fontSize="small"
        className="absolute right-5 top-4 cursor-pointer"
        onClick={handleCloseDialog}
      />

      <DialogContent className="text-xs">
        <p className="mb-3 text-[#484848] font-medium text-sm">Active filters</p>
        {isOpen ? (
          <ActiveFilters
            currentAggregation={currentAggregation}
            setCurrentAggregation={setCurrentAggregation}
          />
        ) : null}
        <p className="mb-3 mt-3 text-[#484848] font-medium text-sm">Keywords</p>
        <div
          className={classnames('relative p-2 rounded border ] overflow-y-auto mt-4', {
            'border-[#D63333]': hasError,
            'border-[#ddd]': !hasError,
          })}
        >
          <Slate
            editor={editor}
            value={initialSlateValue}
            onChange={handleChange}
          >
            <Editable
              id="query-editor"
              data-testid="slate-search"
              decorate={decorate}
              className="h-40"
              renderLeaf={({ attributes, children, leaf }) => {
                return (
                  <span
                    className={classnames({
                      'bg-[#ebf9f2]': !leaf.keyword && !leaf.keychar,
                      'text-cgray-150 inline-block rounded text-center': leaf.keyword || leaf.keychar,
                      'w-2': leaf.keychar,
                      'w-8': leaf.keyword,
                      'bg-[#e7cede]': leaf.keychar,
                      'bg-[#eae6ff]': leaf.keyword,
                    })}
                    {...attributes}
                  >
                    {children}
                  </span>
                );
              }}
            />
          </Slate>
        </div>
        <Keywords editor={editor} />

        <div className="mt-6 flex justify-end text-sm gap-3">
          <Button
            className={
              'px-3 py-2 mt-3 mb-2 border-bluegray-900 border border-solid hover:bg-bluegray-900 hover:text-white bg-[#fff] text-bluegray-900 font-graphik text-xs h-[36px]'
            }
            variant="contained"
            onClick={handleClearClick}
            disabled={isSimplifying}
          >
            Clear
          </Button>
          <Button
            className={classnames(
              'px-3 py-2 mt-3 mb-2 border-bluegray-900 border border-solid bg-bluegray-900 hover:opacity-95 text-white font-graphik text-xs h-[36px]',
              {
                'opacity-50': isSimplifying,
              },
            )}
            variant="contained"
            onClick={handleApplyClick}
            disabled={isSimplifying}
          >
            Apply
            {isSimplifying ? (
              <CircularProgress
                className="text-[#ddd] ml-2"
                size={20}
              />
            ) : null}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
