import { combineReducers } from '@reduxjs/toolkit';
import { reducer as userReducer } from './user';
import { reducer as configReducer } from './config';
import { reducer as modalReducer } from './modal';
import { reducer as searchReducer } from './search';
import { reducer as filtersReducer } from './filters';
import { reducer as tableReducer } from './table';
import { reducer as tableNotPersistReducer } from './table/table-not-persist';
import { reducer as navigationTabsReducer } from './navigation-tabs';
import { reducer as similarCompaniesSearchReducer } from './similar-companies';
import { reducer as selectedCompaniesReducer } from './selected-companies';
import { reducer as searchListsTablesReducer } from './search-lists-tables';
import { reducer as companyProfileReducer } from './company-profile';

export const rootReducer = combineReducers({
  user: userReducer,
  config: configReducer,
  modal: modalReducer,
  search: searchReducer,
  filters: filtersReducer,
  table: tableReducer,
  tableNotPersist: tableNotPersistReducer,
  navigationTabs: navigationTabsReducer,
  similarCompaniesSearch: similarCompaniesSearchReducer,
  selectedCompanies: selectedCompaniesReducer,
  searchListsTables: searchListsTablesReducer,
  companyProfile: companyProfileReducer,
});
