export const readStorageValue = (key, storage = window.localStorage) => {
  if (typeof window === 'undefined') {
    console.warn('there is no window object');

    return;
  }

  try {
    const item = storage.getItem(key);

    return item ? JSON.parse(item) : undefined;
  } catch (error) {
    console.warn(`Error reading ${storage} key “${key}”:`, error);

    return;
  }
};

export const setStorageValue = (key, value, storage = window.localStorage) => {
  if (typeof window === 'undefined') {
    console.warn(`Tried setting ${storage} key “${key}” even though environment is not a client`);
  }

  try {
    storage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.warn(`Error setting ${storage} key “${key}”:`, error);
  }
};
