import { unionBy } from 'lodash';
import { useLocalStorage } from 'usehooks-ts';

import { LOCAL_STORAGE_KEYS } from '@/constants';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { ICompaniesList } from '@/types';

export const useCompaniesLists = () => {
  const {
    data: companiesListsData,
    isFetching: isCompaniesListsFetching,
    isError: isCompaniesListsError,
  } = useQueryCompaniesLists();
  const companiesLists = companiesListsData ?? [];
  const userCompaniesLists = companiesLists
    .filter(({ shared_with_me }) => !shared_with_me)
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  const companiesListsSharedWithUserToCollaborate = companiesLists.filter(({ shared_with_me }) => shared_with_me);
  const [readOnlyCompaniesListsSharedWithUser] = useLocalStorage<ICompaniesList[]>(
    LOCAL_STORAGE_KEYS.COMPANIES_LISTS_SHARED_WITH_USER,
    [],
  );

  const sharedWithUserCompaniesLists = unionBy(
    companiesListsSharedWithUserToCollaborate,
    readOnlyCompaniesListsSharedWithUser,
    'list_id',
  ).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

  return {
    isCompaniesListsFetching,
    isCompaniesListsError,
    userCompaniesLists,
    sharedWithUserCompaniesLists,
  };
};
