import React, { MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import { Tooltip } from '@mui/material';
import { useAppliedKeywordsData } from './use-applied-keywords-data';
import { useSearchCompanies } from '@/hooks/use-search-companies';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { astToLogicalBlocks, QueryBlockDetails } from '@/Utils/pegjs/astTreeUtils';
import { AstNode, Position } from '@/types/ast-tree-models';
import { actions, QueryType } from '@/slices/search';
import { MODALS, SEARCH_EVENTS } from '@/constants';

interface QueryBlockProps {
  handleClick: () => void;
  handleCloseClick: React.MouseEventHandler<SVGSVGElement>;
  isKeyWord?: boolean;
  isKeyChar?: boolean;
  isField?: boolean;
  term?: string | null;
}

const QueryBlock = ({ handleClick, handleCloseClick, isKeyWord, isField, isKeyChar, term }: QueryBlockProps) => (
  <button
    onClick={handleClick}
    className={classnames('w-fit flex items-center p-1 rounded text-xs cursor-pointer mt-2 mr-2', {
      'bg-[#ebf9f2]': !isKeyWord && !isKeyChar,
      'bg-[#eae6ff]': isKeyWord,
      'bg-[#e7cede]': isKeyChar,
      'bg-transparent italic': isField,
    })}
    data-testid={`saved-query-${term}`}
  >
    <p className="text-left">{term}</p>

    {!isKeyChar && !isKeyWord && !isField && (
      <CloseIcon
        className="ml-1 w-4 h-4 opacity-50 hover:opacity-80 transition"
        onClick={handleCloseClick}
      />
    )}
  </button>
);

interface SavedQueriesProps {
  alwaysSeparateKeywords?: boolean;
}

export const SavedQueries = ({ alwaysSeparateKeywords = true }: SavedQueriesProps) => {
  const dispatch = useDispatch();
  const [hasChange, setHasChange] = useState(false);
  const searchCompanies = useSearchCompanies();
  const { handleOpen: openAdvancedSearchModal } = useModal(MODALS.ADVANCED_SEARCH);
  const searchQueryTree = useShallowSelector(({ search }) => search.searchQueryTree);
  const searchText = useShallowSelector((state) => state.search.searchText);
  const queryType = useShallowSelector((state) => state.search.queryType);
  const { hasSearchError } = useShallowSelector(({ search }) => search);

  const { numberOfKeywords, formattedAppliedKeywords, handleRemoveAllKeywords } = useAppliedKeywordsData();

  let key = 0;

  useEffect(() => {
    if (hasChange) {
      const isSmartSearch = queryType === QueryType.SMART;

      searchCompanies({
        searchText,
        queryType: QueryType.KEYWORD,
        eventName: isSmartSearch ? SEARCH_EVENTS.REMOVE_QUERY_SMART_SEARCH : SEARCH_EVENTS.REMOVE_QUERY,
      });
      setHasChange(false);
    }
  }, [queryType, searchCompanies, searchText, hasChange]);

  const handleRemove =
    (position: Position): MouseEventHandler =>
    (event) => {
      event.stopPropagation();
      dispatch(actions.removeSearchQuery(position));
      setHasChange(true);
    };

  const handleClick = () => {
    openAdvancedSearchModal();
  };

  const getQueryBlock = ({ term, isKeyWord, isKeyChar, isField, position }: QueryBlockDetails): ReactNode => {
    return (
      <QueryBlock
        key={key++}
        handleClick={handleClick}
        handleCloseClick={handleRemove(position as Position)}
        isField={isField}
        isKeyWord={isKeyWord}
        isKeyChar={isKeyChar}
        term={term}
      />
    );
  };

  const astTreeToView = (node: AstNode) => {
    const blocks = astToLogicalBlocks(node).filter(({ term }) => !!term);

    return blocks.map((block) => getQueryBlock(block));
  };

  const showSavedQueries = !hasSearchError && !isEmpty(searchQueryTree);

  if (!showSavedQueries) return null;

  if (numberOfKeywords < 5 || alwaysSeparateKeywords) return <>{astTreeToView(searchQueryTree)}</>;

  return (
    <Tooltip title={formattedAppliedKeywords}>
      <span>
        <QueryBlock
          handleClick={handleClick}
          handleCloseClick={handleRemoveAllKeywords}
          term={`Keywords (${numberOfKeywords})`}
        />
      </span>
    </Tooltip>
  );
};
