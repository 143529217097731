import React, { FC } from 'react';
import classnames from 'classnames';

import MUIAvatar from '@mui/material/Avatar';

export enum AvatarVariant {
  HEADER,
  DROPDOWN,
}

interface AvatarProps {
  variant: AvatarVariant;
  userEmail?: string | null;
}

export const Avatar: FC<AvatarProps> = ({ variant, userEmail }) => {
  return (
    <MUIAvatar
      className={classnames('bg-[#21A663] text-white ', {
        'w-[28px] h-[28px] text-sm': variant === AvatarVariant.HEADER,
        'w-[40px] h-[40px] text-base font-semibold': variant === AvatarVariant.DROPDOWN,
      })}
    >
      {userEmail?.[0].toLocaleUpperCase()}
    </MUIAvatar>
  );
};
