import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { useSessionStorage } from 'usehooks-ts';
import { useSnackbar } from 'notistack';
import { CASE_CODE_INITIAL_STATE } from '../use-mutation-log-case-code';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { triggerSelectedCompaniesDataExport } from '@/services/api/export';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { ExportEnum } from '@/types/history';
import { useSearchHistory } from '@/hooks/use-save-search-query';
import { prepareCaseCodeData } from '@/Utils/case-code-utils';
import { getEffectiveIncludeNullList } from '@/Utils/filters';

interface IUseMutationTriggerSelectedCompaniesDataExportBody {
  companyProfileIds?: number[];
}

export const useMutationTriggerSelectedCompaniesDataExport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { saveQuery } = useSearchHistory();

  const { currency } = useShallowSelector((state) => state.config);
  const { email } = useShallowSelector((state) => state.user);
  const { selectedCompanies } = useShallowSelector((state) => state.selectedCompanies);
  const { searchQuery, searchQueryTree, queryType, isUniqueCompanySearch } = useShallowSelector(
    (state) => state.search,
  );
  const storedFilters = useShallowSelector((state) => state.filters);

  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);

  const effectiveIncludeNullList = getEffectiveIncludeNullList(includedNullList, storedFilters);

  const { visible: visibleColumns } = useShallowSelector((state) => state.table);

  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);
  const caseCodeFormData = caseCodeData?.formData;
  const caseCodeFlowType = caseCodeData?.flowType;

  return useMutation({
    mutationKey: ['trigger-selected-companies-data-export'],
    mutationFn: ({ companyProfileIds }: IUseMutationTriggerSelectedCompaniesDataExportBody) => {
      const body = {
        email: email ?? '',
        case_data: { ...prepareCaseCodeData(caseCodeFormData), work_stream: caseCodeFlowType },
        summary_columns: visibleColumns.filter((column) => !['__check__', 'id'].includes(column)),
        filters: {
          bain_id: companyProfileIds ?? selectedCompanies.map(Number),
        },
        company_profile: true,
      };

      return triggerSelectedCompaniesDataExport({
        body,
        currency,
      });
    },
    onSuccess: (__, { companyProfileIds }) => {
      enqueueSnackbar(
        <NotificationMessage
          title="Success! You will receive an email with a link to your export."
          description="Exports may take upwards of 10 minutes based on the size of the data.
        Please wait before re-attempting to export."
        />,
        { variant: 'success' },
      );

      if (companyProfileIds) return;

      saveQuery({
        searchText: searchQuery,
        companiesCount: selectedCompanies.length,
        isLucene: !!searchQueryTree,
        isExport: true,
        exportType: ExportEnum.DATA,
        filters: { ...storedFilters, includedNullList: effectiveIncludeNullList },
        isUniq: isUniqueCompanySearch,
        queryType,
        selectedCompanies: selectedCompanies.join(', '),
      });
    },
    onError: () =>
      enqueueSnackbar(<NotificationMessage title="Error. Please try again or contact support." />, {
        variant: 'error',
      }),
  });
};
