import { useQuery } from '@tanstack/react-query';

import { useDispatch } from 'react-redux';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';

import { useLocalStorage } from 'usehooks-ts';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/navigation-tabs';
import { getSavedSearches } from '@/services/api/saved-search';
import { ISavedSearch } from '@/types';
import { LOCAL_STORAGE_KEYS } from '@/constants';

export const useQuerySavedSearches = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { currentSavedSearch } = useShallowSelector((state) => state.navigationTabs);
  const isAuth = useShallowSelector((state) => state.user.isAuth);

  const [savedSearchesSharedWithUser] = useLocalStorage<ISavedSearch[]>(
    LOCAL_STORAGE_KEYS.SEARCHES_SHARED_WITH_USER,
    [],
  );

  return useQuery(['saved-searches'], getSavedSearches, {
    enabled: isAuth,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
    onSuccess: (savedSearches) => {
      if (currentSavedSearch) {
        return;
      }

      const userSavedSearches = [...savedSearches, ...savedSearchesSharedWithUser];

      const sortedList = orderBy(userSavedSearches, (list) => new Date(list.created_at), ['desc']);

      dispatch(actions.setCurrentSavedSearch(sortedList[0] ?? null));
    },
    onError: () =>
      enqueueSnackbar('Failed to fetch saved searches. Please try again or contact support.', { variant: 'error' }),
  });
};
