import React from 'react';

export const IconAlert = ({ className, style }) => {
  return (
    <svg
      style={style}
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33333 6.00004C7.33333 5.63204 7.632 5.33337 8 5.33337C8.368 5.33337 8.66667 5.63204 8.66667 6.00004V8.66671C8.66667 9.03471 8.368 9.33337 8 9.33337C7.632 9.33337 7.33333 9.03471 7.33333 8.66671V6.00004ZM7.33333 10.6667C7.33333 10.2987 7.632 10 8 10C8.368 10 8.66667 10.2987 8.66667 10.6667C8.66667 11.0347 8.368 11.3334 8 11.3334C7.632 11.3334 7.33333 11.0347 7.33333 10.6667ZM13.9098 12.2354C13.7571 12.5054 13.4598 12.6667 13.1145 12.6667H2.88513C2.5398 12.6667 2.24247 12.5054 2.09047 12.2354C2.01847 12.1087 1.9238 11.8507 2.1018 11.5574L7.2158 3.07871C7.52713 2.56271 8.47247 2.56271 8.7838 3.07871L13.8985 11.5574C14.0758 11.8507 13.9818 12.1087 13.9098 12.2354ZM15.0405 10.8687L9.9258 2.38937C9.52647 1.72804 8.80647 1.33337 7.9998 1.33337C7.19313 1.33337 6.47313 1.72804 6.07447 2.38937L0.959799 10.8687C0.580466 11.4974 0.569133 12.2534 0.929133 12.8907C1.31513 13.5754 2.06513 14 2.88513 14H13.1145C13.9351 14 14.6845 13.5754 15.0711 12.8907C15.4311 12.2534 15.4198 11.4974 15.0405 10.8687Z"
      />
    </svg>
  );
};
