export const PIVOT_TABLE_BAIN_ID = 12345;

export const COMPANY_PROFILE2_PIVOT_TABLE = {
  data: [
    {
      bain_id: PIVOT_TABLE_BAIN_ID,
      DOMAIN: 'stock.walmart.com',
      UNITS: '#',
      YEAR_MONTH: '2023-11',
      TOTAL_VISITS: 210489,
    },
    {
      bain_id: PIVOT_TABLE_BAIN_ID,
      DOMAIN: 'stock.walmart.com',
      UNITS: '#',
      YEAR_MONTH: '2023-08',
      TOTAL_VISITS: 160766,
    },
    {
      bain_id: PIVOT_TABLE_BAIN_ID,
      DOMAIN: 'stock.walmart.com',
      UNITS: '#',
      YEAR_MONTH: '2023-07',
      TOTAL_VISITS: 160129,
    },
    {
      bain_id: PIVOT_TABLE_BAIN_ID,
      DOMAIN: 'stock.walmart.com',
      UNITS: '#',
      YEAR_MONTH: '2023-09',
      TOTAL_VISITS: 149548,
    },
    {
      bain_id: PIVOT_TABLE_BAIN_ID,
      DOMAIN: 'stock.walmart.com',
      UNITS: '#',
      YEAR_MONTH: '2023-12',
      TOTAL_VISITS: 179463,
    },
    {
      bain_id: PIVOT_TABLE_BAIN_ID,
      DOMAIN: 'stock.walmart.com',
      UNITS: '#',
      YEAR_MONTH: '2023-10',
      TOTAL_VISITS: 184747,
    },
  ],
};