import React from 'react';

export const IconSharableElementNotFound = () => (
  <svg
    width="600"
    height="446"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M552.759 108.338C484.66 13.576 340.805-24.864 199.328 16.536 124.533 38.423 52.417 82.278 16.678 167.194-47.21 318.991 86.93 367.471 174.642 382.922c42.413 7.471 82.359 20.022 118.862 37.109 65.672 30.741 181.916 57.981 269.571-70.071 63.533-92.812 35.045-178.501-10.316-241.622Z"
      fill="url(#a)"
    />
    <g clipPath="url(#b)">
      <path
        d="M337.198 295.228c63.852 0 115.614-51.762 115.614-115.614C452.812 115.762 401.05 64 337.198 64c-63.852 0-115.614 51.762-115.614 115.614 0 63.852 51.762 115.614 115.614 115.614Z"
        fill="#3F3D56"
      />
      <path
        opacity=".05"
        d="M337.198 275.057c52.712 0 95.443-42.731 95.443-95.443 0-52.712-42.731-95.443-95.443-95.443-52.712 0-95.443 42.731-95.443 95.443 0 52.712 42.731 95.443 95.443 95.443Z"
        fill="#000"
      />
      <path
        opacity=".05"
        d="M337.198 257.838c43.202 0 78.224-35.022 78.224-78.224 0-43.202-35.022-78.224-78.224-78.224-43.202 0-78.224 35.022-78.224 78.224 0 43.202 35.022 78.224 78.224 78.224Z"
        fill="#000"
      />
      <path
        opacity=".05"
        d="M337.198 235.699c30.975 0 56.085-25.11 56.085-56.085s-25.11-56.085-56.085-56.085-56.085 25.11-56.085 56.085 25.11 56.085 56.085 56.085Z"
        fill="#000"
      />
      <path
        d="M222.608 189.961s-9.09 25.585-5.05 34.675c4.04 9.09 10.436 17.843 10.436 17.843s-2.357-50.835-5.386-52.518Z"
        fill="#D0CDE1"
      />
      <path
        opacity=".1"
        d="M222.608 189.961s-9.09 25.585-5.05 34.675c4.04 9.09 10.436 17.843 10.436 17.843s-2.357-50.835-5.386-52.518Z"
        fill="#000"
      />
      <path
        d="M229.004 249.211s-.673 6.397-1.01 6.733c-.337.337.337 1.01 0 2.02s-.673 2.357 0 2.694c.673.336-3.703 29.961-3.703 29.961s-10.773 14.14-6.397 36.359l1.347 22.555s10.436.674 10.436-3.029c0 0-.673-4.377-.673-6.397 0-2.02 1.683-2.02.673-3.03-1.01-1.01-1.01-1.683-1.01-1.683s1.684-1.346 1.347-1.683c-.337-.337 3.03-24.239 3.03-24.239s3.703-3.703 3.703-5.723v-2.02s1.683-4.376 1.683-4.713c0-.337 9.09-20.873 9.09-20.873l3.703 14.813 4.04 21.209s2.02 19.189 6.059 26.596c0 0 7.07 24.239 7.07 23.565 0-.673 11.783-2.356 11.446-5.386-.336-3.03-7.069-45.448-7.069-45.448l1.683-62.954-45.448.673Z"
        fill="#C00"
      />
      <path
        d="M219.914 347.85s-9.089 17.843-3.03 18.516c6.06.673 8.417.673 11.11-2.02 1.472-1.472 4.454-3.448 6.855-4.936a8.869 8.869 0 0 0 4.206-8.429c-.178-1.647-.793-3.004-2.308-3.131-4.04-.336-8.753-4.039-8.753-4.039l-8.08 4.039Zm50.161 13.13s-9.089 17.842-3.029 18.516c6.059.673 8.416.673 11.109-2.02 1.472-1.473 4.454-3.448 6.855-4.937a8.86 8.86 0 0 0 4.206-8.429c-.178-1.647-.793-3.004-2.308-3.13-4.04-.337-8.753-4.04-8.753-4.04l-8.08 4.04Z"
        fill="#2F2E41"
      />
      <path
        d="M260.469 160.762c7.815 0 14.151-6.336 14.151-14.151 0-7.816-6.336-14.152-14.151-14.152-7.816 0-14.152 6.336-14.152 14.152 0 7.815 6.336 14.151 14.152 14.151Z"
        fill="#FFB8B8"
      />
      <path
        d="M251.371 151.26s-10.108 18.599-10.917 18.599c-.808 0 18.195 6.065 18.195 6.065s5.256-17.79 6.065-19.407l-13.343-5.257Z"
        fill="#FFB8B8"
      />
      <path
        d="M266.877 171.613s-20.199-11.109-22.219-10.772c-2.02.336-23.565 19.189-23.229 26.932.337 7.743 3.03 20.535 3.03 20.535s1.01 35.685 3.03 36.022c2.02.337-.337 6.396.337 6.396.673 0 47.131 0 47.467-1.01.337-1.009-8.416-78.103-8.416-78.103Z"
        fill="#D0CDE1"
      />
      <path
        d="M278.155 251.568s6.396 19.526 1.01 18.853c-5.386-.674-7.743-16.833-7.743-16.833l6.733-2.02Z"
        fill="#FFB8B8"
      />
      <path
        d="M260.986 170.435s-12.456 2.693-10.436 19.526c2.02 16.832 5.723 33.665 5.723 33.665l12.456 27.269 1.346 5.05 9.09-2.357-6.733-39.052s-2.357-41.744-5.386-43.091a13.033 13.033 0 0 0-6.06-1.01Z"
        fill="#D0CDE1"
      />
      <path
        opacity=".1"
        d="m253.411 223.121 15.486 27.605-13.047-29.088-2.439 1.483Z"
        fill="#000"
      />
      <path
        d="m274.557 142.443.047-1.081 2.152.536a2.42 2.42 0 0 0-.964-1.77l2.291-.128a24.719 24.719 0 0 0-16.537-10.22c-4.956-.719-10.475.321-13.874 4-1.649 1.784-2.685 4.053-3.421 6.368-1.358 4.263-1.634 9.345 1.196 12.811 2.876 3.522 7.9 4.212 12.426 4.648 1.593.153 3.262.296 4.738-.322a11.41 11.41 0 0 0-.634-4.999 3.324 3.324 0 0 1-.337-1.592c.201-1.347 1.997-1.686 3.346-1.504 1.35.182 2.972.46 3.859-.573.61-.712.574-1.749.655-2.683.221-2.544 5.034-2.957 5.057-3.491Z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1="300"
        y1="0"
        x2="300"
        y2="445.678"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#FCE3E3"
          stopOpacity=".9"
        />
        <stop
          offset="1"
          stopColor="#FCE3E3"
          stopOpacity="0"
        />
      </linearGradient>
      <clipPath id="b">
        <path
          fill="#fff"
          d="M147 64h305.812v320H147z"
        />
      </clipPath>
    </defs>
  </svg>
);
