import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { useSessionStorage } from 'usehooks-ts';
import { useSnackbar } from 'notistack';
import { CASE_CODE_INITIAL_STATE } from '../use-mutation-log-case-code';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { triggerCompanyOverviewExport } from '@/services/api/export';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { ExportEnum } from '@/types/history';
import { useSearchHistory } from '@/hooks/use-save-search-query';
import { useAllFetchedTableRows } from '@/hooks/table/use-all-fetched-table-rows';
import { prepareCaseCodeData } from '@/Utils/case-code-utils';
import { getEffectiveIncludeNullList } from '@/Utils/filters';

interface IUseMutationTriggerCompanyOverviewOrComparisonExportBody {
  target_id: string;
  competitor_ids: string[];
  companyProfileId?: string;
}

export const useMutationTriggerCompanyOverviewOrComparisonExport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { saveQuery } = useSearchHistory();
  const { getRowByBainId } = useAllFetchedTableRows();

  const { currency } = useShallowSelector((state) => state.config);
  const { email } = useShallowSelector((state) => state.user);
  const { selectedCompanies } = useShallowSelector((state) => state.selectedCompanies);
  const { searchQuery, searchQueryTree, queryType } = useShallowSelector((state) => state.search);
  const storedFilters = useShallowSelector((state) => state.filters);

  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);

  const effectiveIncludeNullList = getEffectiveIncludeNullList(includedNullList, storedFilters);

  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);
  const case_data = caseCodeData?.formData;

  return useMutation({
    mutationKey: ['trigger-company-overview-export'],
    mutationFn: ({ target_id, competitor_ids }: IUseMutationTriggerCompanyOverviewOrComparisonExportBody) =>
      triggerCompanyOverviewExport({
        target_id,
        competitor_ids,
        currency,
        email: email ?? '',
        case_data: prepareCaseCodeData(case_data),
      }),
    onSuccess: (__, { target_id, competitor_ids, companyProfileId }) => {
      enqueueSnackbar(
        <NotificationMessage
          title="Success! You will receive an email with a link to your export."
          description="Exports may take upwards of 10 minutes based on the size of the data.
        Please wait before re-attempting to export."
        />,
        { variant: 'success' },
      );
      const isCompanyProfile = !!companyProfileId;

      if (isCompanyProfile) return;

      const exportType = competitor_ids.length ? ExportEnum.COMPARISON : ExportEnum.OVERVIEW;

      const companyProfiles = selectedCompanies.map(
        (selectedCompany) => getRowByBainId(selectedCompany)?.['self_firmo_name___'],
      );

      saveQuery({
        searchText: searchQuery,
        isLucene: !!searchQueryTree,
        isExport: true,
        exportType,
        companyProfiles,
        filters: { ...storedFilters, includedNullList: effectiveIncludeNullList },
        queryType,
        ...(exportType === ExportEnum.COMPARISON
          ? {
              targetComparisonCompany: getRowByBainId(target_id)?.['self_firmo_name___'],
            }
          : undefined),
      });
    },
    onError: () =>
      enqueueSnackbar(<NotificationMessage title="Error. Please try again or contact support." />, {
        variant: 'error',
      }),
  });
};
