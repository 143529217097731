import React, { useMemo } from 'react';

import { numberFormatterCustom } from '../../../../Shared/common';
import { CURRENCIES } from '@/constants';
import { createCompanyProfileLink } from '@/pages/company-profile';
import { Currency, DatumDatum } from '@/types';
import { getYearFromMetadata } from '@/Utils/text';

interface OverviewTabProps {
  row?: DatumDatum;
  currency: string;
}

export const useOverviewTabData = ({ currency, row }: OverviewTabProps) => {
  const topLevelParentCompany = useMemo(() => {
    const parentName = row?.parent_firmo_name___;

    if (!parentName) return undefined;

    const parentBainId = row?.parent_ids_id__cdp_ ? String(row?.parent_ids_id__cdp_) : undefined;
    const selfBainId = row?.self_ids_id__cdp_ ? String(row?.self_ids_id__cdp_) : undefined;

    if (!parentBainId || parentBainId === selfBainId) return parentName;

    return (
      <button
        onClick={() => window.open(createCompanyProfileLink(parentBainId), '_blank')}
        className="underline cursor-pointer text-[#0484E7]"
      >
        {parentName}
      </button>
    );
  }, [row?.parent_firmo_name___, row?.parent_ids_id__cdp_, row?.self_ids_id__cdp_]);

  const revenueYear = useMemo(() => {
    return getYearFromMetadata(row, 'self_financials_revenue___', 'self_financials_revenue___metadata');
  }, [row]);

  const revenueLabel = useMemo(() => {
    if (!row) return;

    const { self_financials_revenue___ } = row;

    if (!self_financials_revenue___) return;

    const currencySign = CURRENCIES[currency as Currency].label;
    const formattedRevenue = numberFormatterCustom(self_financials_revenue___);
    const revenueWithCurrency = `${currencySign}${formattedRevenue}`;

    if (revenueYear) return <span>{`${revenueWithCurrency}${revenueYear.label}`}</span>;

    return revenueWithCurrency;
  }, [currency, row, revenueYear]);

  return { topLevelParentCompany, revenueLabel };
};
