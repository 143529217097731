import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { getStarterPackWizardById } from '@/services/api/starter-pack-wizard';

export const useQueryStarterPackWizardById = (id: string) => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(['starter-pack-wizard', id], () => getStarterPackWizardById(id), {
    enabled: !!id,
    cacheTime: Infinity,
    staleTime: Infinity,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: () =>
      enqueueSnackbar('Failed to fetch the data. Please try again or contact support.', { variant: 'error' }),
  });
};
