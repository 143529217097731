import { http, HttpResponse } from 'msw';
import { getUuidFromStringEnd } from '@/Utils/url-utils';

export const MOCKED_SAVED_SEARCH_TITLE = 'Test saved search';
export const MOCKED_SAVED_SEARCH_DESCRIPTION = 'test description saved search';
export const MOCKED_SEARCH_QUERY = 'google';
export const MOCKED_AVAILABLE_SAVED_SEARCH_ID = 'available-test-id-555e4567-e89b-12d3-a456-426614174000';
export const MOCKED_NOT_AVAILABLE_SAVED_SEARCH_ID = 'not-available-test-id-666617d5-be13-4f09-96be-de6d4a6da802';

export const savedSearchMock = {
  list_id: getUuidFromStringEnd(MOCKED_AVAILABLE_SAVED_SEARCH_ID),
  list_type: 'dynamic',
  title: MOCKED_SAVED_SEARCH_TITLE,
  description: MOCKED_SAVED_SEARCH_DESCRIPTION,
  currency: 'USD',
  company_search: '',
  search_query: MOCKED_SEARCH_QUERY,
  query_type: 'keyword',
  is_unique_company: false,
  lucene_query: true,
  filters: {
    treeFilters: {},
    includedNullList: [],
    otherFilters: {},
  },
  excluded_company_ids: [],
  sort_key: null,
  sort_order: null,
  creator: '040f572c-af65-448a-b39e-85787a9d8555',
  is_sharable_link: false,
  created_at: '2023-03-15T09:12:49.596917',
  updated_at: '2023-03-16T09:12:49.596932',
  visible_columns: [
    '__check__',
    'self_firmo_name___',
    'self_firmo_description___',
    'self_firmo_webpage___',
    'self_location_country___',
    'self_sector_industry___',
    'self_firmo_employeeCount___',
    'self_financials_revenue___',
    'self_financials_revenueCagr_1yr__',
    'self_financials_ebitda___',
    'self_financials_netIncome___',
  ],
  columns_order: {},
};

const savedSearchesDataMock = {
  saved_searches: [savedSearchMock],
};

export const savedSearchesController = [
  http.get(`${process.env.REACT_APP_API_URL}/saved_searches`, () => HttpResponse.json(savedSearchesDataMock)),

  http.get(`${process.env.REACT_APP_API_URL}/saved_search/:id`, ({ params: { id } }) => {
    if (id === getUuidFromStringEnd(MOCKED_NOT_AVAILABLE_SAVED_SEARCH_ID)) {
      return HttpResponse.json({
        message: `Not found SavedSearch with id: ${MOCKED_NOT_AVAILABLE_SAVED_SEARCH_ID}`,
      }, { status: 401 });
    }

    return HttpResponse.json({ ...savedSearchMock, list_id: id });
  }),

  http.patch(`${process.env.REACT_APP_API_URL}/saved_search/:id`, async ({ request }) => {
    const requestBody = await request.json();

    return HttpResponse.json({ savedSearchMock, ...requestBody });
  }),

  http.post(`${process.env.REACT_APP_API_URL}/saved_search`, async ({ request }) => {
    const requestBody = await request.json();

    return HttpResponse.json({ savedSearchMock, ...requestBody });
  }),
];

export const savedSearchesErrorsController = [
  http.get(`${process.env.REACT_APP_API_URL}/saved_searches`, () => {
    return HttpResponse.json({ message: 'error' }, { status: 500 });
  }),
];
