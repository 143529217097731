import React from 'react';

export const IconAccessDenied = () => (
  <svg
    width="600"
    height="446"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M552.759 108.338C484.66 13.576 340.805-24.864 199.328 16.536 124.533 38.423 52.417 82.278 16.678 167.194-47.21 318.991 86.93 367.471 174.642 382.922c42.413 7.471 82.359 20.022 118.862 37.109 65.672 30.741 181.916 57.981 269.571-70.071 63.533-92.812 35.045-178.501-10.316-241.622Z"
      fill="url(#a)"
    />
    <g clipPath="url(#b)">
      <path
        d="M126.637 389.961c52.819 0 95.637-3.252 95.637-7.263 0-4.012-42.818-7.264-95.637-7.264S31 378.686 31 382.698c0 4.011 42.818 7.263 95.637 7.263Z"
        fill="#E6E6E6"
      />
      <path
        d="M124.654 109.677c16.645-7.079 6.674-23.48 19.397 3.139.188-3.77 11.495 1.048 9.103-1.582-2.907-3.195 5.56.661 7.309-2.716 4.472-8.635-1.935-17.822-8.405-25.462a14.322 14.322 0 0 0-11.957-5.019l-11.984.856a14.349 14.349 0 0 0-12.743 10.258c-2.862 3.891-4.393 7.772-3.429 11.633-4.286 2.929 7.283 4.907 9.209 8.893 1.633 2.509 5.19-2.375 3.5 0-2.22 3.028 1.111-4.745 0-1.159v1.159Zm374.972 235.027H160.407a11.374 11.374 0 0 1-11.374-11.374C272 319.039 392.655 319.039 511 333.33a11.376 11.376 0 0 1-11.374 11.374Z"
        fill="#2F2E41"
      />
      <path
        d="m511 333.999-361.967-.669 41.951-70.587.2-.335V106.843a14.39 14.39 0 0 1 8.876-13.285 14.378 14.378 0 0 1 5.503-1.094h246.9a14.382 14.382 0 0 1 10.167 4.211 14.372 14.372 0 0 1 4.211 10.168v156.636L511 333.999Z"
        fill="#3F3D56"
      />
      <path
        d="M205.235 102.5a4.691 4.691 0 0 0-4.684 4.684v141.843a4.691 4.691 0 0 0 4.684 4.683h249.563a4.686 4.686 0 0 0 4.684-4.683V107.184a4.691 4.691 0 0 0-4.684-4.684H205.235Z"
        fill="#fff"
      />
      <path
        d="M206.28 272.444a2.016 2.016 0 0 0-1.823 1.169l-12.926 28.101a1.997 1.997 0 0 0 .133 1.921 1.999 1.999 0 0 0 1.69.925h271.834a2.013 2.013 0 0 0 1.707-.952 2.001 2.001 0 0 0 .087-1.953l-14.05-28.101a2 2 0 0 0-1.795-1.11H206.28Z"
        fill="#2F2E41"
      />
      <path
        d="M329.013 100.159a3.011 3.011 0 1 0 0-6.022 3.011 3.011 0 0 0 0 6.022Z"
        fill="#fff"
      />
      <path
        d="M302.294 309.912a2.008 2.008 0 0 0-1.938 1.486l-3.243 12.043a2.017 2.017 0 0 0 .345 1.743 2.01 2.01 0 0 0 1.593.786h61.664a2.002 2.002 0 0 0 1.635-.843 2.01 2.01 0 0 0 .261-1.821l-4.168-12.043a2.011 2.011 0 0 0-1.897-1.351h-54.252Zm164.547-49.845v2.676H190.983l.208-.334v-2.342h275.65Z"
        fill="#2F2E41"
      />
      <path
        d="M459.147 149.67c25.866 0 46.835-20.969 46.835-46.835S485.013 56 459.147 56s-46.835 20.969-46.835 46.835 20.969 46.835 46.835 46.835Z"
        fill="#C00"
      />
      <path
        d="M478.55 123.242h-38.806a2.68 2.68 0 0 1-2.676-2.677V97.148a2.678 2.678 0 0 1 2.676-2.676h38.806a2.68 2.68 0 0 1 2.676 2.676v23.417a2.679 2.679 0 0 1-2.676 2.677Zm-38.806-26.094v23.417h38.808l-.002-23.417h-38.806Z"
        fill="#fff"
      />
      <path
        d="M471.19 97.148h-24.086V86.443c0-7.754 5.065-13.382 12.043-13.382 6.978 0 12.043 5.628 12.043 13.382v10.705Zm-21.41-2.676h18.734v-8.03c0-6.302-3.852-10.704-9.367-10.704-5.515 0-9.367 4.402-9.367 10.705v8.029Zm9.367 16.392a2.676 2.676 0 1 0 0-5.352 2.676 2.676 0 0 0 0 5.352Z"
        fill="#fff"
      />
      <path
        d="M426.452 205.599H233.581a3.576 3.576 0 0 1-3.572-3.572v-47.843a3.58 3.58 0 0 1 1.047-2.525 3.58 3.58 0 0 1 2.525-1.047h192.871a3.58 3.58 0 0 1 2.525 1.047 3.58 3.58 0 0 1 1.047 2.525v47.843a3.576 3.576 0 0 1-3.572 3.572Zm-192.871-53.558a2.141 2.141 0 0 0-2.143 2.143v47.843a2.143 2.143 0 0 0 2.143 2.143h192.871a2.143 2.143 0 0 0 2.143-2.143v-47.843a2.143 2.143 0 0 0-2.143-2.143H233.581Z"
        fill="#E6E6E6"
      />
      <path
        d="M263.549 190.819c8.035 0 14.549-6.514 14.549-14.549 0-8.036-6.514-14.55-14.549-14.55S249 168.234 249 176.27c0 8.035 6.514 14.549 14.549 14.549Zm33.602-24.249a2.433 2.433 0 0 0-1.717.709 2.42 2.42 0 0 0-.527 2.645 2.42 2.42 0 0 0 1.314 1.312c.295.122.611.184.93.184h114.315a2.427 2.427 0 0 0 2.424-2.425 2.425 2.425 0 0 0-2.424-2.425H297.151Zm0 14.549a2.424 2.424 0 0 0 0 4.85h49.19a2.423 2.423 0 0 0 2.425-2.425 2.424 2.424 0 0 0-2.425-2.425h-49.19Z"
        fill="#E6E6E6"
      />
      <path
        d="m190.509 182.1-35.654 18.955-.451-15.796c11.637-1.942 22.708-5.325 32.946-10.831l3.729-9.213a6.236 6.236 0 0 1 4.846-3.83 6.241 6.241 0 0 1 5.768 2.209 6.247 6.247 0 0 1-.55 8.516l-10.634 9.99Z"
        fill="#FFB8B8"
      />
      <path
        d="M103.392 291.541a7.012 7.012 0 0 1 .89-5.668l7.832-12.02a13.687 13.687 0 0 1 17.756-4.684c-3.291 5.603-2.833 10.518 1.137 14.789a71.225 71.225 0 0 0-16.91 11.528 7.006 7.006 0 0 1-10.705-3.945Z"
        fill="#2F2E41"
      />
      <path
        d="M192.133 275.825a13.439 13.439 0 0 1-11.423 6.524l-52.031.394-2.257-13.088 23.017-6.77-19.406-14.893 21.212-24.822 38.527 35.909a13.437 13.437 0 0 1 2.361 16.746Z"
        fill="#2F2E41"
      />
      <path
        d="M120.555 365.334H108.37c-10.941-74.995-22.078-150.2 10.831-178.269l38.813 6.318-4.964 32.946-21.663 24.822-10.832 114.183Z"
        fill="#2F2E41"
      />
      <path
        d="M131.267 382.322a7.012 7.012 0 0 1-5.668-.889l-13.231-1.778a13.687 13.687 0 0 1-4.685-17.756c5.603 3.291 10.518 2.832 14.789-1.138 3.018 6.094 7.991 5.722 12.74 10.856a7 7 0 0 1-3.945 10.705Z"
        fill="#2F2E41"
      />
      <path
        d="m149.439 136.969-22.565-5.416c3.747-7.671 4.056-16.207 2.256-25.273l15.345-.452c.481 11.274 1.935 21.807 4.964 31.141Z"
        fill="#FFB8B8"
      />
      <path
        d="M155.641 206.972c-16.427 11.194-28.034.383-36.891-16.297 1.231-10.206-.762-22.42-4.453-35.692a24.293 24.293 0 0 1 14.833-29.297l19.407 8.124c16.478 13.431 19.721 27.981 13.539 43.326l-6.435 29.836Z"
        fill="#C00"
      />
      <path
        d="m108.37 148.252-18.053 9.477 32.043 18.955 4.459 10.998a5.83 5.83 0 0 1-3.504 7.706 5.839 5.839 0 0 1-5.147-.667 5.828 5.828 0 0 1-2.578-4.505l-.451-7.664-40.726-13.44a9.525 9.525 0 0 1-2.403-16.897l32.749-22.467 3.611 18.504Z"
        fill="#FFB8B8"
      />
      <path
        d="M126.422 150.057c-7.529-3.3-14.375.285-23.017 3.159l-1.354-23.92c8.581-4.573 16.762-5.806 24.371-2.256v23.017Z"
        fill="#C00"
      />
      <path
        d="M135.429 117.676c7.957 0 14.407-6.45 14.407-14.407s-6.45-14.407-14.407-14.407-14.408 6.45-14.408 14.407 6.451 14.407 14.408 14.407Z"
        fill="#FFB8B8"
      />
      <path
        d="M152.665 101.451c-14.265 1.397-25.134-.936-32.081-7.578v-5.35h30.87l1.211 12.928Z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1="300"
        y1="0"
        x2="300"
        y2="445.678"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#FCE3E3"
          stopOpacity=".9"
        />
        <stop
          offset="1"
          stopColor="#FCE3E3"
          stopOpacity="0"
        />
      </linearGradient>
      <clipPath id="b">
        <path
          fill="#fff"
          d="M31 56h480v333.961H31z"
        />
      </clipPath>
    </defs>
  </svg>
);
