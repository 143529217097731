import React from 'react';
import { useHistory } from 'react-router';
import { Card } from '../../Card/Card';
import { useCompaniesListMenuItems } from '../CompaniesListMenu/use-companies-list-menu-items';
import { CardIconList } from '../../Card/CardIcons/CardIconList';
import { createCompaniesListPath } from '../ShareCompaniesListLink';
import { ICompaniesListSnippet } from '@/types';

interface IProps {
  companiesList: ICompaniesListSnippet;
  isUserList?: boolean;
}

const SHARED_WITH_ME_EDIT_MODE = 'Shared with me (Edit mode)';
const SHARED_WITH_ME_READ_ONLY = 'Shared with me (Read only)';
const SHARED_BY_ME_WITH_EVERYONE_READ_ONLY = 'Shared by me (Read only)';
const PRIVATE = 'Private';

const getStatusList = (isUserList: boolean, isSharableReadOnly: boolean, isSharedWithMeForEditing?: boolean) => {
  if (isSharedWithMeForEditing) {
    return SHARED_WITH_ME_EDIT_MODE;
  }

  if (!isUserList && isSharableReadOnly) {
    return SHARED_WITH_ME_READ_ONLY;
  }

  if (isUserList && isSharableReadOnly) {
    return SHARED_BY_ME_WITH_EVERYONE_READ_ONLY;
  }

  // for now (BE limitation) there is no way to add option SHARED_BY_MY_EDIT_MODE
  return PRIVATE;
};

export const CompaniesListCard = ({ companiesList, isUserList = false }: IProps) => {
  const history = useHistory();
  const menuItems = useCompaniesListMenuItems(companiesList, !isUserList);

  const handleRoute = () => {
    history.push(createCompaniesListPath(companiesList.title, companiesList.list_id));
  };

  return (
    <Card
      title={companiesList.title}
      onClick={handleRoute}
      menuItems={menuItems}
      icon={<CardIconList />}
    >
      <div className="text-[#484848]">Companies: {companiesList?.pinned_company_ids_count}</div>
      <div className="text-[#484848]">
        Status: {getStatusList(isUserList, companiesList.is_sharable_link, companiesList?.shared_with_me)}
      </div>
    </Card>
  );
};
