import React from 'react';
import { useForm } from 'react-hook-form';
import { CaseCodeForm } from './CaseCodeForm';
import { CaseCodeSubmitButton } from './CaseCodeSubmitButton';
import { CASE_CODE_FORM_STEPS } from '@/constants';
import { Link } from '@/Components/Shared/Link';

export const CaseCodeFirstStep = ({ isLoading, isEditMode, onSubmit, setStep, flowData, defaultValues }) => {
  const form = useForm({ defaultValues });

  return (
    <>
      {flowData ? (
        <>
          <CaseCodeForm
            inputs={flowData?.[CASE_CODE_FORM_STEPS.FIRST]}
            form={form}
            onSubmit={onSubmit}
          />

          {flowData?.[CASE_CODE_FORM_STEPS.SECOND] ? (
            <button
              className="flex justify-start self-start"
              onClick={() => setStep(CASE_CODE_FORM_STEPS.SECOND)}
            >
              <Link>Don’t have a case code?</Link>
            </button>
          ) : null}

          <CaseCodeSubmitButton
            isLoading={isLoading}
            isEditMode={isEditMode}
            onSubmit={form.handleSubmit(onSubmit)}
          />
        </>
      ) : null}
    </>
  );
};
