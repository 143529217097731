import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { actions as filterActions } from '@/slices/filters';
import { actions as searchActions } from '@/slices/search';
import { actions as tableActions } from '@/slices/table';
import { actions as tableNotPersistActions } from '@/slices/table/table-not-persist';
import { actions as selectedCompaniesActions } from '@/slices/selected-companies';

export const useScreener = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const softReset = useCallback(() => {
    dispatch(filterActions.resetFilters());
    dispatch(searchActions.resetSearch());
    dispatch(tableActions.resetTable());
    dispatch(selectedCompaniesActions.reset());
    dispatch(tableNotPersistActions.resetTableNotPersist());

    dispatch(searchActions.setIsElasticEnabled(true));
  }, [dispatch]);

  const resetAll = useCallback(
    async (skipCacheClear?: boolean) => {
      await dispatch(filterActions.resetFilters());
      await dispatch(searchActions.resetSearch());
      await dispatch(tableActions.resetTable());
      await dispatch(selectedCompaniesActions.reset());
      await dispatch(tableNotPersistActions.resetTableNotPersist());

      if (!skipCacheClear) {
        queryClient.clear();
      }

      dispatch(searchActions.setIsElasticEnabled(true));
    },
    [dispatch, queryClient],
  );

  const cancelElastic = useCallback(() => {
    queryClient.cancelQueries({ queryKey: ['table-elastic'] });
  }, [queryClient]);

  return {
    resetAll,
    softReset,
    cancelElastic,
  };
};
