import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { TableGrid } from './index.ui';
import { getColumns } from './columns';
import { useIndexedROws } from './hooks/use-indexed-rows';
import { useQueryTable } from '@/hooks/queries/table/use-query-table';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getCountText } from '@/Utils/table';
import { useQueryColumnMapper } from '@/hooks/queries/column-mapper/use-query-column-mapper';
import { FILTERS_ID } from '@/constants';
import { useTableLoading } from '@/hooks/table/use-table-loading';
import { actions } from '@/slices/selected-companies';

const TABLE_OFFSET = 16;

interface IProps {
  forceEmptyResult?: boolean;
}

export const ScreenerTable = ({ forceEmptyResult }: IProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const observerRef = useRef<MutationObserver | null>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const { pageNumber } = useShallowSelector((state) => state.tableNotPersist);
  const currency = useShallowSelector((state) => state.config.currency);
  const defaultColumns = useShallowSelector((state) => state.table.defaultColumns);
  const selectedCompanies = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);
  const query = useQueryTable();
  const isLoading = useTableLoading();
  const apiRef = useGridApiRef();
  const columnMapperQuery = useQueryColumnMapper();

  const columnMapper = useMemo(() => columnMapperQuery.data ?? [], [columnMapperQuery.data]);
  const columns = useMemo(
    () =>
      getColumns({
        columnMapper,
        currency,
        defaultColumns,
      }),
    [columnMapper, currency, defaultColumns],
  );

  const { indexedRows, rows } = useIndexedROws();

  const countText = useMemo(
    () => getCountText(forceEmptyResult ? 0 : query?.data?.pages[pageNumber]?.total_count ?? 0),
    [forceEmptyResult, pageNumber, query?.data?.pages],
  );

  useEffect(() => {
    if (apiRef.current && !isLoading) {
      const filteredBainIds = selectedCompanies.filter((id) => indexedRows[id]);
      const csv = apiRef.current.getDataAsCsv({ getRowsToExport: () => filteredBainIds });

      dispatch(actions.setSelectedCompaniesInCsv(csv));
    }
  }, [apiRef, dispatch, indexedRows, isLoading, rows, selectedCompanies]);

  useEffect(() => {
    observerRef.current = new MutationObserver(() => {
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect();
        const height = rect.top + TABLE_OFFSET;

        parentRef.current.style.height = `calc(100vh - ${height}px)`;
      }
    });

    return () => {
      observerRef.current?.disconnect();
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  useEffect(() => {
    const filtersNode = document.getElementById(FILTERS_ID);
    const semanticSearchSectionNode = document.getElementById('semantic-search-section');

    document.body.classList.add('overflow-hidden');

    if (filtersNode) {
      observerRef.current?.observe(filtersNode, {
        childList: true,
        characterData: true,
        attributes: true,
        subtree: true,
      });
    }

    if (semanticSearchSectionNode) {
      observerRef.current?.observe(semanticSearchSectionNode, {
        childList: true,
        characterData: true,
        attributes: true,
        subtree: true,
      });
    }
  }, [location.pathname]);

  return (
    <div
      id="screen-grid"
      ref={parentRef}
      className="relative transition-all"
    >
      <TableGrid
        apiRef={apiRef}
        isLoading={forceEmptyResult ? false : isLoading}
        columns={columns}
        rows={forceEmptyResult ? [] : rows}
        countText={countText}
      />
    </div>
  );
};
