import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { IconGraph } from '@/Components/Icons/IconGraph';

export const MultipleLabelsLoaderDialogContent = ({ label }: { label: string }) => (
  <>
    <div className="mt-5 mb-0 flex justify-center">
      <IconGraph className="w-8 h-8" />
    </div>
    <DialogTitle classes={{ root: 'font-medium text-sm text-center' }}>{label}</DialogTitle>

    <DialogContent className="pt-0 pb-8">
      <DialogContentText className="text-sm">
        <div className="mt-3 flex gap-3 justify-center">
          <div className="w-2 h-2 bg-[#0484E7] rounded-full animate-[pulse_1.5s_-0.5s_ease-in-out_infinite]"></div>
          <div className="w-2 h-2 bg-[#0484E7] rounded-full animate-[pulse_1.5s_-0.25s_ease-in-out_infinite]"></div>
          <div className="w-2 h-2 bg-[#0484E7] rounded-full animate-[pulse_1.5s_ease-in-out_infinite]"></div>
        </div>
      </DialogContentText>
    </DialogContent>
  </>
);
