import React from 'react';

export const IconArrowRight = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16671 10.8334H14.0542L11.0267 14.4667C10.7317 14.82 10.78 15.3459 11.1334 15.64C11.4875 15.935 12.0125 15.8867 12.3075 15.5334L16.4742 10.5334C16.5067 10.4942 16.5225 10.4484 16.5467 10.405C16.5667 10.37 16.5909 10.34 16.6059 10.3017C16.6434 10.2059 16.6659 10.105 16.6659 10.0034C16.6659 10.0025 16.6667 10.0009 16.6667 10C16.6667 9.99919 16.6659 9.99752 16.6659 9.99669C16.6659 9.89502 16.6434 9.79419 16.6059 9.69835C16.5909 9.66002 16.5667 9.63002 16.5467 9.59502C16.5225 9.55169 16.5067 9.50585 16.4742 9.46669L12.3075 4.46669C12.1417 4.26919 11.905 4.16669 11.6667 4.16669C11.4784 4.16669 11.2892 4.23002 11.1334 4.36002C10.78 4.65419 10.7317 5.18002 11.0267 5.53335L14.0542 9.16669H4.16671C3.70671 9.16669 3.33337 9.54002 3.33337 10C3.33337 10.46 3.70671 10.8334 4.16671 10.8334Z"
      fill="#2E3F4C"
    />
    <mask
      id="mask0_2975_51906"
      maskUnits="userSpaceOnUse"
      x="3"
      y="4"
      width="14"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16671 10.8334H14.0542L11.0267 14.4667C10.7317 14.82 10.78 15.3459 11.1334 15.64C11.4875 15.935 12.0125 15.8867 12.3075 15.5334L16.4742 10.5334C16.5067 10.4942 16.5225 10.4484 16.5467 10.405C16.5667 10.37 16.5909 10.34 16.6059 10.3017C16.6434 10.2059 16.6659 10.105 16.6659 10.0034C16.6659 10.0025 16.6667 10.0009 16.6667 10C16.6667 9.99919 16.6659 9.99752 16.6659 9.99669C16.6659 9.89502 16.6434 9.79419 16.6059 9.69835C16.5909 9.66002 16.5667 9.63002 16.5467 9.59502C16.5225 9.55169 16.5067 9.50585 16.4742 9.46669L12.3075 4.46669C12.1417 4.26919 11.905 4.16669 11.6667 4.16669C11.4784 4.16669 11.2892 4.23002 11.1334 4.36002C10.78 4.65419 10.7317 5.18002 11.0267 5.53335L14.0542 9.16669H4.16671C3.70671 9.16669 3.33337 9.54002 3.33337 10C3.33337 10.46 3.70671 10.8334 4.16671 10.8334Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2975_51906)"></g>
  </svg>
);
