import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Dialog from '@mui/material/Dialog';

import { isEmpty, isNil, pickBy, uniq, omit, isArray, without, union } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { WizardDialogLoadingScreen } from './WizardDialogLoadingScreen';
import { WizardDialogFooter } from './WizardDialogFooter';
import { WizardDialogHeader } from './WizardDialogHeader';
import { WizardDialogTitle } from './WizardDialogTitle';
import { WizardDialogDescription } from './WizardDialogDescription';
import { WizardDialogChoice } from './WizardDialogChoice';
import { itemShouldBeSkipped } from './utils';
import { CUSTOM_SCREEN_QUERY_PARAMS, FILTER_TYPES, MODALS, ROUTES } from '@/constants';
import { useModal } from '@/hooks/use-modal';
import { useQueryStarterPackWizardById } from '@/hooks/queries/starter-pack-wizard/use-query-starter-pack-wizard-by-id';

import { useFiltersHasChange } from '@/Components/Shared/FiltersDrawer/use-filters-has-change';
import { useQueryAvailableFilters } from '@/hooks/queries/available-filters/use-query-available-filters';
import { FilterType, FiltersAvailableDataItem, TStarterPackWizardStepFilterDefaultValue } from '@/types';
import { useQueryFilterTree } from '@/hooks/queries/use-query-filter-tree';
import {
  STARTER_PACK_WIZARD_STEP_ITEM_TYPE,
  StarterPackWizardChoices,
  StarterPackWizardStepItem,
  StarterPackWizardStepItemFilter,
} from '@/types/api/starter-pack-wizard';
import { FilterItem } from '@/Components/Shared/FiltersDrawer/FilterHeader/FilterItem';
import { actions as filtersActions, TNumericalFilter } from '@/slices/filters';
import { actions as searchActions } from '@/slices/search';
import { actions as tableActions } from '@/slices/table';
import { getAvailableFilterIdsWithType } from '@/Utils/filters';
import { useSimplifyCustomAggregationString } from '@/hooks/queries/custom-aggregation/use-simplify-custom-aggregation-string';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export interface StarterPackWizardModalData {
  wizardId: string;
  iconLink?: string;
}

export const StarterPackWizardDialog = () => {
  const { isOpen, handleClose, data: modalData } = useModal<StarterPackWizardModalData>(MODALS.STARTER_PACK_WIZARD);
  const { handleOpen: openWizardLoadingDialog } = useModal(MODALS.WIZARD_LOADING_DIALOG);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const customAggregationString = useShallowSelector((state) => state.filters.customAggregationString);
  const { otherFilters: reduxOtherFilters, includedNullList } = useShallowSelector((state) => state.filters);
  const { visible: visibleColumns } = useShallowSelector((state) => state.table);

  const appliedDefaultFilters = useRef<string[]>([]);

  const customAggStringApplied = useRef(false);

  const {
    data: availableFilters,
    isFetching: isFetchingAvailableFilters,
    error: availableFiltersError,
  } = useQueryAvailableFilters();

  const [activeStep, setActiveStep] = useState(0);

  const {
    data: wizardData,
    isFetching: isFetchingWizardData,
    error: wizardDataError,
  } = useQueryStarterPackWizardById(modalData?.wizardId);

  const stepData = wizardData?.steps[activeStep];

  const numberOfSteps = wizardData?.steps.length;
  const isAtLeastOneStep = isNil(numberOfSteps) ? false : numberOfSteps > 0;
  const isLastStep = numberOfSteps === activeStep + 1;
  const isFirstStep = activeStep === 0;

  const allFiltersIds = wizardData?.steps
    .reduce(
      (previous: StarterPackWizardStepItemFilter[], current) => [
        ...previous,
        ...current.items.filter((item) => item.type === STARTER_PACK_WIZARD_STEP_ITEM_TYPE.FILTER),
      ],
      [],
    )
    .map(({ filter }) => filter);

  const {
    data: filterStatsData,
    isFetching: isFilterStatsFetching,
    error: filterStatsError,
  } = useQueryFilterTree({
    ids: allFiltersIds ?? [],
    enabled: !!allFiltersIds?.length,
    body: { company_search: '', is_lucene: true },
  });

  const { simplifyCustomAggregationString, isSimplifying } = useSimplifyCustomAggregationString();

  useEffect(() => {
    if (isOpen) {
      appliedDefaultFilters.current.forEach((filter) =>
        dispatch(filtersActions.removeFilter({ filter, skipCustomAggClear: true })),
      );
      appliedDefaultFilters.current = [];
      setActiveStep(0);
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const availableFilterIdsWithType = getAvailableFilterIdsWithType(availableFilters);

      const numericalFilterIds = pickBy(availableFilterIdsWithType, (value) => value === 'numerical');

      dispatch(filtersActions.setIdsToNullList(uniq(Object.keys(numericalFilterIds))));
    }
  }, [availableFilters, dispatch, isOpen]);

  useEffect(() => {
    if (isOpen && wizardData?.custom_agg && !customAggStringApplied.current) {
      dispatch(filtersActions.setCustomAggregationString(wizardData.custom_agg));
      customAggStringApplied.current = true;
    }

    if (!isOpen) {
      customAggStringApplied.current = false;
    }
  }, [customAggregationString, dispatch, isOpen, wizardData?.custom_agg]);

  const { currentHash } = useFiltersHasChange({});

  const isFetching = isFetchingWizardData || isFetchingAvailableFilters || isFilterStatsFetching;
  const isError = availableFiltersError || wizardDataError || filterStatsError;

  const shouldRenderStepContent =
    wizardData &&
    availableFilters &&
    filterStatsData &&
    !isFetching &&
    !isError &&
    !wizardDataError &&
    isAtLeastOneStep &&
    !isNil(numberOfSteps) &&
    wizardData?.title &&
    stepData?.title;

  const filterItems = availableFilters?.reduce(
    (previous: FiltersAvailableDataItem[], current) => [...previous, ...current.items],
    [],
  );

  const [choices, setChoices] = useState<StarterPackWizardChoices>({});

  const updateChoice = useCallback(
    (id: string, value?: string) => {
      setChoices((oldChoices) => ({ ...oldChoices, [id]: value || undefined }));
    },
    [setChoices],
  );

  const completeStepFilters = useMemo(
    () =>
      ((stepData && stepData.items.filter((item) => item.type === STARTER_PACK_WIZARD_STEP_ITEM_TYPE.FILTER)) || [])
        .map((item) => {
          if (itemShouldBeSkipped(item, choices)) {
            return undefined;
          }

          const filterItem = filterItems?.find(({ backendName }) => item.filter === backendName);

          const availableFiltersFilterItems = filterStatsData?.[item.filter] ?? [];

          const isNumericalPlaceholdersAvailable = item.placeholders?.min || item.placeholders?.max;

          if (filterItem)
            return {
              ...filterItem,
              default_values: item.default_values,
              filterItems: isNumericalPlaceholdersAvailable
                ? { hint_min: item.placeholders?.min || '', hint_max: item.placeholders?.max || '' }
                : availableFiltersFilterItems,
            };

          return undefined;
        })
        .filter((item) => !isEmpty(item)) || [],
    [filterItems, filterStatsData, stepData, choices],
  );

  useEffect(() => {
    if (!isOpen) return;

    const stepNumericalFilters = completeStepFilters.filter((item) => item?.__type__ === 'numerical');

    stepNumericalFilters.forEach((item) => {
      if (isArray(item?.default_values) || !item) return;

      const {
        default_values: { excludeNulls },
        backendName,
      } = item;

      const reduxFilter = (reduxOtherFilters[backendName] as TNumericalFilter) || undefined;

      const isFilterRemoved = !reduxFilter || (isNil(reduxFilter?.min) && isNil(reduxFilter?.max));

      if (isFilterRemoved) {
        if (!includedNullList.includes(backendName)) {
          dispatch(filtersActions.addIdToNullList(backendName));
          dispatch(filtersActions.removeFilter({ filter: backendName, skipCustomAggClear: true }));
        }

        return;
      }

      if (excludeNulls && includedNullList.includes(backendName)) {
        dispatch(filtersActions.removeIdFromNullList(backendName));
      }

      if (!excludeNulls && !includedNullList.includes(backendName)) {
        dispatch(filtersActions.addIdToNullList(backendName));
      }
    });
  }, [completeStepFilters, dispatch, includedNullList, reduxOtherFilters, currentHash, isOpen]);

  const onNextStep = () => {
    if (isLastStep) {
      simplifyCustomAggregationString({
        customAggregationStringToSimplify: wizardData?.custom_agg || '',
        onSuccess: () => {
          setChoices({});
          dispatch(searchActions.setIsElasticEnabled(true));

          if (wizardData?.extra_visible_columns) {
            dispatch(tableActions.setVisibleColumns(union(visibleColumns, wizardData.extra_visible_columns)));
          }

          push(
            ROUTES.CUSTOM_SEARCH +
              `?${CUSTOM_SCREEN_QUERY_PARAMS.source.key}=${CUSTOM_SCREEN_QUERY_PARAMS.source.values.WIZARD}`,
          );
          handleClose();
          openWizardLoadingDialog();
        },
      });

      return;
    }

    setActiveStep((prev) => prev + 1);
  };

  const removeCurrentStepFilters = () =>
    completeStepFilters.forEach((filter) => {
      if (filter?.backendName) {
        dispatch(filtersActions.removeFilter({ filter: filter.backendName, skipCustomAggClear: true }));
      }
    });

  const onSkip = () => {
    const currentStepFiltersBackendIds =
      (stepData &&
        stepData.items
          .filter((item) => item.type === STARTER_PACK_WIZARD_STEP_ITEM_TYPE.FILTER)
          .map(({ filter }) => filter)) ||
      [];

    removeCurrentStepFilters();

    onNextStep();

    if (isLastStep) {
      return;
    }

    appliedDefaultFilters.current = without(appliedDefaultFilters.current, ...currentStepFiltersBackendIds);
  };

  const onBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const clearFilters = () => {
    dispatch(filtersActions.resetFilters());
  };

  const clearFiltersAndCloseDialog = () => {
    handleClose();
    clearFilters();
    setChoices({});
  };

  const applyFilter = ({
    backendName,
    default_values,
    __type__,
  }: {
    backendName: string;
    default_values: TStarterPackWizardStepFilterDefaultValue;
    __type__: FilterType;
  }) => {
    if (__type__ == FILTER_TYPES.TREE) {
      if (Array.isArray(default_values)) {
        dispatch(
          filtersActions.setFilterTree({
            id: backendName,
            data: default_values.map((treeNode) => JSON.stringify(treeNode)),
          }),
        );

        appliedDefaultFilters.current.push(backendName);
      }

      return;
    }

    const isNumericalFilter = __type__ === 'numerical';

    if (!isNumericalFilter) {
      dispatch(
        filtersActions.setFilter({
          id: backendName,
          data: default_values,
        }),
      );

      appliedDefaultFilters.current.push(backendName);

      return;
    }

    if (isArray(default_values)) return;

    const { excludeNulls, min, max } = default_values;

    if (isNil(min) && isNil(max)) return;

    dispatch(
      filtersActions.setFilter({
        id: backendName,
        data: omit(default_values, 'excludeNulls'),
      }),
    );

    appliedDefaultFilters.current.push(backendName);

    if (excludeNulls) {
      dispatch(filtersActions.removeIdFromNullList(backendName));
    }
  };

  const hasFilters = stepData?.items.some((item) => item.type === STARTER_PACK_WIZARD_STEP_ITEM_TYPE.FILTER);
  const hasAllChoicesMade = stepData?.items
    .filter((item) => item.type === STARTER_PACK_WIZARD_STEP_ITEM_TYPE.CHOICE)
    .every((item) => !!choices[item.id]);

  const renderStepItem = (item: StarterPackWizardStepItem) => {
    if (!wizardData) {
      return null;
    }

    if (itemShouldBeSkipped(item, choices)) {
      return null;
    }

    switch (item.type) {
      case STARTER_PACK_WIZARD_STEP_ITEM_TYPE.FILTER: {
        const filter = completeStepFilters.find((filterSpec) => filterSpec?.backendName === item.filter);

        if (!filter) {
          return null;
        }

        const { backendName, displayName, filterItems: items, unit, __type__, default_values } = filter;

        if (!appliedDefaultFilters.current.includes(backendName)) {
          applyFilter({ default_values, __type__, backendName });
        }

        return (
          <div
            key={JSON.stringify(item)}
            id={wizardData.slug ? `${wizardData.slug}-${backendName}` : backendName}
          >
            <FilterItem
              isFetching={isFetching}
              itemId={backendName}
              itemName={displayName}
              itemType={__type__}
              itemUnit={unit}
              filterItems={items}
              categoryFilterId=""
              searchValue=""
              withoutCounts
              categoricalAsDropdown
              hideNumericalToggle
            />
          </div>
        );
      }

      case STARTER_PACK_WIZARD_STEP_ITEM_TYPE.CHOICE: {
        return (
          <div
            key={JSON.stringify(item)}
            id={wizardData.slug ? `${wizardData.slug}-choice-${item.id}` : undefined}
          >
            <WizardDialogChoice
              item={item}
              value={choices[item.id] || ''}
              onChange={(value) => updateChoice(item.id, value)}
            />
          </div>
        );
      }

      case STARTER_PACK_WIZARD_STEP_ITEM_TYPE.TITLE: {
        return (
          <div key={JSON.stringify(item)}>
            <WizardDialogTitle item={item} />
          </div>
        );
      }

      case STARTER_PACK_WIZARD_STEP_ITEM_TYPE.DESCRIPTION: {
        return (
          <div key={JSON.stringify(item)}>
            <WizardDialogDescription item={item} />
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: 'w-1/3 rounded-lg p-4 mt-[100px] self-start max-h-[calc(100%-120px)]' }}
    >
      {!isAtLeastOneStep && !isFetching ? <div>There is no steps configured</div> : null}
      {isError ? <div>Something went wrong...</div> : null}
      {isFetching ? <WizardDialogLoadingScreen /> : null}
      {shouldRenderStepContent ? (
        <>
          <WizardDialogHeader
            title={wizardData.title}
            stepTitle={stepData.title}
            stepDescription={stepData.description}
            numberOfSteps={numberOfSteps}
            activeStep={activeStep}
            handleClose={clearFiltersAndCloseDialog}
            iconLink={modalData?.iconLink}
          />

          {stepData.items.map(renderStepItem)}

          <WizardDialogFooter
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            // Next is enabled if all choices are made
            onPrimaryButtonClick={hasAllChoicesMade ? onNextStep : undefined}
            // skip makes sense only for filters
            onSecondaryButtonClick={!hasFilters ? undefined : onSkip}
            onBackButtonClick={onBack}
            isLoading={isSimplifying}
            isOpen={isOpen}
            primaryButtonId={wizardData.slug}
            hideEstimated={!hasFilters}
          />
        </>
      ) : null}
    </Dialog>
  );
};
