import React, { FC, useEffect } from 'react';
import Box from '@mui/material/Box';

import classnames from 'classnames';
import { Skeleton } from '@mui/material';
import ExportComponent from '@/Components/ExportComponent';
import { ScreenerTable } from '@/Components/Shared/ScreenerTable';
import Search from '@/Components/CompanyResearch/Search/Search';

import { ErrorPage } from '@/Components/Shared/ErrorPage';
import { FILTERS_ID } from '@/constants';
import { useScreener } from '@/hooks/use-screen';
import { useTableLoading } from '@/hooks/table/use-table-loading';
import { SemanticSearchSection } from '@/Components/CompanyResearch/Search/SemanticSearch/SemanticSearchSection';

interface IProps {
  isError?: boolean;
  errorPage?: {
    title: string;
    description: string;
    Icon: () => JSX.Element;
  };
  hideSearch?: boolean;
  isCompaniesList?: boolean;
  isSemanticSearch?: boolean;
  controls: JSX.Element;
  forceEmptyResult?: boolean;
}

const SearchPage: FC<IProps> = ({
  isError = false,
  errorPage,
  controls,
  hideSearch,
  isCompaniesList,
  isSemanticSearch,
  forceEmptyResult,
}) => {
  const { softReset } = useScreener();
  const isTableLoading = useTableLoading();

  useEffect(() => {
    return () => {
      softReset();
    };
  }, [softReset]);

  const exportComponent = forceEmptyResult ? null : (
    <Box
      color="#666666"
      flex="none"
    >
      <ExportComponent isCompaniesList={isCompaniesList} />
    </Box>
  );

  if (isError && errorPage) {
    return <ErrorPage {...errorPage} />;
  }

  return (
    <div
      className={classnames('py-4', {
        'bg-[#fafafa]': isCompaniesList,
      })}
    >
      <Box className="flex justify-between px-6">
        {controls}
        {isTableLoading ? (
          <Skeleton
            variant="rounded"
            width={90}
            height={36}
            className="bg-[#0000000f]"
          />
        ) : (
          exportComponent
        )}
      </Box>
      <div id="semantic-search-section">{isSemanticSearch ? <SemanticSearchSection /> : null}</div>
      <Box
        id={FILTERS_ID}
        sx={{ display: 'flex' }}
        mt={hideSearch || isSemanticSearch ? 0 : 2}
        className="px-6"
      >
        <div
          className={classnames('flex flex-wrap flex-col', {
            'invisible h-0': hideSearch,
          })}
        >
          <div className="flex items-start">
            <Search
              isCompaniesList={isCompaniesList}
              isSemanticSearch={isSemanticSearch}
            />
          </div>
        </div>
      </Box>
      <div className="mt-4 px-6">
        <ScreenerTable forceEmptyResult={forceEmptyResult} />
      </div>
    </div>
  );
};

export default SearchPage;
