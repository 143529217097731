import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { useSessionStorage } from 'usehooks-ts';
import { useSnackbar } from 'notistack';
import { CASE_CODE_INITIAL_STATE } from '../use-mutation-log-case-code';
import { useObligatoryElasticBody } from '../table/use-obligatory-elastic-body';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { triggerAllSearchResultsDataExport } from '@/services/api/export';
import { LOCAL_STORAGE_KEYS, MAX_COMPANIES_EXPORT } from '@/constants';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { ExportEnum } from '@/types/history';
import { useSearchHistory } from '@/hooks/use-save-search-query';
import { prepareCaseCodeData } from '@/Utils/case-code-utils';
import { getEffectiveIncludeNullList } from '@/Utils/filters';

interface IUseMutationTriggerAllSearchResultsDataExportBody {
  totalRows: number;
}

export const useMutationTriggerAllSearchResultsDataExport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { saveQuery } = useSearchHistory();

  const { currency, body } = useObligatoryElasticBody();

  const { email } = useShallowSelector((state) => state.user);
  const { searchQuery, searchQueryTree, queryType, isUniqueCompanySearch } = useShallowSelector(
    (state) => state.search,
  );

  const storedFilters = useShallowSelector((state) => state.filters);
  const { visible: visibleColumns } = useShallowSelector((state) => state.table);

  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);
  const caseCodeFormData = caseCodeData?.formData;
  const caseCodeFlowType = caseCodeData?.flowType;

  const filters = useShallowSelector((state) => state.filters);
  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);

  const effectiveIncludeNullList = getEffectiveIncludeNullList(includedNullList, filters);

  return useMutation({
    mutationKey: ['trigger-all-search-results-data-export'],
    mutationFn: () => {
      return triggerAllSearchResultsDataExport({
        body: {
          email: email ?? '',
          case_data: { ...prepareCaseCodeData(caseCodeFormData), work_stream: caseCodeFlowType },
          summary_columns: visibleColumns.filter((column) => !['__check__', 'id'].includes(column)),
          ...body,
        },
        currency,
      });
    },
    onSuccess: (__, { totalRows }: IUseMutationTriggerAllSearchResultsDataExportBody) => {
      enqueueSnackbar(
        <NotificationMessage
          title="Success! You will receive an email with a link to your export."
          description="Exports may take upwards of 10 minutes based on the size of the data.
        Please wait before re-attempting to export."
        />,
        { variant: 'success' },
      );

      saveQuery({
        searchText: searchQuery,
        companiesCount: Math.min(totalRows, MAX_COMPANIES_EXPORT),
        isLucene: !!searchQueryTree,
        isExport: true,
        exportType: ExportEnum.DATA,
        filters: { ...storedFilters, includedNullList: effectiveIncludeNullList },
        isUniq: isUniqueCompanySearch,
        queryType,
      });
    },
    onError: () =>
      enqueueSnackbar(<NotificationMessage title="Error. Please try again or contact support." />, {
        variant: 'error',
      }),
  });
};
