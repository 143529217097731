import { get, post } from 'aws-amplify/api';

import {
  Currency,
  ISimilarCompaniesSearch,
  ITriggerSimilarCompaniesSearch,
  ITriggerSimilarCompaniesSearchResponse,
  SearchSimilarCompaniesPayload,
  ISearchSimilarCompanies,
} from '@/types';

export const getSimilarCompaniesSearchInfoById = (id: string): Promise<ISimilarCompaniesSearch> =>
  // @ts-ignore
  get({
    apiName: 'CDPAPI',
    path: `/similar_companies/${id}`,
  }).response.then(res => res.body.json());

export const triggerSimilarCompaniesSearch = (
  body: ITriggerSimilarCompaniesSearch,
): Promise<ITriggerSimilarCompaniesSearchResponse> =>
  // @ts-ignore
  post({
    apiName: 'CDPAPI',
    path: '/similar_companies',
    options: { body },
  }).response.then(res => res.body.json());

export const searchSimilarCompanies = (
    currency: Currency,
    body: SearchSimilarCompaniesPayload,
  ): Promise<ISearchSimilarCompanies> =>
  // @ts-ignore
  post({
    apiName: 'CDPAPI',
    path: `/search/similar_companies?currency=${currency}`,
    options: { body },
  }).response.then(res => res.body.json());
