import React from 'react';
import MenuItem from '@mui/material/MenuItem';

import { Button, Menu } from '@mui/material';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { HELIX_APPS, LOCAL_STORAGE_KEYS } from '@/constants';
import { useMenu } from '@/hooks/use-menu';
import { IconBrowser } from '@/Components/Icons/IconBrowser';
import { CASE_CODE_INITIAL_STATE } from '@/hooks/queries/use-mutation-log-case-code';
import { addCaseCodeQueryParamsToUrl } from '@/Utils/url-utils';
import { IconChevronDown } from '@/Components/Icons/IconChevronDown';

export const HelixAppsDropdown = () => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const currentApp = HELIX_APPS.FIND.label;
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);

  return (
    <>
      <Button
        data-testid="helix-apps-dropdown"
        aria-controls={isOpen ? 'helix-apps-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        classes={{ root: 'p-1 grid grid-cols-3' }}
      >
        <IconBrowser className="ml-1 mr-2" />

        <span className="text-[#2E3F4C] font-normal text-sm cursor-pointer">Find</span>

        <IconChevronDown
          className={classnames('ml-1 transition', { 'rotate-180': isOpen })}
          fill="#2E3F4C"
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        {Object.entries(HELIX_APPS).map(([key, val]) => {
          const isCurrentApp = val.label === currentApp;

          return (
            <MenuItem
              key={key}
              value={key}
              classes={{ root: classnames('p-0 h-5 ', { 'bg-[#0000000a]': isCurrentApp }) }}
              onClick={handleClose}
            >
              {isCurrentApp ? (
                <Link
                  to="/"
                  className="w-full h-full flex items-center	px-4	"
                >
                  {val.label}
                </Link>
              ) : (
                <a
                  className="w-full h-full flex items-center	px-4	"
                  href={addCaseCodeQueryParamsToUrl(val.link, caseCodeData)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {val.label}
                </a>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
