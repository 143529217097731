import { http, HttpResponse } from 'msw';

export const subsidiariesController = [
  http.get(`${process.env.REACT_APP_API_URL}/company_profile/subsidiaries_elastic/:id`, () => {
    return HttpResponse.json({
      total_count: 4,
      data: [
        {
          bain_id: 1,
          self_firmo_name___: 'Test Company A',
        },
        {
          bain_id: 2,
          self_firmo_name___: 'Test Company B',
        },
        {
          bain_id: 3,
          self_firmo_name___: 'Test Company C',
        },
        {
          bain_id: 4,
          self_firmo_name___: 'Test Company D',
        },
      ],
    });
  }),
];
