import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { actions as similarCompaniesActions } from '@/slices/similar-companies';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { ITriggerSimilarCompaniesSearch } from '@/types/api/similar-companies';
import { triggerSimilarCompaniesSearch } from '@/services/api/similar-companies';
import { MODALS } from '@/constants';
import { useModal } from '@/hooks/use-modal';

export const useMutationTriggerSimilarCompaniesSearch = (onSuccess?: () => void) => {
  const { handleOpen: openSimilarCompaniesLoadingDialog } = useModal(MODALS.SIMILAR_COMPANIES_LOADING_DIALOG);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationKey: ['trigger-similar-companies-search'],
    mutationFn: (body: ITriggerSimilarCompaniesSearch) => triggerSimilarCompaniesSearch(body),
    onSuccess: ({ job_id }) => {
      dispatch(similarCompaniesActions.setSimilarCompaniesSearchId(job_id));
      openSimilarCompaniesLoadingDialog();
      onSuccess?.();
    },
    onError: () => {
      enqueueSnackbar(
        <NotificationMessage title="Failed to trigger similar companies search. Please try again or contact support." />,
        { variant: 'error' },
      );
    },
  });
};
