import { http, HttpResponse } from 'msw';

const WIZARD_DATA = {
  slug: 'GrowthEquity',
  steps: [
    {
      title: 'Startup Identification',
      items: [
        {
          type: 'filter',
          filter: 'self_firm_typeL1___',
          default_values: ['Private', null],
        },
        {
          type: 'filter',
          placeholders: {
            max: '500000000',
            min: '0',
          },
          filter: 'self_financials_revenue___',
          default_values: {
            max: null,
            excludeNulls: false,
            min: null,
          },
        },
      ],
    },
    {
      title: 'Growth Metrics',
      items: [
        {
          type: 'filter',
          placeholders: {
            max: null,
            min: '10',
          },
          filter: 'self_financials_revenueCagr_1yr__',
          default_values: {
            excludeNulls: true,
          },
        },
        {
          type: 'filter',
          placeholders: {
            max: null,
            min: '10',
          },
          filter: 'self_financials_revenueCagr_3yr__',
          default_values: {
            max: null,
            excludeNulls: true,
            min: null,
          },
        },
      ],
    },
    {
      title: 'Funding Metrics',
      items: [
        {
          type: 'filter',
          placeholders: {
            max: '500000000',
            min: null,
          },
          filter: 'self_funding_totalfundingamountraised',
          default_values: {
            max: 500000000,
            excludeNulls: true,
            min: null,
          },
        },
        {
          type: 'filter',
          placeholders: {
            max: null,
            min: '2010',
          },
          filter: 'self_funding_latestfundinground_year',
          default_values: {
            max: null,
            excludeNulls: true,
            min: null,
          },
        },
        {
          type: 'filter',
          filter: 'self_funding_latestfundinground_investmenttype',
          default_values: [],
        },
        {
          type: 'filter',
          placeholders: {
            max: '5',
            min: '1',
          },
          filter: 'self_funding_totalnumberoffundingrounds',
          default_values: {
            max: null,
            excludeNulls: true,
            min: null,
          },
        },
      ],
    },
  ],
  description:
    "Build a list in 3 steps. Specify what you're looking for, leave the fields blank on skip section altoghether.",
  wizard_id: '23af9a13-50ba-48f8-9ced-293176bf8d24',
  custom_agg:
    '( self_firm_typeL1___ AND self_financials_revenue___ ) AND ( self_financials_revenueCagr_1yr__ OR self_financials_revenueCagr_3yr__ ) AND ( self_funding_totalfundingamountraised OR self_funding_latestfundinground_year OR self_funding_latestfundinground_investmenttype OR self_funding_totalnumberoffundingrounds )',
  title: 'Growth Scan',
};

export const starterPackWizardController = [
  http.get(`${process.env.REACT_APP_API_URL}/wizard/:id`, () => {
    return HttpResponse.json(WIZARD_DATA);
  }),
];
