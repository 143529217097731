import Typography from '@mui/material/Typography';
import React from 'react';
import { StarterPackWizardStepItemTitle } from '@/types';

export const WizardDialogTitle = ({ item }: { item: StarterPackWizardStepItemTitle }) => (
  <Typography
    variant="h6"
    gutterBottom
  >
    {item.title}
  </Typography>
);
