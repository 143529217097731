import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { generateSemanticBasedSearchPayload } from '@/services/api/elastic-search';
import { ISemanticSearchAIResponse } from '@/types/state/semantic-search';

export const useQueryGenerateSemanticBasedSearchPayload = ({
  enabled,
  onSuccess,
  onError,
}: {
  enabled: boolean;
  onSuccess?: (data: ISemanticSearchAIResponse) => void;
  onError?: () => void;
}) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const { enqueueSnackbar } = useSnackbar();
  const semanticSearch = useShallowSelector((state) => state.search.semanticSearch);

  const description = semanticSearch?.userDescription;

  return useQuery(
    ['generate-semantic-based-search-payload', description],
    () => generateSemanticBasedSearchPayload(description ?? ''),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: isAuth && enabled && !!description,
      onError: () => {
        enqueueSnackbar('Failed to generate search settings. Please try again or contact support.', {
          variant: 'error',
        });

        onError?.();
      },
      onSuccess,
    },
  );
};
