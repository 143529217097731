import { useQuery } from '@tanstack/react-query';
import { get } from 'aws-amplify/api';

import { useSessionStorage } from 'usehooks-ts';
import { useEffect, useRef } from 'react';
import { useShallowSelector } from '../use-shallow-selector';
import { addPendoScript } from '@/services/pendo';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import { CASE_CODE_INITIAL_STATE } from '@/hooks/queries/use-mutation-log-case-code';

export type TSecrets = {
  pendo: string;
};

export const useQuerySecrets = () => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const [uiSecrets, setUiSecrets] = useSessionStorage<TSecrets | undefined>(LOCAL_STORAGE_KEYS.UI_SECRETS, undefined);
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);
  const caseCodeFormData = caseCodeData?.formData;
  const isPendoAttached = useRef(false);

  useEffect(() => {
    if (!uiSecrets) return;

    if (!isPendoAttached.current) {
      addPendoScript(uiSecrets?.pendo, caseCodeFormData?.caseCode);
      isPendoAttached.current = true;
    }
  }, [caseCodeFormData?.caseCode, uiSecrets]);

  return useQuery(['secrets'], (): Promise<TSecrets> =>
    // @ts-ignore
    get({ apiName: 'CDPAPI', path: '/user_interface_secrets' }).response.then(res => res.body.json()),
    {
    enabled: isAuth && !uiSecrets,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess: (response) => setUiSecrets({ pendo: response.pendo }),
    onError: (error) => alert(error),
  });
};
