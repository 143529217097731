import React, { FC, useState, useMemo } from 'react';
import { range, times } from 'lodash';
import { useDispatch } from 'react-redux';

import Skeleton from '@mui/material/Skeleton';
import { TablePagination as Pagination } from '@mui/material';

import { ICompaniesListSnippet, ISavedSearchSnippet } from '@/types';
import { SavedSearchCard } from '@/Components/Shared/SavedSearch/SavedSearchCard';
import { CompaniesListCard } from '@/Components/Shared/CompaniesList/CompaniesListCard';
import {
  DEFAULT_CARDS_PER_PAGE,
  LIST_TYPE,
  ListSectionType,
  ListType,
  actions as searchListsTablesActions,
} from '@/slices/search-lists-tables';

import { useShallowSelector } from '@/hooks/use-shallow-selector';

interface ListSectionProps {
  error?: string;
  isLoading: boolean;
  lists: (ISavedSearchSnippet & { listType: ListType })[] | (ICompaniesListSnippet & { listType: ListType })[];
  skeletonLoaderHeight?: number;
  listType: ListSectionType;
}

export const ListSection: FC<ListSectionProps> = ({
  error,
  isLoading,
  lists,
  skeletonLoaderHeight = 140,
  listType,
}) => {
  const dispatch = useDispatch();
  const cardsPerPage = useShallowSelector((state) => state.searchListsTables[listType].cardsPerPage);
  const [page, setPage] = useState(0);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(searchListsTablesActions.setCardsPerPage({ table: listType, value: parseInt(event.target.value, 10) }));
    setPage(0);
  };

  const visibleCards = useMemo(
    () => lists?.slice(page * cardsPerPage, page * cardsPerPage + cardsPerPage),
    [lists, page, cardsPerPage],
  );

  const renderCards = () =>
    visibleCards.map((item) => {
      if (item.listType === LIST_TYPE.userSearch || item.listType === LIST_TYPE.sharedWithUserSearch) {
        return (
          <SavedSearchCard
            key={item.list_id}
            savedSearch={item}
            isUserSearch={item.listType === LIST_TYPE.userSearch}
          />
        );
      }

      return (
        <CompaniesListCard
          key={item.list_id}
          companiesList={item}
          isUserList={'shared_with_me' in item && !item.shared_with_me}
        />
      );
    });

  const showPagination = lists.length > DEFAULT_CARDS_PER_PAGE;

  return (
    <div className="flex flex-col mt-2">
      <div className="grid gap-4 grid-cols-3 mt-4">
        {error ? <span className="text-[#666] italic text-sm col-span-3">{error}</span> : null}

        {isLoading
          ? range(3).map((idx) => (
              <Skeleton
                key={idx}
                variant="rounded"
                className="bg-[#0000000f] rounded-2xl"
                height={skeletonLoaderHeight}
              />
            ))
          : renderCards()}

        {!isLoading && lists.length === 0 && !error
          ? range(3).map((idx) => (
              <div
                key={idx}
                className=" h-[100px] rounded-2xl border border-dashed"
              />
            ))
          : null}
      </div>
      {showPagination ? (
        <Pagination
          rowsPerPageOptions={times(3, (index) => (index + 1) * DEFAULT_CARDS_PER_PAGE)}
          component="div"
          count={lists?.length ?? 0}
          rowsPerPage={cardsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Show:"
          className="flex mt-2"
        />
      ) : null}
    </div>
  );
};
