import React, { useState, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { useSessionStorage } from 'usehooks-ts';
import classNames from 'classnames';
import { CaseCodeFirstStep } from './CaseCodeFirstStep';
import { CaseCodeSecondStep } from './CaseCodeSecondStep';
import { useSearchCompanies } from '@/hooks/use-search-companies';

import { useModal } from '@/hooks/use-modal';
import { useMutationLogCaseCode } from '@/hooks/queries/use-mutation-log-case-code';
import { LOCAL_STORAGE_KEYS, MODALS, CASE_CODE_FLOWS, CASE_CODE_FORM_STEPS } from '@/constants';

const filterFieldsValues = (fields, formData) => {
  const allowedFields = fields?.map((field) => field.key);

  return formData
    ? Object.keys(formData)
        .filter((key) => allowedFields?.includes(key))
        .reduce((obj, key) => {
          obj[key] = formData?.[key];

          return obj;
        }, {})
    : {};
};

export const CaseCodeDialog = () => {
  const [flowType, setFlowType] = useState(CASE_CODE_FLOWS.CASE_WORK.value);
  const [step, setStep] = useState(CASE_CODE_FORM_STEPS.FIRST);
  const { isOpen, data: modalData, handleClose } = useModal(MODALS.CASE_CODE);
  const { isOpen: isSemanticSearchLoadingDialogOpen } = useModal(MODALS.SEMANTIC_SEARCH_LOADING_DIALOG);
  const { mutate: logCaseCode, isLoading: isCaseCodeMutating } = useMutationLogCaseCode();
  const [uiSecrets] = useSessionStorage(LOCAL_STORAGE_KEYS.UI_SECRETS, undefined);

  const searchCompanies = useSearchCompanies();

  const isFirstStep = step === CASE_CODE_FORM_STEPS.FIRST;
  const isSecondStep = step === CASE_CODE_FORM_STEPS.SECOND;

  const flowData = CASE_CODE_FLOWS[flowType];
  const stepFields = isFirstStep ? flowData?.[CASE_CODE_FORM_STEPS.FIRST] : flowData?.[CASE_CODE_FORM_STEPS.SECOND];

  const defaultValues = useMemo(
    () => filterFieldsValues(stepFields, modalData?.formData),
    [modalData?.formData, stepFields],
  );

  const isEditMode = modalData?.isEditMode;

  useEffect(() => {
    if (modalData?.flowType) setFlowType(modalData?.flowType);
    if (modalData?.step) setStep(modalData?.step);
  }, [modalData?.flowType, modalData?.step]);

  const onSubmit = (formData) => {
    const formattedFormData = {
      formData: filterFieldsValues(stepFields, formData),
      flowType: CASE_CODE_FLOWS[flowType]?.label,
      step,
    };
    const onSuccess = () => {
      modalData?.onSuccess?.(formattedFormData);

      if (!modalData?.searchConfig) return;

      searchCompanies(modalData?.searchConfig.data, modalData?.searchConfig.options);
    };

    logCaseCode(
      {
        ...formattedFormData,
        onSuccess,
      },
      {},
    );
  };

  const handleCloseDialog = () => {
    handleClose();
  };

  const isLoading = !uiSecrets || isCaseCodeMutating;

  const dialogTitle = isFirstStep ? 'Thanks for using Helix!' : 'Don’t have a case code?';
  const dialogSubtitle = isFirstStep
    ? 'Our goal is to become used throughout Bain, to do this we need to understand who’s using our tool.'
    : 'No problem, we may reach out to you to understand how you are using Helix.';

  return (
    <Dialog
      open={isSemanticSearchLoadingDialogOpen ? false : isOpen}
      classes={{
        paper: classNames('bg-white p-5 mt-[140px] w-[500px] self-start'),
      }}
      onClose={isEditMode ? handleCloseDialog : null}
    >
      {isEditMode ? (
        <CloseIcon
          fontSize="small"
          className="absolute right-3 top-4 cursor-pointer"
          onClick={handleCloseDialog}
        />
      ) : null}

      <DialogTitle classes={{ root: 'p-0 ' }}>
        <div className="text-[#484848] text-lg font-semibold">{dialogTitle}</div>
        <div className="text-[#484848] text-sm font-normal pt-4">{dialogSubtitle}</div>
      </DialogTitle>

      {isFirstStep ? (
        <CaseCodeFirstStep
          flowData={flowData}
          isLoading={isLoading}
          isEditMode={isEditMode}
          onSubmit={onSubmit}
          setStep={setStep}
          defaultValues={defaultValues}
        />
      ) : null}

      {isSecondStep ? (
        <CaseCodeSecondStep
          flowData={flowData}
          isLoading={isLoading}
          isEditMode={isEditMode}
          onSubmit={onSubmit}
          setStep={setStep}
          defaultValues={defaultValues}
        />
      ) : null}
    </Dialog>
  );
};
