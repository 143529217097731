import React, { useLayoutEffect, useRef, useState } from 'react';

import { Tooltip } from '@mui/material';

interface CompanyProfileTitleProps {
  label?: string;
}

export const CompanyProfileTitle = ({ label }: CompanyProfileTitleProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth ?? 0);
  }, []);

  if (!label) return null;

  const shouldShowTooltip = width >= 572;

  const content = (
    <div
      ref={ref}
      className="max-w-[580px] self-center truncate"
    >
      <span
        id="company-profile"
        className="text-3xl font-medium text-[#484848] truncate"
      >
        {label}
      </span>
    </div>
  );

  if (shouldShowTooltip) {
    return (
      <Tooltip
        classes={{ tooltip: 'max-w-[320px] bg-[#484848] p-2', arrow: 'text-[#484848]' }}
        arrow
        title={label}
        enterDelay={300}
        enterNextDelay={300}
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};
