import React from 'react';

export const IconCheck = ({ className, style }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.80299 5.5965L7.28032 8.90916L6.19165 7.5185C5.96499 7.22783 5.54499 7.17716 5.25565 7.4045C4.96565 7.63116 4.91432 8.0505 5.14165 8.33983L6.76232 10.4112C6.88899 10.5725 7.08232 10.6665 7.28765 10.6665H7.29232C7.49832 10.6658 7.69299 10.5685 7.81765 10.4038L10.8637 6.40383C11.087 6.1105 11.0303 5.69316 10.737 5.46983C10.443 5.2465 10.0257 5.30383 9.80299 5.5965ZM7.99992 13.3335C5.05925 13.3335 2.66659 10.9408 2.66659 8.00016C2.66659 5.0595 5.05925 2.66683 7.99992 2.66683C10.9406 2.66683 13.3333 5.0595 13.3333 8.00016C13.3333 10.9408 10.9406 13.3335 7.99992 13.3335ZM7.99992 1.3335C4.31792 1.3335 1.33325 4.31883 1.33325 8.00016C1.33325 11.6815 4.31792 14.6668 7.99992 14.6668C11.6819 14.6668 14.6666 11.6815 14.6666 8.00016C14.6666 4.31883 11.6819 1.3335 7.99992 1.3335Z"
      />
    </svg>
  );
};
