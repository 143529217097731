import React from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useDispatch } from 'react-redux';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { HOME_PAGE_SLUG } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/navigation-tabs';
import { removeSavedSearch } from '@/services/api/saved-search';

export const useMutationRemoveSavedSearch = (removeWithRedirect: boolean) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { currentSavedSearch } = useShallowSelector((state) => state.navigationTabs);

  return useMutation({
    mutationKey: ['remove-saved-search'],
    mutationFn: (id: string) => removeSavedSearch(id),
    onSuccess: (response) => {
      if (currentSavedSearch?.list_id === response.list_id) {
        dispatch(actions.setCurrentSavedSearch(null));
      }

      queryClient.invalidateQueries({ queryKey: ['saved-searches'] });

      if (removeWithRedirect) {
        history.push(HOME_PAGE_SLUG);
      }

      enqueueSnackbar(<NotificationMessage title={'Saved search has been removed'} />, { variant: 'success' });
    },

    onError: () =>
      enqueueSnackbar(
        <NotificationMessage title="Failed to remove saved search. Please try again or contact support." />,
        { variant: 'error' },
      ),
  });
};
