import { useQuery } from '@tanstack/react-query';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { fetchChartConfigs } from '@/services/api/charts';

export const useQueryChartConfigs = () => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);

  return useQuery(['charts'], () => fetchChartConfigs(), {
    enabled: isAuth,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
