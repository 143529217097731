import _, { isNil, isNumber } from 'lodash';

import { ReactNode } from 'react';
import { Nullish } from '@/types';
import { IconDoubleCheck } from '@/Components/Icons/IconDoubleCheck';
import { IconCheck } from '@/Components/Icons/IconCheck';
import { IconAlert } from '@/Components/Icons/IconAlert';

type TData = {
  text: Nullish<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: any;
  color?: Nullish<string>;
} | null;

export const getConfidenceLevelData = _.cond<number, TData>([
  [_.isNil, _.constant(null)],
  [
    (score) => score < 0.3,
    _.constant({
      text: 'LOW',
      Icon: IconAlert,
      color: 'red',
    }),
  ],
  [
    (score) => score >= 0.3 && score <= 0.7,
    _.constant({
      text: 'NORMAL',
      Icon: null,
    }),
  ],
  [
    (score) => score > 0.7 && score <= 0.9,
    _.constant({
      text: 'HIGH',
      Icon: IconCheck,
      color: 'green',
    }),
  ],
  [
    (score) => score > 0.9,
    _.constant({
      text: 'VERY HIGH',
      Icon: IconDoubleCheck,
      color: 'green',
    }),
  ],
]);

type TGetShouldIconShowed = {
  isConfidenceLevelChecked: boolean;
  IconComponent: Nullish<ReactNode>;
  returnValue: unknown;
};

export const getShouldIconShowed = ({ isConfidenceLevelChecked, IconComponent, returnValue }: TGetShouldIconShowed) =>
  isConfidenceLevelChecked && !!IconComponent && !isNil(returnValue) && returnValue !== 'UNKNOWN';

export const getCellColorStyle = (value: unknown) => {
  if (!isNumber(value)) {
    return {};
  }

  if (value > 0.5) return { backgroundColor: '#DEF3E5', color: '#4BBE70' };
  if (value < -0.5) return { backgroundColor: '#FAC8CB', color: '#CC2936' };

  return {
    backgroundColor: '#DDDDDD',
    color: '#8a8a8a',
  };
};
