import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useDispatch } from 'react-redux';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { IUpdateSavedSearchVariables } from '@/types';
import { updateSavedSearch } from '@/services/api/saved-search';
import { createSavedSearchPath } from '@/Components/Shared/SavedSearch/ShareSavedSearchButton';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/navigation-tabs';

export const useMutationUpdateSavedSearch = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { currentSavedSearch } = useShallowSelector((state) => state.navigationTabs);

  return useMutation({
    mutationKey: ['update-saved-search'],
    mutationFn: ({ id, fieldsToUpdate }: IUpdateSavedSearchVariables) =>
      updateSavedSearch({ id, body: fieldsToUpdate }),
    onSuccess: (response, { showDefaultNotification, invalidateResponse }) => {
      if (currentSavedSearch?.list_id === response?.list_id) {
        dispatch(actions.setCurrentSavedSearch(response));
      }

      if (invalidateResponse) {
        queryClient.invalidateQueries({ queryKey: ['saved-search', response.list_id] });
        queryClient.invalidateQueries({ queryKey: ['saved-searches'] });
      }

      if (showDefaultNotification) {
        enqueueSnackbar(
          <NotificationMessage
            title={`The ${response.title} search has been successfully updated`}
            href={{
              to: createSavedSearchPath(response.title, response.list_id),
              name: 'Go to the saved search',
            }}
          />,
          { variant: 'success' },
        );
      }

      onSuccess?.();
    },
    onError: () => {
      enqueueSnackbar(
        <NotificationMessage title="Failed to update saved search. Please try again or contact support." />,
        { variant: 'error' },
      );
    },
  });
};
