import React, { MouseEvent, FC } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { CardMenu, CardMenuItem } from './CardMenu';
import { useMenu } from '@/hooks/use-menu';

interface CardProps {
  onClick: () => void;
  title: string;
  menuItems: CardMenuItem[];
  icon: React.ReactElement;
}

export const Card: FC<CardProps> = ({ onClick, title, menuItems, children, icon }) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    handleClick(event);
  };

  const handleCardClick = () => {
    if (!isOpen) {
      onClick();
    }
  };

  return (
    <div
      onClick={handleCardClick}
      className="flex flex-col  justify-between rounded-lg border border-[#ddd] p-4 gap-3 shadow-[0_1px_3px_0px_rgba(46,48,48,0.14)] cursor-pointer min-h-[140px]"
    >
      <div className="grid grid-cols-[40px_1fr_34px] items-center ">
        {icon}

        <span className="text-lg text-[#484848] font-medium truncate mr-3">{title}</span>

        <IconButton
          onClick={handleOpen}
          size="small"
        >
          <MoreVertIcon />
        </IconButton>
      </div>

      {children}

      <CardMenu
        menuItems={menuItems}
        isOpen={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </div>
  );
};
