import { http, HttpResponse } from 'msw';

export const categoricalFilterDataMock = [
  {
    value: 'Active',
    active: 16784509,
    _backend: 'self_firm_statusL2___',
  },
  {
    value: 'Active Parent',
    active: 348022,
    _backend: 'self_firm_statusL2___',
  },
  {
    value: 'Active Subsidiary',
    active: 1393231,
    _backend: 'self_firm_statusL2___',
  },
  {
    value: 'Not a company status',
    active: 263396,
    _backend: 'self_firm_statusL2___',
  },
  {
    value: 'UNKNOWN',
    active: 24527905,
    _backend: 'self_firm_statusL2___',
  },
];

export const numericalFilterDataMock = {
  min: 0,
  hint_min: '0',
  max: 2300000,
  hint_max: '4M',
};

export const treeFilterDataMock = [
  {
    value: 'Business Services',
    active: 139783,
    _backend: 'parent_sector_sector___',
    children: [
      {
        value: 'BPO',
        active: 361,
        _backend: 'parent_sector_industryGroup___',
        children: [
          {
            value: 'BPO - Unspecified',
            active: 253,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'BPO - Unspecified',
                active: 253,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
          {
            value: 'Customer Management (Callcenters)',
            active: 1,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Customer Management (Callcenters)',
                active: 1,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
        ],
      },
      {
        value: 'Business Services - Unspecified',
        active: 19944,
        _backend: 'parent_sector_industryGroup___',
        children: [
          {
            value: 'Business Services - Unspecified',
            active: 19944,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Business Services - Unspecified',
                active: 19942,
                _backend: 'parent_sector_subIndustry___',
              },
              {
                value: 'UNKNOWN',
                active: 2,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    value: 'Consumer',
    active: 200109,
    _backend: 'parent_sector_sector___',
    children: [
      {
        value: 'Consumer - Unspecified',
        active: 28,
        _backend: 'parent_sector_industryGroup___',
        children: [
          {
            value: 'Consumer - Unspecified',
            active: 28,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Consumer - Unspecified',
                active: 28,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
        ],
      },
      {
        value: 'Consumer Durables & Apparel',
        active: 35174,
        _backend: 'parent_sector_industryGroup___',
        children: [
          {
            value: 'Household Durables',
            active: 8875,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Home Furnishings',
                active: 3718,
                _backend: 'parent_sector_subIndustry___',
              },
              {
                value: 'Household Appliances',
                active: 3337,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
          {
            value: 'Leisure Products',
            active: 4648,
            _backend: 'parent_sector_industry___',
            children: [
              {
                value: 'Leisure Products - Other',
                active: 2,
                _backend: 'parent_sector_subIndustry___',
              },
              {
                value: 'Leisure Products - Unspecified',
                active: 4643,
                _backend: 'parent_sector_subIndustry___',
              },
            ],
          },
        ],
      },
    ],
  },
];

export const availableNumericalFilterMock = {
  backendName: 'self_firmo_employeeCount___',
  displayName: 'Employee Count',
  __type__: 'numerical',
  unit: 'count',
  filterItems: numericalFilterDataMock,
};

export const availableCategoricalFilterMock = {
  backendName: 'self_firm_statusL2___',
  displayName: 'Status Detail',
  __type__: 'categorical',
  unit: null,
  filterItems: categoricalFilterDataMock,
};
export const availableTreeFilterMock = {
  backendName: 'parent_sector_sector___',
  displayName: 'Parent Sector',
  __type__: 'tree',
  unit: null,
  filterItems: treeFilterDataMock,
};

export const availableCategoryFiltersMock = [
  availableNumericalFilterMock,
  availableCategoricalFilterMock,
  availableTreeFilterMock,
];

export const availableFiltersMock = [
  {
    displayHeader: 'Firmographics',
    items: [
      availableNumericalFilterMock,
      {
        backendName: 'self_financials_revenue___',
        displayName: 'Revenue',
        __type__: 'numerical',
        unit: 'count',
        filterItems: numericalFilterDataMock,
      },
      availableCategoricalFilterMock,
    ],
  },
  {
    displayHeader: 'Parent',
    items: [availableTreeFilterMock],
  },
];

export const filtersController = [
  http.post(`${process.env.REACT_APP_API_URL}/search/stats`, () =>
    HttpResponse.json({
      self_firmo_employeeCount___: numericalFilterDataMock,
      self_firm_statusL2___: categoricalFilterDataMock,
      parent_sector_sector___: treeFilterDataMock,
    }),
  ),
  http.get(`${process.env.REACT_APP_API_URL}/filters/available`, () =>
    HttpResponse.json({
      data: availableFiltersMock,
    }),
  ),
];
