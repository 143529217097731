import { http, HttpResponse } from 'msw';

export const itCompaniesListInputPrompt = 'give me a list of IT companies';

export const smartSearchController = [
  http.post(`${process.env.REACT_APP_API_URL}/smart_search`, async ({ request }) => {
    const requestBody = await request.json();
    const userInput = requestBody?.user_input;

    if (userInput === itCompaniesListInputPrompt) {
      return HttpResponse.json({
        suggested_query: 'IT OR tech',
      });
    }

    return HttpResponse.json({
      suggested_query: 'test',
    });
  }),
];
