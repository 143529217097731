export const COMPANY_PROFILE_PIVOT_TABLE = {
  data: [
    {
      'Backend Name': 'bain_id',
      'Display Name': 'Bain ID',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: null,
      NUMERIC_PRECISION: null,
      'Display Header': null,
      'Display Rank': 1.0,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: false,
      SORTABLE_COLUMN: false,
    },
    {
      'Backend Name': 'DOMAIN',
      'Display Name': 'Domain',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: null,
      NUMERIC_PRECISION: null,
      'Display Header': null,
      'Display Rank': 2.0,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: true,
      SORTABLE_COLUMN: false,
    },
    {
      'Backend Name': 'UNITS',
      'Display Name': 'Units',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: null,
      NUMERIC_PRECISION: null,
      'Display Header': null,
      'Display Rank': 3.0,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: true,
      SORTABLE_COLUMN: false,
    },
    {
      'Backend Name': 'YEAR_MONTH',
      'Display Name': 'Year-Month',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: null,
      NUMERIC_PRECISION: null,
      'Display Header': null,
      'Display Rank': 4.0,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: true,
      SORTABLE_COLUMN: false,
    },
    {
      'Backend Name': 'TOTAL_VISITS',
      'Display Name': 'Visits',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: '#',
      NUMERIC_PRECISION: 0,
      'Display Header': null,
      'Display Rank': 5.0,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: true,
      SORTABLE_COLUMN: true,
    },
  ],
};

export const COMPANY_PROFILE_TABLE = {
  data: [
    {
      'Backend Name': 'bain_id',
      'Display Name': 'Bain ID',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: null,
      NUMERIC_PRECISION: null,
      'Display Header': null,
      'Display Rank': 1,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: false,
      SORTABLE_COLUMN: false,
    },
    {
      'Backend Name': 'DOMAIN',
      'Display Name': 'Domain',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: null,
      NUMERIC_PRECISION: null,
      'Display Header': null,
      'Display Rank': 2,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: true,
      SORTABLE_COLUMN: false,
    },
    {
      'Backend Name': 'COUNTRY',
      'Display Name': 'Country',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: null,
      NUMERIC_PRECISION: null,
      'Display Header': null,
      'Display Rank': 3,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: true,
      SORTABLE_COLUMN: false,
    },
    {
      'Backend Name': 'TRAFFIC_SHARE',
      'Display Name': 'Traffic Share',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: '%',
      NUMERIC_PRECISION: 1,
      'Display Header': null,
      'Display Rank': 4,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: true,
      SORTABLE_COLUMN: true,
    },
    {
      'Backend Name': 'TOTAL_VISITS',
      'Display Name': 'Visits',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: '#',
      NUMERIC_PRECISION: 0,
      'Display Header': null,
      'Display Rank': 5,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: true,
      SORTABLE_COLUMN: false,
    },
    {
      'Backend Name': 'YEAR_MONTH',
      'Display Name': 'Year-Month',
      FILTER_COLUMN: null,
      NUMERIC_TYPE: null,
      NUMERIC_PRECISION: null,
      'Display Header': null,
      'Display Rank': 6,
      taxonomy_parent: null,
      ultimate_parent: null,
      DEFAULT_COLUMN: true,
      SORTABLE_COLUMN: false,
    },
  ],
};
