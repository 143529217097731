import { useQuery } from '@tanstack/react-query';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getColumnMapperDataset } from '@/services/api/column-mapper';

export const useQueryColumnMapperDataset = (dataset: string) => {
  const currency = useShallowSelector((state) => state.config.currency);
  const isAuth = useShallowSelector((state) => state.user.isAuth);

  return useQuery(['column-mapper-dataset', dataset, currency], () => getColumnMapperDataset(dataset, currency), {
    enabled: isAuth && !!dataset,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
