import { useQuery } from '@tanstack/react-query';
import { get } from 'aws-amplify/api';

import { useDispatch } from 'react-redux';
import { pickBy, uniq } from 'lodash';
import { useShallowSelector } from '../../use-shallow-selector';
import { FiltersAvailableData } from '@/types';
import { TAppDispatch } from '@/store';
import { actions } from '@/slices/filters';
import { getAvailableFilterIdsWithType } from '@/Utils/filters';

export const useQueryAvailableFilters = () => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.config.currency);
  const dispatch = useDispatch<TAppDispatch>();
  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);

  return useQuery(
    ['/filters/available', currency],
    (): Promise<FiltersAvailableData[]> =>
      get({ apiName: 'CDPAPI', path: `/filters/available?currency=${currency}` })
        .response.then((res) => res.body.json())
        // @ts-ignore
        .then((response) => response.data),
    {
      enabled: !!currency && isAuth,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: 1,
      onSuccess: (availableFilters) => {
        if (availableFilters && !includedNullList.length) {
          const availableFilterIdsWithType = getAvailableFilterIdsWithType(availableFilters);

          const numericalFilterIds = pickBy(availableFilterIdsWithType, (value) => value === 'numerical');

          dispatch(actions.setIdsToNullList(uniq(Object.keys(numericalFilterIds))));
        }
      },
    },
  );
};
