import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isArray, isNil } from 'lodash';

import { dataNotAvailable, financialRevenueValue, getCurrencyFormat } from '../../common';
import { Link } from '../../Link';
import { getCellColorStyle, getConfidenceLevelData, getShouldIconShowed } from './utils';
import { CommonCellTooltip, TTooltipPlacement } from './CommonCellToltip';
import { MultiValueCell } from './cells/multi-value-cell';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { ColumnFilterType, IElasticTableData } from '@/types';
import { useTableLoading } from '@/hooks/table/use-table-loading';
import { formatCompanyDescription } from '@/Utils/text';

export interface IProps extends GridRenderCellParams {
  displayName: string;
  row: IElasticTableData;
  filterType?: ColumnFilterType;
  numericPrecision?: number;
}

export interface ITooltip {
  children: JSX.Element;
  placement?: TTooltipPlacement;
  displayName: string;
  field: string;
  row: IElasticTableData;
}

const Tooltip = ({ children, placement, displayName, row, field }: ITooltip) => {
  return (
    <CommonCellTooltip
      displayName={displayName}
      row={row}
      field={field}
      placement={placement}
    >
      {children}
    </CommonCellTooltip>
  );
};

export const CommonCell = ({ displayName, field, row, filterType, numericPrecision }: IProps) => {
  const isLoading = useTableLoading();
  const currency = useShallowSelector((state) => state.config.currency);
  const isConfidenceLevelChecked = useShallowSelector((state) => state.table.isConfidenceLevelChecked);

  if (isLoading) {
    return (
      <Skeleton
        data-testid="table-skeleton-loader"
        className="w-full h-full bg-[#0000000f]"
      />
    );
  }

  const returnValue = row[field];
  const resultConfidence = row[`${field}confidence`];
  const resultMetadata = row[`${field}metadata`];
  const confidenceLevelData = getConfidenceLevelData(resultConfidence as number);
  const IconComponent = confidenceLevelData?.Icon;
  const cellColorStyle = getCellColorStyle(returnValue);
  const hasUnknownValue = isNil(returnValue) || returnValue === 'UNKNOWN';
  const hideCellTooltip = (!resultMetadata && !row[field]) || hasUnknownValue;
  const isWebpage = field === 'self_firmo_webpage___' || field === 'DOMAIN';

  const shouldIconShowed = getShouldIconShowed({
    isConfidenceLevelChecked,
    IconComponent,
    returnValue,
  });

  const Icon = shouldIconShowed ? (
    <IconComponent
      className="absolute w-[15px] left-[5px]"
      style={{ fill: confidenceLevelData?.color }}
    />
  ) : null;

  const isCompanyDescriptionField = field === 'self_firmo_description___';
  const isMultiValue = isArray(returnValue);

  if (filterType === '%') {
    const percent = isNil(returnValue) ? undefined : Number(returnValue);

    return (
      <Tooltip
        displayName={displayName}
        row={row}
        field={field}
      >
        <div
          style={cellColorStyle}
          className="overflow-hidden m-auto px-[10px] py-[3px] rounded-md leading-none font-medium flex items-center"
        >
          {Icon}
          <div className="pl-4 flex items-center">
            <span className="w-full inline-block h-full truncate">
              {!isNil(percent) && !isNaN(percent) ? `${percent.toFixed(numericPrecision)}%` : dataNotAvailable()}
            </span>
          </div>
        </div>
      </Tooltip>
    );
  } else if ((filterType === 'EUR' || filterType === 'USD') && field === 'self_financials_revenue___') {
    const value = !isNil(returnValue)
      ? financialRevenueValue(
          Number(row['self_financials_revenue___']),
          Number(row['self_financials_revenue___min']),
          Number(row['self_financials_revenue___max']),
          currency,
          numericPrecision,
        )
      : dataNotAvailable(!hideCellTooltip);

    return (
      <Tooltip
        displayName={displayName}
        row={row}
        field={field}
      >
        <div className="w-full h-full flex items-center">
          {Icon}
          <div className="pl-4 w-full h-full flex items-center">
            <span className="w-full inline-block truncate">{value}</span>
          </div>
        </div>
      </Tooltip>
    );
  } else if (filterType === 'EUR' || filterType === 'USD') {
    const value = !isNil(returnValue)
      ? getCurrencyFormat(Number(returnValue), filterType, numericPrecision)
      : dataNotAvailable(!hideCellTooltip);

    return (
      <Tooltip
        displayName={displayName}
        row={row}
        field={field}
      >
        <div className="w-full h-full flex items-center">
          {Icon}
          <div className="pl-4 w-full h-full flex items-center">
            <span className="w-full inline-block truncate">{value}</span>
          </div>
        </div>
      </Tooltip>
    );
  } else if (isMultiValue) {
    return (
      <MultiValueCell
        displayName={displayName}
        field={field}
        row={row}
        values={returnValue}
        Icon={Icon}
      />
    );
  } else {
    let result = null;

    if (hasUnknownValue) {
      result = <span>{dataNotAvailable(!hideCellTooltip)}</span>;
    } else if (typeof returnValue === 'number') {
      const precision = 10 ** (numericPrecision || 0);
      const roundedResult = Math.round((returnValue + Number.EPSILON) * precision) / precision;

      result = (
        <span className="w-full inline-block truncate">
          {filterType === '#' ? roundedResult.toLocaleString('en-US') : roundedResult}
        </span>
      );
    } else if (isCompanyDescriptionField) {
      result = (
        <span className="w-full inline-block truncate">{formatCompanyDescription(returnValue)?.description}</span>
      );
    } else if (isWebpage) {
      result = (
        <Link
          href={`https://${returnValue}`}
          className="truncate"
        >
          {returnValue}
        </Link>
      );
    } else {
      result = <span className="w-full inline-block truncate">{returnValue}</span>;
    }

    return (
      <Tooltip
        displayName={displayName}
        row={row}
        field={field}
        placement="bottom-start"
      >
        <div className="w-full h-full flex items-center">
          {Icon}
          <div className="pl-4 w-full h-full flex items-center">{result}</div>
        </div>
      </Tooltip>
    );
  }
};
