import React from 'react';

export const IconClear = (props) => {
  const { fill, ...rest } = props;

  return (
    <svg
      {...rest}
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.92 11.188a.834.834 0 0 0-1.048.542c-.78 2.455-3.079 4.103-5.719 4.103-3.301 0-5.986-2.617-5.986-5.833s2.685-5.833 5.986-5.833c1.44 0 2.799.501 3.871 1.391l-1.805-.297a.826.826 0 0 0-.958.686.832.832 0 0 0 .687.957l3.538.585a.855.855 0 0 0 .422-.04c.031-.012.056-.033.086-.05.055-.027.113-.052.16-.092.03-.025.05-.06.075-.088.04-.04.081-.08.11-.13.021-.036.03-.078.045-.118.02-.05.047-.095.057-.15l.628-3.333a.833.833 0 1 0-1.638-.309l-.227 1.2a7.692 7.692 0 0 0-5.05-1.88C5.933 2.5 2.5 5.865 2.5 10c0 4.137 3.433 7.5 7.653 7.5 3.369 0 6.305-2.115 7.308-5.264a.834.834 0 0 0-.542-1.047Z"
        fill={fill ?? '#2E3F4C'}
      />
    </svg>
  );
};
