import { http, HttpResponse } from 'msw';

export const MOCKED_SIMILAR_COMPANIES_NEW_JOB_ID = 'new-job-id';
export const MOCKED_SIMILAR_COMPANIES_NEW_JOB_PROGRESS = 0;
export const MOCKED_SIMILAR_COMPANIES_IN_PROGRESS_JOB_ID = 'in-progress-job-id';
export const MOCKED_SIMILAR_COMPANIES_IN_PROGRESS_JOB_PROGRESS = 50;
export const MOCKED_SIMILAR_COMPANIES_FAILED_JOB_ID = 'failed-job-id';
export const MOCKED_SIMILAR_COMPANIES_SUCCESS_JOB_ID = 'success-job-id';
export const MOCKED_SIMILAR_COMPANIES_RESPONSE_QUERY = 'google AND amazon';

export const configController = [
  http.get(`${process.env.REACT_APP_API_URL}/config/starter_pack`, () => {
    return HttpResponse.json({
      key: 'starter_pack',
      tiles: [
        {
          icon: 'https://d1v3le3p9duxrt.cloudfront.net/bill_by_email.svg',
          link_type: 'search-list',
          description: 'A static list of top companies at the end of 2023.',
          backgroundColor: '#EBF9F2',
          title: 'London Stock Exchange Top Companies',
          link_id: '00000003-0000-0000-0000-000000000000',
        },
        {
          icon: 'https://d1v3le3p9duxrt.cloudfront.net/graph.svg',
          link_type: 'saved-search',
          description: 'Ready to go search with filters pre-applied for these types of company.',
          backgroundColor: '#FFF4F4',
          title: 'Growing Start-Ups',
          link_id: null,
        },
        {
          icon: 'https://d1v3le3p9duxrt.cloudfront.net/funds.svg',
          link_type: 'saved-search',
          description: 'Companies that have had a significant amount of investment in the last year.',
          backgroundColor: '#F3F7FF',
          title: 'Recent funding injections',
          link_id: null,
        },
      ],
    });
  }),
  http.get(`${process.env.REACT_APP_API_URL}/config/section_order`, () => {
    return HttpResponse.json({
      key: 'section_order',
      section_order: [
        'Default',
        'Location',
        'Industry',
        'Firmographics',
        'Web Traffic',
        'Financials',
        'M&A',
        'Funding',
        'Fragmentation',
        'Executives',
        'Ownership',
        'Parent',
        'Subsidiaries',
        'Funding',
        'Investment Themes',
        'Investment Theme - Sector M&A',
        'Investment Theme - Platform Consolidation',
        'Investment Theme - Potential Carve Out',
        'Investment Theme - Debt Maturity',
        'Investment Theme - Generational Change',
        'Investment Theme - Leadership Change',
        'Investment Theme - Growth Ambition',
        'Investment Theme - Internal M&A',
        'News',
        'Others',
      ],
    });
  }),
];
