import React from 'react';

import { ErrorPage } from '@/Components/Shared/ErrorPage';
import { ERROR_PAGE_PROPS } from '@/constants';

const PageNotFound = () => {
  return <ErrorPage {...ERROR_PAGE_PROPS.PAGE_NOT_FOUND} />;
};

export default PageNotFound;
