import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useShallowSelector } from './use-shallow-selector';
import { actions } from '@/slices/modal';

export const useModal = <T = Record<string, unknown>>(modalId: string) => {
  const dispatch = useDispatch();
  const isOpen = useShallowSelector((state) => state.modal?.[modalId]?.isOpen ?? false);
  const data = useShallowSelector<T>((state) => state.modal?.[modalId]?.data as T);

  const handleOpen = useCallback(
    // @ts-ignore
    (additionalData: T = {}) => {
      dispatch(actions.openModal({ id: modalId, data: additionalData }));
    },
    [dispatch, modalId],
  );

  const handleClose = useCallback(() => {
    dispatch(actions.closeModal({ id: modalId, data: {} }));
  }, [dispatch, modalId]);

  return {
    isOpen,
    data,
    handleOpen,
    handleClose,
  };
};
