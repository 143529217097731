import { reduce, isEmpty, isNull, cloneDeep, isNil, isUndefined, isArray, sortBy } from 'lodash';
import { numberFormatterCustom } from '@/Components/Shared/common';

import { HIDE_FILTERS_COUNT, CURRENCIES, FILTER_TYPES } from '@/constants';
import {
  TREE_TOP_LEVEL_NODE_KEY,
  TREE_TOP_LEVEL_NODE_VALUE,
} from '@/Components/Shared/FiltersDrawer/FilterHeader/FilterItem/FilterTree';

const filtersLabelMapper = {
  Y: 'Yes',
  N: 'No',
  [null]: 'Unclassified',
  UNKNOWN: 'Missing',
  UNOWNED: 'Unowned',
};

export const getFilterValueLabel = (value) => filtersLabelMapper[value] ?? value;

const isTreeFiltersLegacy = (value) =>
  Array.isArray(value) &&
  value.every((filter) => typeof filter === 'string' && filter.startsWith('{') && filter.endsWith('}'));

const parseTreeFilterLegacy = (value) => {
  const nodes = value
    .map(JSON.parse)
    .filter((treeNode) => !treeNode.hasParentNodeChecked && !treeNode.halfChecked)
    .map((node) => node.path)
    .toSorted((arr1, arr2) => arr1.length - arr2.length);

  const minifiedCheckedNodes = Array.from(
    nodes.reduce((accumulator, key) => {
      for (let i = 0; i < key.length; i++) {
        if (accumulator.has(JSON.stringify(key.slice(0, i)))) {
          return accumulator;
        }
      }

      accumulator.add(JSON.stringify(key));

      return accumulator;
    }, new Set([])),
  );

  return minifiedCheckedNodes;
};

export const parseTreeFilters = (value) => (isTreeFiltersLegacy(value) ? parseTreeFilterLegacy(value) : value);

export const fixTreeFilters = (storeFilters) => {
  if (!storeFilters) return;

  const treeFilters = Object.fromEntries(
    Object.entries(storeFilters?.treeFilters).map(([key, value]) => [key, parseTreeFilters(value)]),
  );

  return { ...storeFilters, ...{ treeFilters } };
};

export const getCheckedTreeFilters = (treeFilters) => {
  // Builds API call from Saved Search state representation

  return Object.fromEntries(
    Object.entries(treeFilters).map(([key, value]) => [
      key,
      value.map((v) => JSON.parse(v).reduce((o1, o2) => Object.assign(o1, o2), {})),
    ]),
  );
};

export const getFilters = ({ treeFilters, otherFilters }) => ({
  ...reduce(
    {
      ...getCheckedTreeFilters(treeFilters),
      ...otherFilters,
    },
    (acc, item, id) => {
      if (!isEmpty(item)) {
        acc[id] = item;
      }

      return acc;
    },
    {},
  ),
});

export const getEffectiveIncludeNullList = (includedNullList, storeFilters) =>
  includedNullList.filter((filter) => filter in getFilters(storeFilters));

export const getFiltersForElasticSearch = (filters) =>
  reduce(getFilters(filters), (acc, item, id) => acc.concat({ [id]: item }), []);

export const getAvailableFilterIdsWithType = (availableFilters) =>
  (availableFilters ?? []).reduce((acc, filter) => {
    filter.items.forEach(({ backendName, __type__ }) => {
      acc[backendName] = __type__;
    });

    return acc;
  }, {});

export const getFilterIdsByType = (availableFilters, filters, type) => {
  const availableFilterIdsWithType = getAvailableFilterIdsWithType(availableFilters);

  return reduce(
    filters,
    (acc, item, id) => {
      if (availableFilterIdsWithType[id] === type) {
        acc.push(id);
      }

      return acc;
    },
    [],
  );
};

const getColumnInfoBeKey = (columnMapper, beKey) => columnMapper.find((x) => x['Backend Name'] === beKey);

const getColumnDisplayLabel = (columnMapper, beKey) => getColumnInfoBeKey(columnMapper, beKey)?.['Display Name'];

export const transformFiltersArrayToDisplayStr = (value) => value.map(getFilterValueLabel).join(', ');

const getNumericalFormattedValue = (value, unit) => {
  const localeStringValue = value?.toLocaleString('en');

  if (!unit) return localeStringValue;

  if (unit === 'percent') return `${localeStringValue}%`;

  if (unit === 'EUR' || unit === 'USD') return `${CURRENCIES[unit].label}${numberFormatterCustom(value)}`;

  if (unit === 'year') return value;

  return localeStringValue;
};

export const transformFilterNumericValueToDisplayStr = (value, unit) => {
  const minFormattedValue = getNumericalFormattedValue(value.min, unit);
  const maxFormattedValue = getNumericalFormattedValue(value.max, unit);

  if (isNil(value.min) && !isNil(value.max)) {
    return `<=${maxFormattedValue}`;
  }

  if (!isNil(value.min) && isNil(value.max)) {
    return `>=${minFormattedValue}`;
  }

  if (isNil(value.min) && isNil(value.max)) {
    return '';
  }

  return `${minFormattedValue}-${maxFormattedValue}`;
};

export const getFilterValueDisplayStr = (value, unit) => {
  if (Array.isArray(value)) {
    return transformFiltersArrayToDisplayStr(value);
  }

  if (value.min?.toString() || value.max?.toString()) {
    return transformFilterNumericValueToDisplayStr(value, unit);
  }

  if (isNull(value.min) && isNull(value.max)) {
    return 'exclude missing values';
  }

  return value;
};

export const getTreeFiltersStr = (columnMapper, values) => {
  const resultsObj = {};

  values.forEach((x) => {
    const { key, value } = JSON.parse(x);

    if (resultsObj[key]) {
      resultsObj[key].values.push(value);
    } else {
      resultsObj[key] = {
        label: getColumnDisplayLabel(columnMapper, key),
        backendName: key,
        values: [value],
      };
    }
  });

  return Object.values(resultsObj).map(({ label, values: resultValues, backendName }) => ({
    label,
    value: resultValues.join(', '),
    backendName,
  }));
};

export const getParentNode = (key, tree) => {
  let parentNode;

  for (const element of tree) {
    const node = element;

    if (node.children) {
      if (node.children.some((item) => item?.key === key)) {
        parentNode = node;
      } else if (getParentNode(key, node.children)) {
        parentNode = getParentNode(key, node.children);
      }
    }
  }

  return parentNode;
};

export const getParentKey = (key, tree) => {
  let parentKey;

  for (const element of tree) {
    const node = element;

    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }

  return parentKey;
};

export const prepareFilterTree = ({ data, categoryFilterId, Icon, path = null, isFetching, withoutCounts }) =>
  data.map((node) => {
    const hideFiltersCount = HIDE_FILTERS_COUNT || withoutCounts;

    // when called with top-level tree node ("All"), path is null.
    // Then each subsequent code appends tree values.
    const joinedPath = path === null ? [] : [...path, { [node._backend]: node.value }];
    const filterCount = isFetching ? '' : ` (${node.active.toLocaleString('en')})`;

    return {
      key: JSON.stringify(joinedPath),
      title: `${getFilterValueLabel(node.value)}${hideFiltersCount ? '' : filterCount}`,
      description: node?.description ?? null,
      icon: Icon,
      ...(node?.children && {
        children: prepareFilterTree({
          data: node.children,
          categoryFilterId: categoryFilterId ?? node.categoryFilterId,
          Icon,
          path: joinedPath,
          isFetching,
          withoutCounts,
        }),
      }),
    };
  });

export const normalizeFilters = (filters) => {
  const filtersCopy = cloneDeep(filters);

  Object.keys(filtersCopy).forEach((key) => {
    const filterFiled = filtersCopy[key];
    const isNumericalFilter =
      !Array.isArray(filterFiled) && (!isUndefined(filterFiled.min) || !isUndefined(filterFiled.max));

    if (isNumericalFilter) {
      filtersCopy[key] = {
        ...(!isUndefined(filterFiled.min) && { min: filterFiled.min }),
        ...(!isUndefined(filterFiled.max) && { max: filterFiled.max }),
      };
    }
  });

  return filtersCopy;
};

export const getCurrentFiltersToDisplay = (filters, columnMapper) => {
  const keyObj = filters?.otherFilters ?? {};
  const filtersToDisplay = []; // e.g { label: 'Location', value: '' }

  Object.values(filters?.treeFilters ?? {}).forEach((values) => {
    filtersToDisplay.push(...getTreeFiltersStr(columnMapper, values));
  });

  Object.entries(keyObj).forEach(([beKey, value]) => {
    const info = getColumnInfoBeKey(columnMapper, beKey);

    filtersToDisplay.push({
      label: info?.['Display Name'],
      value: getFilterValueDisplayStr(value),
    });
  });

  if (filters?.includedNullList?.length > 0) {
    filtersToDisplay.push({
      label: 'Filters including null values',
      value: filters.includedNullList.map((x) => getColumnInfoBeKey(columnMapper, x)?.['Display Name']).join(', '),
    });
  }

  return filtersToDisplay;
};

const isTextContainsSearchValue = (searchValue, text) => text?.toLowerCase().includes(searchValue.toLowerCase());

export const treeHasHighlightedValue = (searchValue, data, objectKey) => {
  if (!data) return false;

  return data?.some(
    (node) =>
      isTextContainsSearchValue(searchValue, getFilterValueLabel(node[objectKey])) ||
      treeHasHighlightedValue(searchValue, node.children, objectKey),
  );
};

export const getFilteredFilters = (availableFiltersWithDetails, searchValue) => {
  return availableFiltersWithDetails
    .map((availableFilterWithDetails) => {
      return {
        ...availableFilterWithDetails,
        items: availableFilterWithDetails.items.filter((filter) => {
          const { __type__, filterItems: availableFilterItems, displayName } = filter;

          const lowerCaseDisplayName = displayName.toLowerCase();
          const lowerCaseSearchValue = searchValue.toLowerCase();

          if (__type__ === 'numerical') {
            return lowerCaseDisplayName.includes(lowerCaseSearchValue);
          }

          if (__type__ === 'categorical' || (__type__ === 'multiple-select' && isArray(availableFilterItems))) {
            const isCategoricalTitleHighlighted = lowerCaseDisplayName.includes(lowerCaseSearchValue);

            const isCategoricalItemHighlighted = availableFilterItems?.some(({ value }) =>
              getFilterValueLabel(value).toLowerCase().includes(lowerCaseSearchValue),
            );

            return isCategoricalTitleHighlighted || isCategoricalItemHighlighted;
          }

          if (__type__ === 'tree' && isArray(availableFilterItems)) {
            const isTreeTitleHighlighted = lowerCaseDisplayName.includes(lowerCaseSearchValue);

            const isTreeItemHighlighted = treeHasHighlightedValue(lowerCaseSearchValue, availableFilterItems, 'value');

            return isTreeTitleHighlighted || isTreeItemHighlighted;
          }

          return true;
        }),
      };
    })
    .filter(({ items }) => items.some(Boolean));
};

export const overrideFiltersCounts = (dataToOverride, sourceData) => {
  const updatedCountsFilterItem =
    isArray(sourceData) &&
    sourceData.find(
      (updatedCountsFilter) =>
        dataToOverride.value === updatedCountsFilter.value && dataToOverride._backend === updatedCountsFilter._backend,
    );

  if (updatedCountsFilterItem) {
    return {
      ...dataToOverride,
      ...(dataToOverride?.children && {
        children: dataToOverride?.children.map((items) =>
          overrideFiltersCounts(items, updatedCountsFilterItem?.children),
        ),
      }),
      active: updatedCountsFilterItem.active,
    };
  }

  return {
    ...dataToOverride,
    active: 0,
    ...(dataToOverride.children
      ? {
          children: dataToOverride?.children.map((items) =>
            overrideFiltersCounts(items, updatedCountsFilterItem?.children),
          ),
        }
      : {}),
  };
};

export const filterNodes = (searchValue, nodes) => {
  if (!nodes) return false;

  return nodes?.map((node) => {
    const { title, children } = node;

    if (isTextContainsSearchValue(searchValue, title)) return node;

    if (!treeHasHighlightedValue(searchValue, children, 'title') && !isTextContainsSearchValue(searchValue, title))
      return { ...node, selectable: true };

    if (!treeHasHighlightedValue(searchValue, children, 'title') && isTextContainsSearchValue(searchValue, title))
      return node;

    if (!children) return node;

    return {
      ...node,
      children: filterNodes(searchValue, children),
    };
  });
};

export const getTreeFiltersStrFromFiltersAvailable = (key, availableColumns, values) => {
  const parsedTree = values
    .filter((treeNodeKey) => treeNodeKey !== TREE_TOP_LEVEL_NODE_KEY)
    .map(JSON.parse)
    .toSorted((o1, o2) => o1.length > o2.length);

  const parsedKey = parsedTree.map((arr) => Object.keys(arr.at(-1))[0]).at(0) || key;

  const columnData = availableColumns[parsedKey];

  const parsedFilterLabel = columnData?.['Display Name'];
  const sectionLabel = columnData?.['Display Header'];

  const parsedValues = parsedTree.map((arr) => Object.values(arr.at(-1))[0]).map((value) => getFilterValueLabel(value));

  const formattedParsedValues = parsedValues.join(', ') || TREE_TOP_LEVEL_NODE_VALUE;

  return {
    backendName: key,
    value: `${parsedFilterLabel}: ${formattedParsedValues}`,
    filterType: FILTER_TYPES.TREE,
    sectionLabel,
    parsedFilterLabel,
    parsedValues,
    rawValues: values,
  };
};

export const getFlatAvailableColumns = (availableColumns) =>
  availableColumns?.reduce((acc, item) => {
    acc[item['Backend Name']] = item;

    return acc;
  }, {});

export const getFlatAvailableFilters = (availableFilters) =>
  availableFilters?.reduce((acc, filter) => {
    filter.items.forEach((item) => {
      acc[item.backendName] = item;
    });

    return acc;
  }, {});

export const getFiltersToDisplayAsChips = (filters, availableFilters, availableColumns) => {
  const keyObj = filters?.otherFilters ?? {};
  const filtersToDisplay = [];

  if (!availableColumns || !availableFilters) return [];

  const flatAvailableFilters = getFlatAvailableFilters(availableFilters);
  const flatAvailableColumns = getFlatAvailableColumns(availableColumns);

  Object.entries(filters?.treeFilters ?? {}).forEach(([key, values]) => {
    if (values && values.length > 0) {
      filtersToDisplay.push(getTreeFiltersStrFromFiltersAvailable(key, flatAvailableColumns, values));
    }
  });

  Object.entries(keyObj).forEach(([beKey, value]) => {
    const info = flatAvailableFilters[beKey];

    if (info) {
      filtersToDisplay.push({
        value: `${info?.displayName}: ${getFilterValueDisplayStr(value, info?.unit)}`,
        backendName: beKey,
        filterType: info?.__type__,
      });
    }
  });

  const flatAvailableFiltersArray = (availableFilters ?? []).flatMap((section) =>
    section.items.map((item) => item.backendName),
  );

  return sortBy(filtersToDisplay, (item) => {
    const index = flatAvailableFiltersArray.indexOf(item.backendName);

    return index === -1 ? Infinity : index;
  });
};
