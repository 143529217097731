import React from 'react';

import { ButtonWithIcon } from './ButtonWithIcon';
import { IconDownload } from '@/Components/Icons/IconDownload';

interface IProps {
  id: string;
  tooltipTitle?: string;
  dataTestId?: string;
  onClick: () => void;
  disabled?: boolean;
}

export const DownloadCsvButton = ({ id, dataTestId, onClick, disabled, tooltipTitle = 'Download Data' }: IProps) => (
  <ButtonWithIcon
    tooltipTitle={tooltipTitle}
    id={id}
    onClick={onClick}
    disabled={disabled}
    dataTestId={dataTestId}
  >
    <IconDownload />
  </ButtonWithIcon>
);
