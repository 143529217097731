import React, { useState, useEffect, useCallback, useRef } from 'react';
import Dialog from '@mui/material/Dialog';

import { isArray, isObject, mapValues, omit } from 'lodash';
import { useIsFetching } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { MultipleLabelsLoaderDialogContent } from '../Shared/MultipleLabelsLoaderDialogContent';
import { actions as searchActions } from '../../../slices/search';
import { useCaseCodePopupConditionally } from '../CaseCodeDialog/use-show-case-code-popup-conditionally';
import { MODALS } from '@/constants';

import { useModal } from '@/hooks/use-modal';
import { useQueryGenerateSemanticBasedSearchPayload } from '@/hooks/queries/semantic-search/use-query-generate-semantic-based-search-payload';
import { ISemanticSearchAIResponse } from '@/types/state/semantic-search';
import { useSearchCompanies } from '@/hooks/use-search-companies';
import { QueryType } from '@/slices/search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { TFilter } from '@/slices/filters';
import { splitObject, splitObjectToSingleFieldObjects } from '@/Utils';

const stepLabels = ['Refining your description...', 'Selecting industry filters...', 'Determining keywords...'];

export const SemanticSearchLoadingDialog = () => {
  const dispatch = useDispatch();
  const searchCompanies = useSearchCompanies();
  const {
    isOpen,
    handleClose,
    data: modalData,
  } = useModal<{ skipSearch?: boolean }>(MODALS.SEMANTIC_SEARCH_LOADING_DIALOG);
  const [step, setStep] = useState(0);
  const includedNullList = useShallowSelector((state) => state.filters.includedNullList);
  const semanticSearch = useShallowSelector((state) => state.search.semanticSearch);
  const isTableLoading = useIsFetching({ queryKey: ['table-elastic'] }) > 0;
  const semanticPayloadApplied = useRef(false);

  const { shouldShowCaseCodePopup, handleCaseCodeModalOpen } = useCaseCodePopupConditionally();

  const onPayloadGenerationSuccess = useCallback(
    ({ keywords, filters }: ISemanticSearchAIResponse) => {
      if (!semanticSearch) return;

      const searchText = keywords.length ? keywords.map((keyword) => `"${keyword}"`).join(' ') : '';

      const { objectPositive: treeFilters, objectNegative: otherFilters } = splitObject<
        Record<string, string | TFilter>
      >(filters, (value) => {
        if (isArray(value) && value.every((item) => isObject(item))) return true;

        if (isObject(value) || (isArray(value) && value.every((item) => typeof item === 'string'))) return false;

        return false;
      });

      const transformedTreeFilters = mapValues(treeFilters, (arrayOfFilters) =>
        arrayOfFilters.map((filter: object) => JSON.stringify(splitObjectToSingleFieldObjects(filter))),
      );

      const stateFilters = {
        treeFilters: transformedTreeFilters,
        otherFilters,
        includedNullList,
      };

      dispatch(
        searchActions.setSemanticSearch({
          ...semanticSearch,
          applyDescription: true,
          aiResponse: { keywords: searchText, filters: omit(stateFilters, 'includedNullList') },
        }),
      );

      if (modalData?.skipSearch) return;

      searchCompanies(
        {
          searchText: searchText,
          description: semanticSearch?.userDescription,
          queryType: QueryType.KEYWORD,
          filters: stateFilters,
        },
        { useStateFilters: false, skipCaseCodePopupCheck: true },
      );
    },
    [dispatch, includedNullList, modalData?.skipSearch, searchCompanies, semanticSearch],
  );

  const { data, isLoading: isSemanticSearchLoading } = useQueryGenerateSemanticBasedSearchPayload({
    enabled: isOpen,
    onError: handleClose,
  });

  useEffect(() => {
    if (!data || !isOpen || semanticPayloadApplied.current) return;
    onPayloadGenerationSuccess(data);
    semanticPayloadApplied.current = true;
  }, [data, isOpen, onPayloadGenerationSuccess]);

  const label = stepLabels[step];

  useEffect(() => {
    if (isOpen) return;

    semanticPayloadApplied.current = false;
    setStep(0);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    const intervalId = setInterval(() => {
      if (step === stepLabels.length - 1) {
        if (isTableLoading || isSemanticSearchLoading) return;

        if (shouldShowCaseCodePopup) {
          handleCaseCodeModalOpen();
        }

        handleClose();

        return;
      }

      setStep((prevState) => prevState + 1);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    handleCaseCodeModalOpen,
    handleClose,
    isSemanticSearchLoading,
    isOpen,
    isTableLoading,
    shouldShowCaseCodePopup,
    step,
  ]);

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: 'w-[350px] top-[20%] self-start' }}
    >
      <MultipleLabelsLoaderDialogContent label={isOpen ? label : ''} />
    </Dialog>
  );
};
