import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { without, isEmpty, isNil, isArray } from 'lodash';

import { Autocomplete, Chip, ListItemText, MenuItem, TextField } from '@mui/material';
import { HIDE_FILTERS_COUNT } from '../../../../../../constants';
import { useShallowSelector } from '../../../../../../hooks/use-shallow-selector';
import { actions } from '../../../../../../slices/filters';
import { getLabel } from '../FilterMultipleSelectChip';
import { getFilterValueLabel } from '@/Utils/filters';
import { CategoricalValue, IFilterCategoricalData } from '@/types/state/filter-categorical';

//@ts-ignore
const prepareFilterValue = ({ checked, filterData, value }) =>
  checked ? [...filterData, value] : without(filterData, value);

const MIN_ITEMS_RENDERED_AS_DROPDOWN = 6;

interface IFilterCheckboxList {
  itemId: string;
  values: IFilterCategoricalData[];
  isFetching: boolean;
  searchValue: string;
  withoutCounts?: boolean;
  asDropdown?: boolean;
}

export const FilterCheckboxList = ({
  itemId,
  values,
  isFetching,
  searchValue,
  withoutCounts,
  asDropdown,
}: IFilterCheckboxList) => {
  const dispatch = useDispatch();
  const filterData = useShallowSelector((state) => state.filters.otherFilters?.[itemId] ?? []);

  useEffect(() => {
    if (isEmpty(filterData)) {
      dispatch(actions.removeFilter({ filter: itemId }));
    }
  }, [dispatch, filterData, itemId]);

  if (!isArray(filterData)) return null;

  const handleCheckboxListChange =
    (value: CategoricalValue | null) => (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      dispatch(
        actions.setFilter({
          id: itemId,
          data: prepareFilterValue({
            checked,
            filterData,
            value: value,
          }),
        }),
      );
    };

  const handleDropdownCheckboxItemClick = (value: CategoricalValue | null, checked: boolean) => {
    dispatch(
      actions.setFilter({
        id: itemId,
        data: prepareFilterValue({
          checked: !checked,
          filterData,
          value,
        }),
      }),
    );
  };

  const handleAutocompleteChange = (_: React.SyntheticEvent<Element, Event>, newValue: IFilterCategoricalData[]) => {
    if (!isEmpty(newValue)) return;
    dispatch(
      actions.setFilter({
        id: itemId,
        data: [],
      }),
    );
  };

  if (values.length >= MIN_ITEMS_RENDERED_AS_DROPDOWN || asDropdown) {
    return (
      <Autocomplete
        disableCloseOnSelect
        className="mt-4 mb-3"
        renderTags={(tags) =>
          tags.map(({ value, active }) => (
            <Chip
              key={value}
              label={withoutCounts ? getFilterValueLabel(value) : getLabel(value, searchValue, active, isFetching)}
              className="text-[#484848] m-0.5 mr-2"
            />
          ))
        }
        onChange={handleAutocompleteChange}
        multiple
        options={values}
        getOptionLabel={({ value, active }) => {
          if (HIDE_FILTERS_COUNT || withoutCounts) {
            return getFilterValueLabel(value);
          }

          const count = isNil(active) ? '(0)' : `(${active?.toLocaleString('en')})`;

          return `${getFilterValueLabel(value)} ${isFetching ? '' : count}`;
        }}
        value={
          filterData.map((value) => ({
            value,
            _backend: itemId,
            active: values.find((option) => option.value === value)?.active ?? 0,
          })) as IFilterCategoricalData[]
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Please select…"
          />
        )}
        renderOption={(props, { value, active }) => {
          const filterValue = value;
          // @ts-ignore
          const isChecked: boolean = filterData.includes(filterValue);

          return (
            <MenuItem
              key={value}
              value={value}
              {...props}
              onClick={() => handleDropdownCheckboxItemClick(value, isChecked)}
            >
              <Checkbox
                color="info"
                sx={{
                  color: '#DDD',
                  paddingLeft: 0,
                }}
                checked={isChecked}
                disableRipple
                disableFocusRipple
              />
              <ListItemText primary={getLabel(value, searchValue, active, isFetching, withoutCounts)} />
            </MenuItem>
          );
        }}
      />
    );
  }

  return (
    <div>
      {values?.map(({ value, active }) => {
        const filterValue = value;
        // @ts-ignore
        const isChecked: boolean = filterData.includes(filterValue);

        return (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                color="info"
                sx={{
                  color: '#DDD',
                }}
                checked={isChecked}
                disableRipple
                disableFocusRipple
              />
            }
            onChange={handleCheckboxListChange(value)}
            label={getLabel(value, searchValue, active, isFetching, withoutCounts)}
            classes={{
              root: 'flex',
              label: 'text-[#484848]',
            }}
            value={active}
          />
        );
      })}
    </div>
  );
};
