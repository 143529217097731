import { useMemo } from 'react';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const useSearchSettings = () => {
  const company_search = useShallowSelector((state) => state.search.searchText);
  const search_query = useShallowSelector((state) => state.search.searchQuery);
  const query_type = useShallowSelector((state) => state.search.queryType);
  const is_unique_company = useShallowSelector((state) => state.search.isUniqueCompanySearch);
  const filters = useShallowSelector((state) => state.filters);
  const currency = useShallowSelector((state) => state.config.currency);
  const {
    visible: visible_columns,
    sortModel,
    customColumnsOrder: columns_order,
  } = useShallowSelector((state) => state.table);

  const semantic_description = useShallowSelector((state) => state.search.semanticSearch?.userDescription);

  return useMemo(
    () => ({
      company_search,
      search_query,
      query_type,
      filters,
      currency,
      sort_key: sortModel[0]?.field ?? null,
      sort_order: sortModel[0]?.sort ?? null,
      visible_columns,
      columns_order,
      is_unique_company,
      semantic_description,
    }),
    [
      columns_order,
      company_search,
      currency,
      filters,
      is_unique_company,
      query_type,
      search_query,
      sortModel,
      visible_columns,
      semantic_description,
    ],
  );
};
