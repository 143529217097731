import { get, post, put, del, patch } from 'aws-amplify/api';

import {
  ICreateCompaniesList,
  ICompaniesListSnippet,
  ICompaniesList,
  IUpdateCompaniesList,
  IUpdateCompaniesListCollaboratorVariables,
  ICompaniesListRevisionsQuery,
  ICompaniesListRevisionsResponse,
} from '@/types';

export const getCompaniesLists = (): Promise<ICompaniesListSnippet[]> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: '/search_lists' }).response.then(res => res.body.json()).then((response) => response.search_lists);

export const getCompaniesListById = (id: string): Promise<ICompaniesList> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: `/search_list/${id}` }).response.then(res => res.body.json());

export const createCompaniesList = (body: ICreateCompaniesList): Promise<ICompaniesList> =>
  // @ts-ignore
  post({ apiName: 'CDPAPI', path: '/search_list', options: { body: { ...body, list_type: 'static' } } }).response.then(res => res.body.json());

export const updateCompaniesList = ({
  id,
  body,
}: {
  id: string;
  body: IUpdateCompaniesList;
}): Promise<ICompaniesList> =>
// @ts-ignore
patch({ apiName: 'CDPAPI', path: `/search_list/${id}`, options: { body } }).response.then(res => res.body.json());

export const removeCompaniesList = (id: string): Promise<ICompaniesList> =>
  // @ts-ignore
  del({ apiName: 'CDPAPI', path: `/search_list/${id}` }).response.then(res => res.body.json());

export const updateCompaniesListCollaborator = ({
  id,
  email,
}: IUpdateCompaniesListCollaboratorVariables): Promise<unknown> =>
  put({ apiName: 'CDPAPI', path: `/search_list/${id}/collaborators`, options: { body: { email } } }).response;

export const getCompaniesListCollaborators = (id: string): Promise<string[]> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: `/search_list/${id}/collaborators` }).response.then(res => res.body.json());

export const removeCompaniesListCollaborator = (listId: string, userId: string): Promise<unknown> =>
  // @ts-ignore
  del({ apiName: 'CDPAPI', path: `/search_list/${listId}/collaborators/${userId}` }).response;

export const getCompaniesListRevisions = (
  listId: string,
  query: ICompaniesListRevisionsQuery,
): Promise<ICompaniesListRevisionsResponse> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: `/search_list/${listId}/revisions?${new URLSearchParams(query as unknown as Record<string, string>).toString()}` }).response.then(res => res.body.json());

export const getCompaniesListRevisionById = (listId: string, revisionId: number): Promise<ICompaniesList> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: `/search_list/${listId}/${revisionId}` }).response.then(res => res.body.json());
