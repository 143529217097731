import React, { useState } from 'react';
import classnames from 'classnames';
import { Divider } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';

import { CompanyProfileWidgetEntry, CompanyProfileWidgetEntryProps } from './CompanyProfileWidgetEntry';
import { IconArrowRight } from '@/Components/Icons/IconArrowRight';

interface CompanyProfileWidgetProps {
  title?: { text?: string; id?: string };
  viewMoreUrl?: string;
  description?: { text?: string; id?: string; expandLimit?: number };
  entries: CompanyProfileWidgetEntryProps[];
}

const DEFAULT_DESCRIPTION_EXPAND_LIMIT = 150;

export const CompanyProfileWidget: React.FC<CompanyProfileWidgetProps> = ({
  title,
  viewMoreUrl,
  description,
  entries,
}) => {
  const descriptionExpandLimit = description?.expandLimit ?? DEFAULT_DESCRIPTION_EXPAND_LIMIT;
  const isLongerDescription = description?.text ? description?.text?.length > descriptionExpandLimit : false;
  const [showFullDescription, setShowFullDescription] = useState(false);

  return (
    <div className="p-6 bg-white rounded border border-[#f5f5f5] break-inside-avoid text-[#484848] shadow-[0_0_4px_0_rgba(95,95,95,0.25)]">
      {title?.text ? (
        <div id={title.id}>
          <div className="flex justify-between items-center mb-4">
            <p className="font-medium leading-9">{title.text}</p>

            {viewMoreUrl ? (
              <Button
                className="text-[#2E3F4C]"
                endIcon={<IconArrowRight />}
                href={viewMoreUrl}
              >
                View
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}

      {description?.text ? (
        <div
          className="relative"
          id="overview-description"
        >
          <p
            style={{
              wordBreak: 'break-word',
            }}
            className={classnames('text-sm leading-7', {
              invisible: showFullDescription,
              'h-[58px] overflow-hidden absolute': isLongerDescription,
            })}
          >
            {description?.text}
          </p>

          {isLongerDescription ? (
            <>
              <Collapse
                in={showFullDescription}
                timeout={0}
              >
                <p
                  style={{
                    wordBreak: 'break-word',
                  }}
                  className="text-sm leading-7"
                >
                  {description?.text}
                </p>
              </Collapse>

              <Button
                size="small"
                className={classnames('text-[#2E3F4C] border-0 h-full text-medium', {
                  'mt-[72px]': !showFullDescription,
                  'mt-[20px]': showFullDescription,
                })}
                onClick={() => setShowFullDescription((prevState) => !prevState)}
              >
                <span className="text-sm font-medium">{showFullDescription ? 'Hide' : 'Read more'}</span>

                <KeyboardArrowDownIcon
                  className={classnames('fill-cgray-50 text-xl transition ml-1', { 'rotate-180': showFullDescription })}
                />
              </Button>

              <Divider className="my-4 border-[#f5f5f5]" />
            </>
          ) : null}
        </div>
      ) : null}

      {entries.map((entry) => (
        <CompanyProfileWidgetEntry
          key={entry.label}
          {...entry}
          widgetId={title?.id}
        />
      ))}
    </div>
  );
};
