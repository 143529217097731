import React, { PropsWithChildren } from 'react';

import { IconButton, Tooltip } from '@mui/material';

interface IProps {
  tooltipTitle?: string;
  id: string;
  dataTestId?: string;
  onClick: () => void;
  disabled?: boolean;
}

export const ButtonWithIcon = ({
  children,
  tooltipTitle,
  id,
  dataTestId,
  onClick,
  disabled,
}: PropsWithChildren<IProps>) => (
  <Tooltip title={tooltipTitle}>
    <span>
      <IconButton
        id={id}
        onClick={onClick}
        className="ml-1 mb-[1px]"
        size="small"
        disabled={disabled}
        data-testid={dataTestId}
      >
        {children}
      </IconButton>
    </span>
  </Tooltip>
);
