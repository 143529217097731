import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { StarterPackWizardStepItemChoice } from '@/types';

export const WizardDialogChoice = ({
  item,
  value,
  onChange,
}: {
  item: StarterPackWizardStepItemChoice;
  value: string;
  onChange: (value: string) => void;
}) => (
  <div className="my-5">
    <p className="font-medium text-sm">{item.question}</p>
    <FormControl sx={{ minWidth: 180, marginY: 2 }}>
      <Select
        value={value}
        onChange={(event: SelectChangeEvent) => onChange(event?.target.value)}
      >
        {item.options.map((option) => (
          <MenuItem
            key={option.option}
            value={option.option}
          >
            {option.option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
);
