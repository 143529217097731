import { MouseEventHandler, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@/slices/search';
import { astToLogicalBlocks } from '@/Utils/pegjs/astTreeUtils';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const useAppliedKeywordsData = () => {
  const dispatch = useDispatch();
  const searchQueryTree = useShallowSelector(({ search }) => search.searchQueryTree);

  const handleRemoveAllKeywords: MouseEventHandler<SVGSVGElement> = (event) => {
    event.stopPropagation();

    dispatch(actions.setSearchQuery(''));
  };

  const appliedKeywords = useMemo(() => {
    if (!searchQueryTree) return [];

    return astToLogicalBlocks(searchQueryTree).filter(
      ({ term, isKeyChar, isKeyWord }) => !!term && !isKeyChar && !isKeyWord,
    );
  }, [searchQueryTree]);

  const numberOfKeywords = appliedKeywords.length;

  const formattedAppliedKeywords = appliedKeywords.map(({ term }) => term).join(', ');

  return {
    numberOfKeywords,
    appliedKeywords,
    formattedAppliedKeywords,
    handleRemoveAllKeywords,
  };
};
