import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useMenu } from '@/hooks/use-menu';
import { ShareButton } from '@/Components/Shared/Buttons/ShareButton';
import { useMutationUpdateCompaniesList } from '@/hooks/queries/companies-list/use-mutation-update-companies-list';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { copyToClipboard } from '@/Utils/navigator-utils';
import { ROUTES, MODALS } from '@/constants';
import { removeTrailingSlash, spaceToDash, clearString } from '@/Utils/text';
import { useModal } from '@/hooks/use-modal';

export const createCompaniesListPath = (companiesListTitle: string, companiesListId: string) =>
  `${ROUTES.COMPANIES_LIST}/${spaceToDash(clearString(companiesListTitle))}-${companiesListId}`;

export const createCompaniesListLink = (companiesListTitle: string, companiesListId: string) =>
  `${removeTrailingSlash(process.env.REACT_APP_ROOT_URL as string)}${createCompaniesListPath(
    companiesListTitle,
    companiesListId,
  )}`;

interface ShareCompaniesListLinkProps {
  listId?: string;
  listTitle?: string;
  shared?: boolean;
  isSharedWithUserList?: boolean;
}

export const ShareCompaniesListLink = ({
  listId,
  listTitle,
  shared,
  isSharedWithUserList,
}: ShareCompaniesListLinkProps) => {
  const [isShared, setIsShared] = useState(shared);
  const [hasChanged, setHasChanged] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isOpen, anchorEl, handleClick: handleShareButtonClick, handleClose: handleDropdownClose } = useMenu();
  const { handleOpen: handleModalOpen } = useModal(MODALS.SHARE_LIST_WITH_COLLABORATORS);

  const onSuccessUpdate = () => {
    if (!listId || !listTitle) return;

    setIsShared(true);
    setHasChanged(true);
    copyToClipboard(
      createCompaniesListLink(listTitle, listId),
      () =>
        enqueueSnackbar(<NotificationMessage title={'Link copied to clipboard'} />, {
          variant: 'success',
        }),
      () =>
        enqueueSnackbar(
          <NotificationMessage title={'Failed to copy link to clipboard. Please try again or contact support.'} />,
          {
            variant: 'error',
          },
        ),
    );
  };

  const { mutate, isLoading } = useMutationUpdateCompaniesList(onSuccessUpdate);

  const updateCompaniesList = () => {
    if (!listId) return;

    mutate({
      id: listId,
      fieldsToUpdate: {
        is_sharable_link: true,
      },
    });
  };

  useEffect(() => {
    return () => {
      if (hasChanged) {
        queryClient.invalidateQueries({ queryKey: ['companies-list', listId] });
        queryClient.invalidateQueries({ queryKey: ['companies-lists'] });
      }
    };
  }, [hasChanged, listId, queryClient]);

  if (isSharedWithUserList) {
    return (
      <ShareButton
        onClick={onSuccessUpdate}
        dataTestId={isShared ? 'share-list-link-shared' : 'share-list-link-not-shared'}
        isLoading={isLoading}
        isSharedWithUserList
      />
    );
  }

  return (
    <>
      <ShareButton
        onClick={handleShareButtonClick}
        dataTestId={isShared ? 'share-list-link-shared' : 'share-list-link-not-shared'}
        isLoading={isLoading}
        isOpen={isOpen}
      />
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleDropdownClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        <MenuItem onClick={isShared ? onSuccessUpdate : updateCompaniesList}>Share read only</MenuItem>

        <MenuItem
          onClick={() => {
            handleDropdownClose();
            handleModalOpen({ listId });
          }}
        >
          Share to collaborate
        </MenuItem>
      </Menu>
    </>
  );
};
