import { get } from 'aws-amplify/api';
import { IColumnMapper } from '@/types';

export const getColumnMapper = (currency: string): Promise<IColumnMapper[]> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: `/column_mapper?currency=${currency}` }).response.then(res => res.body.json()).then((res) => res?.data);

export const getColumnMapperDataset = (dataset: string, currency: string): Promise<IColumnMapper[]> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: `/column_mapper/${dataset}?currency=${currency}` }).response.then(res => res.body.json()).then((res) => res.data);
