import { filtersController } from './handlers/filters-controller';
import { columnMapperController } from './handlers/column-mapper-controller';
import { companiesListsController, collaboratorsCompaniesListController } from './handlers/companies-lists-controller';
import { savedSearchesController } from './handlers/saved-searches-controller';
import { similarCompaniesController } from './handlers/similar-companies';
import { configController } from './handlers/config';
import { elasticController } from './handlers/elastic-controller';
import { exportController } from './handlers/export-controller';
import { companySearchController } from './handlers/company-search-controller';
import { smartSearchController } from './handlers/smart-search-controller';
import { companyOverviewExportController } from './handlers/company-overview-export-controller';
import { logUserDataController } from './handlers/log-user-data-controller';
import { chartController } from './handlers/chart-controller';
import { subsidiariesController } from './handlers/subsidiaries-controller';
import companyProfileController from './handlers/company-profile-controller';
import { starterPackWizardController } from './handlers/starter-pack-wizard';

export const handlers = [
  ...filtersController,
  ...columnMapperController,
  ...companiesListsController,
  ...collaboratorsCompaniesListController,
  ...savedSearchesController,
  ...similarCompaniesController,
  ...configController,
  ...elasticController,
  ...exportController,
  ...companySearchController,
  ...smartSearchController,
  ...companyOverviewExportController,
  ...logUserDataController,
  ...chartController,
  ...subsidiariesController,
  ...companyProfileController,
  ...starterPackWizardController,
];
