import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';

export interface CardMenuItem {
  name: string;
  onClick: (handleCloseMenu?: () => void) => void;
  disabled: boolean;
  loading: boolean;
}

interface CardMenuProps {
  menuItems: CardMenuItem[];
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

export const CardMenu = ({ isOpen, anchorEl, handleClose, menuItems }: CardMenuProps) => {
  return (
    <Menu
      className="p-0"
      classes={{
        list: 'p-0',
      }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
    >
      {menuItems.map(({ name, onClick, loading, disabled }) => {
        return (
          <MenuItem
            disabled={disabled}
            onClick={() => onClick(handleClose)}
            className="flex justify-between"
            key={name}
          >
            <span>{name}</span>

            <CircularProgress
              className={classnames({
                hidden: !loading,
              })}
              size={20}
            />
          </MenuItem>
        );
      })}
    </Menu>
  );
};
