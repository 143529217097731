import React, { useRef } from 'react';

import { Tooltip } from '@mui/material';

interface SimilarCompanyCardTitleProps {
  label?: string;
}

export const SimilarCompanyCardTitle = ({ label }: SimilarCompanyCardTitleProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const spanRef = useRef<HTMLSpanElement | null>(null);

  if (!label) return null;

  const margin = 6;

  const shouldShowTooltip = (spanRef?.current?.offsetWidth ?? 0) + margin >= (containerRef?.current?.offsetWidth ?? 0);

  const content = (
    <div
      ref={containerRef}
      className="block w-full truncate text-left"
    >
      <span
        ref={spanRef}
        id="similar-company-card-title"
        className="font-medium text-[#484848] truncate group-hover/title:underline"
      >
        {label}
      </span>
    </div>
  );

  if (shouldShowTooltip) {
    return (
      <Tooltip
        classes={{ tooltip: 'max-w-[320px] bg-[#484848] p-2', arrow: 'text-[#484848]' }}
        arrow
        title={label}
        enterDelay={300}
        enterNextDelay={300}
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};
