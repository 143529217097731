import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useShallowSelector } from '../../use-shallow-selector';
import { getConfig } from '@/services/api/config';

export const useQueryConfig = <Type extends object>(key: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(['config', key], () => getConfig(key) as Promise<{ key: typeof key } & Type>, {
    enabled: isAuth && !!key,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
    onError: () =>
      enqueueSnackbar('Failed to fetch config. Please try again or contact support.', {
        variant: 'error',
      }),
  });
};
