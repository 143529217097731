import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classnames from 'classnames';

import { useSnackbar } from 'notistack';
import { MODALS } from '@/constants';
import { getAriaValue } from '@/Utils/accessibility-utils';
import { useModal } from '@/hooks/use-modal';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useMenu } from '@/hooks/use-menu';
import { useMutationTriggerCompanyOverviewOrComparisonExport } from '@/hooks/queries/export/use-mutation-trigger-company-overview-or-comparison-export';
import { Loader } from '@/Components/Shared/Loader/Loader';
import { useMutationTriggerSelectedCompaniesDataExport } from '@/hooks/queries/export/use-mutation-trigger-selected-companies-data-export';
import { useCaseCodePopupConditionally } from '@/Components/Dialogs/CaseCodeDialog/use-show-case-code-popup-conditionally';
import { copyToClipboard } from '@/Utils/navigator-utils';
import { useIndexedROws } from '@/Components/Shared/ScreenerTable/hooks/use-indexed-rows';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';

interface IProps {
  companyProfileId?: string;
}

const HIDE_OVERVIEW_AND_COMPARISON = true;

export const ExportDropdown = ({ companyProfileId }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleOpen: handleDataExportModalOpen } = useModal(MODALS.DATA_EXPORT);
  const { handleOpen: handleComparisonExportModalOpen } = useModal(MODALS.COMPARISON_EXPORT);
  const { isOpen, anchorEl, handleClick: handleDropdownOpen, handleClose: handleDropdownClose } = useMenu();
  const triggerCompanyOverviewExportMutation = useMutationTriggerCompanyOverviewOrComparisonExport();
  const triggerSelectedCompaniesDataExportMutation = useMutationTriggerSelectedCompaniesDataExport();
  const { shouldShowCaseCodePopup, handleCaseCodeModalOpen } = useCaseCodePopupConditionally();
  const selectedCompanies = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);
  const csvSelectedCompanies = useShallowSelector((state) => state.selectedCompanies.csvSelectedCompanies);
  const areCompaniesSelected = selectedCompanies.length > 0;
  const isComparable = selectedCompanies.length >= 2 && selectedCompanies.length <= 5;
  const isCompanyProfile = !!companyProfileId;
  const isOverviewExportAllowed = selectedCompanies.length === 1 || isCompanyProfile;
  const skipComparison = isCompanyProfile;
  const { areAllSelectedCompaniesOnCurrentPage } = useIndexedROws();

  const dataOption = {
    label: 'Data',
    disabled: false,
    description: 'Export a spreadsheet with all company data',
    isLoading: triggerSelectedCompaniesDataExportMutation.isLoading,
    onClick: () => {
      if (isCompanyProfile) {
        triggerSelectedCompaniesDataExportMutation.mutate(
          { companyProfileIds: [Number(companyProfileId)] },
          { onSettled: handleDropdownClose },
        );

        return;
      }

      handleDataExportModalOpen();
      handleDropdownClose();
    },
  };

  const copyCsv = {
    label: 'Copy',
    disabled: selectedCompanies.length === 0,
    description: 'Copy companies that are displayed on this page directly to your clipboard.',
    isLoading: false,
    onClick: () => {
      if (!areAllSelectedCompaniesOnCurrentPage) {
        enqueueSnackbar(
          <NotificationMessage
            title="Too many companies"
            description="You can only copy companies that are displayed on this page, reduce your selection and try again."
          />,
          {
            variant: 'warning',
            persist: true,
          },
        );

        return;
      }

      copyToClipboard(csvSelectedCompanies, () => {
        handleDropdownClose();
      });
    },
  };

  const items = HIDE_OVERVIEW_AND_COMPARISON
    ? [copyCsv, dataOption]
    : [
        {
          label: 'Overview',
          disabled: !isOverviewExportAllowed,
          description: 'A single company overview including key facts, financials & sources',
          isLoading: triggerCompanyOverviewExportMutation.isLoading,
          onClick: () => {
            const companyId = isCompanyProfile ? companyProfileId : selectedCompanies[0];

            if (!companyId) return;

            const performOverviewExport = () => {
              triggerCompanyOverviewExportMutation.mutate(
                { target_id: companyId, competitor_ids: [], companyProfileId },
                { onSettled: handleDropdownClose },
              );
            };

            if (shouldShowCaseCodePopup) {
              handleCaseCodeModalOpen({ onSuccess: performOverviewExport });

              return;
            }

            performOverviewExport();
          },
        },
        ...(!skipComparison
          ? [
              {
                label: 'Comparison',
                disabled: !isComparable,
                description: 'Choose a target from your selection and compare financial data',
                onClick: () => {
                  handleComparisonExportModalOpen();
                  handleDropdownClose();
                },
                isLoading: false,
              },
            ]
          : []),
        copyCsv,
        dataOption,
      ];

  return (
    <div>
      <Button
        data-testid="export-button"
        aria-controls={getAriaValue(isOpen, 'export-dropdown-menu')}
        aria-haspopup="true"
        aria-expanded={getAriaValue(isOpen, 'true')}
        disableElevation
        onClick={handleDropdownOpen}
        className={classnames(
          'ml-[1px] py-2 px-3 border border-solid font-medium font-graphik text-white text-xs h-[36px]',
          {
            'border-cred-900 bg-cred-900': !areCompaniesSelected || isCompanyProfile,
            'border-bluegray-900 bg-bluegray-900 rounded-l-none': areCompaniesSelected && !isCompanyProfile,
            'rounded-l': skipComparison,
          },
        )}
      >
        <span>Export</span>
        <KeyboardArrowDownIcon className={classnames('text-xl transition ml-1', { 'rotate-180': isOpen })} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleDropdownClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.label}
            onClick={item.onClick}
            disabled={item.disabled}
            className="w-[492px] flex justify-between items-center"
          >
            <Box className="flex flex-col">
              <Typography
                className="font-medium"
                variant="inherit"
                noWrap
              >
                {item.label}
              </Typography>
              <span className="text-xs text-[#666666]">{item.description}</span>
            </Box>
            {item?.isLoading ? <Loader /> : null}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
