import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

export interface DialogHeaderProps {
  title: string;
  handleClose: () => void;
}

export const DialogHeader = ({ title, handleClose }: DialogHeaderProps) => (
  <>
    <div className="flex justify-between items-center pb-4">
      <div className="flex flex-col gap-4">
        <DialogTitle className="flex text-base font-medium p-0 text-[#484848]">{title}</DialogTitle>
      </div>

      <CloseIcon
        fontSize="small"
        className="cursor-pointer fill-[#484848]"
        onClick={handleClose}
      />
    </div>
    <div className="w-full h-[1px] bg-[#F5F5F5] mb-6" />
  </>
);
