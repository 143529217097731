import React, { useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, ChartOptions, Plugin } from 'chart.js';
import Divider from '@mui/material/Divider';
import _ from 'lodash';
import 'chart.js/auto';

import { DownloadChartImageButton } from '../common/DownloadChartImageButton';
import { DownloadCsvButton } from '../../Buttons/DownloadCsvButton';
import { numberFormatterCustom } from '@/Components/Shared/common';
import { ChartConfigType, ChartDataType, DatumDatum } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getYaxisLabel } from '@/Utils/charts';
import { CHART_SECTIONS } from '@/constants';

interface IProps {
  config: ChartConfigType;
  params?: { row: DatumDatum };
  section: string;
  data: ChartDataType[];
  handleDownloadXlsx: () => void;
  handleDownloadImage: (ref: React.RefObject<Chart>) => void;
}

const COLOR = '#506D85';

const getOptions = (currency: string, config: ChartConfigType): ChartOptions<'line'> => {
  const aggregateBy = config.METADATA?.aggregate_by;

  return {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '',
      },
      tooltip: {
        enabled: true,
        position: 'nearest',
        yAlign: 'top',
        xAlign: 'center',
        caretSize: 8,
        caretPadding: 18,
        itemSort: (a, b) => Number(b.raw) - Number(a.raw),
        filter: (data) => !_.isNil(data.raw),
        ...(!aggregateBy && {
          displayColors: false,
          callbacks: {
            label: function (context) {
              if (context.parsed.y !== null) {
                return `${currency}${numberFormatterCustom(context.parsed.y)}`;
              }
            },
          },
        }),
      },
      // @ts-ignore
      customCanvasBackgroundColor: {
        color: 'white',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: '#ddd',
        },
        border: {
          dash: [2, 2],
        },
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${currency}${numberFormatterCustom(Number(value))}`;
          },
        },
      },
    },
  };
};

const plugin: Plugin<'line'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, __, opts) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = opts.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const getDatasets = (labels: (string | number)[], data: ChartDataType[], config: ChartConfigType) => {
  const aggregateBy = config.METADATA?.aggregate_by;

  if (aggregateBy) {
    const datasets = _.map(_.groupBy(data, aggregateBy), (items, country) => {
      const dataPoints = labels.map((date) => {
        const item = items.find((it) => it[config.XAXIS] === date);

        return item ? item[config.YAXIS] : null;
      });

      return {
        label: country,
        data: dataPoints,
        pointRadius: 0,
        pointHoverRadius: 6,
      };
    });

    return datasets;
  }

  return [
    {
      data: data.map((item) => item[config.YAXIS]) as number[],
      borderColor: COLOR,
      pointBackgroundColor: COLOR,
      pointBorderColor: COLOR,
      pointRadius: 0,
      pointHoverRadius: 6,
      pointHoverBackgroundColor: '#0484e7',
      pointHoverBorderColor: '#fff',
    },
  ];
};

export const LineChart = ({ config, data, section, handleDownloadXlsx, handleDownloadImage }: IProps) => {
  const currency = useShallowSelector((state) => state.config.currency);
  const ref = useRef<Chart<'line'>>(null);
  const yAxisLabel = getYaxisLabel({ yAxisType: config.YAXIS_FORMAT, currency });
  const options = useMemo(() => getOptions(yAxisLabel, config), [yAxisLabel, config]);
  const labels = useMemo(() => _.uniq(data.map((item) => item[config.XAXIS])), [config.XAXIS, data]);
  const datasets = useMemo(() => getDatasets(labels, data, config), [config, data, labels]);
  const chartData = useMemo(() => ({ labels, datasets }), [labels, datasets]);

  return (
    <div
      className="bg-white rounded"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      <div className="flex items-center justify-between px-6 py-3">
        <div className="flex items-center gap-4">
          <span className="text-[#484848] text-sm font-semibold">{config.TITLE}</span>
          {/*
          This is tactical fix for 2024-06 refresh. We must come up with generic solution.
          One idea would be to have three separate column types:
          - values (xaxis, yaxis)
          - statics (column with single distinct value across all rows)
          - filters (column with more than one distinct value across all rows).
          Then "Data Source" can be generic filter without need to special implementation.
          */}
          {section === CHART_SECTIONS.WEB_TRAFFIC && data?.[0]?.DOMAIN && (
            <>
              <Divider
                className="text-[#ddd] h-[16px]"
                orientation="vertical"
                variant="middle"
              />
              <a
                href={`${'https://' + data?.[0]?.DOMAIN}`}
                target="_blank"
                rel="noreferrer"
                className="text-[#0484e7] text-sm underline"
              >
                {data?.[0]?.DOMAIN}
              </a>
            </>
          )}
        </div>

        <div className="flex items-center gap-4">
          {/* {section === CHART_SECTIONS.FINANCIALS && (
            <span className="text-sm text-[#666]">Source: {dataSource}</span>
          )} */}
          <DownloadChartImageButton
            onClick={() => handleDownloadImage(ref)}
            dataTestId="chart-download-image"
            id={`${config.SLUG}-chart-download-image`}
          />

          <DownloadCsvButton
            onClick={handleDownloadXlsx}
            dataTestId="chart-download-xlsx"
            id={`${config.SLUG}-chart-download-xlsx`}
          />
        </div>
      </div>

      <hr className="text-[#ddd]" />

      <div className="h-[400px] px-6 pb-4">
        <Line
          // @ts-ignore
          ref={ref}
          data={chartData}
          options={options}
          plugins={[plugin]}
        />
      </div>
    </div>
  );
};
