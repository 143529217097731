export const BE_LIST_TYPE = {
  search: 'saved-search',
  companiesList: 'search-list',
  wizard: 'wizard',
} as const;

type BackedListTypeKeys = keyof typeof BE_LIST_TYPE;
export type BackendListType = typeof BE_LIST_TYPE[BackedListTypeKeys];

export type StarterPackTile = {
  backgroundColor: string;
  description: string;
  icon: string | null;
  title: string;
  link_id: string | null;
  link_type: BackendListType;
  slug: string;
};

export type StarterPackData = {
  tiles: StarterPackTile[];
};
