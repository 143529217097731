import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from '@/types';

const initialState = {
  currency: 'USD' as Currency,
};

export const { reducer, actions } = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setCurrency: (state, { payload }: PayloadAction<Currency>) => {
      state.currency = payload;
    },
  },
});

export type TState = typeof initialState;
