import React from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import classNames from 'classnames';

export const CardIconList = ({ active }: { active?: boolean }) => (
  <div
    className={classNames('w-7 h-7 bg-[#DCE2E8] rounded-full mr-3 flex items-center justify-center', {
      'opacity-50': !active,
    })}
  >
    <FormatListBulletedIcon
      fontSize="medium"
      className="fill-[#231F20] p-[4px]"
    />
  </div>
);
