import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { removeCompaniesListCollaborator } from '@/services/api/companies-list';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';

interface RemoveCollaboratorVariables {
  id: string;
  email: string;
}

export const useMutationRemoveCompaniesListCollaborator = (listId: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationKey: ['remove-companies-list-collaborator'],
    mutationFn: ({ id: userId }: RemoveCollaboratorVariables) => removeCompaniesListCollaborator(listId, userId),
    onSuccess: (_, { email }) => {
      queryClient.invalidateQueries({ queryKey: ['companies-list-collaborators'] });

      enqueueSnackbar(<NotificationMessage title={`${email} collaborator has been removed`} />, {
        variant: 'success',
      });
    },

    onError: (_, { email }) =>
      enqueueSnackbar(
        <NotificationMessage title={`Failed to remove ${email} collaborator. Please try again or contact support.`} />,
        { variant: 'error' },
      ),
  });
};
