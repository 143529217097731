import React, { useEffect, useMemo, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { isEmpty, isNil } from 'lodash';

import { IconButton } from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { SkeletonLoader } from '../../common/SkeletonLoader';
import { SimilarCompanyCard } from './SimilarCompanyCard';
import { useQuerySimilarCompaniesSearch } from '@/hooks/queries/similar-companies/use-query-search-similar-company';
import { useQueryCompanyProfile } from '@/hooks/queries/company-profile/use-query-company-profile';
import { IProfile, SimilarCompanyType } from '@/types';
import { BetaTag } from '@/Components/Shared/BetaTag/BetaTag';
interface IProps {
  bainId: string;
}

const extractCompanyDescription = (data: IProfile[]) => {
  const visualizationTable = data?.find((item: { table: string }) => item.table === 'visualization');

  return visualizationTable?.data?.[0]?.['self_firmo_description___'] ?? '';
};

const getDataSlice = (data: SimilarCompanyType[], page: number, showPerPage: string) => {
  const lastIdx = page * parseInt(showPerPage);
  const fristIdx = lastIdx - parseInt(showPerPage);

  return data.slice(fristIdx, lastIdx);
};

export const SimilarTab = ({ bainId }: IProps) => {
  const [page, setPage] = useState(1);
  const [like, setLike] = useState<boolean | null>(null);
  const [showPerPage, setShowPerPage] = useState('15');
  const queryProfile = useQueryCompanyProfile(bainId);
  const companyDescription = useMemo(() => extractCompanyDescription(queryProfile.data ?? []), [queryProfile.data]);
  const querySimilarCompanies = useQuerySimilarCompaniesSearch(Number(bainId));
  const similarCompanies = useMemo(() => querySimilarCompanies.data?.data ?? [], [querySimilarCompanies.data?.data]);
  const totalCount = similarCompanies.length;
  const items = getDataSlice(similarCompanies, page, showPerPage);
  const pageCount = Math.ceil(totalCount / parseInt(showPerPage));

  const handleLike = (isLiked: boolean) => {
    const shouldTrackFeedback = isNil(like) || (isLiked && !like) || (!isLiked && like);

    if (shouldTrackFeedback) {
      pendo?.track('Beta similar companies thumbs feedback', {
        bainId,
        like: isLiked,
      });
    }

    setLike((prevState) => {
      if (isNil(prevState)) {
        return isLiked;
      }

      if (isLiked && prevState) return null;
      if (isLiked && !prevState) return true;

      if (!isLiked && prevState) return false;

      return null;
    });
  };

  const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeShowPerPage = (event: SelectChangeEvent) => {
    setShowPerPage(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (companyDescription && !querySimilarCompanies.isFetched) {
      querySimilarCompanies.refetch();
    }
  }, [companyDescription, querySimilarCompanies]);

  if (queryProfile.isFetching || querySimilarCompanies.isFetching) {
    return <SkeletonLoader />;
  }

  if (isEmpty(similarCompanies) && !querySimilarCompanies.isFetching) {
    return <p>This tab is currently unavailable due to insufficient data.</p>;
  }

  return (
    <div className="flex flex-col mb-4">
      <div className="grid grid-cols-3 gap-4 mb-6 items-center">
        <div className="flex gap-1 text-sm">
          <span className="font-medium">{totalCount}</span>
          <span className="text-[#484848]">similar companies found</span>
        </div>

        <div className="flex justify-between items-center bg-[#ECF6FD] rounded-lg p-2">
          <div className="flex">
            <BetaTag />
            <span className="text-[#484848] ml-2 mr-6 text-xs">
              Let us know if this evolving tab is showing good results!
            </span>
          </div>
          <div className="flex gap-2">
            <span>
              <IconButton
                id={`similar-like${like ? '-active' : ''}`}
                onClick={() => handleLike(true)}
                size="small"
              >
                {like ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
              </IconButton>
            </span>

            <span>
              <IconButton
                id={`similar-dislike${!isNil(like) && !like ? '-active' : ''}`}
                onClick={() => handleLike(false)}
                size="small"
              >
                {!isNil(like) && !like ? <ThumbDownAltIcon /> : <ThumbDownOffAltIcon />}
              </IconButton>
            </span>
          </div>
        </div>

        <div className="flex items-center justify-end">
          <Select
            variant="standard"
            value={showPerPage}
            onChange={handleChangeShowPerPage}
            label="Show"
            size="small"
          >
            <MenuItem value={'15'}>15</MenuItem>
            <MenuItem value={'30'}>30</MenuItem>
            <MenuItem value={'50'}>50</MenuItem>
          </Select>

          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChangePage}
          />
        </div>
      </div>

      <div className="grid gap-4 grid-cols-3">
        {items.map((item) => (
          <SimilarCompanyCard
            key={item.bain_id}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};
