// @ts-nocheck
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useAllFetchedTableRows = () => {
  const queryClient = useQueryClient();
  const allElasticData = queryClient.getQueriesData(['table-elastic']);

  const getRowByBainId = useCallback(
    (bainId) => {
      return allElasticData
        ?.flat(2)
        ?.filter((item) => typeof item !== 'string')
        ?.reduce((acc, item) => {
          if (!item) return acc;

          return [...acc, ...item?.pages];
        }, [])
        ?.reduce((acc, item) => {
          if (!item) return acc;

          return [...acc, ...item?.data];
        }, [])
        ?.find((item) => String(item?.bain_id) === bainId);
    },
    [allElasticData],
  );

  return {
    getRowByBainId,
  };
};
