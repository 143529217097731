import { http, HttpResponse } from 'msw';

export const itCompaniesListInputPrompt = 'give me a list of IT companies';

const CHARTS_CONFIG = {
  charts: [
    {
      ID: 1,
      TITLE: 'Revenue Over Time',
      CHART_TYPE: 'LINE',
      XAXIS: 'YEAR',
      YAXIS: 'REVENUE',
      SECTION: null,
      YAXIS_FORMAT: 'currency',
      XAXIS_FORMAT: null,
    },
  ],
};

const DATA = {
  data: [
    {
      BAIN_ID: 1,
      FIRM_NAME: 'firm-1',
      YEAR: 2012,
      REVENUE: 10000,
    },
    {
      BAIN_ID: 2,
      FIRM_NAME: 'firm-2',
      YEAR: 2013,
      REVENUE: 20000,
    },
    {
      BAIN_ID: 3,
      FIRM_NAME: 'fimr-3',
      YEAR: 2014,
      REVENUE: 30000,
    },
  ],
};

export const chartController = [
  http.get(`${process.env.REACT_APP_API_URL}/charts`, () => {
    return HttpResponse.json(CHARTS_CONFIG);
  }),
  http.get(`${process.env.REACT_APP_API_URL}/charts/:id`, () => {
    return HttpResponse.json(DATA);
  }),
];
