import React from 'react';
import { useForm } from 'react-hook-form';
import {
  CreateUpdateSharedElementDialog,
  CREATE_SHARED_ELEMENT_DEFAULT_VALUES,
} from '../Shared/CreateUpdateSharedElementDialog';
import { useMutationCreateSavedSearch } from '@/hooks/queries/saved-search/use-mutation-create-saved-search';
import { MODALS } from '@/constants';
import { useModal } from '@/hooks/use-modal';

export const CreateSavedSearchDialog = () => {
  const { isOpen, handleClose } = useModal(MODALS.CREATE_SAVED_SEARCH);
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: CREATE_SHARED_ELEMENT_DEFAULT_VALUES,
  });

  const mutation = useMutationCreateSavedSearch(reset);

  const onSubmit = (formData) => {
    mutation.mutate(formData);
  };

  return (
    <CreateUpdateSharedElementDialog
      title="Save search"
      subtitle="A search settings that you can share."
      buttonLabel="Save"
      register={register}
      formState={formState}
      isOpen={isOpen}
      handleClose={handleClose}
      handleButtonClick={handleSubmit(onSubmit)}
      isLoading={mutation.isLoading}
      disabled={mutation.isLoading}
    />
  );
};
