import { useQuery } from '@tanstack/react-query';

import { useDispatch } from 'react-redux';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { useLocalStorage } from 'usehooks-ts';
import { getCompaniesLists } from '@/services/api/companies-list';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/navigation-tabs';
import { LOCAL_STORAGE_KEYS } from '@/constants';

export const useQueryCompaniesLists = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompaniesList } = useShallowSelector((state) => state.navigationTabs);
  const [companiesListsSharedWithUser] = useLocalStorage(LOCAL_STORAGE_KEYS.COMPANIES_LISTS_SHARED_WITH_USER, []);
  const isAuth = useShallowSelector((state) => state.user.isAuth);

  return useQuery(['companies-lists'], getCompaniesLists, {
    enabled: isAuth,
    cacheTime: Infinity,
    staleTime: Infinity,
    onSuccess: (companiesLists) => {
      if (currentCompaniesList) {
        return;
      }

      const userCompaniesLists = [...companiesLists, ...companiesListsSharedWithUser];

      const sortedList = orderBy(userCompaniesLists, (list) => new Date(list.created_at), ['desc']);

      dispatch(actions.setCurrentCompaniesList(sortedList[0] ?? null));
    },
    onError: () =>
      enqueueSnackbar('Failed to fetch companies lists. Please try again or contact support.', { variant: 'error' }),
  });
};
