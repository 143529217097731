import React, { useMemo, ReactNode } from 'react';
import classnames from 'classnames';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

import { dataNotAvailable } from '@/Components/Shared/common';
import { CommonCellTooltip } from '@/Components/Shared/ScreenerTable/CommonCell/CommonCellToltip';
import { DatumDatum } from '@/types';

export const ENTRY_DIRECTION = {
  horizontal: 'horizontal',
  vertical: 'vertical',
} as const;

export interface CompanyProfileWidgetEntryProps {
  label: string;
  value?: unknown;
  widgetId?: string;
  id?: string;
  direction?: keyof typeof ENTRY_DIRECTION;
  expandableContent?: unknown;
  isLoading?: boolean;
  confidenceScore?: ReactNode;
  row?: DatumDatum;
  field?: string;
}

export const CompanyProfileWidgetEntry: React.FC<CompanyProfileWidgetEntryProps> = ({
  label,
  value,
  id,
  widgetId,
  direction = ENTRY_DIRECTION.horizontal,
  expandableContent,
  isLoading,
  confidenceScore,
  field,
  row,
}) => {
  const isHorizontalDirection = direction === ENTRY_DIRECTION.horizontal;
  const isVerticalDirection = direction === ENTRY_DIRECTION.vertical;

  const paragraphValue = useMemo(() => {
    if (isLoading)
      return (
        <Skeleton
          width={50}
          height={20}
          className="bg-[#0000000f]"
        />
      );

    return (
      <span className="text-sm">
        {value ? (
          <CommonCellTooltip
            displayName={label}
            //@ts-ignore
            row={row}
            field={field}
          >
            <div className="flex items-center justify-center gap-2">
              <div className="flex items-center gap-2">
                <span
                  className={classnames('font-medium', {
                    'bg-[#ecf6fd] p-1 rounded': widgetId === 'industry-header',
                  })}
                >
                  {value}
                </span>
                <span>{confidenceScore}</span>
              </div>
            </div>
          </CommonCellTooltip>
        ) : (
          dataNotAvailable()
        )}
      </span>
    );
  }, [confidenceScore, field, isLoading, label, row, value, widgetId]);

  return (
    <div
      key={label}
      id={id}
      data-testid={id}
    >
      {id === 'overview-subsidiaries-count' && <Divider className="my-4 border-[#f5f5f5]" />}

      <div className="flex flex-col mb-4">
        <p className={classnames('text-sm leading-7', { 'w-1/2': isHorizontalDirection })}>{label}</p>

        <div
          className={classnames('flex', {
            'items-center': !expandableContent,
            'flex-col items-start': expandableContent,
          })}
        >
          {isHorizontalDirection ? paragraphValue : null}
        </div>
      </div>

      <div className="mb-4">{isVerticalDirection ? paragraphValue : null}</div>
    </div>
  );
};
