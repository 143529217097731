import { useQuery } from '@tanstack/react-query';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { searchSimilarCompanies } from '@/services/api/similar-companies';

export const useQuerySimilarCompaniesSearch = (bain_id: number) => {
  const currency = useShallowSelector((state) => state.config.currency);

  return useQuery(['similar-companies-search', bain_id], () => searchSimilarCompanies(currency, { bain_id }), {
    enabled: false,
    retry: 3,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
