import { useMemo, useState, useCallback, useEffect } from 'react';
import { isEmpty, isEqual, reduce, sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { useDebounceValue } from 'usehooks-ts';
import {
  GridColumnOrderChangeParams,
  GridColDef,
  GridColumnResizeParams,
  GridDensity,
  GridState,
  GridFilterModel,
  GridFilterItem,
  GridSortModel,
  GridColumnVisibilityModel,
  GridEventListener,
} from '@mui/x-data-grid-pro';
import { useShallowSelector } from '../use-shallow-selector';
import { arrayMove } from '@/Utils/array-utils';
import { actions as tableActions } from '@/slices/table';
import { actions as tableNotPersistActions } from '@/slices/table/table-not-persist';
import { actions as searchActions } from '@/slices/search';
import { DEFAULT_PAGE_SIZE } from '@/constants';

export const DEFAULT_DENSITY = 'standard';

interface UseCustomTableTransformationProps {
  columns: GridColDef[];
}

enum FilterOperatorEnum {
  CONTAINES = 'contains',
  EQUALS = 'equals',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  IS_EMPTY = 'isEmpty',
  IS_NOT_EMPTY = 'isNotEmpty',
  IS_ANY_OF = 'isAnyOf',
}

const IS_OR_NOT_EMPTY = [FilterOperatorEnum.IS_EMPTY, FilterOperatorEnum.IS_NOT_EMPTY];

export const useCustomTableTransformation = ({ columns }: UseCustomTableTransformationProps) => {
  const dispatch = useDispatch();
  const sortModel = useShallowSelector((state) => state.table.sortModel);
  const pageSize = useShallowSelector((state) => state.table.pageSize);
  const pageNumber = useShallowSelector((state) => state.tableNotPersist.pageNumber);
  const isConfidenceLevelChecked = useShallowSelector((state) => state.table.isConfidenceLevelChecked);
  const defaultColumns = useShallowSelector((state) => state.table.defaultColumns);
  const columnsVisibility = useShallowSelector((state) => state.table.visible);
  const filterColumns = useShallowSelector((state) => state.table.filterColumns);
  const customColumnsOrder = useShallowSelector((state) => state.table.customColumnsOrder);
  const [customColumnsWidth, setCustomColumnsWidth] = useState<Record<string, number>>({});
  const [customDensity, setCustomDensity] = useState<GridDensity>(DEFAULT_DENSITY);
  const [customTableFilters, setCustomTableFilters] = useState<GridFilterItem[]>([]);
  const [delayedTableFilters] = useDebounceValue<GridFilterItem[]>(customTableFilters, 1500);
  const [muiTableKey, setMuiTableKey] = useState(0);

  useEffect(() => {
    const tableFilters = delayedTableFilters
      .filter(item => item.value || (IS_OR_NOT_EMPTY.includes(item.operator as FilterOperatorEnum)))
      .filter(item => !(item.operator === FilterOperatorEnum.IS_ANY_OF && item.value?.length === 0))
      .map(item => ({ key: item.field, op: item.operator, arg: item.value }));

    dispatch(searchActions.setTableFilters(tableFilters));
    dispatch(searchActions.setIsElasticEnabled(true));
  }, [dispatch, delayedTableFilters]);

  const transformedColumns = useMemo(() => {
    let orderedColumns = columns;

    for (const columnFieldName in customColumnsOrder) {
      const oldIndex = orderedColumns.findIndex((column) => column.field === columnFieldName);

      orderedColumns = arrayMove(orderedColumns, oldIndex, customColumnsOrder[columnFieldName]);
    }

    return isEmpty(customColumnsWidth)
      ? orderedColumns
      : orderedColumns.map((column) => {
        if (column.field in customColumnsWidth) {
          return {
            ...column,
            width: customColumnsWidth[column.field],
          };
        }

        return column;
      });
  }, [columns, customColumnsOrder, customColumnsWidth]);

  const columnVisibilityModel = useMemo(() => {
    return reduce(columns, (acc, { field }) => ({
      ...acc, [field]: columnsVisibility.concat(filterColumns).includes(field),
    }), {});
  }, [columnsVisibility, filterColumns, columns]);

  const handleColumnOrderChange: GridEventListener<'columnOrderChange'> = useCallback(
    (params: GridColumnOrderChangeParams) => {
      const {
        column: { field },
        targetIndex,
      } = params;

      dispatch(
        tableActions.setCustomColumnsOrder({
          ...customColumnsOrder,
          [field]: targetIndex,
        }),
      );
    },
    [customColumnsOrder, dispatch],
  );

  const handleColumnVisibilityModelChange = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      const visibleColumns = Object.keys(newModel).filter((key) => newModel[key]);

      dispatch(tableActions.setVisibleColumns(visibleColumns));
    },
    [dispatch],
  );

  const handleTableStateChange = useCallback((params: GridState) => {
    const density = params.density.value;

    setCustomDensity((prevDensity) => {
      if (prevDensity !== density) {
        return density;
      }

      return prevDensity;
    });
  }, []);

  const handleFilterModelChange = useCallback((params: GridFilterModel) => {
    const { items } = params;

    setCustomTableFilters(items);
  }, []);

  const handleColumnWidthChange = useCallback((params: GridColumnResizeParams) => {
    const { colDef, width } = params;

    setCustomColumnsWidth((prevState) => {
      return {
        ...prevState,
        [colDef.field]: width,
      };
    });
  }, []);

  const handleResetCustomSettings = useCallback(() => {
    dispatch(tableActions.setCustomColumnsOrder({}));
    setCustomColumnsWidth({});
    setCustomDensity(DEFAULT_DENSITY);
    setCustomTableFilters([]);
    setMuiTableKey((prevKey) => prevKey + 1);

    dispatch(tableActions.resetTable());
    dispatch(tableNotPersistActions.resetTableNotPersist());
  }, [dispatch]);

  const handleSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      dispatch(tableActions.setSortModel(newSortModel));
      dispatch(searchActions.setIsElasticEnabled(true));
    },
    [dispatch],
  );

  const isTableModified =
    ![customColumnsOrder, customColumnsWidth, sortModel].every(isEmpty) ||
    !isEqual(sortBy(['__check__'].concat(defaultColumns)), sortBy(columnsVisibility)) ||
    customDensity !== DEFAULT_DENSITY ||
    isConfidenceLevelChecked ||
    !!customTableFilters.length ||
    pageSize !== DEFAULT_PAGE_SIZE ||
    pageNumber !== 0;

  return {
    transformedColumns,
    customDensity,
    isTableModified,
    muiTableKey,
    customTableFilters,
    columnVisibilityModel,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnVisibilityModelChange: handleColumnVisibilityModelChange,
    onColumnWidthChange: handleColumnWidthChange,
    onResetColumnSettings: handleResetCustomSettings,
    onFilterModelChange: handleFilterModelChange,
    onStateChange: handleTableStateChange,
    onSortModelChange: handleSortModelChange,
  };
};
