import { get, post, patch, del } from 'aws-amplify/api';

import { ICreateSavedSearch, ISavedSearch, ISavedSearchSnippet, IUpdateSavedSearch } from '@/types';

export const getSavedSearches = (): Promise<ISavedSearchSnippet[]> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: '/saved_searches' }).response.then(res => res.body.json()).then(res => res.saved_searches);

export const getSavedSearchById = (id: string): Promise<ISavedSearch> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: `/saved_search/${id}` }).response.then(res => res.body.json());

export const createSavedSearch = (body: ICreateSavedSearch): Promise<ISavedSearch> =>
  // @ts-ignore
  post({ apiName: 'CDPAPI', path: '/saved_search', options: { body: { ...body, list_type: 'dynamic' } } }).response.then(res => res.body.json());

export const updateSavedSearch = ({ id, body }: { id: string; body: IUpdateSavedSearch }): Promise<ISavedSearch> =>
  // @ts-ignore
  patch({ apiName: 'CDPAPI', path: `/saved_search/${id}`, options: { body } }).response.then(res => res.body.json());

export const removeSavedSearch = (id: string): Promise<ISavedSearch> =>
  // @ts-ignore
  del({ apiName: 'CDPAPI', path: `/saved_search/${id}` }).response.then(res => res).then(res => res.body.json());
