import React, { useEffect, useMemo, useState } from 'react';
import { differenceBy } from 'lodash';
import { useDispatch } from 'react-redux';

import { checkIsSystemUser } from '../companies-list/check-is-system-user';
import { CtaButtons } from './cta-buttons';
import { ListSection } from './list-section';
import { useSavedSearches } from './use-saved-searches';
import { useCompaniesLists } from './use-companies-lists';
import { StarterPackSection } from './starter-pack-section';
import { HomeSearch } from './home-search';
import { LIST_SECTION_TYPE, LIST_TYPE } from '@/slices/search-lists-tables';
import { actions as filtersActions } from '@/slices/filters';

export const FAIL_FETCH_TEXT = 'Failed to fetch the data. Please try again or contact support.';

export enum HomePageTabsEnum {
  ALL = 'All',
  SEARCHES = 'Searches',
  LISTS = 'Lists',
}

export const HOME_PAGE_TABS = [HomePageTabsEnum.ALL, HomePageTabsEnum.SEARCHES, HomePageTabsEnum.LISTS];

const SearchLists = () => {
  const dispatch = useDispatch();
  const { allSavedSearches, userSavedSearches, isSavedSearchesFetching, isSavedSearchesError } = useSavedSearches();

  const { isCompaniesListsFetching, isCompaniesListsError, userCompaniesLists, sharedWithUserCompaniesLists } =
    useCompaniesLists();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleCurrentTabIndexChange = (_: React.SyntheticEvent<Element, Event>, newIndex: number) =>
    setCurrentTabIndex(newIndex);

  useEffect(() => {
    dispatch(filtersActions.resetFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentTab = HOME_PAGE_TABS[currentTabIndex];

  const listEntries = useMemo(() => {
    const lists = [];

    const sharedWithUserSavedSearches = differenceBy(allSavedSearches, userSavedSearches, 'list_id');

    const sharedWithUserSavedSearchesWithType = sharedWithUserSavedSearches.map((search) => ({
      ...search,
      listType: LIST_TYPE.sharedWithUserSearch,
    }));
    const userSavedSearchesWithType = userSavedSearches.map((search) => ({
      ...search,
      listType: LIST_TYPE.userSearch,
    }));
    const userCompaniesListsWithType = userCompaniesLists.map((list) => ({
      ...list,
      listType: LIST_TYPE.userList,
    }));
    const sharedWithUserCompaniesListsWithType = sharedWithUserCompaniesLists.map((list) => ({
      ...list,
      listType: LIST_TYPE.sharedWithUserList,
    }));

    if (currentTab === HomePageTabsEnum.SEARCHES) {
      lists.push(...userSavedSearchesWithType, ...sharedWithUserSavedSearchesWithType);
    }

    if (currentTab === HomePageTabsEnum.LISTS) {
      lists.push(...userCompaniesListsWithType, ...sharedWithUserCompaniesListsWithType);
    }

    if (currentTab === HomePageTabsEnum.ALL) {
      lists.push(
        ...userSavedSearchesWithType,
        ...sharedWithUserSavedSearchesWithType,
        ...userCompaniesListsWithType,
        ...sharedWithUserCompaniesListsWithType,
      );
    }

    const filteredLists = lists.filter((list) => !checkIsSystemUser(list));

    return filteredLists.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  }, [allSavedSearches, currentTab, sharedWithUserCompaniesLists, userCompaniesLists, userSavedSearches]);

  const isLoading = isCompaniesListsFetching || isSavedSearchesFetching;

  return (
    <div className="flex flex-col relative">
      <HomeSearch />

      <div className="mx-6 my-8">
        <CtaButtons
          currentTabIndex={currentTabIndex}
          handleCurrentTabIndexChange={handleCurrentTabIndexChange}
        />

        <ListSection
          error={isCompaniesListsError || isSavedSearchesError ? FAIL_FETCH_TEXT : undefined}
          isLoading={isLoading}
          lists={listEntries}
          listType={LIST_SECTION_TYPE.myWork}
        />

        <StarterPackSection />
      </div>
    </div>
  );
};

export default SearchLists;
