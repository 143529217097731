import { CURRENCIES } from '@/constants';
import { ChartConfigType, Currency } from '@/types';
import { Tab as TabType } from '@/Components/CompanyResearch/CompanyProfile/types';

export const getYaxisLabel = ({ yAxisType, currency }: { yAxisType?: string | null; currency: Currency }) => {
  return yAxisType === 'currency' ? CURRENCIES[currency].label : '';
};

export const getTabsWithCharts = (tabs: TabType[], chartConfigs: ChartConfigType[]) => {
  const sections = Array.from(new Set(chartConfigs.map((config) => config.SECTION)));

  const modifiedTabs = tabs.map((tab) => {
    if (sections.includes(tab.label)) {
      return {
        ...tab,
        components: [{ component: 'Charts' }, ...(tab.components ?? [])],
      };
    }

    return tab;
  });

  return modifiedTabs;
};
