import React from 'react';
import { Dialog } from '@mui/material';
import { DialogHeader } from '../common/DialogHeader';
import { DialogFooter } from '../common/DialogFooter';
import { useModal } from '@/hooks/use-modal';
import { MODALS } from '@/constants';

export interface ConfirmDialogProps {
  title: string;
  subtitle?: string;
  onPrimaryButtonClick: () => void;
  primaryButtonLabel: string;
  onSecondaryButtonClick: () => void;
  secondaryButtonLabel: string;
  primaryButtonDisabled?: boolean;
  isLoading?: boolean;
}

export const ConfirmDialog = () => {
  const { isOpen, handleClose, data } = useModal<ConfirmDialogProps>(MODALS.CONFIRM_DIALOG);

  if (!data) return null;

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: 'p-4 min-w-[350px] top-[20%] self-start' }}
    >
      <DialogHeader
        title={data.title ?? ''}
        handleClose={handleClose}
      />

      {data.subtitle ? <div className="text-[#484848] text-sm font-normal">{data.subtitle}</div> : null}

      <DialogFooter
        onPrimaryButtonClick={data.onPrimaryButtonClick}
        onBackButtonClick={data.onSecondaryButtonClick}
        primaryButtonDisabled={data.primaryButtonDisabled}
        isLoading={data.isLoading}
        primaryButtonLabel={data.primaryButtonLabel}
        secondaryButtonLabel={data.secondaryButtonLabel}
      />
    </Dialog>
  );
};
