import React from 'react';
import Button from '@mui/material/Button';
import { useParams } from 'react-router';
import { ROUTES } from '@/constants';

import { getUuidFromStringEnd } from '@/Utils/url-utils';
import { useQuerySavedSearchById } from '@/hooks/queries/saved-search/use-query-saved-search-by-id';
import { useScreener } from '@/hooks/use-screen';

export const ClearSearchButton = ({ onClick }: { onClick?: () => void }) => {
  const { resetAll, softReset } = useScreener();
  const isSavedSearch = location.pathname.startsWith(ROUTES.SAVED_SEARCH);
  const { id: idWithTitle } = useParams<{ id: string }>();
  const savedSearchId = isSavedSearch ? getUuidFromStringEnd(idWithTitle) : '';
  const savedSearchByIdQuery = useQuerySavedSearchById({ id: savedSearchId });

  const onClear = () => {
    if (savedSearchId) {
      softReset();
      savedSearchByIdQuery.refetch();
    } else {
      resetAll();
    }

    onClick?.();
  };

  return (
    <Button
      className="px-2 py-2 mr-2 border-bluegray-900 text-bluegray-900 border border-solid hover:bg-bluegray-900 hover:text-white font-graphik text-xs h-[36px]"
      onClick={onClear}
      id="custom-reset-button"
    >
      Clear
    </Button>
  );
};
