export const COMPANY_PROFILE_CONFIG = {
  key: 'company_profile',
  tabs: [
    {
      components: [
        {
          component: 'OverviewTab',
        },
      ],
      datasets: ['company_search', 'visualization'],
      id: 'overview',
      label: 'Overview',
      slug: 'overview',
    },
    {
      components: [
        {
          component: 'MultiyearFinancialsTab',
        },
      ],
      datasets: ['visualization_time_series', 'master_time_series'],
      id: 'multi-year-financials',
      label: 'Financials',
      slug: 'financial-details',
    },
    {
      components: [
        {
          component: 'MnATab',
        },
      ],
      datasets: ['master_mna', 'master_mna_buyer'],
      id: 'm-a',
      label: 'M & A',
      slug: 'mergers-and-acquisitions-details',
    },
  ],
};