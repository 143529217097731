import { Link } from '@mui/material';
import React from 'react';

export const SearchRecord = ({ query, onSelect }) => {
  const { searchText, isLucene } = query;
  const handleClick = () => {
    onSelect?.();
  };

  return (
    <Link
      component="button"
      className="text-black decoration-black text-left"
      onClick={handleClick}
    >
      {isLucene ? searchText : `"${searchText}"`}
    </Link>
  );
};
