import React, { PropsWithChildren } from 'react';

import { Badge, Tooltip } from '@mui/material';

import { IconEstimated } from '@/Components/Icons/IconEstimated';

interface EstimatedSignProps {
  isEstimated?: boolean;
}

export const EstimatedSign = ({ children, isEstimated }: PropsWithChildren<EstimatedSignProps>) => {
  if (!isEstimated) return <>{children}</>;

  const badge = (
    <Tooltip
      title="Estimated"
      arrow
    >
      <span className="inline-block">
        <IconEstimated
          width={8}
          className="fill-[red]"
        />
      </span>
    </Tooltip>
  );

  return (
    <Badge
      classes={{
        badge: 'pr-0 top-[4px]',
      }}
      badgeContent={badge}
    >
      {children}
    </Badge>
  );
};
