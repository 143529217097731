import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';

import SearchPage from '../search-page';
import { actions as searchActions } from '@/slices/search';
import { actions as companiesListsActions } from '@/slices/navigation-tabs';
import { CompaniesListControls } from '@/Components/Shared/CompaniesList/CompaniesListControls';
import { useQueryCompaniesListById } from '@/hooks/queries/companies-list/use-query-companies-list-by-id';
import { ERROR_PAGE_PROPS, HISTORY_KEYS, LOCAL_STORAGE_KEYS } from '@/constants';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { ICompaniesList } from '@/types';
import { getUuidFromStringEnd } from '@/Utils/url-utils';

const CompaniesList = () => {
  const dispatch = useDispatch();
  const { id: idWithTitle, revision: revisionUrlParam } = useParams<{ id: string; revision?: string }>();
  const id = getUuidFromStringEnd(idWithTitle);
  const [isEmptyList, setIsEmptyList] = useState(false);

  const onSuccess = (data: ICompaniesList) => {
    if (data?.pinned_company_ids.length > 0) {
      setIsEmptyList(false);
      dispatch(searchActions.setPinnedCompanyIds(data.pinned_company_ids.map(Number)));
      dispatch(searchActions.setIsElasticEnabled(true));

      return;
    }

    if (Array.isArray(data?.pinned_company_ids)) {
      dispatch(searchActions.setPinnedCompanyIds([]));
    }

    setIsEmptyList(true);
  };

  const revision = isNaN(Number(revisionUrlParam)) ? undefined : Number(revisionUrlParam);

  const query = useQueryCompaniesListById(id, revision, {
    onSuccess,
  });

  const { data: companiesLists } = useQueryCompaniesLists();

  const [companiesListsSharedWithUser, setCompaniesListsSharedWithUser] = useLocalStorage<ICompaniesList[]>(
    LOCAL_STORAGE_KEYS.COMPANIES_LISTS_SHARED_WITH_USER,
    [],
  );

  useEffect(() => {
    if (query.status === 'success' && !query.isRefetching) {
      onSuccess(query.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, query.isRefetching, query.data]);

  useEffect(() => {
    dispatch(searchActions.setHistoryKey(HISTORY_KEYS.SCREENERS_HISTORY));
  }, [dispatch]);

  useEffect(() => {
    if (!query.data || query.isFetching || !companiesLists) return;
    dispatch(companiesListsActions.setCurrentCompaniesList(query.data));

    if (companiesLists.some((list) => list.list_id === query.data.list_id)) return;

    const alreadySharedWithUserItem = companiesListsSharedWithUser.find(
      (sharedCompaniesList) => sharedCompaniesList.list_id === query.data.list_id,
    );

    if (alreadySharedWithUserItem) {
      setCompaniesListsSharedWithUser(
        companiesListsSharedWithUser.map((sharedCompaniesList) =>
          sharedCompaniesList.list_id === query.data.list_id ? query.data : sharedCompaniesList,
        ),
      );

      return;
    }

    setCompaniesListsSharedWithUser([...companiesListsSharedWithUser, query.data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query.data, query.isFetching, companiesLists]);

  const isAccessDenied = query.error?.response?.status == 403;

  return (
    <SearchPage
      isError={query.isError}
      errorPage={isAccessDenied ? ERROR_PAGE_PROPS.ACCESS_DENIED : ERROR_PAGE_PROPS.LIST_NOT_FOUND}
      controls={
        <CompaniesListControls
          isLoading={query.isFetching}
          companiesList={query.data}
        />
      }
      hideSearch
      isCompaniesList
      forceEmptyResult={isEmptyList}
    />
  );
};

export default CompaniesList;
