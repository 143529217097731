import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  pageNumber: 0,
};

export const { reducer, actions } = createSlice({
  name: 'tableNotPersist',
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    resetTableNotPersist: () => initialState,
  },
});

export type TState = typeof initialState;
