import _ from 'lodash';
import { Text } from 'slate';

import { SPECIAL_SEARCH_WORDS, SPECIAL_SEARCH_CHARS } from '../constants';

export const getTextLength = (token) => {
  if (_.isString(token)) {
    return token.length;
  } else if (_.isString(token.content)) {
    return token.content.length;
  } else {
    return token.content.reduce((l, t) => l + getTextLength(t), 0);
  }
};

const parseWithSpecialWords = (text) =>
  text.split(/(\s+)/).map((word) => (SPECIAL_SEARCH_WORDS.includes(word) ? { content: word, type: 'keyword' } : word));
const parseWithSpecialChars = (items) =>
  items.reduce((acc, item) => {
    const tst = _.isString(item)
      ? item.split('').map((char) => (SPECIAL_SEARCH_CHARS.includes(char) ? { content: char, type: 'keychar' } : char))
      : item;

    return acc.concat(tst);
  }, []);

export const tokenize = _.flow([parseWithSpecialWords, parseWithSpecialChars]);

/**
 * https://github.com/ianstormtaylor/slate/blob/main/site/examples/code-highlighting.tsx
 */
export const decorate = ([node, path]) => {
  const ranges = [];

  if (!Text.isText(node)) {
    return ranges;
  }

  const tokens = tokenize(node.text);

  let start = 0;

  for (const token of tokens) {
    const length = getTextLength(token);
    const end = start + length;

    if (!_.isString(token)) {
      ranges.push({
        [token.type]: true,
        anchor: { path, offset: start },
        focus: { path, offset: end },
      });
    }

    start = end;
  }

  return ranges;
};

export const getSlateText = (searchQuery, searchText) => {
  let text = '';

  if (searchQuery.length > searchText.length) {
    text = searchQuery;
  }

  if (searchQuery.length === searchText.length) {
    text = searchText;
  }

  if (searchQuery.length < searchText.length) {
    text = searchText;
  }

  return text.replace(/\s\s+/g, ' ');
};
