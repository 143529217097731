import React from 'react';

export const IconShare = () => (
  <svg
    width="20"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.606 3.25c1.818-1.823 4.752-2.152 6.78-.567a5.052 5.052 0 0 1 .477 7.55l-1.186 1.188a.836.836 0 0 1-1.185 0 .84.84 0 0 1 0-1.188l1.185-1.188a3.367 3.367 0 0 0-.227-4.959c-1.356-1.135-3.39-.919-4.64.333L9.75 5.48a.836.836 0 0 1-1.186 0c-.327-.327-.33-.856.148-1.335l.894-.896Zm1.472 4.494-3.334 3.334a.832.832 0 1 0 1.179 1.178l3.333-3.334a.832.832 0 1 0-1.178-1.178Zm-.844 6.753-1.062 1.06c-1.25 1.247-3.29 1.462-4.647.33a3.346 3.346 0 0 1-.227-4.942l1.187-1.183a.836.836 0 0 0 0-1.184.844.844 0 0 0-1.187 0l-1.063 1.06c-1.81 1.806-2.127 4.71-.562 6.73a5.051 5.051 0 0 0 7.56.497l1.188-1.184a.836.836 0 0 0 0-1.184.84.84 0 0 0-1.187 0Z"
      fill="#2E3F4C"
    />
  </svg>
);
