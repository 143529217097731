import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  similarCompaniesSearchId: null as null | string,
  similarCompaniesSearchProgress: null as null | number,
};

export const { reducer, actions } = createSlice({
  name: 'similarCompaniesSearch',
  initialState,
  reducers: {
    setSimilarCompaniesSearchId: (state, { payload }: PayloadAction<string | null>) => {
      state.similarCompaniesSearchId = payload;
    },
    setSimilarCompaniesSearchProgress: (state, { payload }: PayloadAction<number | null>) => {
      state.similarCompaniesSearchProgress = payload;
    },
    resetState: () => initialState,
  },
});

export type TState = typeof initialState;
