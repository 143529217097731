import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { autocompleteCompanySearch } from '@/services/api/autocomplete-company-search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const useQueryAutocompleteCompanySearch = (
  company: string,
  enabled: boolean,
  setMatchCompanySearchEnabled: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const { enqueueSnackbar } = useSnackbar();
  const currency = useShallowSelector((state) => state.config.currency);

  return useQuery(
    ['match-company-search', company],
    () =>
      autocompleteCompanySearch({
        currency,
        company,
      }),
    {
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: isAuth && enabled && company.length >= 3,
      onError: () =>
        enqueueSnackbar('Failed to fetch companies list. Please try again or contact support.', {
          variant: 'error',
        }),
      onSettled: () => setMatchCompanySearchEnabled(false),
    },
  );
};
