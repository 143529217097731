import React, { FC, useRef } from 'react';

import { Tooltip } from '@mui/material';
import { ICompaniesSubsidiaries } from '@/types';
import { createCompanyProfileLink } from '@/pages/company-profile';

interface SubsidiariesTableItemProps {
  label?: string;
  bainId?: number;
}

export const SubsidiariesTableItem = ({ label, bainId }: SubsidiariesTableItemProps) => {
  const containerRef = useRef<HTMLButtonElement | null>(null);
  const spanRef = useRef<HTMLSpanElement | null>(null);

  if (!label || !bainId) return null;

  const margin = 6;

  const shouldShowTooltip = (spanRef?.current?.offsetWidth ?? 0) + margin >= (containerRef?.current?.offsetWidth ?? 0);

  const content = (
    <button
      ref={containerRef}
      onClick={() => window.open(createCompanyProfileLink(bainId.toString()), '_blank')}
      id="subsidiaries-table-item"
      className="block mb-2 underline cursor-pointer text-[#484848] truncate text-left w-[calc(33.33vw-96px)]"
    >
      <span
        ref={spanRef}
        className="mr-2 font-medium text-[#484848] truncate group-hover/title:underline"
      >
        {label}
      </span>
    </button>
  );

  if (shouldShowTooltip) {
    return (
      <Tooltip
        classes={{ tooltip: 'max-w-[320px] bg-[#484848] p-2', arrow: 'text-[#484848]' }}
        arrow
        title={label}
        enterDelay={300}
        enterNextDelay={300}
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

interface SubsidiariesTableProps {
  subsidiaries: ICompaniesSubsidiaries;
}

export const SubsidiariesTable: FC<SubsidiariesTableProps> = ({ subsidiaries }) => {
  return (
    <div>
      <div className="h-[140px] overflow-auto overflow-x-hidden">
        {subsidiaries.map((item, idx) => (
          <SubsidiariesTableItem
            key={`${item.self_firmo_name___}:${idx}`}
            label={item.self_firmo_name___}
            bainId={item.bain_id}
          />
        ))}
      </div>
    </div>
  );
};
