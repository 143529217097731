import React from 'react';

export const IconGraph = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_675_3245)">
        <path
          d="M30 60.5C13.4315 60.5 -3.8147e-06 47.0685 -3.8147e-06 30.5C-3.8147e-06 13.9315 13.4315 0.5 30 0.5C46.5685 0.5 60 13.9315 60 30.5C60 47.0685 46.5685 60.5 30 60.5Z"
          fill="#ECECEC"
        />
        <path
          d="M57.6001 50.9H2.4001"
          stroke="#06016A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36 54.5H21.6"
          stroke="#06016A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.6 50.9H49.2V7.69995L39.6 13.7V50.9Z"
          fill="#B5C4FF"
          stroke="#06016A"
          strokeMiterlimit="10"
        />
        <path
          d="M25.2 50.9H34.8V20.9L25.2 26.9V50.9Z"
          fill="#B5C4FF"
          stroke="#06016A"
          strokeMiterlimit="10"
        />
        <path
          d="M10.7999 50.9H20.3999V29.3L10.7999 35.3V50.9Z"
          fill="#72809E"
          stroke="#06016A"
          strokeMiterlimit="10"
        />
        <path
          d="M49.2007 1.70007L34.8007 10.7001V14.9001L0.600686 36.5001"
          stroke="#06016A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_675_3245">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="matrix(-1 0 0 1 60 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
