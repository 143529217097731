import React from 'react';

import { useSnackbar } from 'notistack';

import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';

const MIN_CHARACTERS = 50;
const MAX_CHARACTERS = 2000;

export const useSemanticSearchValueValidation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const validate = (value?: string) => {
    const errorMessage = `Oops. Please provide at least ${MIN_CHARACTERS} characters and focus on what the company does, avoiding excessive negatives. This will help us deliver more accurate results. Try again`;

    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      enqueueSnackbar(<NotificationMessage title={errorMessage} />, { variant: 'error' });

      return false;
    }

    const numberOfCharacters = trimmedValue.length;

    if (numberOfCharacters < MIN_CHARACTERS) {
      enqueueSnackbar(<NotificationMessage title={errorMessage} />, { variant: 'error' });

      return false;
    }

    if (numberOfCharacters > MAX_CHARACTERS) {
      enqueueSnackbar(
        <NotificationMessage
          title={`Oops. Please provide maximum ${MAX_CHARACTERS} characters and focus on what the company does, avoiding excessive negatives. This will help us deliver more accurate results. Try again`}
        />,
        { variant: 'error' },
      );

      return false;
    }

    return true;
  };

  return { validate };
};
