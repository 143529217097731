import React, { ReactNode } from 'react';
import cn from 'classnames';

interface IProps {
  children: ReactNode;
  id?: string;
  href?: string;
  className?: string;
}

export const Link = ({ children, id, href, className }: IProps) => {
  return href
    ? (
      <a
        href={href}
        id={id}
        target="_blank"
        rel="noopener noreferrer"
        className={cn('cursor-pointer underline text-[#0484E7] text-sm', className)}
      >
        {children}
      </a>
    ) :
    <span id={id} className={cn('cursor-pointer underline text-[#0484E7] text-sm', className)}>{children}</span>;
};
