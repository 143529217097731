import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export const CaseCodeSubmitButton = ({ isLoading, isEditMode, onSubmit }) => {
  const label = isEditMode ? 'Change' : 'Done';

  return (
    <Button
      variant="contained"
      className="text-[#fff] border-[#2e3f4c] bg-[#2e3f4c] self-start mt-7 h-10 min-w-[90px] font-medium"
      data-testid="login-button"
      disabled={isLoading}
      onClick={onSubmit}
    >
      {isLoading ? (
        <CircularProgress
          className="text-white"
          size={20}
        />
      ) : (
        label
      )}
    </Button>
  );
};
