import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import { useTableLoading } from '@/hooks/table/use-table-loading';
import { createCompanyProfileLink } from '@/pages/company-profile';
import { useCaseCodePopupConditionally } from '@/Components/Dialogs/CaseCodeDialog/use-show-case-code-popup-conditionally';

export const CompanyCell = ({ row, value }: GridRenderCellParams) => {
  const isLoading = useTableLoading();
  const { shouldShowCaseCodePopup, handleCaseCodeModalOpen } = useCaseCodePopupConditionally();

  const openCompanyProfile = () => window.open(createCompanyProfileLink(row.bain_id), '_blank');

  const handleClick = () => {
    if (shouldShowCaseCodePopup) {
      handleCaseCodeModalOpen({ onSuccess: openCompanyProfile });

      return;
    }

    openCompanyProfile();
  };

  return isLoading ? (
    <Skeleton className="w-full h-full bg-[#0000000f]" />
  ) : (
    <span
      id="open-company-profile"
      className="w-full"
      onClick={handleClick}
    >
      <Tooltip title={value?.length > 20 ? value : ''}>
        <Button
          className="min-w-0 normal-case text-[#484848] font-normal px-2.5 py-2 w-full justify-start"
          id={value}
        >
          <span className="truncate">{value}</span>
        </Button>
      </Tooltip>
    </span>
  );
};
