import { useCallback, useMemo } from 'react';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getFiltersToDisplayAsChips, getFlatAvailableFilters, normalizeFilters } from '@/Utils/filters';
import { useQueryColumnMapper } from '@/hooks/queries/column-mapper/use-query-column-mapper';
import { useQueryAvailableFilters } from '@/hooks/queries/available-filters/use-query-available-filters';
import { FilterTypesTypes } from '@/constants';

export const ELEMENT_ROLES = {
  keyword: 'keyword',
  keychar: 'keychar',
  filter: 'filter',
} as const;

type ElementRolesKeys = keyof typeof ELEMENT_ROLES;
type ElementRole = typeof ELEMENT_ROLES[ElementRolesKeys];

export const KEY_WORDS = {
  AND: 'AND',
  OR: 'OR',
};

export const KEY_WORDS_ARRAY = Object.values(KEY_WORDS);

export const KEY_CHARS = {
  OPENING_BRACKET: '(',
  CLOSING_BRACKET: ')',
};

export const KEY_CHARS_ARRAY = Object.values(KEY_CHARS);

export interface IAggregationElement {
  value: string;
  role: ElementRole;
  backendName?: string;
  filterType?: FilterTypesTypes;
  sectionLabel?: string;
  parsedFilterLabel?: string;
  parsedValues?: string[];
  rawValues?: string[];
  rawFilterValue?: string;
  tooltipTitle?: string;
}

export const validateAggregation = (customAggregation?: IAggregationElement[]) => {
  const filtersBackendNames = customAggregation
    ?.filter((item) => item.role === ELEMENT_ROLES.filter)
    ?.map((item) => item.backendName);

  if (!filtersBackendNames) return '';

  const hasDuplicateFilter = filtersBackendNames?.length !== new Set([...filtersBackendNames]).size;

  if (hasDuplicateFilter) return 'Duplicated filter';

  const numberOfOpeningBrackets =
    customAggregation?.filter((item) => item.role === ELEMENT_ROLES.keychar && item.value === KEY_CHARS.OPENING_BRACKET)
      ?.length || 0;

  const numberOfClosingBrackets =
    customAggregation?.filter((item) => item.role === ELEMENT_ROLES.keychar && item.value === KEY_CHARS.CLOSING_BRACKET)
      ?.length || 0;

  if (numberOfOpeningBrackets === 0 && numberOfClosingBrackets === 0) return '';

  if (numberOfOpeningBrackets !== numberOfClosingBrackets)
    return 'Active filters: Make sure to close your bracket groups';

  return '';
};

export const checkIfAggregationIsCustom = (aggregation?: IAggregationElement[]) => {
  if (!aggregation) return false;

  if (aggregation.some((item) => item.role === ELEMENT_ROLES.keychar)) return true;

  if (aggregation.some((item) => item.role === ELEMENT_ROLES.keyword && item.value === KEY_WORDS.OR)) return true;

  return false;
};

export const getAggregationString = (aggregation?: IAggregationElement[]) =>
  aggregation
    ?.reduce(
      (acc, current) => (acc += `${current.role === ELEMENT_ROLES.filter ? current.backendName : current.value} `),
      '',
    )
    ?.trim();

export const useFiltersToDisplay = () => {
  const { customAggregationString, ...filters } = useShallowSelector((store) => store.filters);

  // we use column mapper here to get columns of sub-tree filters
  const {
    data: availableFilters,
    isFetching: isFetchingAvailableFilters,
    error: availableFiltersError,
  } = useQueryAvailableFilters();

  const {
    data: availableColumns,
    isFetching: isFetchingAvailableColumns,
    error: availableColumnsError,
  } = useQueryColumnMapper();

  const filtersToDisplay: IAggregationElement[] = useMemo(() => {
    const normalizedFilters = { ...filters, otherFilters: normalizeFilters(filters.otherFilters) };

    return getFiltersToDisplayAsChips(normalizeFilters(normalizedFilters), availableFilters, availableColumns);
  }, [availableColumns, availableFilters, filters]);

  const getFiltersAggregation = useCallback(
    (aggString: string | undefined = customAggregationString): IAggregationElement[] | null => {
      if (filtersToDisplay.length === 0) return null;

      if (!aggString) {
        return filtersToDisplay.flatMap((value, index, array) => {
          const filter = { ...value, role: ELEMENT_ROLES.filter };

          return array.length - 1 !== index ? [filter, { value: KEY_WORDS.AND, role: ELEMENT_ROLES.keyword }] : filter;
        });
      }

      return aggString.split(' ').map((value) => {
        if (KEY_WORDS_ARRAY.includes(value)) return { value, role: ELEMENT_ROLES.keyword };
        if (KEY_CHARS_ARRAY.includes(value)) return { value, role: ELEMENT_ROLES.keychar };

        const flatAvailableFilters = getFlatAvailableFilters(availableFilters);

        const fallbackValue = flatAvailableFilters?.[value] ? `${flatAvailableFilters[value].displayName}: any` : '';

        return {
          role: ELEMENT_ROLES.filter,
          backendName: value,
          value: filtersToDisplay.find((filter) => filter.backendName === value)?.value || fallbackValue,
        };
      });
    },
    [availableFilters, customAggregationString, filtersToDisplay],
  );

  const showSavedFilters =
    !!filtersToDisplay.length ||
    !isFetchingAvailableFilters ||
    !availableFiltersError ||
    !isFetchingAvailableColumns ||
    !availableColumnsError;

  return { showSavedFilters, filtersToDisplay, customAggregationString, getFiltersAggregation };
};
