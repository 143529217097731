import { isEmpty } from 'lodash';
import React from 'react';

export const SEMANTIC_SEARCH_CHECKBOXES = {
  description: 'description',
  filters: 'filters',
  keywords: 'keywords',
} as const;

type SemanticSearchCheckboxesKeys = keyof typeof SEMANTIC_SEARCH_CHECKBOXES;
type SemanticSearchCheckbox = typeof SEMANTIC_SEARCH_CHECKBOXES[SemanticSearchCheckboxesKeys];

const getSemanticSearchNarrowingLabels = (checkedCheckboxes: SemanticSearchCheckbox[]) => {
  if (isEmpty(checkedCheckboxes))
    return {
      title: 'Match no areas  |  Most results',
      subtitle:
        'Nothing from your search applied to narrow your list of companies. Any additional filters will still be applied.',
    };

  const onlyDescriptionChecked = checkedCheckboxes.every(
    (checkbox) => checkbox === SEMANTIC_SEARCH_CHECKBOXES.description,
  );

  if (onlyDescriptionChecked)
    return {
      title: 'Match one area  |  More results',
      subtitle: 'Companies with only semantic similarity to your description.',
    };

  const onlyFiltersChecked = checkedCheckboxes.every((checkbox) => checkbox === SEMANTIC_SEARCH_CHECKBOXES.filters);

  if (onlyFiltersChecked)
    return {
      title: 'Match one area  |  More results',
      subtitle: 'Companies which only match your AI derived industries.',
    };

  const onlyKeywordsChecked = checkedCheckboxes.every((checkbox) => checkbox === SEMANTIC_SEARCH_CHECKBOXES.keywords);

  if (onlyKeywordsChecked)
    return {
      title: 'Match one area  |  More results',
      subtitle: 'Companies which only match your AI derived keywords.',
    };

  const numberOfCheckboxes = Object.entries(SEMANTIC_SEARCH_CHECKBOXES).length;

  const descriptionUnchecked =
    checkedCheckboxes.length === numberOfCheckboxes - 1 &&
    !checkedCheckboxes.includes(SEMANTIC_SEARCH_CHECKBOXES.description);

  if (descriptionUnchecked)
    return {
      title: 'Match some areas  |  Fewer results',
      subtitle: 'Companies which match your AI derived keywords and industries.',
    };

  const filtersUnchecked =
    checkedCheckboxes.length === numberOfCheckboxes - 1 &&
    !checkedCheckboxes.includes(SEMANTIC_SEARCH_CHECKBOXES.filters);

  if (filtersUnchecked)
    return {
      title: 'Match some areas  |  Fewer results',
      subtitle: 'Companies with semantic similarity to your description which also match your AI derived keywords.',
    };

  const keywordsUnchecked =
    checkedCheckboxes.length === numberOfCheckboxes - 1 &&
    !checkedCheckboxes.includes(SEMANTIC_SEARCH_CHECKBOXES.keywords);

  if (keywordsUnchecked)
    return {
      title: 'Match some areas  |  Fewer results',
      subtitle: 'Companies with semantic similarity to your description which also match your AI derived industries.',
    };

  return {
    title: 'Match all areas  |  Fewest results',
    subtitle:
      'Companies with semantic similarity to your description which also match your AI derived keywords and industries.',
  };
};

interface SemanticSearchNarrowingDescriptionProps {
  checkedCheckboxes: SemanticSearchCheckbox[];
}

export const SemanticSearchNarrowingDescription = ({ checkedCheckboxes }: SemanticSearchNarrowingDescriptionProps) => {
  const { title, subtitle } = getSemanticSearchNarrowingLabels(checkedCheckboxes);

  return (
    <div className="flex flex-col  border border-[#ddd] px-5 py-4 mr-4 w-[430px] h-[100px]">
      <span className="text-[#484848] text-sm font-medium mb-1">{title}</span>
      <span className="text-[#666] text-sm font-normal leading-[22px]">{subtitle}</span>
    </div>
  );
};
