import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import type { PreloadedState } from '@reduxjs/toolkit';
import { rootReducer } from '@/slices';
import { IS_DEV } from '@/constants';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['modal', 'similarCompaniesSearch', 'selectedCompanies', 'tableNotPersist'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const storeConfig = {
  reducer: persistedReducer,
  devTools: IS_DEV,
};

export const store = configureStore(storeConfig);

export const setupStore = (preloadedState?: PreloadedState<TRootState>) => {
  return configureStore({
    ...storeConfig,
    preloadedState,
  });
};

export const persistor = persistStore(store);

export type TRootState = ReturnType<typeof store.getState>;
export type TAppStore = ReturnType<typeof setupStore>;
export type TAppDispatch = typeof store.dispatch;
