import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { isEmpty, isNil } from 'lodash';
import {
  Alert,
  Box,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Dialog,
} from '@mui/material';

import { ExportButton } from '../../Shared/Buttons/ExportButton';
import { useCaseCodePopupConditionally } from '../CaseCodeDialog/use-show-case-code-popup-conditionally';
import { ExportOption } from './types';
import { MODALS, MAX_COMPANIES_EXPORT } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { useQueryTable } from '@/hooks/queries/table/use-query-table';
import { useMutationTriggerAllSearchResultsDataExport } from '@/hooks/queries/export/use-mutation-trigger-all-search-results-data-export';
import { useMutationTriggerSelectedCompaniesDataExport } from '@/hooks/queries/export/use-mutation-trigger-selected-companies-data-export';
import { Link } from '@/Components/Shared/Link';

const ExportDialog = () => {
  const { isOpen, handleClose: handleModalClose } = useModal(MODALS.DATA_EXPORT);
  const triggerAllSearchResultsDataExport = useMutationTriggerAllSearchResultsDataExport();
  const triggerSelectedCompaniesDataExportMutation = useMutationTriggerSelectedCompaniesDataExport();
  const { shouldShowCaseCodePopup, handleCaseCodeModalOpen } = useCaseCodePopupConditionally();

  const { pageNumber } = useShallowSelector((state) => state.tableNotPersist);
  const { selectedCompanies } = useShallowSelector((state) => state.selectedCompanies);
  const { pinnedCompanyIds } = useShallowSelector((state) => state.search);

  const [exportOption, setExportOption] = useState<ExportOption>('all');

  const query = useQueryTable();
  const totalRows = query?.data?.pages[pageNumber]?.total_count ?? 0;

  const handleExportData = () => {
    if (exportOption === 'selected') {
      triggerSelectedCompaniesDataExportMutation.mutate({}, { onSettled: handleModalClose });

      return;
    }

    if (!isNil(pinnedCompanyIds)) {
      triggerSelectedCompaniesDataExportMutation.mutate(
        { companyProfileIds: pinnedCompanyIds },
        { onSettled: handleModalClose },
      );

      return;
    }

    triggerAllSearchResultsDataExport.mutate({ totalRows }, { onSettled: handleModalClose });
  };

  const onExportClick = () => {
    if (shouldShowCaseCodePopup) {
      handleCaseCodeModalOpen({ onSuccess: handleExportData });

      return;
    }

    handleExportData();
  };

  useEffect(() => {
    setExportOption(selectedCompanies.length ? 'selected' : 'all');
  }, [selectedCompanies.length, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleModalClose}
      id="dialog-box"
      classes={{ paper: 'mt-[250px] self-start min-w-[650px]' }}
    >
      <div style={{ backgroundColor: 'white', position: 'absolute', right: '5px', padding: '10px' }}>
        <Close
          id="close-icon"
          onClick={handleModalClose}
          sx={{ cursor: 'pointer', color: 'black' }}
        />
      </div>
      <DialogTitle
        sx={{
          backgroundColor: 'white',
          color: 'black',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          paddingBottom: 0,
        }}
      >
        <Typography className="pb-2">
          <b>Export Results</b>
        </Typography>

        <Typography
          variant="body1"
          gutterBottom
        >
          Please select an export option:
        </Typography>
      </DialogTitle>
      <DialogActions
        sx={{ width: '500px', backgroundColor: 'white', color: 'black', padding: '15px' }}
        id="dialog-actions"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <RadioGroup
              aria-label="export"
              name="export"
              value={exportOption}
              onChange={(_: React.ChangeEvent<HTMLInputElement>, value) => setExportOption(value as ExportOption)}
              style={{
                position: 'relative',
              }}
              id="radio-group"
            >
              <FormControlLabel
                value="all"
                control={
                  <Radio
                    id="export-all-radio"
                    color="info"
                  />
                }
                label="Export summary data for all search results (excludes time-series data on financials, news, M&A, others...)"
                id="export-all"
              />
              <FormControlLabel
                value="selected"
                control={
                  <Radio
                    id="export-selected-radio"
                    color="info"
                  />
                }
                disabled={isEmpty(selectedCompanies)}
                label="Export all data for only selected companies"
                id="export-selected"
              />
            </RadioGroup>
          </Box>

          {totalRows > MAX_COMPANIES_EXPORT && exportOption === 'all' ? (
            <Alert
              severity="warning"
              className="mb-3 mt-3 px-3 py-0.5 whitespace-pre-line bg-[#FEF7EA]"
            >
              {`You can only export the first ${MAX_COMPANIES_EXPORT.toLocaleString('en')} companies.
Refine your search or if you need to exceed this limit contact the `}

              <Link href="mailto:helixfind@bain.com">Helix Find Team</Link>
            </Alert>
          ) : null}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <ExportButton
              id="export-button-1"
              onClick={onExportClick}
              isLoading={
                triggerAllSearchResultsDataExport.isLoading || triggerSelectedCompaniesDataExportMutation.isLoading
              }
            />
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
