import React from 'react';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ShareSavedSearchButton } from '../ShareSavedSearchButton';
import { SavedSearchMenu } from '../SavedSearchMenu';
import { SaveSearchButton } from '../SaveSearchButton';
import { useMenu } from '@/hooks/use-menu';
import { useQuerySavedSearches } from '@/hooks/queries/saved-search/use-query-saved-searches';
import { ISavedSearch } from '@/types';

interface SavedSearchControlsProps {
  savedSearch?: ISavedSearch;
  isLoading: boolean;
  hasSearchChanged: boolean;
}

export const SavedSearchControls = ({ isLoading, savedSearch, hasSearchChanged }: SavedSearchControlsProps) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const { data: savedSearches } = useQuerySavedSearches();
  const isSavedSearchSharedWithUser = savedSearches?.every((search) => search.list_id !== savedSearch?.list_id);

  return (
    <div className="flex items-center gap-5">
      {isLoading ? (
        <Skeleton
          width={200}
          height={30}
          className="bg-[#0000000f]"
        />
      ) : (
        <>
          <Button
            className="text-2xl text-[#666666] py-0 px-1"
            endIcon={<ArrowDropDownIcon fontSize="small" />}
            onClick={handleClick}
          >
            {savedSearch?.title}
          </Button>
          <SaveSearchButton
            disabled={!hasSearchChanged}
            showTooltip={hasSearchChanged}
          />
          <ShareSavedSearchButton
            savedSearchId={savedSearch?.list_id}
            savedSearchTitle={savedSearch?.title}
            shared={savedSearch?.is_sharable_link}
            disabled={hasSearchChanged}
          />
        </>
      )}

      <SavedSearchMenu
        savedSearch={savedSearch}
        isOpen={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        sharedWithUser={isSavedSearchSharedWithUser}
        removeWithRedirect
        hideDelete={isSavedSearchSharedWithUser}
      />
    </div>
  );
};
