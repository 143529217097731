import React from 'react';

import { CardMenu } from '../../Card/CardMenu';
import { useSavedSearchMenuItems } from './use-saved-search-menu-items';
import { ISavedSearch } from '@/types';

interface IProps {
  savedSearch?: ISavedSearch;
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  sharedWithUser?: boolean;
  removeWithRedirect?: boolean;
  hideDelete?: boolean;
}

export const SavedSearchMenu = ({
  savedSearch,
  isOpen,
  anchorEl,
  handleClose,
  sharedWithUser = false,
  removeWithRedirect = false,
  hideDelete = false,
}: IProps) => {
  const menuItems = useSavedSearchMenuItems(savedSearch, sharedWithUser, removeWithRedirect, hideDelete);

  return (
    <CardMenu
      menuItems={menuItems}
      isOpen={isOpen}
      anchorEl={anchorEl}
      handleClose={handleClose}
    />
  );
};
