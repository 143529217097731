import { post } from 'aws-amplify/api';

export const postSmartSearch = ({ text }: { text: string }): Promise<{ suggested_query: string }> =>
  // @ts-ignore
  post({
    apiName: 'CDPAPI',
    path: '/smart_search',
    options: {
      body: { user_input: text },
    },
  }).response.then(res => res.body.json());
