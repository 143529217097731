import React, { useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, ChartOptions, Plugin } from 'chart.js';
import _ from 'lodash';
import 'chart.js/auto';

import { DownloadChartImageButton } from '../common/DownloadChartImageButton';
import { DownloadCsvButton } from '../../Buttons/DownloadCsvButton';
import { numberFormatterCustom } from '@/Components/Shared/common';
import { ChartConfigType, ChartDataType, DatumDatum } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getYaxisLabel } from '@/Utils/charts';

interface IProps {
  config: ChartConfigType;
  data: ChartDataType[];
  params?: { row: DatumDatum };
  section: string;
  handleDownloadXlsx: () => void;
  handleDownloadImage: (ref: React.RefObject<Chart>) => void;
}

const getOptions = (config: ChartConfigType, currency: string): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
    tooltip: {
      enabled: true,
      itemSort: (a, b) => Number(b.raw) - Number(a.raw),
      filter: (data) => !_.isNil(data.raw),
    },
    // @ts-ignore
    customCanvasBackgroundColor: {
      color: 'white',
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: true,
        color: '#ddd',
      },
      border: {
        dash: [2, 2],
      },
      ...(config.YAXIS_FORMAT === 'percent' && {
        max: 100,
      }),
      beginAtZero: true,
      ticks: {
        callback: function (value) {
          return `${currency}${numberFormatterCustom(Number(value))}`;
        },
      },
    },
  },
});

const plugin: Plugin<'bar'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, __, opts) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = opts.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const getDatasets = (labels: (string | number)[], data: ChartDataType[], config: ChartConfigType) => {
  const aggregateBy = config.METADATA?.aggregate_by ?? 'COUNTRY';

  const datasets = _.map(_.groupBy(data, aggregateBy), (items, country) => {
    const dataPoints = labels.map((date) => {
      const item = items.find((it) => it[config.XAXIS] === date);

      return item ? item[config.YAXIS] : null;
    });

    return {
      label: country,
      data: dataPoints,
    };
  });

  return datasets;
};

export const StackedBarChart = ({ config, data, handleDownloadXlsx, handleDownloadImage }: IProps) => {
  const ref = useRef<Chart<'bar'>>(null);
  const currency = useShallowSelector((state) => state.config.currency);
  const yAxisLabel = getYaxisLabel({ yAxisType: config.YAXIS_FORMAT, currency });
  const labels = useMemo(() => _.uniq(data.map((item) => item[config.XAXIS])), [config.XAXIS, data]);
  const datasets = useMemo(() => getDatasets(labels, data, config), [config, data, labels]);
  const options = useMemo(() => getOptions(config, yAxisLabel), [config, yAxisLabel]);
  const chartData = useMemo(() => ({ labels, datasets }), [labels, datasets]);

  return (
    <div
      className="bg-white rounded"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      <div className="flex items-center justify-between px-6 py-3">
        <span className="text-[#484848] text-sm font-semibold">{config.TITLE}</span>

        <div className="flex items-center gap-4">
          <DownloadChartImageButton
            onClick={() => handleDownloadImage(ref)}
            dataTestId="chart-download-image"
            id={`${config.SLUG}-chart-download-image`}
          />

          <DownloadCsvButton
            onClick={handleDownloadXlsx}
            dataTestId="chart-download-xlsx"
            id={`${config.SLUG}-chart-download-xlsx`}
          />
        </div>
      </div>

      <hr className="text-[#ddd]" />

      <div className="h-[400px] px-6 pb-4">
        <Bar
          // @ts-ignore
          ref={ref}
          data={chartData}
          options={options}
          plugins={[plugin]}
        />
      </div>
    </div>
  );
};
