import React, { useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import classnames from 'classnames';
import { Skeleton } from '@mui/material';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions as tableActions } from '@/slices/table';
import { actions as tableNotPersistActions } from '@/slices/table/table-not-persist';
import { actions as searchActions } from '@/slices/search';
import { Chevron } from '@/Components/Icons/Chevron';
import { useQueryTable } from '@/hooks/queries/table/use-query-table';
import { useTableLoading } from '@/hooks/table/use-table-loading';

export const Pagination = () => {
  const query = useQueryTable();
  const isLoading = useTableLoading();
  const { pageSize } = useShallowSelector((state) => state.table);
  const { pageNumber } = useShallowSelector((state) => state.tableNotPersist);
  const dispatch = useDispatch();

  const totalRows = query?.data?.pages[pageNumber]?.total_count ?? 0;

  const goToPreviousPageHandler = () => {
    dispatch(tableNotPersistActions.setPageNumber(pageNumber - 1));

    query.fetchPreviousPage();
  };

  const goToNextPageHandler = () => {
    dispatch(tableNotPersistActions.setPageNumber(pageNumber + 1));

    query.fetchNextPage();
  };

  const showPrevIcon = pageNumber > 0;

  const showNextIcon = useMemo(() => {
    const availablePages = Math.ceil(totalRows / pageSize);

    if (availablePages <= 1) return false;

    return pageNumber < availablePages - 1;
  }, [pageNumber, pageSize, totalRows]);

  if (isLoading)
    return (
      <Skeleton
        width={200}
        height={40}
        className="ml-3 bg-[#0000000f]"
      />
    );

  return (
    <div className="flex items-center gap-x-3">
      <FormControl
        variant="standard"
        className="flex items-center, mx-2 width-5"
      >
        <div className="flex items-center gap-x-2.5">
          <span className="text-xs color-[#666666]">Show:</span>
          <Select
            labelId="pageSize"
            value={pageSize}
            onChange={(e) => {
              dispatch(tableNotPersistActions.setPageNumber(0));
              dispatch(tableActions.setPageSize(Number(e.target.value)));
              dispatch(searchActions.setIsElasticEnabled(true));
            }}
          >
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>
        </div>
      </FormControl>
      <div className="flex items-center gap-x-2.5">
        <IconButton
          size="large"
          className={classnames({
            invisible: !showPrevIcon,
          })}
          onClick={goToPreviousPageHandler}
        >
          <Chevron className="rotate-180" />
        </IconButton>
        <span>{pageNumber + 1}</span>
        <IconButton
          size="large"
          onClick={goToNextPageHandler}
          className={classnames({
            invisible: !showNextIcon,
          })}
        >
          <Chevron className="" />
        </IconButton>
      </div>
    </div>
  );
};
