import { useQuery } from '@tanstack/react-query';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { fetchChartData } from '@/services/api/charts';

export const useQueryChartData = (chartId: number, bainId: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.config.currency);

  return useQuery(['chart-data', chartId, bainId, currency], () => fetchChartData({ chartId, bainId, currency }), {
    enabled: isAuth,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: 1,
  });
};
