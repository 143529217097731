import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Download } from '@mui/icons-material';
import classNames from 'classnames';
import { Loader } from '../Loader/Loader';

type Props = {
  id: string;
  companiesSelected?: boolean;
  isLoading?: boolean;
} & ButtonProps;

export const ExportButton = ({ id = 'export-button', companiesSelected, isLoading, ...rest }: Props) => (
  <Button
    id={id}
    className={classNames(
      'ml-[1px] py-2 px-2 pr-3 border border-solid font-medium font-graphik text-white text-xs min-h-[38px]',
      {
        'border-cred-900 bg-cred-900': !companiesSelected,
        'bg-bluegray-900 text-white border-bluegray-900 rounded-l-none': companiesSelected,
        'opacity-50': isLoading,
      },
    )}
    disabled={isLoading}
    {...rest}
  >
    <Download className="text-[16px] mr-2 mt-0.5" />
    Export
    {isLoading ? (
      <span className="ml-2">
        <Loader />
      </span>
    ) : null}
  </Button>
);
