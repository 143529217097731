import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { Tooltip } from '@mui/material';
import { FilterDrawerAccordion } from '../FilterHeader/FilterDrawerAccordion';

import { SavedQueries } from '@/Components/CompanyResearch/SavedQueries';
import SearchBar from '@/Components/CompanyResearch/Search/SearchBar';
import { useAppliedKeywordsData } from '@/Components/CompanyResearch/SavedQueries/use-applied-keywords-data';

const DISPLAY_HEADER = 'Keywords';

interface KeywordsAccordionProps {
  isSemanticSearch?: boolean;
}

export const KeywordsAccordion = ({ isSemanticSearch }: KeywordsAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { numberOfKeywords, formattedAppliedKeywords, handleRemoveAllKeywords } = useAppliedKeywordsData();

  if (!isSemanticSearch) return null;

  return (
    <div data-testid="keywords-accordion-item">
      <FilterDrawerAccordion
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        displayHeader={DISPLAY_HEADER}
        accordionSummaryContent={
          <Tooltip title={formattedAppliedKeywords}>
            <span>
              {DISPLAY_HEADER}
              {numberOfKeywords ? (
                <span className="bg-[#EBF9F2] text-[#484848] rounded border-[#7EC8A3] border-[1px] text-xs px-1 ml-4 font-medium inline-flex items-center h-full">
                  {`${numberOfKeywords} active`}{' '}
                  <CloseIcon
                    onClick={handleRemoveAllKeywords}
                    className="fill-[#666] text-[18px] ml-1"
                  />
                </span>
              ) : null}
            </span>
          </Tooltip>
        }
      >
        <div className="mt-6">
          <SearchBar
            isSemanticSearch
            isHistoryOpened={false}
          />
        </div>
        <div className="flex flex-wrap max-h-[230px] overflow-y-auto my-3">
          <SavedQueries />
        </div>
      </FilterDrawerAccordion>
    </div>
  );
};
