import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  title: string;
  description?: string;
  href?: { to: string; name: string };
  content?: ReactElement;
  actions?: { label: ReactNode; onClick: () => void }[];
}

export const NotificationMessage = ({ title, description, content, href, actions }: IProps) => {
  return (
    <div className="flex flex-col px-2 gap-2">
      {title ? <p className="font-medium">{title}</p> : null}

      {description ? <p style={{ wordWrap: 'break-word' }}>{description}</p> : null}

      {content ?? null}

      {href && (
        <Link to={href.to}>
          <span className="border-b text-xs">{href.name}</span>
        </Link>
      )}
      {actions ? (
        <div>
          {actions.map(({ label, onClick }, idx) => (
            <button
              key={idx}
              onClick={onClick}
              className="mr-4"
            >
              {label}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};
