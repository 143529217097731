import React, { useMemo } from 'react';
import { SkeletonLoader } from '../../common/SkeletonLoader';
import MnATable from './MnATable';
import MnABuyerSummary from './MnABuyerSummary';
import { IMergesAndAcquisitions } from '@/types';
import { useQueryCompanyMergesAndAcquisitions } from '@/hooks/queries/company-profile/use-query-company-m-a';

export interface MnATabProps {
  bainId: string;
  isCompanyProfileLoading: boolean;
}

const MnATab = ({ isCompanyProfileLoading, bainId }: MnATabProps) => {
  const mna = useQueryCompanyMergesAndAcquisitions(bainId, true);
  const maArray = mna.data && Object.entries(mna.data);

  const dataSets = useMemo(() => {
    return (maArray?.flat().filter((data) => typeof data !== 'string') as IMergesAndAcquisitions[])?.reduce(
      (accumulator, currentValue) => {
        return { ...accumulator, ...currentValue };
      },
      {},
    );
  }, [maArray]);

  if (mna.isLoading || isCompanyProfileLoading) return <SkeletonLoader />;

  if (!maArray || maArray.length === 0) return <div>No merges and acquisitions data available</div>;

  const buyerSummary = dataSets.buyer_summary?.[0];
  const targetTransactions = dataSets.target_transactions;
  const buyerTransactions = dataSets.buyer_transactions;

  return (
    <div>
      {buyerSummary ? <MnABuyerSummary data={buyerSummary} /> : null}
      {targetTransactions ? (
        <MnATable
          tableData={targetTransactions}
          title="Transactions as target"
        />
      ) : null}
      {buyerTransactions ? (
        <MnATable
          tableData={buyerTransactions}
          title="Transactions as buyer"
        />
      ) : null}
    </div>
  );
};

export default MnATab;
