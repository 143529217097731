import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';

import { Tab as TabType } from './types';
import CompanyProfileTabs from './tabs/CompanyProfileTabs';
import { CompanyProfileHeader } from './CompanyProfileHeader/CompanyProfileHeader';
import { ErrorPage } from '@/Components/Shared/ErrorPage';
import { ERROR_PAGE_PROPS, HEADER_HEIGHT } from '@/constants';
import { AddToListDropdown } from '@/Components/Shared/CompaniesList/SelectCompaniesControls/AddToListDropdown';
import { useMenu } from '@/hooks/use-menu';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { useQueryCompanyProfile } from '@/hooks/queries/company-profile/use-query-company-profile';

export interface CompanyProfileViewProps {
  bainId: string;
  currentTab: TabType;
}

const CompanyProfileView: React.FC<CompanyProfileViewProps> = ({ bainId, currentTab }) => {
  const { data: profile, isLoading: isCompanyProfileLoading } = useQueryCompanyProfile(bainId);
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const query = useQueryCompaniesLists();
  const companiesLists = query.data ?? [];
  const [showConfidenceScore, setShowConfidenceScore] = useState(false);

  const params = useMemo(() => {
    const visualizationTable = profile?.find((item: { table: string }) => item.table === 'visualization');
    const data = visualizationTable?.data?.[0];

    if (!data) return undefined;

    return {
      row: { ...data },
      value: data.self_firmo_name___,
    };
  }, [profile]);

  if (!isCompanyProfileLoading && !params?.value) return <ErrorPage {...ERROR_PAGE_PROPS.COMPANY_PROFILE_NOT_FOUND} />;

  return (
    <Box
      className="flex flex-col bg-[#FAFAFA]"
      style={{
        minHeight: `calc(100vh - ${HEADER_HEIGHT}px`,
      }}
    >
      <CompanyProfileHeader
        bainId={bainId}
        companiesLists={companiesLists}
        params={params}
        showConfidenceScore={showConfidenceScore}
        isCompanyProfileLoading={isCompanyProfileLoading}
        setShowConfidenceScore={setShowConfidenceScore}
        handleClick={handleClick}
      />

      <CompanyProfileTabs
        bainId={bainId}
        params={params}
        currentTab={currentTab}
        isCompanyProfileLoading={isCompanyProfileLoading}
        showConfidenceScore={showConfidenceScore}
      />

      <AddToListDropdown
        isOpen={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedCompanies={[bainId]}
        companiesLists={companiesLists}
        isCompanyProfile
      />
    </Box>
  );
};

export default CompanyProfileView;
