import React from 'react';

import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { Link } from '@/Components/Shared/Link';

interface CheckSingleQuotesNotificationProps {
  wrongTextParts: string[];
  standardizedText: string;
  onYes: () => void;
  onNo: () => void;
}

const CheckSingleQuotesNotification = ({
  wrongTextParts,
  standardizedText,
  onYes,
  onNo,
}: CheckSingleQuotesNotificationProps) => {
  return (
    <NotificationMessage
      title="Single quotations detected"
      description="Single quotations can produce unexpected results, unless it’s a name use double quotes."
      content={
        <div className="mt-3 flex flex-col gap-1 mb-2 max-h-[500px] overflow-y-auto">
          {wrongTextParts.map((part) => (
            <p
              key={part}
              className="font-medium"
            >
              {standardizedText.startsWith(part) ? '' : '...'}
              {part}
              {standardizedText.endsWith(part) ? '' : '...'}
            </p>
          ))}
          <p className="mt-5">Is this correct?</p>
        </div>
      }
      actions={[
        {
          label: <Link>Yes, keep as is</Link>,
          onClick: onYes,
        },
        {
          label: <Link>No, replace with double quotes</Link>,
          onClick: onNo,
        },
      ]}
    />
  );
};

export default CheckSingleQuotesNotification;
