import React from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useDispatch } from 'react-redux';
import { removeCompaniesList } from '@/services/api/companies-list';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { HOME_PAGE_SLUG } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/navigation-tabs';

export const useMutationRemoveCompaniesList = (removeWithRedirect: boolean) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompaniesList } = useShallowSelector((state) => state.navigationTabs);

  return useMutation({
    mutationKey: ['remove-companies-list'],
    mutationFn: (id: string) => removeCompaniesList(id),
    onSuccess: (response) => {
      if (currentCompaniesList?.list_id === response.list_id) {
        dispatch(actions.setCurrentCompaniesList(null));
      }

      queryClient.invalidateQueries({ queryKey: ['companies-lists'] });

      if (removeWithRedirect) {
        history.push(HOME_PAGE_SLUG);
      }

      enqueueSnackbar(<NotificationMessage title={`List ${response.title} has been removed`} />, {
        variant: 'success',
      });
    },

    onError: () =>
      enqueueSnackbar(
        <NotificationMessage title="Failed to remove companies list. Please try again or contact support." />,
        { variant: 'error' },
      ),
  });
};
