import { getCurrentUser, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';

class awsConfig {
  constructor() {
    this.isAuthenticated = false;
    this.user = undefined;

    this.getUser().then((userData) => this.setUser(userData));
  }

  // redirect here
  getUser() {
    return getCurrentUser()
      .then((userData) => userData)
      .catch(() => this.setAuthenticationStatus(false));
  }

  initializeAws = () => {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: process.env.REACT_APP_USER_POOL_ID,
          identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
          userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
          loginWith: {
            oauth: {
              providers: [{ custom: process.env.REACT_APP_FEDERATED_IDP }],
              domain: process.env.REACT_APP_DOMAIN,
              scopes: ['email openid'],
              redirectSignIn: [process.env.REACT_APP_REDIRECT_SIGN_IN],
              redirectSignOut: [process.env.REACT_APP_REDIRECT_SIGN_OUT],
              responseType: 'code',
            },
          },
        },
      },
      API: {
        REST: {
          CDPAPI: {
            endpoint: process.env.REACT_APP_API_URL,
            region: 'eu-west-1',
          },
        },
      },
    });
  };

  authenticate = async () => {
    const { user } = this;

    try {
      if (!user) {
        await signInWithRedirect({
          provider: {
            custom: process.env.REACT_APP_FEDERATED_IDP,
          },
        });
      }

      this.setUser(user);

      return user;
    } catch (error) {
      this.setAuthenticationStatus(false);

      return user;
    }
  };

  setUser = (user) => {
    this.user = user;
  };

  getAuthenticationStatus = () => {
    return this.isAuthenticated;
  };

  setAuthenticationStatus = (status) => {
    this.isAuthenticated = status;
  };

  signOut = async () => {
    try {
      await signOut();
      this.setAuthenticationStatus(false);
      this.setUser(undefined);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error signing out: ', error);
    }
  };
}

export default new awsConfig();
