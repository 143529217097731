import React from 'react';

import { CardMenu } from '../../Card/CardMenu';
import { useCompaniesListMenuItems } from './use-companies-list-menu-items';
import { ICompaniesList } from '@/types';

interface IProps {
  companiesList?: ICompaniesList;
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  sharedWithUser?: boolean;
  removeWithRedirect?: boolean;
  hideDelete?: boolean;
  readOnlyMode?: boolean;
}

export const CompaniesListMenu = ({
  companiesList,
  isOpen,
  anchorEl,
  handleClose,
  sharedWithUser = false,
  removeWithRedirect = false,
  hideDelete = false,
  readOnlyMode = false,
}: IProps) => {
  const menuItems = useCompaniesListMenuItems(
    companiesList,
    sharedWithUser,
    removeWithRedirect,
    hideDelete,
    readOnlyMode,
  );

  return (
    <CardMenu
      menuItems={menuItems}
      isOpen={isOpen}
      anchorEl={anchorEl}
      handleClose={handleClose}
    />
  );
};
