import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';

import { useCaseCodePopupConditionally } from '../CaseCodeDialog/use-show-case-code-popup-conditionally';
import { MultipleLabelsLoaderDialogContent } from '../Shared/MultipleLabelsLoaderDialogContent';
import { MODALS } from '@/constants';

import { useModal } from '@/hooks/use-modal';

const stepLabels = [
  'Refining your request...',
  'Enriching it with semantics from the LLM...',
  'Finally resolving the angles of similarity...',
];

export const WizardLoadingDialog = () => {
  const { isOpen, handleClose } = useModal(MODALS.WIZARD_LOADING_DIALOG);
  const [step, setStep] = useState(0);

  const { shouldShowCaseCodePopup, handleCaseCodeModalOpen } = useCaseCodePopupConditionally();

  const label = stepLabels[step];

  useEffect(() => {
    if (isOpen) return;

    setStep(0);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    const intervalId = setInterval(() => {
      if (step === stepLabels.length - 1) {
        handleClose();

        if (shouldShowCaseCodePopup) {
          handleCaseCodeModalOpen();
        }

        return;
      }

      setStep((prevState) => prevState + 1);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, isOpen, step]);

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: 'w-[350px] top-[20%] self-start' }}
    >
      <MultipleLabelsLoaderDialogContent label={isOpen ? label : ''} />
    </Dialog>
  );
};
