import React from 'react';

export const IconSimilarCompany = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#ddd"
      d="M28 2H16a2.002 2.002 0 0 0-2 2v10H4a2.002 2.002 0 0 0-2 2v14h28V4a2.002 2.002 0 0 0-2-2ZM9 28v-7h4v7H9Zm19 0H15v-8a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v8H4V16h12V4h12v24Z"
    />
    <path
      fill="#ddd"
      d="M18 8h2v2h-2V8Zm6 0h2v2h-2V8Zm-6 6h2v2h-2v-2Zm6 0h2v2h-2v-2Zm-6 6h2v2h-2v-2Zm6 0h2v2h-2v-2Z"
    />
  </svg>
);
