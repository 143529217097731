import React, { FC } from 'react';
import { Switch } from './Switch';

interface ConfidenceScoresSwitchProps {
  onToggle: () => void;
  isChecked: boolean;
  labelPlacement?: 'start' | 'end';
}

export const ConfidenceScoresSwitch: FC<ConfidenceScoresSwitchProps> = ({
  onToggle,
  isChecked,
  labelPlacement = 'start',
}) => {
  return (
    <Switch
      onToggle={onToggle}
      isChecked={isChecked}
      label="Confidence scores"
      dataTestId="column-visibility-toggle"
      labelPlacement={labelPlacement}
    />
  );
};
