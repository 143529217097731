import { http, HttpResponse } from 'msw';

import {
  COMPANY_PROFILE2_PIVOT_TABLE,
  COMPANY_PROFILE_CONFIG,
  COMPANY_PROFILE_MA,
  COMPANY_PROFILE_MULTIYEAR,
  COMPANY_PROFILE_PIVOT_TABLE,
  COMPANY_PROFILE_TABLE,
  PIVOT_TABLE_BAIN_ID,
} from '../utils';
import { MOCK_COMPANY_DATA } from './elastic-controller';

export const PIVOT_TABLE_DATASET = 'pivot_dataset';

export const PIVOT_TABLE_BAIN_ID_STRING = String(PIVOT_TABLE_BAIN_ID);

const companyProfileController = [
  http.post(`${process.env.REACT_APP_API_URL}/company_profile`, () => {
    return HttpResponse.json({
      data: [
        {
          table: 'visualization',
          data: [MOCK_COMPANY_DATA],
        },
      ],
    });
  }),
];

const companyProfileConfigController = [
  http.get(`${process.env.REACT_APP_API_URL}/config/company_profile`, () => {
    return HttpResponse.json(COMPANY_PROFILE_CONFIG);
  }),
];

const datasetsController = [
  http.get(`${process.env.REACT_APP_API_URL}/company_profile2/:id/_datasets`, () => {
    return HttpResponse.json({
      datasets: ['funding', 'master_time_series', 'company_search'],
    });
  }),
];

const companyProfileDatasetsController = [
  http.get(`${process.env.REACT_APP_API_URL}/company_profile2/:id/:dataset`, ({ params: { id, dataset } }) => {
    if (id === PIVOT_TABLE_BAIN_ID_STRING && dataset === PIVOT_TABLE_DATASET) {
      return HttpResponse.json(COMPANY_PROFILE2_PIVOT_TABLE);
    }

    return HttpResponse.json({
      data: [
        {
          bain_id: 23617408,
          DOMAIN: 'stock.walmart.com',
          COUNTRY: 'Georgia',
          TRAFFIC_SHARE: 11.1,
          TOTAL_VISITS: 19912.76,
          YEAR_MONTH: '2023-12',
        },
        {
          bain_id: 23617408,
          DOMAIN: 'stock.walmart.com',
          COUNTRY: 'Bulgaria',
          TRAFFIC_SHARE: 0.97,
          TOTAL_VISITS: 1743.61,
          YEAR_MONTH: '2023-12',
        },
      ],
    });
  }),
];

const companyProfileColumnMapper = [
  http.get(`${process.env.REACT_APP_API_URL}/column_mapper/:dataset`, ({ params: { dataset } }) => {
    if (dataset === PIVOT_TABLE_DATASET) {
      return HttpResponse.json(COMPANY_PROFILE_PIVOT_TABLE);
    }

    return HttpResponse.json(COMPANY_PROFILE_TABLE);
  }),
];

const companyProfileMnA = [
  http.get(`${process.env.REACT_APP_API_URL}/company_profile/ma/:bainId`, () => {
    return HttpResponse.json(COMPANY_PROFILE_MA);
  }),
];

const companyProfileMultiyearFinancials = [
  http.get(`${process.env.REACT_APP_API_URL}/company_profile/multiyear/:id`, () => {
    return HttpResponse.json(COMPANY_PROFILE_MULTIYEAR);
  }),
];

export default [
  ...companyProfileController,
  ...companyProfileConfigController,
  ...datasetsController,
  ...companyProfileDatasetsController,
  ...companyProfileColumnMapper,
  ...companyProfileMnA,
  ...companyProfileMultiyearFinancials,
];
