import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const DEFAULT_CARDS_PER_PAGE = 3;

export const LIST_SECTION_TYPE = {
  myWork: 'myWork',
} as const;

type Keys = keyof typeof LIST_SECTION_TYPE;
export type ListSectionType = typeof LIST_SECTION_TYPE[Keys];

export const LIST_TYPE = {
  userSearch: 'userSearch',
  sharedWithUserSearch: 'sharedWithUserSearch',
  userList: 'userList',
  sharedWithUserList: 'sharedWithUserList',
} as const;

type ListTypeKeys = keyof typeof LIST_TYPE;
export type ListType = typeof LIST_TYPE[ListTypeKeys];

export interface SearchListsTableSettings {
  cardsPerPage: number;
}

type SearchListsTablesInitialState = Record<Keys, SearchListsTableSettings>;

const initialState: SearchListsTablesInitialState = {
  myWork: {
    cardsPerPage: DEFAULT_CARDS_PER_PAGE,
  },
};

export const { reducer, actions } = createSlice({
  name: 'searchListsTables',
  initialState,
  reducers: {
    setCardsPerPage: (state, { payload }: PayloadAction<{ table: Keys; value: number }>) => {
      state[payload.table].cardsPerPage = payload.value;
    },
  },
});

export type TState = typeof initialState;
