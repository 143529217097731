import { useQueries } from '@tanstack/react-query';
import { isNil } from 'lodash';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { fetchChartData } from '@/services/api/charts';
import { ChartConfigType } from '@/types';
import { CHART_SECTIONS } from '@/constants';

export const useQueriesChartData = (
  configs: ChartConfigType[],
  bainId: string,
  section: string,
) => {
  const currency = useShallowSelector((state) => state.config.currency);
  const financialDataSource = useShallowSelector((state) => state.companyProfile.financialDataSource);
  const dataSource = section === CHART_SECTIONS.FINANCIALS ? financialDataSource : null;
  const isEnabled = section === CHART_SECTIONS.FINANCIALS ? !isNil(dataSource) : true;

  return useQueries({
    queries: configs.map(config => ({
      enabled: isEnabled,
      queryKey: ['chart-data', config.ID, bainId, currency, dataSource],
      queryFn: () => fetchChartData({ chartId: config.ID, bainId, currency, dataSource }),
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 1,
    })),
  });
};
