/**************************************************************************
 *
 *     Copyright Bain & Company. 2020, 2021
 *
 **************************************************************************/
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { isNil } from 'lodash';
import { Currency } from '@/types';

export function getCurrencyFormat(value: number, currency: Currency, numericPrecision?: number) {
  if (currency) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: numericPrecision,
      maximumFractionDigits: numericPrecision,
    }).format(value);
  }

  return value;
}

export const numberFormatterCustom = (num: number | string, numericPrecision = 1) =>
  new Intl.NumberFormat('en-GB', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: numericPrecision,
  }).format(Number(num));

export function dataNotAvailable(hideTooltip?: boolean) {
  const content = (
    <div
      className="w-full h-full flex items-center"
      data-testid="dataNotAvailable"
      id="data-not-available"
    >
      <div
        style={{
          borderRadius: '6px',
          backgroundColor: '#d3d3d3',
          padding: 0,
          height: '6px',
          width: '20px',
        }}
      ></div>
    </div>
  );

  if (hideTooltip) {
    return content;
  } else {
    return <Tooltip title={'Data not available'}>{content}</Tooltip>;
  }
}

export function dataNotAvailablePage() {
  return (
    <Tooltip
      title={'Data not available'}
      data-testid="dataNotAvailablePage"
      id="data-not-available-page"
    >
      <span style={{ position: 'relative' }}>
        <span
          style={{
            borderRadius: '6px',
            backgroundColor: '#d3d3d3',
            padding: 0,
            height: '6px',
            width: '20px',
            position: 'absolute',
            top: '8px',
            left: '5px',
          }}
        ></span>
      </span>
    </Tooltip>
  );
}

export function financialRevenueValue(
  revenue: number,
  minRevenue: number,
  maxRevenue: number,
  currency: Currency = 'EUR',
  numericPrecision: number | undefined = undefined,
) {
  const ret = !isNil(revenue) ? `${getCurrencyFormat(revenue, currency, numericPrecision)}` : '';
  const min = getCurrencyFormat(minRevenue, currency, numericPrecision);
  const max = getCurrencyFormat(maxRevenue, currency, numericPrecision);

  return min && max && min !== '0' && max !== '0' && min !== max ? `~${ret} (${min}-${max})` : ret;
}

export function currencySign(currency: Currency) {
  switch (currency) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    default:
      return '';
  }
}
