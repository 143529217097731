import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { isNil } from 'lodash';
import { Loader } from '../../Loader/Loader';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions as selectedCompaniesActions } from '@/slices/selected-companies';
import { useQueryTable } from '@/hooks/queries/table/use-query-table';
import { useMenu } from '@/hooks/use-menu';
import { useQueryAllBainIds } from '@/hooks/queries/bain-ids/use-query-all-bain-ids';
import { IElasticAllBainIdsResponse } from '@/types';
import { MAX_COMPANIES_ON_THE_LIST } from '@/constants';
import { useTableLoading } from '@/hooks/table/use-table-loading';

export const CheckboxHeader = () => {
  const dispatch = useDispatch();
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const isTableLoading = useTableLoading();

  const [enableQueryAllBainIds, setEnableQueryAllBainIds] = useState(false);

  const onSuccess = (data: IElasticAllBainIdsResponse) => {
    dispatch(selectedCompaniesActions.setSelectedCompanies(data.bain_id.map(String)));
    handleClose();
  };

  const {
    data: allBainIds,
    isFetching,
    isPreviousData,
  } = useQueryAllBainIds({
    enabled: enableQueryAllBainIds,
    setEnable: setEnableQueryAllBainIds,
    onSuccess,
  });

  const query = useQueryTable();
  const { selectedCompanies } = useShallowSelector((state) => state.selectedCompanies);
  const { pinnedCompanyIds } = useShallowSelector((state) => state.search);
  const { pageSize } = useShallowSelector((state) => state.table);
  const { pageNumber } = useShallowSelector((state) => state.tableNotPersist);

  const totalRows = query?.data?.pages[pageNumber]?.total_count ?? 0;
  const pageData = query?.data?.pages[pageNumber]?.data;
  const isCompaniesList = !isNil(pinnedCompanyIds);
  const isAllowedToCheck = isCompaniesList ? pinnedCompanyIds.length > 0 : totalRows > 0;

  const isHalfChecked = useMemo(() => {
    if (selectedCompanies.length > 0 && selectedCompanies.length < totalRows) {
      return true;
    }
  }, [selectedCompanies.length, totalRows]);

  const isChecked = useMemo(
    () => selectedCompanies.length > 0 && selectedCompanies.length === totalRows,
    [selectedCompanies.length, totalRows],
  );

  const thisPageRecordsToSelect = totalRows < pageSize ? totalRows : pageSize;
  const allRecordsToSelectLabel =
    totalRows < MAX_COMPANIES_ON_THE_LIST
      ? `Select all companies (${totalRows.toLocaleString('en')})`
      : `Select all companies (first ${MAX_COMPANIES_ON_THE_LIST.toLocaleString('en')})`;

  const options = [
    {
      label: `Select all companies on this page (${thisPageRecordsToSelect})`,
      onClick: () => {
        if (!pageData) return;

        dispatch(selectedCompaniesActions.setSelectedCompanies(pageData.map(({ bain_id }) => String(bain_id))));
        handleClose();
      },
    },
    {
      label: allRecordsToSelectLabel,
      onClick: () => {
        if (isCompaniesList) {
          dispatch(selectedCompaniesActions.setSelectedCompanies(pinnedCompanyIds.map(String)));
          handleClose();

          return;
        }

        if (allBainIds && !isPreviousData) {
          dispatch(selectedCompaniesActions.setSelectedCompanies(allBainIds.bain_id.map(String)));
          handleClose();

          return;
        }

        setEnableQueryAllBainIds(true);
      },
      isLoading: isFetching,
    },
  ];

  const handleCheckboxClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!isAllowedToCheck) return;

    if (isHalfChecked || isChecked) {
      dispatch(selectedCompaniesActions.setSelectedCompanies([]));

      return;
    }

    if (totalRows < pageSize) {
      if (!pageData) return;

      dispatch(selectedCompaniesActions.setSelectedCompanies(pageData.map(({ bain_id }) => String(bain_id))));

      return;
    }

    handleClick(event);
  };

  return (
    <>
      <Checkbox
        checked={isChecked}
        indeterminate={isHalfChecked}
        disableRipple
        disableFocusRipple
        classes={{
          ...((!isChecked && !isHalfChecked) && {
            root: 'text-[#ddd]',
          }),
          indeterminate: 'text-[#0288d1]',
          checked: 'text-[#0288d1]',
        }}
        onClick={handleCheckboxClick}
        disabled={isTableLoading}
        data-testid="select-checkbox-header"
      />
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={option.onClick}
            sx={{ width: '280px' }}
          >
            <div className="flex gap-2">
              {option.label}
              {option.isLoading ? <Loader /> : null}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
