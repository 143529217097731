import { http, HttpResponse } from 'msw';
import { isEqual } from 'lodash';

export const FIRST_COMPANY_BAIN_ID = '1';
export const SECOND_COMPANY_BAIN_ID = '2';

const correctRequestBodyOverviewExport = {
  target_id: FIRST_COMPANY_BAIN_ID,
  competitor_ids: [FIRST_COMPANY_BAIN_ID],
  currency: 'USD',
  email: 'test@test.com',
  case_data: { case_code: 'test' },
};

const correctRequestBodyComparisonExport = {
  ...correctRequestBodyOverviewExport,
  competitor_ids: [FIRST_COMPANY_BAIN_ID, SECOND_COMPANY_BAIN_ID],
};

export const companyOverviewExportController = [
  http.post(`${process.env.REACT_APP_API_URL}/company_overview_slides`, async ({ request }) => {
    const requestBody = await request.json();

    if (
      isEqual(correctRequestBodyOverviewExport, requestBody) ||
      isEqual(correctRequestBodyComparisonExport, requestBody)
    ) {
      return HttpResponse.json({ success: true });
    }

    return HttpResponse.json({ message: 'error' }, { status: 500 });
  }),
];
