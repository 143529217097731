import { useState, useMemo, useEffect, useRef } from 'react';
import { uniqBy } from 'lodash';
import { SelectChangeEvent } from '@mui/material/Select';
import { IElasticTableData } from '@/types';

export const getDataSource = (row: IElasticTableData) => row?.DATA_SOURCE ?? row?.data_source;

export const useGenericTableDataSource = (tableRows: IElasticTableData[]) => {
  const [currentDataSource, setCurrentDataSource] = useState<string | null>(null);
  const hasInitialValue = useRef(false);

  const dataSourcesAvailable = useMemo(
    () => (tableRows ? uniqBy(tableRows, (row) => getDataSource(row)).map((row) => getDataSource(row)) : []),
    [tableRows],
  );

  // set initial value for data source
  useEffect(() => {
    const preferredDataSource = 'Crunchbase';

    if (hasInitialValue.current || !dataSourcesAvailable.length) return;

    hasInitialValue.current = true;

    if (dataSourcesAvailable.includes(preferredDataSource)) {
      setCurrentDataSource(preferredDataSource);

      return;
    }

    setCurrentDataSource(dataSourcesAvailable.length ? (dataSourcesAvailable[0] as string) : null);
  }, [dataSourcesAvailable]);

  const handleDataSourceChange = (event: SelectChangeEvent<string | null>) => {
    setCurrentDataSource(event.target.value);
  };

  return {
    currentDataSource,
    dataSourcesAvailable: dataSourcesAvailable as string[],
    handleDataSourceChange,
  };
};
