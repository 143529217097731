import React, { useCallback, useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { utils, writeFile } from 'xlsx';
import { isEmpty } from 'lodash';
import { Chart } from 'chart.js';
import 'chart.js/auto';

import { useQueryChartData } from '@/hooks/queries';
import { BarChart, LineChart } from '@/Components/Shared/Charts';
import { ChartConfigType } from '@/types';

export interface IProps {
  config: ChartConfigType;
  bainId: string;
  section: string;
}

export const ChartTab = ({ config, bainId, section }: IProps) => {
  const query = useQueryChartData(config.ID, bainId);
  const chartData = useMemo(() => query.data ?? [], [query.data]);

  const handleDownloadXlsx = useCallback(() => {
    const ws = utils.json_to_sheet(chartData);
    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws, 'Data');
    writeFile(wb, `${config.TITLE}-data.xlsx`);
  }, [chartData, config.TITLE]);

  const handleDownloadImage = useCallback((ref: React.RefObject<Chart>) => {
    if (ref.current) {
      const link = document.createElement('a');

      link.href = ref.current.toBase64Image('image/png', 1);
      link.download = `${config.TITLE}-chart.png`;
      link.click();
    }
  }, [config.TITLE]);

  if (isEmpty(chartData) && !query.isLoading) {
    return <p>This chart is currently unavailable due to insufficient data.</p>
  }

  return query.isLoading ? (
    <Skeleton
      height={400}
      variant="rounded"
      className="bg-[#0000000f]"
      data-testid="skeleton"
    />
  ) : (
    <div
      className="bg-white rounded"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      {config.CHART_TYPE === 'LINE' && (
        <LineChart
          config={config}
          data={chartData}
          section={section}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
        />
      )}

      {config.CHART_TYPE === 'BAR' && (
        <BarChart
          config={config}
          data={chartData}
          section={section}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
        />
      )}
    </div>
  );
};
