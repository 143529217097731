import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { isNil } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { actions as searchActions, QueryType } from '../slices/search';
import { Filters, actions as filterActions } from '../slices/filters';
import { actions as selectedCompaniesActions } from '../slices/selected-companies';
import { useSearchHistory } from './use-save-search-query';
import { useShallowSelector } from './use-shallow-selector';
import { useModal } from './use-modal';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { normalizeQueryString, sanitizeQueryString, guessUserFriendlyLuceneError } from '@/Utils/pegjs/astTreeUtils';
import { DEFAULT_ERROR_MESSAGE, MODALS, SEARCH_EVENTS } from '@/constants';
import { postSmartSearch } from '@/services/api/smart-search';
import { useCaseCodePopupConditionally } from '@/Components/Dialogs/CaseCodeDialog/use-show-case-code-popup-conditionally';

export interface SearchParams {
  searchText: string;
  description?: string;
  isUniq?: boolean;
  isLucene?: boolean;
  filters?: Filters;
  queryType: QueryType;
  eventName?: string;
}

const mergeQueries = (prevQuery: string, nextQuery: string) =>
  !prevQuery ? nextQuery : `${prevQuery} AND ${nextQuery}`;

export const useSearchCompanies = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { saveQuery } = useSearchHistory();
  const { isOpen: isAdvancedSearchModalOpen, handleClose: handleAdvancedSearchModalClose } = useModal(
    MODALS.ADVANCED_SEARCH,
  );
  const {
    searchQuery,
    searchText: searchTextValue,
    searchQueryTree,
    queryType,
    hasLuceneGrammarError,
    isUniqueCompanySearch,
  } = useShallowSelector((state) => state.search);

  const { shouldShowCaseCodePopup, handleCaseCodeModalOpen } = useCaseCodePopupConditionally();

  const isKeywordQuery = queryType === QueryType.KEYWORD;
  const isSmartQuery = queryType === QueryType.SMART;

  useEffect(() => {
    if (hasLuceneGrammarError) {
      dispatch(searchActions.setHasLuceneGrammarError(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextValue]);

  const elasticSearch = useCallback(
    (searchValue: string, concatPrevQuery: boolean, skipInputClear?: boolean) => {
      const noSearchValueSearchText = !searchValue ? searchQuery : mergeQueries(searchQuery, searchValue);

      const searchText = !(concatPrevQuery && !!searchQueryTree) ? searchValue : noSearchValueSearchText;

      try {
        const normalizedQuery = normalizeQueryString(sanitizeQueryString(searchText));

        if (isKeywordQuery && !skipInputClear) {
          dispatch(searchActions.setSearchText(''));
          dispatch(searchActions.setAdvancedSearchText(''));
        }

        if (isAdvancedSearchModalOpen) {
          handleAdvancedSearchModalClose();
        }

        dispatch(searchActions.setSearchQuery(normalizedQuery));

        dispatch(searchActions.setIsElasticEnabled(true));

        if (normalizedQuery) {
          saveQuery({
            searchText: isSmartQuery ? searchTextValue : normalizedQuery,
            isLucene: isKeywordQuery,
            isUniq: isUniqueCompanySearch,
            queryType,
          });
        }
      } catch (error) {
        dispatch(searchActions.setHasLuceneGrammarError(true));

        const luceneQueryError = guessUserFriendlyLuceneError(searchText);
        const isDefaultMessage = luceneQueryError === DEFAULT_ERROR_MESSAGE;

        enqueueSnackbar(
          <NotificationMessage
            title={isDefaultMessage ? DEFAULT_ERROR_MESSAGE : 'Your query is not valid'}
            description={!isDefaultMessage ? luceneQueryError : undefined}
          />,
          { variant: 'error' },
        );
      }
    },
    [
      dispatch,
      enqueueSnackbar,
      handleAdvancedSearchModalClose,
      isAdvancedSearchModalOpen,
      isKeywordQuery,
      isSmartQuery,
      isUniqueCompanySearch,
      queryType,
      saveQuery,
      searchQuery,
      searchQueryTree,
      searchTextValue,
    ],
  );

  const regularSearch = useCallback(
    (searchValue: string, params: SearchParams) => {
      dispatch(searchActions.setSearchText(searchValue));
      dispatch(searchActions.setSearchQuery(searchValue));
      dispatch(searchActions.setIsUniqueCompanySearch(!!params.isUniq));

      dispatch(searchActions.setIsElasticEnabled(true));

      saveQuery({
        searchText: searchValue,
        isLucene: false,
        isUniq: params.isUniq,
        queryType: params.queryType,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, saveQuery, queryType],
  );

  const { mutateAsync: smartSearchCall } = useMutation({
    mutationKey: ['smart-search'],
    mutationFn: (text: string) => postSmartSearch({ text }),
    onSuccess(res) {
      elasticSearch(res.suggested_query, false);
    },
    onError() {
      enqueueSnackbar(
        <NotificationMessage title="Failed to proceed smart search. Please try again or contact support." />,
        { variant: 'error' },
      );
    },
  });

  const smartSearch = useCallback(
    async (searchValue: string) => {
      if (searchValue) {
        await smartSearchCall(searchValue);
      }
    },
    [smartSearchCall],
  );

  const eventAvailableSearch = useCallback(
    (data: SearchParams, options, searchValue) => {
      const isHistory = data.eventName === SEARCH_EVENTS.HISTORY;
      const isRemoveQuerySmartSearchEvent = data.eventName === SEARCH_EVENTS.REMOVE_QUERY_SMART_SEARCH;
      const isRemoveQueryEvent = data.eventName === SEARCH_EVENTS.REMOVE_QUERY;
      const isRegularHistorySearch = isHistory && data.queryType === QueryType.MATCH;
      const isSmartHistorySearch = isHistory && data.queryType === QueryType.SMART;

      if (isRegularHistorySearch) {
        regularSearch(searchValue, data);
      } else if (isSmartHistorySearch) {
        smartSearch(searchValue);
      } else if (isRemoveQuerySmartSearchEvent || isRemoveQueryEvent) {
        elasticSearch('', options.concatPrevQuery, true);
      } else {
        elasticSearch(searchValue, options.concatPrevQuery);
      }
    },
    [elasticSearch, smartSearch, regularSearch],
  );

  const search = useCallback(
    (
      data: SearchParams,
      options = {
        concatPrevQuery: true,
        useStateFilters: true,
        skipCaseCodePopupCheck: false,
      },
    ) => {
      if (shouldShowCaseCodePopup && !options.skipCaseCodePopupCheck) {
        handleCaseCodeModalOpen({ searchConfig: { data, options } });

        return;
      }

      const searchValue = data.searchText.trim();

      dispatch(selectedCompaniesActions.reset());
      dispatch(searchActions.setIsUniqueCompanySearch(false));

      if (!options.useStateFilters && !isNil(data?.filters)) {
        dispatch(filterActions.setFilters(data?.filters));
      }

      if (data?.eventName && Object.values(SEARCH_EVENTS).includes(data.eventName)) {
        eventAvailableSearch(data, options, searchValue);

        return;
      }

      if ((!data.queryType && isKeywordQuery) || data.queryType === QueryType.KEYWORD) {
        elasticSearch(searchValue, options.concatPrevQuery);
      } else if ((!data?.queryType && isSmartQuery) || data?.queryType === QueryType.SMART) {
        smartSearch(searchValue);
      } else {
        regularSearch(searchValue, data);
      }
    },
    [
      shouldShowCaseCodePopup,
      dispatch,
      isKeywordQuery,
      isSmartQuery,
      handleCaseCodeModalOpen,
      eventAvailableSearch,
      elasticSearch,
      smartSearch,
      regularSearch,
    ],
  );

  return search;
};
