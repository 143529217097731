import React from 'react';

export const IconArrowLeft = () => (
  <svg
    width="25"
    height="21"
    viewBox="0 0 25 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8901 9.24553H7.78149L11.4891 5.6122C11.8503 5.25886 11.7912 4.73303 11.3585 4.43886C10.9247 4.14386 10.2818 4.1922 9.92154 4.54553L4.81889 9.54553C4.77909 9.5847 4.75867 9.63053 4.72908 9.67386C4.70459 9.70886 4.67499 9.73886 4.65662 9.7772C4.6107 9.87303 4.58314 9.97386 4.58314 10.0755C4.58314 10.0764 4.58212 10.078 4.58212 10.0789C4.58212 10.0797 4.58314 10.0814 4.58314 10.0822C4.58314 10.1839 4.6107 10.2847 4.65662 10.3805C4.67499 10.4189 4.70459 10.4489 4.72908 10.4839C4.75867 10.5272 4.77909 10.573 4.81889 10.6122L9.92154 15.6122C10.1236 15.8097 10.4134 15.9122 10.7053 15.9122C10.936 15.9122 11.1676 15.8489 11.3585 15.7189C11.7912 15.4247 11.8503 14.8989 11.4891 14.5455L7.78149 10.9122H19.8901C20.4534 10.9122 20.9106 10.5389 20.9106 10.0789C20.9106 9.61886 20.4534 9.24553 19.8901 9.24553Z"
      fill="#2E3F4C"
    />
    <mask
      id="mask0_3081_58851"
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="17"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8901 9.24553H7.78149L11.4891 5.6122C11.8503 5.25886 11.7912 4.73303 11.3585 4.43886C10.9247 4.14386 10.2818 4.1922 9.92154 4.54553L4.81889 9.54553C4.77909 9.5847 4.75867 9.63053 4.72908 9.67386C4.70459 9.70886 4.67499 9.73886 4.65662 9.7772C4.6107 9.87303 4.58314 9.97386 4.58314 10.0755C4.58314 10.0764 4.58212 10.078 4.58212 10.0789C4.58212 10.0797 4.58314 10.0814 4.58314 10.0822C4.58314 10.1839 4.6107 10.2847 4.65662 10.3805C4.67499 10.4189 4.70459 10.4489 4.72908 10.4839C4.75867 10.5272 4.77909 10.573 4.81889 10.6122L9.92154 15.6122C10.1236 15.8097 10.4134 15.9122 10.7053 15.9122C10.936 15.9122 11.1676 15.8489 11.3585 15.7189C11.7912 15.4247 11.8503 14.8989 11.4891 14.5455L7.78149 10.9122H19.8901C20.4534 10.9122 20.9106 10.5389 20.9106 10.0789C20.9106 9.61886 20.4534 9.24553 19.8901 9.24553Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3081_58851)"></g>
  </svg>
);
