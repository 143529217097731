import React from 'react';

export const IconSave = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8333 15C15.8333 15.4592 15.46 15.8333 15 15.8333H13.3333V13.3333C13.3333 12.8733 12.9608 12.5 12.5 12.5H7.5C7.03917 12.5 6.66667 12.8733 6.66667 13.3333V15.8333H5C4.54 15.8333 4.16667 15.4592 4.16667 15V5C4.16667 4.54083 4.54 4.16667 5 4.16667H6.66667V8.33333C6.66667 8.79333 7.03917 9.16667 7.5 9.16667H10.8333C11.2942 9.16667 11.6667 8.79333 11.6667 8.33333C11.6667 7.87333 11.2942 7.5 10.8333 7.5H8.33333V4.16667H10.9767C11.1958 4.16667 11.41 4.25583 11.5658 4.41083L15.5892 8.43417C15.7467 8.59167 15.8333 8.80167 15.8333 9.02417V15ZM8.33333 15.8333H11.6667V14.1667H8.33333V15.8333ZM16.7675 7.25583L12.7442 3.2325C12.2725 2.76 11.6442 2.5 10.9767 2.5H5C3.62167 2.5 2.5 3.62167 2.5 5V15C2.5 16.3783 3.62167 17.5 5 17.5H7.5H12.5H15C16.3783 17.5 17.5 16.3783 17.5 15V9.02417C17.5 8.35583 17.2392 7.7275 16.7675 7.25583V7.25583Z"
      fill="#2E3F4C"
    />
    <mask
      id="mask0_4450_144265"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="16"
      height="16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8333 15C15.8333 15.4592 15.46 15.8333 15 15.8333H13.3333V13.3333C13.3333 12.8733 12.9608 12.5 12.5 12.5H7.5C7.03917 12.5 6.66667 12.8733 6.66667 13.3333V15.8333H5C4.54 15.8333 4.16667 15.4592 4.16667 15V5C4.16667 4.54083 4.54 4.16667 5 4.16667H6.66667V8.33333C6.66667 8.79333 7.03917 9.16667 7.5 9.16667H10.8333C11.2942 9.16667 11.6667 8.79333 11.6667 8.33333C11.6667 7.87333 11.2942 7.5 10.8333 7.5H8.33333V4.16667H10.9767C11.1958 4.16667 11.41 4.25583 11.5658 4.41083L15.5892 8.43417C15.7467 8.59167 15.8333 8.80167 15.8333 9.02417V15ZM8.33333 15.8333H11.6667V14.1667H8.33333V15.8333ZM16.7675 7.25583L12.7442 3.2325C12.2725 2.76 11.6442 2.5 10.9767 2.5H5C3.62167 2.5 2.5 3.62167 2.5 5V15C2.5 16.3783 3.62167 17.5 5 17.5H7.5H12.5H15C16.3783 17.5 17.5 16.3783 17.5 15V9.02417C17.5 8.35583 17.2392 7.7275 16.7675 7.25583V7.25583Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_4450_144265)"></g>
  </svg>
);
