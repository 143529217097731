import React, { useRef } from 'react';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { isEmpty } from 'lodash';

import { numberFormatterCustom } from '@/Components/Shared/common';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { ChartDataType, Currency } from '@/types';
import { getYaxisLabel } from '@/Utils/charts';

const getOptions = (config: CommonChartConfigType, currency: Currency): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        callback: function (tick) {
          const value = `${this.getLabelForValue(tick as number)}`.slice(-2);

          return `'${value}`;
        },
      },
    },
    'y-axis-0': {
      position: 'left',
      grid: {
        display: true,
        color: '#ddd',
      },
      border: {
        dash: [2, 2],
      },
      beginAtZero: true,
      ticks: {
        callback: function (value) {
          const unit = getYaxisLabel({
            yAxisType: config.yAxis[0].yAxisType,
            currency,
          });

          return `${unit}${numberFormatterCustom(Number(value))}`;
        },
      },
    },
    ...(config.yAxis[1] && {
      'y-axis-1': {
        grid: {
          display: false,
        },
        position: 'right',
        ticks: {
          callback: function (value) {
            const unit = getYaxisLabel({
              yAxisType: config.yAxis[1].yAxisType,
              currency,
            });

            return `${unit}${numberFormatterCustom(Number(value))}`;
          },
        },
      },
    }),
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
    },
  },
});

export type CommonChartConfigType = {
  slug: string;
  title: string;
  yAxis: { chartType: string; field: string; label: string; yAxisType: string | null }[];
  xAxis: { field: string }[];
};

interface IProps {
  chartConfig: CommonChartConfigType;
  data: ChartDataType[];
  isLoading: boolean;
  handlePushToFinancials: () => void;
}

export const OverviewChart = ({ chartConfig, data, isLoading, handlePushToFinancials }: IProps) => {
  const ref = useRef<Chart<'bar'>>(null);
  const currency = useShallowSelector((state) => state.config.currency);
  const xAxisField = chartConfig.xAxis[0].field ?? '';
  const options = getOptions(chartConfig, currency);

  const chartData: ChartData<'bar'> = {
    labels: data.map((item) => Number(item[xAxisField])),
    // @ts-ignore
    datasets: chartConfig.yAxis.map((yItem, idx) => {
      const chartType = yItem.chartType?.toLowerCase();
      const isBar = chartType === 'bar';

      return {
        label: yItem.label,
        type: chartType,
        data: data.map((item) => Number(item[yItem.field])),
        ...(isBar && {
          type: chartType,
          backgroundColor: '#728a9d',
        }),
        ...(!isBar && {
          borderColor: '#02c7e6',
          backgroundColor: '#02c7e6',
          pointRadius: 0,
          pointHoverBackgroundColor: '#0484e7',
          pointHoverBorderColor: '#fff',
        }),
        yAxisID: `y-axis-${idx}`,
        order: isBar ? 1 : 0,
      };
    }),
  };

  if (isLoading) {
    return (
      <Skeleton
        height={390}
        variant="rounded"
        className="bg-[#0000000f] break-inside-avoid"
      />
    );
  }

  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className="p-6 bg-white rounded break-inside-avoid shadow-[0_0_4px_0_rgba(95,95,95,0.25)]">
      <div className="flex items-center justify-between mb-4 min-h-9">
        <span className="text-base font-medium text-[#484848]">{chartConfig.title}</span>

        <IconButton
          onClick={handlePushToFinancials}
          size="small"
          id={`CompanyProfile${chartConfig.slug}PreviewChartRedirectButton`}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>

      <div className="min-h-[290px]">
        <Bar
          ref={ref}
          data={chartData}
          options={options}
        />
      </div>
    </div>
  );
};
