import React from 'react';
import classNames from 'classnames';
import { IconSearch } from '@/Components/Icons/IconSearch';

export const CardIconSearch = ({ active }: { active?: boolean }) => (
  <div
    className={classNames('w-7 h-7 bg-[#EAE6FF] rounded-full mr-3 flex items-center justify-center', {
      'opacity-50': !active,
    })}
  >
    <IconSearch className="fill-[#231F20]" />
  </div>
);
