import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import { GridSortDirection } from '@mui/x-data-grid-pro';

import SearchPage from '../search-page';
import { actions as searchActions, QueryType } from '@/slices/search';
import { actions as navigationTabsActions } from '@/slices/navigation-tabs';
import { actions as filterActions } from '@/slices/filters';
import { actions as tableActions } from '@/slices/table';
import { actions as configActions } from '@/slices/config';
import { ERROR_PAGE_PROPS, HISTORY_KEYS, LOCAL_STORAGE_KEYS, MODALS, SEARCH_EVENTS } from '@/constants';
import { ISavedSearch } from '@/types';
import { SavedSearchControls } from '@/Components/Shared/SavedSearch/SavedSearchControls';
import { useQuerySavedSearchById } from '@/hooks/queries/saved-search/use-query-saved-search-by-id';
import { useQuerySavedSearches } from '@/hooks/queries/saved-search/use-query-saved-searches';
import { getUuidFromStringEnd } from '@/Utils/url-utils';
import { useSearchCompanies } from '@/hooks/use-search-companies';
import { useHasChangedSearchSettings } from '@/hooks/saved-search/use-has-changed-search-settings';
import { fixTreeFilters } from '@/Utils/filters';
import { useModal } from '@/hooks/use-modal';

const SavedSearch = () => {
  const dispatch = useDispatch();
  const { id: idWithTitle } = useParams<{ id: string }>();
  const id = getUuidFromStringEnd(idWithTitle);
  const { handleOpen: openLoadingModal } = useModal(MODALS.SEMANTIC_SEARCH_LOADING_DIALOG);

  const { data: savedSearches } = useQuerySavedSearches();
  const searchCompanies = useSearchCompanies();

  const search = useCallback(
    (queryData: ISavedSearch) => {
      const isSmartSearch = queryData?.query_type === QueryType.SMART;

      if (queryData?.semantic_description) {
        dispatch(
          searchActions.setSemanticSearch({
            userDescription: queryData.semantic_description,
            applyDescription: true,
          }),
        );

        openLoadingModal({ skipSearch: true });
      }

      dispatch(searchActions.setQueryType(queryData?.query_type));
      dispatch(configActions.setCurrency(queryData?.currency));

      if (queryData?.filters) {
        if (queryData?.filters?.treeFilters) {
          // apply conversion from old serialization scheme to modern serialization scheme

          const fixedFilters = fixTreeFilters(queryData?.filters);

          dispatch(filterActions.setFilters(fixedFilters));
        } else {
          dispatch(filterActions.setFilters(queryData?.filters));
        }
      }

      if (queryData?.company_search) {
        dispatch(searchActions.setSearchText(queryData?.company_search));
      }

      if (queryData?.search_query && !isSmartSearch) {
        dispatch(searchActions.setSearchQuery(queryData?.search_query));
      }

      if (queryData?.visible_columns) {
        dispatch(tableActions.setVisibleColumns(queryData?.visible_columns));
      }

      if (queryData?.sort_order && queryData?.sort_key) {
        dispatch(
          tableActions.setSortModel([
            {
              sort: queryData?.sort_order as GridSortDirection,
              field: queryData?.sort_key,
            },
          ]),
        );
      }

      if (queryData?.columns_order) {
        dispatch(tableActions.setCustomColumnsOrder(queryData?.columns_order));
      }

      searchCompanies(
        {
          searchText: isSmartSearch ? queryData?.company_search : queryData?.search_query,
          description: queryData?.semantic_description,
          queryType: queryData?.query_type,
          eventName: SEARCH_EVENTS.HISTORY,
          isUniq: queryData?.is_unique_company,
        },
        { concatPrevQuery: false },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const query = useQuerySavedSearchById({ id, onSuccess: (queryData) => search(queryData) });

  const hasSearchChanged = useHasChangedSearchSettings(query.data);

  const [savedSearchesSharedWithUser, setSavedSearchesSharedWithUser] = useLocalStorage<ISavedSearch[]>(
    LOCAL_STORAGE_KEYS.SEARCHES_SHARED_WITH_USER,
    [],
  );

  useEffect(() => {
    if (!query.data || !query.isFetched) return;

    search(query.data);
  }, [dispatch, query.isFetched, query.data, search]);

  useEffect(() => {
    dispatch(searchActions.setHistoryKey(HISTORY_KEYS.SCREENERS_HISTORY));
  }, [dispatch]);

  useEffect(() => {
    if (!query.data || query.isFetching || !savedSearches) return;
    dispatch(navigationTabsActions.setCurrentSavedSearch(query.data));

    if (savedSearches.some((list) => list.list_id === query.data.list_id)) return;

    const alreadySharedWithUserItem = savedSearchesSharedWithUser.find(
      (sharedSavedSearch) => sharedSavedSearch.list_id === query.data.list_id,
    );

    if (alreadySharedWithUserItem) {
      setSavedSearchesSharedWithUser(
        savedSearchesSharedWithUser.map((sharedSavedSearch) =>
          sharedSavedSearch.list_id === query.data.list_id ? query.data : sharedSavedSearch,
        ),
      );

      return;
    }

    setSavedSearchesSharedWithUser([...savedSearchesSharedWithUser, query.data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query.data, query.isFetching, savedSearches]);

  return (
    <SearchPage
      isError={query.isError}
      isSemanticSearch={!!query.data?.semantic_description}
      errorPage={ERROR_PAGE_PROPS.SAVED_SEARCH_NOT_FOUND}
      controls={
        <SavedSearchControls
          isLoading={query.isFetching}
          savedSearch={query.data}
          hasSearchChanged={hasSearchChanged}
        />
      }
    />
  );
};

export default SavedSearch;
