import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CreateUpdateSharedElementDialog } from '../Shared/CreateUpdateSharedElementDialog';
import { useModal } from '@/hooks/use-modal';
import { useMutationUpdateCompaniesList } from '@/hooks/queries/companies-list/use-mutation-update-companies-list';
import { MODALS } from '@/constants';

export const UpdateCompaniesListDialog = () => {
  const { isOpen, handleClose: handleCloseModal, data } = useModal(MODALS.UPDATE_COMPANIES_LIST);
  const listData = data?.companiesList;
  const { register, handleSubmit, reset } = useForm();
  const isSharedWithUserList = data?.sharedWithUser;
  const isReadOnlyMode = data?.readOnlyMode;

  const handleClose = () => {
    handleCloseModal();
    data?.handleCloseMenu();
  };

  const mutation = useMutationUpdateCompaniesList(handleClose);

  useEffect(() => {
    reset();
  }, [listData, reset]);

  const onSubmit = (formData) => {
    mutation.mutate({
      id: listData?.list_id,
      fieldsToUpdate: formData,
      showDefaultNotification: true,
      invalidateResponse: true,
    });
  };

  return (
    <CreateUpdateSharedElementDialog
      title="View details"
      buttonLabel="Done"
      register={register}
      isOpen={isOpen}
      handleClose={handleClose}
      handleButtonClick={handleSubmit(onSubmit)}
      isLoading={mutation.isLoading}
      disabled={mutation.isLoading || isSharedWithUserList || isReadOnlyMode}
      elementData={listData}
    />
  );
};
