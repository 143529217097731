import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import { useTableLoading } from '@/hooks/table/use-table-loading';

interface IProps {
  headerName: string;
}

export const CompanyHeader = ({ headerName }: IProps) => {
  const isLoading = useTableLoading();

  return isLoading ? (
    <Skeleton className="w-full h-full bg-[#0000000f]" />
  ) : (
    <>
      <span
        className="MuiDataGrid-columnHeaderTitle"
        style={{ marginLeft: '10px', marginTop: '-2px' }}
      >
        {headerName}
      </span>
      <Tooltip
        title={<b>Click on company&apos;s name for more details</b>}
        arrow
      >
        <span className="ml-[5px] mb-[2px]">
          <InfoOutlined
            id="info-outlined"
            className="w-[17px]"
          />
        </span>
      </Tooltip>
    </>
  );
};
