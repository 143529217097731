import React from 'react';

import { range } from 'lodash';
import { Step, StepConnector, StepIconProps, StepLabel, Stepper, stepConnectorClasses, styled } from '@mui/material';
import classNames from 'classnames';
import { IconCheck } from '@/Components/Icons/IconCheck';

const Connector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#0484E7',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#0484E7',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#999',
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const StepIcon = (props: Readonly<StepIconProps>) => {
  const { active, completed, className } = props;

  return (
    <div
      className={classNames(className, 'flex h-[22px] items-center', {
        'text-[#999]': !active,
        'text-[#0484E7]': active,
      })}
    >
      {completed ? (
        <IconCheck
          className="text-[#0484E7] w-4 h-4"
          style={{ fill: '#0484E7' }}
        />
      ) : (
        <div className=" w-2 h-2 bg-current rounded-[50%]" />
      )}
    </div>
  );
};

export interface WizardStepperProps {
  numberOfSteps: number;
  activeStep: number;
}

export const WizardStepper = ({ numberOfSteps, activeStep }: WizardStepperProps) => {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<Connector />}
    >
      {range(numberOfSteps).map((index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={StepIcon} />
        </Step>
      ))}
    </Stepper>
  );
};
