import { uniq } from 'lodash';

export interface ValidateSingleQuotationMarksReturnType {
  valid: boolean;
  wrongTextParts: string[] | null;
  standardizedText: string;
}

const DEFAULT_BEFORE_AFTER_CHARACTERS = 8;

const getWrongTextParts = (text: string) =>
  uniq(
    text
      .split('')
      .map((char, index, array) => {
        if (char == "'")
          return array
            .slice(
              index < DEFAULT_BEFORE_AFTER_CHARACTERS ? 0 : index - DEFAULT_BEFORE_AFTER_CHARACTERS,
              index + 1 + DEFAULT_BEFORE_AFTER_CHARACTERS,
            )
            .join('');
      })
      .filter((part) => part !== undefined),
  ) as string[];

export const validateSingleQuotationMarks = (text: string): ValidateSingleQuotationMarksReturnType => {
  const standardizedText = text.replaceAll(/[‘’`]/g, "'").replaceAll(/“”/g, '"');

  const numberOfSingleQuotes = (standardizedText.match(/'/g) ?? []).length;

  return {
    valid: !numberOfSingleQuotes,
    wrongTextParts: numberOfSingleQuotes ? getWrongTextParts(standardizedText) : null,
    standardizedText,
  };
};
