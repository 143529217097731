import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import hash from 'object-hash';
import { useObligatoryElasticBody } from '../table/use-obligatory-elastic-body';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getAllBainIds } from '@/services/api/elastic-search';
import { IElasticAllBainIdsResponse } from '@/types';

interface IProps {
  enabled: boolean;
  setEnable: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (data: IElasticAllBainIdsResponse) => void;
}

export const useQueryAllBainIds = ({ enabled, setEnable, onSuccess }: IProps) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);

  const { currency, body } = useObligatoryElasticBody();

  const { enqueueSnackbar } = useSnackbar();

  const hashedBody = hash({ currency, body });

  return useQuery(
    ['all-bain-ids', hashedBody],
    () =>
      getAllBainIds({
        currency,
        body,
      }),
    {
      keepPreviousData: true,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      enabled: isAuth && enabled,
      onSettled: () => setEnable(false),
      onSuccess,
      onError: () => {
        enqueueSnackbar('Failed to fetch all Bain ids. Please try again or contact support.', {
          variant: 'error',
        });
      },
    },
  );
};
