import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { ShareButton } from '@/Components/Shared/Buttons/ShareButton';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { copyToClipboard } from '@/Utils/navigator-utils';
import { ROUTES } from '@/constants';
import { removeTrailingSlash, spaceToDash, clearString } from '@/Utils/text';
import { useMutationUpdateSavedSearch } from '@/hooks/queries/saved-search/use-mutation-update-saved-search';

export const createSavedSearchPath = (savedSearchTitle: string, savedSearchId: string) =>
  `${ROUTES.SAVED_SEARCH}/${spaceToDash(clearString(savedSearchTitle))}-${savedSearchId}`;

export const createSavedSearchLink = (savedSearchTitle: string, savedSearchId: string) =>
  `${removeTrailingSlash(process.env.REACT_APP_ROOT_URL as string)}${createSavedSearchPath(
    savedSearchTitle,
    savedSearchId,
  )}`;

interface ShareSavedSearchButtonProps {
  savedSearchId?: string;
  savedSearchTitle?: string;
  shared?: boolean;
  disabled?: boolean;
}

export const ShareSavedSearchButton = ({
  savedSearchId,
  savedSearchTitle,
  shared,
  disabled,
}: ShareSavedSearchButtonProps) => {
  const [isShared, setIsShared] = useState(shared);
  const [hasChanged, setHasChanged] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const onSuccessUpdate = () => {
    if (!savedSearchId || !savedSearchTitle) return;

    setIsShared(true);
    setHasChanged(true);
    copyToClipboard(
      createSavedSearchLink(savedSearchTitle, savedSearchId),
      () =>
        enqueueSnackbar(<NotificationMessage title={'Link copied to clipboard'} />, {
          variant: 'success',
        }),
      () =>
        enqueueSnackbar(
          <NotificationMessage title={'Failed to copy link to clipboard. Please try again or contact support.'} />,
          {
            variant: 'error',
          },
        ),
    );
  };

  const { mutate, isLoading } = useMutationUpdateSavedSearch(onSuccessUpdate);

  const updateSavedSearch = () => {
    if (!savedSearchId) return;

    mutate({
      id: savedSearchId,
      fieldsToUpdate: {
        is_sharable_link: true,
      },
    });
  };

  useEffect(() => {
    return () => {
      if (hasChanged) {
        queryClient.invalidateQueries({ queryKey: ['saved-search', savedSearchId] });
        queryClient.invalidateQueries({ queryKey: ['saved-searches'] });
      }
    };
  }, [hasChanged, savedSearchId, queryClient]);

  return (
    <ShareButton
      onClick={isShared ? onSuccessUpdate : updateSavedSearch}
      dataTestId={isShared ? 'share-search-link-shared' : 'share-search-link-not-shared'}
      isLoading={isLoading}
      disabled={disabled}
    />
  );
};
