import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useSessionStorage } from 'usehooks-ts';

import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import awsService from '../../../awsService';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions as configActions } from '@/slices/config';
import { actions as userActions } from '@/slices/user';
import { CURRENCIES, LOCAL_STORAGE_KEYS, MODALS, CASE_CODE_FLOWS } from '@/constants';
import { useMenu } from '@/hooks/use-menu';
import { CASE_CODE_INITIAL_STATE } from '@/hooks/queries/use-mutation-log-case-code';
import { useModal } from '@/hooks/use-modal';
import { Avatar } from '@/Components/Shared/Avatar/Avatar';
import { isCurrency } from '@/Utils/currency';
import { actions as searchActions } from '@/slices/search';
import { Link } from '@/Components/Shared/Link';
import { IconChevronDown } from '@/Components/Icons/IconChevronDown';

export const COVERAGE_LINK =
  'https://tableau.bain.com/t/HelixFind/views/DQ_Dashboards_User_v1/HFDataCoverageOverview?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=n';

export const SOURCE_LINK = 'https://tableau.bain.com/#/site/HelixFind/views/HelixDataDictionary/HDPDataDictionary';

enum AvatarVariant {
  HEADER,
  DROPDOWN,
}

export const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const { handleOpen: handleCaseCodeModalOpen } = useModal(MODALS.CASE_CODE);
  const userEmail = useShallowSelector((state) => state.user.email);
  const currency = useShallowSelector((state) => state.config.currency);
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);

  const caseCodeFormData = caseCodeData?.formData;
  const caseCodeFlowType = caseCodeData?.flowType;
  const caseCodeFormStep = caseCodeData?.step;

  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (isCurrency(value)) dispatch(configActions.setCurrency(value));

    dispatch(searchActions.setIsElasticEnabled(true));

    handleClose();
  };

  const logOut = () => {
    dispatch(userActions.setEmail(null));
    dispatch(userActions.setIsAuth(false));
    awsService.signOut();
  };

  const changeCaseCode = () => {
    handleCaseCodeModalOpen({
      formData: caseCodeFormData,
      flowType: Object.values(CASE_CODE_FLOWS).find((f) => f.label == caseCodeFlowType)?.value,
      step: caseCodeFormStep,
      isEditMode: true,
    });
    handleClose();
  };

  return (
    <>
      <Button
        data-testid="profile-dropdown"
        aria-controls={isOpen ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        classes={{ root: 'p-1' }}
      >
        <Avatar
          variant={AvatarVariant.HEADER}
          userEmail={userEmail}
        />

        <IconChevronDown className={classnames('transition', { 'rotate-180': isOpen })} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] min-w-[250px]',
        }}
      >
        <div className="flex p-4 gap-4">
          <div>
            <Avatar
              variant={AvatarVariant.DROPDOWN}
              userEmail={userEmail}
            />
          </div>
          <div>
            <div className="flex flex-col">
              <Typography className="text-[#2E3F4C] font-normal text-base">{userEmail}</Typography>
              {caseCodeFormData?.caseCode ? (
                <Typography className="text-[#2E3F4C] font-normal text-base">{`Case code - ${caseCodeFormData?.caseCode}`}</Typography>
              ) : null}
              <button
                className="flex justify-start"
                onClick={changeCaseCode}
              >
                <Link>Change</Link>
              </button>
            </div>
          </div>
        </div>

        <Divider />

        <FormControl className="w-full py-2">
          <RadioGroup
            aria-labelledby="currency-radio-buttons-group"
            name="currency-radio-buttons-group"
            value={currency}
            onChange={handleChange}
          >
            {Object.entries(CURRENCIES).map(([key, val]) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio color="info" />}
                label={`${key} - ${val.label}`}
                className={classnames('pl-1 m-0 h-[42px] min-h-[42px] hover:bg-[#0000000a]')}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Divider />

        <MenuItem className="h-[42px] min-h-[42px] p-0 mt-2">
          <Link
            href={COVERAGE_LINK}
            className="flex items-center pl-4"
          >
            Data coverage
          </Link>
        </MenuItem>

        <MenuItem className="h-[42px] min-h-[42px] p-0">
          <Link
            href={SOURCE_LINK}
            className="flex items-center pl-4"
          >
            Data source descriptions
          </Link>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => logOut()}
          className="h-[42px] min-h-[42px]"
        >
          <span className="font-semibold">Log out</span>
        </MenuItem>
      </Menu>
    </>
  );
};
