import React, { Component, ReactNode } from 'react';
import { ErrorPage } from '../Shared/ErrorPage';
import { ERROR_PAGE_PROPS } from '@/constants';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          {...ERROR_PAGE_PROPS.ERROR_BOUNDARY}
          //@ts-ignore
          onClick={() => (window.location = '/')}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
