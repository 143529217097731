import { post } from 'aws-amplify/api';

import {
  IElasticAllBainIdsResponse,
  IElasticResponse,
  IReqElastic,
  IReqSimplifyCustomAggregationString,
  ISimplifyCustomAggregationStringResponse,
} from '@/types';
import { ISemanticSearchAIResponse } from '@/types/state/semantic-search';

export const getElasticData = ({
  currency,
  body,
  pinnedCompanyIds,
  pageParam,
}: IReqElastic): Promise<IElasticResponse> => {
  const modifiedBody = pinnedCompanyIds ? { ...body, filters: { bain_id: pinnedCompanyIds } } : body;

  // @ts-ignore
  return post({
    apiName: 'CDPAPI',
    path: `/search?currency=${currency}`,
    // @ts-ignore
    options: { body: { ...modifiedBody, ...(pageParam ? { page_after: pageParam } : {}) } },
  }).response.then((res) => res.body.json());
};

export const getAllBainIds = ({ currency, body }: IReqElastic): Promise<IElasticAllBainIdsResponse> => {
  // @ts-ignore
  return post({ apiName: 'CDPAPI', path: `/search/all_ids?currency=${currency}`, options: { body } }).response.then(
    (res) => res.body.json(),
  );
};

export const simplifyCustomAggregationString = (
  body: IReqSimplifyCustomAggregationString,
): Promise<ISimplifyCustomAggregationStringResponse> => {
  // @ts-ignore
  return post({ apiName: 'CDPAPI', path: '/search/simplify', options: { body } }).response.then((res) =>
    res.body.json(),
  );
};

export const generateSemanticBasedSearchPayload = (description: string): Promise<ISemanticSearchAIResponse> => {
  // @ts-ignore
  return post({ apiName: 'CDPAPI', path: '/search/semantic', options: { body: { description } } }).response.then(
    (res) => res.body.json(),
  );
};
