import React from 'react';
import Button from '@mui/material/Button';
import classnames from 'classnames';
import { Tooltip } from '@mui/material';
import { MODALS } from '@/constants';
import { useModal } from '@/hooks/use-modal';
import { IconSave } from '@/Components/Icons/IconSave';

interface SaveSearchButtonProps {
  disabled?: boolean;
  showTooltip?: boolean;
}

export const SaveSearchButton = ({ disabled, showTooltip }: SaveSearchButtonProps) => {
  const { handleOpen } = useModal(MODALS.CREATE_SAVED_SEARCH);

  return (
    <Tooltip title={showTooltip ? 'Save as new search' : undefined}>
      <Button
        variant="outlined"
        startIcon={<IconSave />}
        className={classnames('text-[#2E3F4C] border-0 pl-3 pr-2 h-full', {
          'opacity-50': disabled,
        })}
        onClick={() => handleOpen()}
        data-testid="save-search-button"
        disabled={disabled}
      >
        <span
          className={classnames('flex justify-center font-normal', {
            'opacity-50': disabled,
          })}
        >
          Save
        </span>
      </Button>
    </Tooltip>
  );
};
