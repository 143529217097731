import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getCompaniesListRevisions } from '@/services/api/companies-list';
import { ICompaniesListRevisionsQuery } from '@/types';

interface UseQueryCompaniesListRevisionsProps extends ICompaniesListRevisionsQuery {
  id?: string;
  enable: boolean;
}

export const useQueryCompaniesListRevisions = ({ id, enable, ...query }: UseQueryCompaniesListRevisionsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const isAuth = useShallowSelector((state) => state.user.isAuth);

  return useQuery(
    ['companies-list-revisions', id],
    () => {
      if (!id) return;

      return getCompaniesListRevisions(id, query);
    },
    {
      enabled: isAuth && !!id && enable,
      refetchOnWindowFocus: false,
      onError: () =>
        enqueueSnackbar('Failed to fetch companies list history. Please try again or contact support.', {
          variant: 'error',
        }),
    },
  );
};
