import React from 'react';
import { isNil } from 'lodash';
import { CompanyProfileWidget } from '../../common/CompanyProfileWidget/CompanyProfileWidget';
import { IBuyerSummary } from '@/types';

export interface MnABuyerSummaryProps {
  data: IBuyerSummary;
}

const MnABuyerSummary = ({ data }: MnABuyerSummaryProps) => {
  return (
    <div className="grid grid-cols-3 gap-4 mb-4">
      <CompanyProfileWidget
        title={{ text: 'Portfolio', id: 'portfolio-header' }}
        entries={[
          {
            label: 'Current count',
            value: !isNil(data.current_portfolio_count) ? data.current_portfolio_count.toFixed(0) : undefined,
            id: 'current-count',
          },
          {
            label: 'Exited count',
            value: !isNil(data.exited_portfolio_count) ? data.exited_portfolio_count.toFixed(0) : undefined,
            id: 'exited-count',
          },
          {
            label: 'Current average age',
            value: !isNil(data.current_portfolio_avg_age)
              ? `${data.current_portfolio_avg_age.toFixed(2)} YRS`
              : undefined,
            id: 'average-age',
          },
          {
            label: 'Holding period average',
            value: !isNil(data.holding_period_avg) ? data.holding_period_avg.toFixed(2) : undefined,
            id: 'holding-period-average',
          },
          {
            label: 'Holding period standard',
            value: !isNil(data.holding_period_std) ? data.holding_period_std.toFixed(2) : undefined,
            id: 'holding-period-standard',
          },
        ]}
      />
      <CompanyProfileWidget
        title={{ text: 'Aquisitions', id: 'aquisitions-header' }}
        entries={[
          {
            label: 'Last year',
            value: !isNil(data.acquisitions_last_1yr) ? data.acquisitions_last_1yr.toFixed(0) : undefined,
            id: 'aq-last-year',
          },
          {
            label: 'Last 3 years',
            value: !isNil(data.acquisitions_last_3yr) ? data.acquisitions_last_3yr.toFixed(0) : undefined,
            id: 'aq-last-3-years',
          },
          {
            label: 'Last 5 years',
            value: !isNil(data.acquisitions_last_5yr) ? data.acquisitions_last_5yr.toFixed(0) : undefined,
            id: 'aq-last-5-years',
          },
        ]}
      />
      <CompanyProfileWidget
        title={{ text: 'Divestitures', id: 'divestitures-header' }}
        entries={[
          {
            label: 'Last year',
            value: !isNil(data.sales_last_1yr) ? data.sales_last_1yr.toFixed(0) : undefined,
            id: 'di-last-year',
          },
          {
            label: 'Last 3 years',
            value: !isNil(data.sales_last_3yr) ? data.sales_last_3yr.toFixed(0) : undefined,
            id: 'di-last-3-years',
          },
          {
            label: 'Last 5 years',
            value: !isNil(data.sales_last_5yr) ? data.sales_last_5yr.toFixed(0) : undefined,
            id: 'di-last-5-years',
          },
        ]}
      />
    </div>
  );
};

export default MnABuyerSummary;
