import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import { useObligatoryElasticBody } from '../table/use-obligatory-elastic-body';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { simplifyCustomAggregationString } from '@/services/api/elastic-search';

export type TSimplifyCustomAggregationStringVariables = {
  customAggregationStringToSimplify: string;
  onSuccess: (newCustomAggregationString: string) => void;
  onError?: () => void;
};

export const useMutationSimplifyCustomAggregationString = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { body } = useObligatoryElasticBody();

  return useMutation({
    mutationKey: ['simplify-custom-agg-string'],
    mutationFn: ({ customAggregationStringToSimplify }: TSimplifyCustomAggregationStringVariables) =>
      simplifyCustomAggregationString({
        custom_agg: customAggregationStringToSimplify,
        filters: body.filters,
        ...(!isEmpty(body.include_null_list) && { include_null_list: body.include_null_list }),
      }),
    onSuccess: ({ custom_agg }, { onSuccess }) => onSuccess(custom_agg),
    onError: (_, { onError }) => {
      onError?.();

      enqueueSnackbar(
        <NotificationMessage title="Failed to simplify expression. Please try again or contact support." />,
        {
          variant: 'error',
        },
      );
    },
  });
};
