import React, { useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { KeywordChip } from './KeywordChip';
import { replaceArrayItem } from './utils';
import { FilterChipWithDropdown } from './FilterChipWithDropdown';
import { KeycharChip } from './KeycharChip';
import {
  ELEMENT_ROLES,
  IAggregationElement,
  useFiltersToDisplay,
} from '@/Components/CompanyResearch/SavedFilters/use-filters-to-display';
import { FilterChip } from '@/Components/CompanyResearch/SavedFilters/FilterChip';

interface IActiveFilters {
  currentAggregation: IAggregationElement[] | null;
  setCurrentAggregation: React.Dispatch<React.SetStateAction<IAggregationElement[] | null>>;
}

export const ActiveFilters = ({ currentAggregation, setCurrentAggregation }: IActiveFilters) => {
  const { getFiltersAggregation, filtersToDisplay } = useFiltersToDisplay();
  const filtersAggregation = getFiltersAggregation();
  const refSetInitialValue = useRef(false);

  useEffect(() => {
    if (filtersAggregation && refSetInitialValue.current) return;

    refSetInitialValue.current = true;
    setCurrentAggregation(filtersAggregation);
  }, [filtersAggregation, setCurrentAggregation]);

  const wrapperClassName =
    'w-full p-2 rounded border h-[178px] gap-3 flex flex-wrap border-[#ddd] justify-start overflow-auto items-start content-start';

  const wrapperTestId = 'active-filters-section';

  if (filtersAggregation?.length === 1) {
    return (
      <div
        className={wrapperClassName}
        data-testid={wrapperTestId}
      >
        {currentAggregation?.map(({ value, role, backendName }, index) => {
          const key = `${value} ${role} ${index} ${backendName}`;

          if (role === ELEMENT_ROLES.keyword || role === ELEMENT_ROLES.keychar) return null;

          if (role === ELEMENT_ROLES.filter && !!backendName)
            return (
              <FilterChip
                key={key}
                label={value}
              />
            );
        })}
      </div>
    );
  }

  return (
    <div
      className={wrapperClassName}
      data-testid={wrapperTestId}
    >
      {currentAggregation?.map(({ value, role, backendName }, index) => {
        const key = `${value} ${role} ${index} ${backendName}`;

        if (role === ELEMENT_ROLES.keyword)
          return (
            <KeywordChip
              key={key}
              label={value}
              value={value}
              onSelect={(newValue) =>
                setCurrentAggregation((prevState) => {
                  if (!prevState) return null;

                  return replaceArrayItem(prevState, index, {
                    value: newValue,
                    role: ELEMENT_ROLES.keyword,
                  });
                })
              }
            />
          );

        if (role === ELEMENT_ROLES.keychar)
          return (
            <KeycharChip
              key={key}
              label={value}
              onClick={() =>
                setCurrentAggregation((prevState) => {
                  if (!prevState) return null;

                  const arrayCopy = cloneDeep(prevState);

                  arrayCopy.splice(index, 1);

                  return arrayCopy;
                })
              }
            />
          );

        const isActive = filtersToDisplay.some((item) => item.backendName === backendName);

        if (role === ELEMENT_ROLES.filter && !!backendName && isActive) {
          return (
            <FilterChipWithDropdown
              key={key}
              filtersToDisplay={filtersToDisplay}
              label={value}
              backendName={backendName}
              setCurrentAggregation={setCurrentAggregation}
              index={index}
            />
          );
        }

        if (role === ELEMENT_ROLES.filter && !!backendName && !isActive)
          return (
            <FilterChip
              key={key}
              label={value}
              disabled
            />
          );

        return null;
      })}
    </div>
  );
};
