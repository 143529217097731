import React from 'react';

import { StepProps } from '../types';

import { WorkflowDialogHeader } from '../../Common/WorkflowDialogHeader';
import { WorkflowDialogFooter } from '../../Common/WorkflowDialogFooter';

export const LoadingScreenStep = ({ setStep, progress }: StepProps) => {
  return (
    <>
      <WorkflowDialogHeader title="What are you looking for?" />
      <div>LoadingScreenStep</div>
      <WorkflowDialogFooter
        onPrimaryButtonClick={() => setStep((prevState) => prevState + 1)}
        onBackButtonClick={() => setStep((prevState) => prevState - 1)}
        isLoading={false}
        progress={progress}
      />
    </>
  );
};
