import { useQuery } from '@tanstack/react-query';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getColumnMapper } from '@/services/api/column-mapper';

export const useQueryColumnMapper = () => {
  const currency = useShallowSelector((state) => state.config.currency);
  const isAuth = useShallowSelector((state) => state.user.isAuth);

  return useQuery(['column-mapper', currency], () => getColumnMapper(currency), {
    enabled: isAuth,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
