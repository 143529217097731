import React from 'react';

import { Skeleton } from '@mui/material';

interface IProps {
  text: string;
  textToHighlight: string;
  isFetching?: boolean;
}

export const getHighlightedText = ({ text, textToHighlight, isFetching }: IProps) => {
  const stringTitle = text;
  const stringTitleLowerCase = text.toLowerCase();
  const index = stringTitleLowerCase.indexOf(textToHighlight.toLowerCase());
  const beforeHighlightedText = stringTitle.substring(0, index);
  const highlightedText = stringTitle.substring(index, index + textToHighlight.length);
  const afterHighlightedText = stringTitle.slice(index + textToHighlight.length);

  const skeleton = isFetching ? (
    <Skeleton
      width={40}
      className="bg-[#0000000f]"
      data-testid="skeleton-loader"
    />
  ) : null;

  const isHighlighted = index > -1;

  return {
    isHighlighted,
    text: isHighlighted ? (
      <div className="flex gap-2">
        <span>
          {beforeHighlightedText}
          <span className="text-[#cc0000]">{highlightedText}</span>
          {afterHighlightedText}
        </span>
        {skeleton}
      </div>
    ) : (
      <div className="flex gap-2">
        <span>{stringTitle}</span>
        {skeleton}
      </div>
    ),
  };
};
