import React, { ComponentType, useEffect } from 'react';
import { Switch, Route, RouteProps, Redirect, RouteComponentProps } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import SearchLists from './search-lists';
import CompanyFinder from './company-finder';
import CompaniesList from './companies-list';
import PageNotFound from './not-found';
import SavedSearch from './saved-search';
import CompanyProfile from './company-profile';
import CreateListWorkflow from './create-list-workflow';
import awsService from '@/awsService';
import Login from '@/Components/login';
import { CompanyResearch } from '@/Components/CompanyResearch/CompanyResearch';
import { HOME_PAGE_SLUG, LOCAL_STORAGE_KEYS, ROUTES } from '@/constants';

interface IProps extends RouteProps {
  component: ComponentType<RouteComponentProps> | ComponentType;
}

const GuardedRoute = ({ component: Component, ...rest }: IProps) => {
  const isAuth = awsService.getAuthenticationStatus();
  const [, setRedirectUrl] = useLocalStorage<string>(LOCAL_STORAGE_KEYS.REDIRECT_URL, '');

  useEffect(() => {
    if (!isAuth) {
      setRedirectUrl(`${rest.location?.pathname}${rest.location?.search ?? ''}`);
    }
  }, [isAuth, rest.location?.pathname, rest.location?.search, setRedirectUrl]);

  return (
    <Route
      {...rest}
      render={(props) => (isAuth ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

export default function Routes() {
  return (
    <Switch>
      <Route
        path={ROUTES.ROOT}
        component={Login}
        exact
      />
      <GuardedRoute
        path={`${ROUTES.COMPANIES_LIST}/:id/:revision?`}
        component={CompaniesList}
        exact
      />
      <GuardedRoute
        path={HOME_PAGE_SLUG}
        component={SearchLists}
        exact
      />
      <GuardedRoute
        path={ROUTES.CREATE_LIST_WORKFLOW}
        component={CreateListWorkflow}
        exact
      />
      <GuardedRoute
        path={ROUTES.CUSTOM_SEARCH}
        component={CompanyResearch}
        exact
      />
      <GuardedRoute
        path={`${ROUTES.CUSTOM_SEARCH}/:type`}
        component={CompanyResearch}
        exact
      />
      <GuardedRoute
        path={`${ROUTES.SAVED_SEARCH}/:id`}
        component={SavedSearch}
        exact
      />
      <GuardedRoute
        path={`${ROUTES.COMPANY_PROFILE}/:bainId/:tab?`}
        component={CompanyProfile}
        exact
      />
      <GuardedRoute
        path={`${ROUTES.COMPANY_FINDER}`}
        component={CompanyFinder}
        exact
      />

      <Route component={PageNotFound} />
    </Switch>
  );
}
