import { Collapse, TextField } from '@mui/material';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isNull } from 'lodash';
import { ClearSearchButton } from '../Buttons/ClearSearchButton';
import { AdvancedSearchButton } from '../Buttons/AdvancedSearchButton';
import { SemanticAgainButton } from './SearchAgainButton';
import { SemanticSearchNarrowingBox } from './SemanticSearchNarrowingBox';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { IconMessageCircle } from '@/Components/Icons/IconMessageCircle';
import { FiltersDrawer } from '@/Components/Shared/FiltersDrawer';
import { useScreener } from '@/hooks/use-screen';

export const SemanticSearchSection = () => {
  const { softReset } = useScreener();
  const [showDescriptionSection, setShowDescriptionSection] = useState(true);

  const { semanticSearch: semanticSearchState } = useShallowSelector((state) => state.search);
  const descriptionChecked = semanticSearchState?.applyDescription;
  const userDescription = semanticSearchState?.userDescription;

  const [newUserDescription, setNewUserDescription] = useState(userDescription);

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const isDescriptionChanged = userDescription !== newUserDescription;

  const isSemanticNull = isNull(semanticSearchState);

  const disabled = isSemanticNull ? false : !descriptionChecked;

  useEffect(() => {
    if (isSemanticNull) {
      setNewUserDescription('');
    }

    if (userDescription) {
      setNewUserDescription(userDescription);
    }
  }, [isSemanticNull, userDescription]);

  useEffect(() => {
    return () => softReset();
  }, [softReset]);

  return (
    <div className="bg-[#F8F9FA] border-y border-[#ddd] mt-4 px-6 pt-5 ">
      <div className="flex justify-between items-center h-6 mb-4">
        <div className="flex gap-2">
          <div className="flex gap-2 items-center bg-white px-2 py-1 rounded">
            <div>
              <IconMessageCircle />
            </div>
            <p className="text-[#484848] text-base font-medium leading-4 mb-1">Description</p>
            <p className="text-[#8C8C8C] text-base font-normal leading-4 mb-1 italic">
              powered by semantic search and GenAI
            </p>
          </div>
          <div className="flex gap-3">
            <FiltersDrawer isSemanticSearch />

            <AdvancedSearchButton />

            <ClearSearchButton />
          </div>
        </div>

        <button
          className="color-[#484848] text-sm font-medium"
          onClick={() => setShowDescriptionSection((prevState) => !prevState)}
        >
          <span>{showDescriptionSection ? 'Hide description' : 'Show description'}</span>
          <KeyboardArrowDownIcon
            className={classnames('fill-[#484848] text-xl transition ml-1', { 'rotate-180': showDescriptionSection })}
          />
        </button>
      </div>
      <Collapse
        in={showDescriptionSection}
        timeout="auto"
        unmountOnExit
      >
        <div className="flex gap-4 relative h-[200px] mb-5">
          <TextField
            inputRef={inputRef}
            placeholder="Add a few sentences about what a company does for us to work with..."
            variant="outlined"
            size="small"
            multiline
            className="bg-white w-full rounded-lg shadow-[0px_2px_4px_0px_#0000001A] [&>div]:h-full [&>div]:items-start [&>div]:pb-6"
            inputProps={{
              className: 'max-h-[140px] overflow-auto',
            }}
            disabled={disabled}
            value={newUserDescription}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewUserDescription(event.target.value);
            }}
          />

          {isDescriptionChanged && newUserDescription ? (
            <SemanticAgainButton newDescription={newUserDescription} />
          ) : null}

          <SemanticSearchNarrowingBox />
        </div>
      </Collapse>
    </div>
  );
};
