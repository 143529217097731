import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { actions as searchActions } from '../../slices/search';
import SearchPage from '../../pages/search-page';
import { CUSTOM_SCREEN_SEARCH_TYPES, HISTORY_KEYS } from '@/constants';
import { SaveSearchButton } from '@/Components/Shared/SavedSearch/SaveSearchButton';

export const CompanyResearch = () => {
  const dispatch = useDispatch();
  const { type: searchType } = useParams<{ type?: string }>();
  const isSemanticSearch = searchType === CUSTOM_SCREEN_SEARCH_TYPES.SEMANTIC;

  useEffect(() => {
    dispatch(searchActions.setHistoryKey(HISTORY_KEYS.CUSTOM_HISTORY));
  }, [dispatch]);

  return (
    <SearchPage
      isSemanticSearch={isSemanticSearch}
      controls={
        <div className="flex items-center gap-5">
          <span className="text-2xl text-[#666666] py-[2px] font-semibold">Company search</span>
          <SaveSearchButton />
        </div>
      }
    />
  );
};
