import React from 'react';

import { Button, CircularProgress } from '@mui/material';
import classnames from 'classnames';

export interface DialogFooterProps {
  onPrimaryButtonClick: () => void;
  onBackButtonClick?: () => void;
  primaryButtonDisabled?: boolean;
  isLoading?: boolean;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
}

export const DialogFooter = ({
  onPrimaryButtonClick,
  onBackButtonClick,
  primaryButtonDisabled,
  isLoading,
  primaryButtonLabel = 'Next',
  secondaryButtonLabel = 'Back',
}: DialogFooterProps) => {
  const isPrimaryButtonDisabled = primaryButtonDisabled || isLoading;

  return (
    <div className="flex justify-between items-center mt-6">
      {onBackButtonClick ? (
        <Button
          variant="outlined"
          className="text-[#2e3f4c] border-none bg-[#fff] hover:bg-[#0000000a] h-10 py-2 text-sm font-medium flex items-center"
          onClick={onBackButtonClick}
          size="medium"
          disabled={isLoading}
        >
          <span className="self-baseline">{secondaryButtonLabel}</span>
        </Button>
      ) : (
        <div />
      )}

      <div className="flex gap-4">
        <Button
          variant="outlined"
          className={classnames(
            'text-[#fff] border-[#2e3f4c] bg-[#2e3f4c] hover:bg-[#2e3f4cdd] h-10 py-2 text-sm font-medium flex items-center',
            {
              'opacity-50': isPrimaryButtonDisabled,
            },
          )}
          onClick={onPrimaryButtonClick}
          size="medium"
          disabled={isPrimaryButtonDisabled}
        >
          <span className="self-baseline">{primaryButtonLabel}</span>
          {isLoading ? (
            <CircularProgress
              className="text-[#ddd] ml-3"
              size={20}
            />
          ) : null}
        </Button>
      </div>
    </div>
  );
};
