import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'react-redux';
import { PopoverProps } from '@mui/material/Popover';

import { actions, QueryType } from '@/slices/search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { ucFirstLetter } from '@/Utils/text';
import { useScreener } from '@/hooks/use-screen';
import { BetaTag } from '@/Components/Shared/BetaTag/BetaTag';

interface ISearchTypeDropdown {
  anchorElement?: PopoverProps['anchorEl'];
  toggleDropdown: () => void;
  closeDropdown: () => void;
}

const SearchTypeDropdown = ({ anchorElement, toggleDropdown, closeDropdown }: ISearchTypeDropdown) => {
  const dispatch = useDispatch();
  const queryType = useShallowSelector((state) => state.search.queryType);
  const isOpened = !!anchorElement;
  const { resetAll } = useScreener();

  const handleOptionClick = async (payload: QueryType) => {
    await resetAll(true);
    dispatch(actions.setQueryType(payload));
    closeDropdown();
    dispatch(actions.setIsElasticEnabled(false));
  };

  const options = [
    {
      label: 'Keyword',
      type: QueryType.KEYWORD,
      description: 'Use terms to describe your target companies e.g. "e-learning" OR "online courses',
      onClick: () => {
        handleOptionClick(QueryType.KEYWORD);
      },
    },
    {
      label: 'Match',
      type: QueryType.MATCH,
      description: 'Use company names, URL’s or descriptions e.g. “Amazon”',
      onClick: () => {
        handleOptionClick(QueryType.MATCH);
      },
    },
    {
      label: 'Smart search',
      type: QueryType.SMART,
      afterLabel: <BetaTag />,
      description: 'Use sentences e.g. "Production of biofuel from algae"',
      onClick: () => {
        handleOptionClick(QueryType.SMART);
      },
    },
  ];

  return (
    <>
      <Button
        data-testid="search-type-dropdown"
        aria-controls={isOpened ? 'history-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpened ? 'true' : undefined}
        disableElevation
        onClick={toggleDropdown}
        classes={{ root: 'min-w-[30px] p-1 w-20' }}
      >
        <span className="font-normal text-[#666666] text-xs">{ucFirstLetter(queryType)}</span>
        <KeyboardArrowDownIcon
          className={classnames('fill-cgray-50 text-xl transition ml-1', { 'rotate-180': isOpened })}
        />
      </Button>
      <Menu
        anchorEl={anchorElement}
        open={isOpened}
        onClose={closeDropdown}
        classes={{
          paper: 'rounded mt-1 border border-[#C3C4C4] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]',
        }}
        disableAutoFocusItem
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={option.onClick}
            sx={{ width: '492px' }}
            disabled={option.type === queryType}
          >
            <Box className="flex flex-col">
              <Box className="flex gap-x-1">
                <Typography
                  className="font-medium"
                  variant="inherit"
                  noWrap
                >
                  {option.label}
                </Typography>
                {option.afterLabel}
              </Box>
              <span className="text-xs text-[#666666]">{option.description}</span>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SearchTypeDropdown;
