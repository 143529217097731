import React from 'react';
import { useHistory } from 'react-router-dom';

import { Card } from '../../Card/Card';
import { createSavedSearchPath } from '../ShareSavedSearchButton';
import { useSavedSearchMenuItems } from '../SavedSearchMenu/use-saved-search-menu-items';
import { CardIconSearch } from '../../Card/CardIcons/CardIconSearch';
import { ISavedSearchSnippet } from '@/types';

interface IProps {
  savedSearch: ISavedSearchSnippet;
  isUserSearch?: boolean;
}

const getSearchStatus = (isUserSearch: boolean, isSharable: boolean) => {
  if (!isUserSearch) {
    return 'Shared with me';
  }

  return isSharable ? 'Shared by me' : 'Private';
};

export const SavedSearchCard = ({ savedSearch, isUserSearch = false }: IProps) => {
  const history = useHistory();
  const menuItems = useSavedSearchMenuItems(savedSearch, !isUserSearch);

  const handleRoute = () => {
    history.push(createSavedSearchPath(savedSearch.title, savedSearch.list_id));
  };

  return (
    <Card
      title={savedSearch.title}
      onClick={handleRoute}
      menuItems={menuItems}
      icon={<CardIconSearch />}
    >
      <div className="text-[#484848]">Status: {getSearchStatus(isUserSearch, savedSearch.is_sharable_link)}</div>
    </Card>
  );
};
