import React from 'react';
import { Chip } from '@mui/material';
import classnames from 'classnames';
import { CommonCellTooltip } from '../CommonCellToltip';
import { IElasticTableData } from '@/types';

export interface IMultiValueCellChipProps {
  label: string;
  inTooltip?: boolean;
}

export const MultiValueCellChip = ({ label, inTooltip }: IMultiValueCellChipProps) => {
  return (
    <Chip
      label={label}
      className={classnames('h-[24px] mr-2 bg-[#ddd]', {
        'mb-2': inTooltip,
      })}
    />
  );
};

export interface IProps {
  displayName: string;
  field: string;
  row: IElasticTableData;
  values: string[];
  Icon: JSX.Element | null;
}

export const MultiValueCell = ({ displayName, field, row, values, Icon }: IProps) => {
  return (
    <CommonCellTooltip
      displayName={displayName}
      row={row}
      field={field}
    >
      <div className="w-full h-full flex items-center">
        {Icon}
        <div className="pl-4 w-full h-full flex items-center">
          <span className="w-full inline-block truncate">
            {values.map((item) => {
              return (
                <MultiValueCellChip
                  key={item}
                  label={item}
                />
              );
            })}
          </span>
        </div>
      </div>
    </CommonCellTooltip>
  );
};
