import React, { useState } from 'react';
import {
  DataGridPro,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPinnedColumns,
  GridRowsProp,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  useGridApiRef,
  GridColDef,
} from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { times } from 'lodash';
import { EstimatedSign } from '../../common/EstimatedSign';
import { IconTableColumns } from '@/Components/Icons/IconTableColumns';
import { getIdByLabel } from '@/Utils';
import { DownloadCsvButton } from '@/Components/Shared/Buttons/DownloadCsvButton';

const DEFAULT_PAGE_SIZE = 10;

interface GenericTableProps {
  tableName: string;
  columns: GridColDef[];
  rows: GridRowsProp;
  filterModel?: GridFilterModel;
  sortModel?: GridSortModel;
  pinnedColumns?: GridPinnedColumns;
  disallowDownload?: boolean;
  initialColumnVisibilityModel?: GridColumnVisibilityModel;
  isEstimated?: boolean;
}

export const GenericTable = ({
  tableName,
  columns,
  rows,
  filterModel,
  sortModel,
  pinnedColumns,
  disallowDownload,
  initialColumnVisibilityModel,
  isEstimated,
}: GenericTableProps) => {
  const apiRef = useGridApiRef();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  });

  const renderToolbar = () => {
    return (
      <GridToolbarContainer className="h-14 px-4 border-b flex justify-between items-center">
        <div className="flex gap-3 flex-1">
          <Tooltip title="Columns">
            <GridToolbarColumnsButton className="min-w-[40px] h-[40px] m-0 toolbar-button rounded-full [&>span]:m-0" />
          </Tooltip>

          <p className="font-semibold self-center text-sm">
            <EstimatedSign isEstimated={isEstimated}>{tableName}</EstimatedSign>
          </p>
        </div>
        <div>
          <DownloadCsvButton
            id={`${getIdByLabel(tableName)}-table-download-csv`}
            onClick={() => apiRef.current.exportDataAsCsv()}
            disabled={disallowDownload}
            tooltipTitle={
              disallowDownload
                ? 'Download is disallowed for this dataset. Reach us if you are interested in raw data.'
                : 'Download Data'
            }
          />
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div className="mb-4">
      <DataGridPro
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autoHeight
        rowHeight={48}
        //@ts-ignore
        customHeaderHeight={28}
        hideFooter={rows.length <= DEFAULT_PAGE_SIZE}
        filterModel={filterModel}
        disableColumnReorder
        pinnedColumns={pinnedColumns}
        sx={{
          backgroundColor: '#fff',
          '.MuiDataGrid-main': {
            boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 14%)',
            borderRadius: '4px',
          },
          '.MuiDataGrid-cell, .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid rgba(0,0,0,0.04)',
          },
        }}
        slots={{
          columnSelectorIcon: IconTableColumns,
          toolbar: renderToolbar,
        }}
        initialState={{
          sorting: { sortModel: sortModel },
          columns: { columnVisibilityModel: initialColumnVisibilityModel },
        }}
        localeText={{
          toolbarColumns: '',
          toolbarDensity: '',
        }}
        pagination
        pageSizeOptions={times(5, (index) => (index + 1) * DEFAULT_PAGE_SIZE)}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </div>
  );
};
