import React, { useCallback } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { utils, writeFile } from 'xlsx';
import { isEmpty } from 'lodash';
import { Chart } from 'chart.js';
import 'chart.js/auto';

import { BarChart, LineChart, StackedBarChart, LineOverBarChart, SankeyChart } from '@/Components/Shared/Charts';
import { ChartConfigType, ChartDataType, DatumDatum } from '@/types';

export interface IChartCommonProps {
  data: ChartDataType[];
  isLoading: boolean;
  section: string;
  params?: { row: DatumDatum };
  config: ChartConfigType;
}

export const ChartCommon = ({ data, params, section, isLoading, config }: IChartCommonProps) => {
  const type = config.CHART_TYPE;
  const label = config.TITLE;

  const handleDownloadXlsx = useCallback(() => {
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws, 'Data');
    writeFile(wb, `${label}-data.xlsx`);
  }, [data, label]);

  const handleDownloadImage = useCallback((ref: React.RefObject<Chart>) => {
    if (ref.current) {
      const link = document.createElement('a');

      link.href = ref.current.toBase64Image('image/png', 1);
      link.download = `${label}-chart.png`;
      link.click();
    }
  }, [label]);

  if (isLoading) {
    return (
      <Skeleton
        height={400}
        variant="rounded"
        className="bg-[#0000000f] rounded-2xl"
        data-testid="skeleton"
      />
    );
  }

  if (isEmpty(data) && !isLoading) {
    return null;
  }

  return (
    <div
      className="bg-white rounded mb-4"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      {type === 'LINE' && (
        <LineChart
          config={config}
          data={data}
          params={params}
          section={section}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
        />
      )}

      {type === 'BAR' && (
        <BarChart
          config={config}
          data={data}
          params={params}
          section={section}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
        />
      )}

      {type === 'STACKED_BAR' && (
        <StackedBarChart
          config={config}
          data={data}
          params={params}
          section={section}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
        />
      )}

      {type === 'LineOverBar' && config && (
        <LineOverBarChart
          config={config}
          data={data}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
        />
      )}

      {type === 'SANKEY' && (
        <SankeyChart
          config={config}
          data={data}
        />
      )}
    </div>
  );
};
