import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullish } from '@/types';
import { parseQueryToAstTree, removeNodeFromAstTreeAndFix } from '@/Utils/pegjs/astTreeUtils';
import { AstTree, Position } from '@/types/ast-tree-models';
import { HISTORY_KEYS } from '@/constants';
import { TSemanticSearchState } from '@/types/state/semantic-search';

export enum QueryType {
  KEYWORD = 'keyword',
  MATCH = 'match',
  SMART = 'smart',
}

type TableFilterType = { key: string, op: string, arg: string | string[] };

export const initialState = {
  isElasticEnabled: false,
  isInitialSearchPerformed: false,
  semanticSearch: null as TSemanticSearchState,
  searchText: '',
  backupSearchText: '',
  advancedSearchText: '',
  searchQueryTree: {} as Nullish<AstTree>,
  searchQuery: '',
  hasLuceneGrammarError: false,
  hasSearchError: false as boolean,
  queryType: QueryType.KEYWORD,
  historyKey: HISTORY_KEYS.CUSTOM_HISTORY,
  isUniqueCompanySearch: false,
  isFiltersDrawerOpen: false,
  pinnedCompanyIds: null as number[] | null,
  table_filters: [] as TableFilterType[],
};

export const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setIsElasticEnabled: (state, action: PayloadAction<boolean>) => {
      state.isElasticEnabled = action.payload;
    },
    setIsInitialSearchPerformed: (state, action: PayloadAction<boolean>) => {
      state.isInitialSearchPerformed = action.payload;
    },
    setHasSearchError: (state, { payload }: PayloadAction<boolean>) => {
      state.hasSearchError = payload;
    },
    setSearchText: (state, { payload }: PayloadAction<string>) => {
      state.searchText = payload;
    },
    setBackupSearchText: (state, { payload }: PayloadAction<string>) => {
      state.backupSearchText = payload;
    },
    setAdvancedSearchText: (state, { payload }: PayloadAction<string>) => {
      state.advancedSearchText = payload;
    },
    setQueryType: (state, { payload }: PayloadAction<QueryType>) => {
      state.queryType = payload;
    },
    setSearchQuery: (state, { payload }: PayloadAction<string>) => {
      state.searchQuery = payload; // payload is already normalized query
      state.searchQueryTree = [QueryType.KEYWORD, QueryType.SMART].includes(state.queryType)
        ? parseQueryToAstTree(state.searchQuery)
        : null;
    },
    removeSearchQuery: (state, { payload }: PayloadAction<Position>) => {
      const { query, tree } = removeNodeFromAstTreeAndFix(state.searchQueryTree as AstTree, payload);

      state.searchQuery = query;
      state.searchQueryTree = tree;
    },
    setHasLuceneGrammarError: (state, { payload }: PayloadAction<boolean>) => {
      state.hasLuceneGrammarError = payload;
    },
    setHistoryKey: (state, { payload }: PayloadAction<string>) => {
      state.historyKey = payload;
    },
    resetSearch: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...initialState,
      historyKey: payload ?? state.historyKey,
      isInitialSearchPerformed: state.isInitialSearchPerformed,
    }),
    setIsUniqueCompanySearch: (state, { payload }: PayloadAction<boolean>) => {
      state.isUniqueCompanySearch = payload;
    },
    setIsFiltersDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isFiltersDrawerOpen = payload;
    },
    setPinnedCompanyIds: (state, { payload }: PayloadAction<number[] | null>) => {
      state.pinnedCompanyIds = payload;
    },
    setTableFilters: (state, { payload }: PayloadAction<TableFilterType[]>) => {
      state.table_filters = payload;
    },
    setSemanticSearch: (state, { payload }: PayloadAction<TSemanticSearchState>) => {
      state.semanticSearch = payload;
    },
  },
});

export const { reducer } = slice;
export const actions = {
  ...slice.actions,
};

export type TState = typeof initialState;
