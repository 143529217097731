import { useQuery } from '@tanstack/react-query';

import { useSnackbar } from 'notistack';
import { getCompaniesListCollaborators } from '@/services/api/companies-list';

export const useQueryCompaniesListCollaboratorsById = (id: string) => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(['companies-list-collaborators', id], () => getCompaniesListCollaborators(id), {
    enabled: !!id,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    onError: () =>
      enqueueSnackbar('Failed to fetch companies list collaborators. Please try again or contact support.', {
        variant: 'error',
      }),
  });
};
