import { isEmpty } from 'lodash';
import { useSessionStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_KEYS, MODALS } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useModal } from '@/hooks/use-modal';
import { CASE_CODE_INITIAL_STATE } from '@/hooks/queries/use-mutation-log-case-code';

export const useCaseCodePopupConditionally = () => {
  const { isInitialSearchPerformed } = useShallowSelector((state) => state.search);

  const { handleOpen: handleCaseCodeModalOpen, isOpen, handleClose } = useModal(MODALS.CASE_CODE);
  const [caseCodeFormData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);

  return {
    shouldShowCaseCodePopup: isInitialSearchPerformed && !isOpen && isEmpty(caseCodeFormData?.formData),
    handleCaseCodeModalOpen,
    handleCaseCodeModalClose: handleClose,
  };
};
