import React from 'react';

import { Button, CircularProgress, LinearProgress } from '@mui/material';
import classnames from 'classnames';

export interface WorkflowDialogFooterProps {
  onPrimaryButtonClick?: () => void;
  onBackButtonClick?: () => void;
  primaryButtonDisabled?: boolean;
  isLoading?: boolean;
  primaryButtonLabel?: string;
  primaryButtonId?: string;
  secondaryButtonLabel?: string;
  secondaryButtonId?: string;
  progress: number;
}

export const WorkflowDialogFooter = ({
  onPrimaryButtonClick,
  onBackButtonClick,
  primaryButtonDisabled,
  isLoading,
  primaryButtonLabel = 'Next',
  primaryButtonId,
  secondaryButtonLabel = 'Back',
  secondaryButtonId,
  progress,
}: WorkflowDialogFooterProps) => {
  const isPrimaryButtonDisabled = primaryButtonDisabled || isLoading;

  return (
    <div className="mt-6 h-24 flex flex-col justify-evenly">
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          backgroundColor: '#F5F5F5',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#2E3F4C',
          },
        }}
      />
      <div className="flex justify-between items-center px-8">
        {onBackButtonClick ? (
          <Button
            variant="outlined"
            className="text-[#2e3f4c] border-none bg-[#fff] hover:bg-[#0000000a] h-10 py-2 text-sm font-medium flex items-center"
            onClick={onBackButtonClick}
            size="medium"
            disabled={isLoading}
            id={secondaryButtonId}
          >
            <span className="self-baseline">{secondaryButtonLabel}</span>
          </Button>
        ) : (
          <div />
        )}

        <div className="flex gap-4">
          {onPrimaryButtonClick ? (
            <Button
              variant="outlined"
              className={classnames(
                'text-[#fff] border-[#2e3f4c] bg-[#2e3f4c] hover:bg-[#2e3f4cdd] h-10 py-2 text-sm font-medium flex items-center',
                {
                  'opacity-50': isPrimaryButtonDisabled,
                },
              )}
              onClick={onPrimaryButtonClick}
              size="medium"
              disabled={isPrimaryButtonDisabled}
              id={primaryButtonId}
            >
              <span className="self-baseline">{primaryButtonLabel}</span>
              {isLoading ? (
                <CircularProgress
                  className="text-[#ddd] ml-3"
                  size={20}
                />
              ) : null}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
