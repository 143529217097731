import React from 'react';
import { useSessionStorage } from 'usehooks-ts';
import Button from '@mui/material/Button';

import { HELIX_APPS, LOCAL_STORAGE_KEYS } from '@/constants';
import { CASE_CODE_INITIAL_STATE } from '@/hooks/queries/use-mutation-log-case-code';
import { addCaseCodeQueryParamsToUrl } from '@/Utils/url-utils';
import { IconCompetitorFinder } from '@/Components/Icons/IconCompetitorFinder';
import { Link } from '@/Components/Shared/Link';

interface CompetitorFinderTabProps {
  bainId: string;
}

const CompetitorFinderTab = ({ bainId }: CompetitorFinderTabProps) => {
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);

  return (
    <div className="flex flex-col items-center mt-2">
      <p className="font-normal  leading-6 text-[#484848] self-center mb-10">
        Explore our Competitive Finder tool to assess and analyze a group of peer companies. We&apos;re working to
        integrate this feature into Helix Find. Meanwhile, you can get a preview of similar companies on the next tab,
        to easily build a list of peers based on sector and revenue and our proprietary matching technology.
      </p>
      <IconCompetitorFinder className="w-[100px]" />
      <p className="mt-8 font-medium leading-8 text-[#484848] text-center text-2xl">Competitor Finder</p>
      <p className="font-normal mt-4 leading-6 text-[#484848] text-center">
        Launch our data-driven tool to finding bespoke company <br /> competitors (powered by AI & custom ML algorithms)
      </p>

      <Link href={`${addCaseCodeQueryParamsToUrl(HELIX_APPS.COMPETITOR_FINDER.link, caseCodeData)}&bain_id=${bainId}`}>
        <Button
          id="competitor-finder-button"
          className="bg-[#D63333] text-white text-xs mt-8 px-4 py-2.5"
        >
          Launch Competitor Finder
        </Button>
      </Link>
    </div>
  );
};

export default CompetitorFinderTab;
