import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { isNil } from 'lodash';
import { actions as selectedCompaniesActions } from '@/slices/selected-companies';
import { useMenu } from '@/hooks/use-menu';
import { CompaniesListMenu } from '@/Components/Shared/CompaniesList/CompaniesListMenu';
import { ShareCompaniesListLink } from '@/Components/Shared/CompaniesList/ShareCompaniesListLink';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { ICompaniesList } from '@/types';
import { IconHistory } from '@/Components/Icons/IconHistory';
import { MODALS, ROUTES } from '@/constants';
import { useModal } from '@/hooks/use-modal';
import { CompaniesListHistoryDialogModalData } from '@/Components/Dialogs/CompaniesListHistoryDialog';
import { checkIsSystemUser } from '@/pages/companies-list/check-is-system-user';
interface CompaniesListControlsProps {
  companiesList?: ICompaniesList;
  isLoading: boolean;
}

export const CompaniesListControls = ({ isLoading, companiesList }: CompaniesListControlsProps) => {
  const dispatch = useDispatch();
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const { handleOpen: handleCompaniesHistoryModalOpen } = useModal<CompaniesListHistoryDialogModalData>(
    MODALS.COMPANIES_LIST_HISTORY,
  );
  const { data: companiesLists } = useQueryCompaniesLists();
  const isSharedWithUserList =
    companiesLists?.find((list) => list.list_id === companiesList?.list_id)?.shared_with_me ??
    companiesLists?.every((list) => list.list_id !== companiesList?.list_id);

  const { id: idWithTitle, revision: revisionUrlParam } = useParams<{ id: string; revision?: string }>();

  const revision = isNaN(Number(revisionUrlParam)) ? undefined : Number(revisionUrlParam);

  const readOnlyMode = !isNil(revision);

  useEffect(() => {
    dispatch(selectedCompaniesActions.reset());
  }, [dispatch, revision]);

  const isSystemUser = checkIsSystemUser(companiesList);

  return (
    <div>
      <div className="flex items-center gap-5">
        {isLoading ? (
          <Skeleton
            width={200}
            height={30}
            className="bg-[#0000000f]"
            data-testid="skeleton"
          />
        ) : (
          <>
            <Button
              className="text-2xl text-[#666666] py-0 px-1"
              endIcon={<ArrowDropDownIcon fontSize="small" />}
              onClick={handleClick}
            >
              {companiesList?.title}
            </Button>
            <ShareCompaniesListLink
              listId={companiesList?.list_id}
              listTitle={companiesList?.title}
              shared={companiesList?.is_sharable_link}
              isSharedWithUserList={isSharedWithUserList}
            />
            {isSystemUser ? null : (
              <Button
                variant="outlined"
                startIcon={
                  <IconHistory
                    fill="currentColor"
                    className="mr-1"
                  />
                }
                className={'text-[#2E3F4C] border-0 pl-3 pr-2 h-full'}
                onClick={() =>
                  handleCompaniesHistoryModalOpen({
                    listId: companiesList?.list_id,
                    listIdWithTitle: idWithTitle,
                    revision,
                    is_sharable_link: companiesList?.is_sharable_link,
                  })
                }
                data-testid="companies-list-history-modal-trigger"
              >
                <span className={'w-[45px] flex justify-center font-medium'}>History</span>
              </Button>
            )}

            {revision ? (
              <Tooltip title="Redirect to current version">
                <IconButton
                  className="text-[#2E3F4C]"
                  size="medium"
                  component={Link}
                  to={`${ROUTES.COMPANIES_LIST}/${idWithTitle}`}
                >
                  <Typography>{`v. ${revision}`}</Typography>
                </IconButton>
              </Tooltip>
            ) : null}
          </>
        )}
        <CompaniesListMenu
          companiesList={companiesList}
          isOpen={isOpen}
          anchorEl={anchorEl}
          handleClose={handleClose}
          sharedWithUser={isSharedWithUserList}
          removeWithRedirect
          hideDelete={isSharedWithUserList || readOnlyMode}
          readOnlyMode={readOnlyMode}
        />
      </div>
      {isSystemUser && companiesList?.description ? (
        <p className="text-sm text-[#666666] pt-2 px-1">{companiesList.description}</p>
      ) : null}
    </div>
  );
};
