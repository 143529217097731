import React, { useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, ChartData, ChartOptions, Plugin } from 'chart.js';
import 'chart.js/auto';

import { isNaN } from 'lodash';
import { DownloadChartImageButton } from '../common/DownloadChartImageButton';
import { DownloadCsvButton } from '../../Buttons/DownloadCsvButton';
import { numberFormatterCustom } from '@/Components/Shared/common';
import { ChartConfigType, ChartDataType, Currency } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getYaxisLabel } from '@/Utils/charts';

interface IProps {
  config: ChartConfigType;
  data: ChartDataType[];
  handleDownloadXlsx: () => void;
  handleDownloadImage: (ref: React.RefObject<Chart>) => void;
}

const getOptions = (config: ChartConfigType, currency: Currency): ChartOptions<'bar'> => {
  const yAxes = config.METADATA?.Y_AXIS ?? [];

  const yAxesConfig = Object.assign(
    {},
    ...yAxes.map((axis, index) => {
      const firstAxis = index === 0;

      return {
        [`y-axis-${index}`]: {
          title: { display: true, text: axis.TITLE, font: { family: 'Graphik' } },
          position: axis['AXIS-POSITION'].toLowerCase() as 'left' | 'right',
          ticks: {
            callback: function (value: unknown) {
              const unit = getYaxisLabel({
                yAxisType: axis.FORMAT,
                currency,
              });

              return `${unit}${numberFormatterCustom(Number(value))}`;
            },
          },
          ...(firstAxis
            ? {
                border: {
                  dash: [2, 2],
                },
                beginAtZero: true,
                grid: {
                  display: true,
                  color: '#ddd',
                },
              }
            : {
                grid: {
                  display: false,
                },
              }),
        },
      };
    }),
  );

  return {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      ...yAxesConfig,
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        reverse: true,
      },
      tooltip: {
        filter: (tooltipItem) => {
          const { raw } = tooltipItem;

          if (!isNaN(Number(raw))) return (raw as number) > 0;

          return true;
        },
      },
    },
  };
};

const plugin: Plugin<'bar'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export const LineOverBarChart = ({ data, config, handleDownloadXlsx, handleDownloadImage }: IProps) => {
  const ref = useRef<Chart<'bar'>>(null);
  const currency = useShallowSelector((state) => state.config.currency);
  const options = useMemo(() => getOptions(config, currency), [config, currency]);
  const yAxes = config.METADATA?.Y_AXIS ?? [];
  const xAxis = config.METADATA?.X_AXIS ?? [];

  const chartData: ChartData<'bar'> = {
    labels: data.map((item) => item[xAxis[0]?.FIELD_NAME ?? '']),
    // @ts-ignore
    datasets: yAxes.map((yItem, idx) => {
      const chartType = yItem.CHART_TYPE?.toLowerCase();
      const isBar = chartType === 'bar';

      return {
        label: yItem.TITLE,
        type: chartType,
        data: data.map((item) => (item[yItem.FIELD_NAME] ? Number(item[yItem.FIELD_NAME]) : null)),
        ...(isBar && {
          type: chartType,
          backgroundColor: '#728a9d',
          skipNull: true,
        }),
        ...(!isBar && {
          borderColor: '#02c7e6',
          backgroundColor: '#02c7e6',
          pointRadius: 0,
          pointHoverBackgroundColor: '#0484e7',
          pointHoverBorderColor: '#fff',
          spanGaps: true,
        }),
        yAxisID: `y-axis-${idx}`,
        order: isBar ? 1 : 0,
      };
    }),
  };

  return (
    <div
      className="bg-white rounded"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      <div className="flex items-center justify-between px-6 py-3">
        <span className="text-[#484848] text-sm font-semibold">{config.TITLE}</span>

        <div className="flex items-center gap-4">
          <DownloadChartImageButton
            onClick={() => handleDownloadImage(ref)}
            dataTestId="chart-download-image"
            id={`${config.SLUG}-chart-download-image`}
          />

          <DownloadCsvButton
            onClick={handleDownloadXlsx}
            dataTestId="chart-download-xlsx"
            id={`${config.SLUG}-chart-download-xlsx`}
          />
        </div>
      </div>

      <hr className="text-[#ddd] mb-6" />

      <div className="h-[400px] px-6 pb-4">
        <Bar
          ref={ref}
          data={chartData}
          options={options}
          plugins={[plugin]}
        />
      </div>
    </div>
  );
};
