import React, { useLayoutEffect, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { useSessionStorage } from 'usehooks-ts';
import { Box, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getCurrentUser } from 'aws-amplify/auth';

import awsService from '../awsService';
import { actions as userActions } from '../slices/user';

import { readStorageValue, setStorageValue } from '@/Utils/local-storage-utils';
import {
  LOCAL_STORAGE_KEYS,
  HOME_PAGE_SLUG,
  CASE_CODE_FLOWS,
  CASE_CODE_FORM_STEPS,
  // HELIX_LANDING_PAGE_URL,
  // IS_PROD,
} from '@/constants';

const isQueryParamsCaseCodeValid = (queryParams) => {
  if (!queryParams) return false;

  const validFlow = Object.values(CASE_CODE_FLOWS).find(({ label }) => label === queryParams.flowType);
  const validStep = Object.values(CASE_CODE_FORM_STEPS).find((step) => step === queryParams.step);

  if (!validFlow || !validStep) return false;

  const availableCaseCodeFields = validFlow[validStep];

  if (!availableCaseCodeFields) return false;

  const requiredFields = availableCaseCodeFields.filter(({ validation }) => !!validation?.required);

  return requiredFields.every(({ key }) => !!queryParams?.[key]?.trim());
};

const removeQueryParams = (url) => {
  const hasUrlQueryParams = url.includes('?');

  if (!hasUrlQueryParams) return url;

  return url.split('?')?.[0];
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [, setCaseCode] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE);
  const isAuthenticatingRef = useRef(false);

  const postLogin = (email) => {
    const redirectUrl = readStorageValue(LOCAL_STORAGE_KEYS.REDIRECT_URL);

    const caseCodeFormData = readStorageValue(LOCAL_STORAGE_KEYS.CASE_CODE, window.sessionStorage);
    const isCaseCodeProvided = !isEmpty(caseCodeFormData);

    awsService.setAuthenticationStatus(true);

    const fallbackUrl = redirectUrl ? redirectUrl : HOME_PAGE_SLUG;

    history.push(isCaseCodeProvided && !!redirectUrl ? removeQueryParams(redirectUrl) : fallbackUrl);

    setStorageValue(LOCAL_STORAGE_KEYS.REDIRECT_URL, '');

    dispatch(userActions.setEmail(email.split('_')[1]));
    dispatch(userActions.setIsAuth(true));
  };

  const authenticate = () => {
    getCurrentUser()
      .then((user) => {
        if (!user) return;

        postLogin(user.username);
      })
      .catch(() => {
        awsService
          .authenticate()
          .then((user) => {
            if (!user) throw new Error();

            postLogin(user.username);
          })
          .catch(() => {
            // if (!IS_PROD) return;

            // if (isQueryParamsCaseCodeValidated) return;

            // if (!redirectUrl || redirectUrl === HOME_PAGE_SLUG) {
            //   window.location = HELIX_LANDING_PAGE_URL;
            // }
          });
      })
      .finally(() => isAuthenticatingRef.current = true);
  };

  useLayoutEffect(() => {
    const redirectUrl = readStorageValue(LOCAL_STORAGE_KEYS.REDIRECT_URL);
    const redirectUrlLocationSearch = redirectUrl?.split('?')?.[1];

    const urlLocationSearch =
      window.location.search || (redirectUrlLocationSearch ? `?${redirectUrlLocationSearch}` : '');

    const queryParams = Object.fromEntries(new URLSearchParams(urlLocationSearch));

    const isQueryParamsCaseCodeValidated = isQueryParamsCaseCodeValid(queryParams);

    if (isQueryParamsCaseCodeValidated) {
      const { flowType, step, ...rest } = queryParams;

      const caseCodeFormData = {
        flowType,
        step,
        formData: rest,
        shouldBeLogged: true,
      };

      setCaseCode(caseCodeFormData);
    }

    authenticate();
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      if (!isAuthenticatingRef.current) {
        authenticate();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{ display: 'flex' }}
      p={20}
      data-testid="loginComponent"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '100',
          width: 315,
          borderRadius: 2,
        }}
        px={3}
        py={5}
        mx="auto"
      >
        <Button
          id="login-button"
          disabled
          className={classnames('px-3 bg-[#ddd]')}
          data-testid="login-using-sso"
        >
          <span className="flex items-center relative">
            Login Using SSO
            <CircularProgress
              color="info"
              size={18}
              sx={{ ml: 1, position: 'absolute', left: '100%' }}
            />
          </span>
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
