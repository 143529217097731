import { http, HttpResponse } from 'msw';
import { times } from 'lodash';

export const MOCKED_WALMART_CAPIQ_ID = '1999';

export const companySearchController = [
  http.post(`${process.env.REACT_APP_API_URL}/search/autocomplete`, async ({ request }) => {
    const requestBody = await request.json();
    const companySearch = requestBody?.company_search;

    if (companySearch === MOCKED_WALMART_CAPIQ_ID) {
      return HttpResponse.json({
        data: [
          {
            bain_id: 1,
            self_firmo_name___: 'Walmart Inc.',
            self_sector_industry___: 'Lorem Ipsum',
            self_firmo_description___: 'Lorem Ipsum',
            id_matches: { self_ids_id__ciq_: MOCKED_WALMART_CAPIQ_ID },
          },
        ],
      });
    }

    return HttpResponse.json({
      data: times(3, (index) => ({
        self_firmo_name___: `${companySearch} ${index + 1}`,
        bain_id: 1 + index,
        self_sector_industry___: `Lorem ${index + 1}`,
        self_firmo_description___: 'Description',
      })),
    });
  }),
];
