import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions as similarCompaniesActions } from '@/slices/similar-companies';
import { actions as searchActions, QueryType } from '@/slices/search';

import { getSimilarCompaniesSearchInfoById } from '@/services/api/similar-companies';
import { SEARCH_EVENTS } from '@/constants';
import { useSearchCompanies } from '@/hooks/use-search-companies';

const POLLING_INTERVAL = 2000;

export const SIMILAR_COMPANIES_SEARCH_BY_ID_ERROR_MESSAGE =
  'Failed to fetch similar companies search data. Please try again or contact support.';

export const useQuerySimilarCompaniesSearchById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const searchCompanies = useSearchCompanies();
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const id = useShallowSelector((state) => state.similarCompaniesSearch.similarCompaniesSearchId);

  const onError = () => {
    dispatch(similarCompaniesActions.resetState());

    enqueueSnackbar(SIMILAR_COMPANIES_SEARCH_BY_ID_ERROR_MESSAGE, {
      variant: 'error',
    });
  };

  return useQuery(['similar-companies-search'], () => (id ? getSimilarCompaniesSearchInfoById(id) : undefined), {
    enabled: !!id && isAuth,
    refetchInterval: id ? POLLING_INTERVAL : false,
    onSuccess: (data) => {
      if (data && !isNaN(data.progress)) {
        dispatch(similarCompaniesActions.setSimilarCompaniesSearchProgress(data.progress));
      }

      if (data?.status === 'success') {
        dispatch(similarCompaniesActions.resetState());
        dispatch(searchActions.resetSearch());

        searchCompanies(
          {
            searchText: data?.generated_query,
            queryType: QueryType.KEYWORD,
            eventName: SEARCH_EVENTS.HISTORY,
            isUniq: false,
          },
          { concatPrevQuery: false },
        );
      }

      if (data?.status === 'failed') {
        onError();
      }
    },
    onError,
  });
};
