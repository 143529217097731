import React, { useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, ChartOptions, Plugin } from 'chart.js';
import 'chart.js/auto';

import { DownloadChartImageButton } from '../common/DownloadChartImageButton';
import { DownloadCsvButton } from '../../Buttons/DownloadCsvButton';
import { numberFormatterCustom } from '@/Components/Shared/common';
import { ChartConfigType, ChartDataType, DatumDatum } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getYaxisLabel } from '@/Utils/charts';

interface IProps {
  config: ChartConfigType;
  data: ChartDataType[];
  params?: { row: DatumDatum };
  section: string;
  handleDownloadXlsx: () => void;
  handleDownloadImage: (ref: React.RefObject<Chart>) => void;
}

const getOptions = (currency: string): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: '',
    },
    tooltip: {
      enabled: true,
      position: 'nearest',
      yAlign: 'bottom',
      xAlign: 'center',
      caretSize: 8,
      caretPadding: 18,
      displayColors: false,
      callbacks: {
        label: function (context) {
          if (context.parsed.y !== null) {
            return `${currency}${numberFormatterCustom(context.parsed.y)}`;
          }
        },
      },
    },
    // @ts-ignore
    customCanvasBackgroundColor: {
      color: 'white',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
        color: '#ddd',
      },
      border: {
        dash: [2, 2],
      },
      beginAtZero: true,
      ticks: {
        callback: function (value) {
          return `${currency}${numberFormatterCustom(Number(value))}`;
        },
      },
    },
  },
});

const plugin: Plugin<'bar'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, _, opts) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = opts.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export const BarChart = ({ config, data, handleDownloadXlsx, handleDownloadImage }: IProps) => {
  const ref = useRef<Chart<'bar'>>(null);
  const currency = useShallowSelector((state) => state.config.currency);
  const yAxisLabel = getYaxisLabel({ yAxisType: config.YAXIS_FORMAT, currency });
  const options = useMemo(() => getOptions(yAxisLabel), [yAxisLabel]);

  const chartData = useMemo(
    () => ({
      labels: data.map((item) => item[config.XAXIS].toString()) as string[],
      datasets: [
        {
          data: data.map((item) => item[config.YAXIS]) as number[],
          backgroundColor: '#506D85',
        },
      ],
    }),
    [config.XAXIS, config.YAXIS, data],
  );

  return (
    <div
      className="bg-white rounded"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      <div className="flex items-center justify-between px-6 py-3">
        <span className="text-[#484848] text-sm font-semibold">{config.TITLE}</span>

        <div className="flex items-center gap-4">
          {/* {section === CHART_SECTIONS.FINANCIALS && (
            <span className="text-sm text-[#666]">Source: {dataSource}</span>
          )} */}

          <DownloadChartImageButton
            onClick={() => handleDownloadImage(ref)}
            dataTestId="chart-download-image"
            id={`${config.SLUG}-chart-download-image`}
          />

          <DownloadCsvButton
            onClick={handleDownloadXlsx}
            dataTestId="chart-download-xlsx"
            id={`${config.SLUG}-chart-download-xlsx`}
          />
        </div>
      </div>

      <hr className="text-[#ddd]" />

      <div className="h-[400px] px-6 pb-4">
        <Bar
          ref={ref}
          data={chartData}
          options={options}
          plugins={[plugin]}
        />
      </div>
    </div>
  );
};
