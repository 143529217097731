import Typography from '@mui/material/Typography';
import React from 'react';
import { StarterPackWizardStepItemDescription } from '@/types';

export const WizardDialogDescription = ({ item }: { item: StarterPackWizardStepItemDescription }) => (
  <Typography
    variant="body2"
    gutterBottom
  >
    {item.description}
  </Typography>
);
