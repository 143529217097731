import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Tree } from 'antd';
import classnames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import { getHighlightedText } from '../get-highlighted-text';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/filters';
import { prepareFilterTree, getParentKey, filterNodes } from '@/Utils/filters';

import './index.scss';

// Sentinels
export const TREE_TOP_LEVEL_NODE_VALUE = 'All';
export const TREE_TOP_LEVEL_NODE_KEY = '[]';

const TreeCheckbox = ({ checked, halfChecked }) => {
  if (checked)
    return (
      <CheckBoxIcon
        className="mr-2 fill-[#0288d1]"
        data-testid="checkbox-checked"
      />
    );

  if (halfChecked)
    return (
      <IndeterminateCheckBoxIcon
        className="mr-2 fill-[#0288d1]"
        data-testid="checkbox-half-checked"
      />
    );

  return (
    <CheckBoxOutlineBlankIcon
      className="mr-2 fill-[#DDD]"
      data-testid="checkbox-not-checked"
    />
  );
};

export const FilterTree = ({ itemId, data, isFetching, searchValue, isFilterTitleHighlighted, withoutCounts }) => {
  const dispatch = useDispatch();
  const nodes = useMemo(
    () => prepareFilterTree({ data: data ?? [], Icon: TreeCheckbox, isFetching, searchValue, withoutCounts }),
    [data, isFetching, searchValue, withoutCounts],
  );

  const handleTreeChange = (checked, event) => {
    const fullyCheckedNodes = event.checkedNodes
      .filter((treeNode) => !treeNode.hasParentNodeChecked && !treeNode.halfChecked)
      .map((treeNode) => JSON.parse(treeNode.key))
      .toSorted((arr1, arr2) => arr1.length - arr2.length);

    const minifiedCheckedNodes = Array.from(
      fullyCheckedNodes.reduce((accumulator, key) => {
        for (let i = 0; i < key.length; i++) {
          if (accumulator.has(JSON.stringify(key.slice(0, i)))) {
            return accumulator;
          }
        }

        accumulator.add(JSON.stringify(key));

        return accumulator;
      }, new Set([])),
    );

    if (minifiedCheckedNodes.length) {
      dispatch(
        actions.setFilterTree({
          id: itemId,
          data: minifiedCheckedNodes,
        }),
      );
    } else {
      dispatch(actions.removeFilterTree({ filter: itemId }));
    }
  };

  const checkedKeys = useShallowSelector((state) => state.filters.treeFilters[itemId] || []);

  const checkedKeysWithoutHalfChecked = useMemo(
    () => checkedKeys.filter((checkedKey) => !JSON.parse(checkedKey).halfChecked),
    [checkedKeys],
  );

  const [expandedKeys, setExpandedKeys] = useState([nodes[0].key]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const dataFlatList = useMemo(() => {
    const dataList = [];

    const generateList = (nodesData) => {
      for (const node of nodesData) {
        dataList.push(node);

        if (node.children) {
          generateList(node.children);
        }
      }
    };

    generateList(nodes);

    return dataList;
  }, [nodes]);

  useEffect(() => {
    if (searchValue === '') return;

    const newExpandedKeys = dataFlatList
      .map((node) => {
        if (node.title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
          return getParentKey(node.key, nodes);
        }

        return null;
      })
      .filter((item, index, self) => item && self.indexOf(item) === index);

    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(true);
  }, [dataFlatList, nodes, searchValue]);

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const filteredTreeData = useMemo(() => {
    if (!searchValue || isFilterTitleHighlighted) return nodes;

    return filterNodes(searchValue, nodes) ?? [];
  }, [nodes, searchValue, isFilterTitleHighlighted]);

  const treeData = useMemo(() => {
    const loop = (nodesData) =>
      nodesData.map((node) => {
        const { text: title } = getHighlightedText({
          text: node.title,
          textToHighlight: searchValue,
          isFetching,
        });

        if (node.children) {
          return { ...node, title, children: loop(node.children) };
        }

        return {
          ...node,
          title,
        };
      });

    return loop(filteredTreeData);
  }, [isFetching, filteredTreeData, searchValue]);

  return (
    <Tree
      checkable
      showIcon
      treeData={treeData}
      selectable={false}
      checkedKeys={checkedKeysWithoutHalfChecked}
      onCheck={handleTreeChange}
      titleRender={(node) =>
        node.description ? (
          <Tooltip
            title={node.description}
            placement="top"
          >
            <span>{node.title}</span>
          </Tooltip>
        ) : (
          node.title
        )
      }
      switcherIcon={(props) => (
        <KeyboardArrowDownIcon
          className={classnames('fill-[#979797] text-xl mt-0.5 transition', {
            'rotate-180': props.expanded,
          })}
        />
      )}
      className="mt-2"
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
    />
  );
};
