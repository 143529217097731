import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { post } from 'aws-amplify/api';
import { useDispatch } from 'react-redux';
import { useSessionStorage } from 'usehooks-ts';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useShallowSelector } from '../use-shallow-selector';
import { actions as modalActions } from '@/slices/modal';

import { IS_DEV, LOCAL_STORAGE_KEYS, MODALS, CASE_CODE_FORM_STEPS } from '@/constants';
import { prepareCaseCodeData } from '@/Utils/case-code-utils';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';

export type TCaseCodeData = {
  formData: TFormData;
  onSuccess?: () => void;
  flowType: string;
  step: string;
  shouldBeLogged?: boolean;
};

export const CASE_CODE_INITIAL_STATE: TCaseCodeData = {
  formData: {},
  flowType: '',
  step: CASE_CODE_FORM_STEPS.FIRST,
};

export type TFormData = {
  caseCode?: string;
  partnerEmail?: string;
  projectType?: string;
  projectName?: string;
  otherInfo?: string;
  otherEmail?: string;
};

export type TFormDataApi = {
  case_code?: string;
  partner_email?: string;
  project_type?: string;
  project_name?: string;
  other_info?: string;
  other_email?: string;
};

export const useMutationLogCaseCode = () => {
  const dispatch = useDispatch();
  const email = useShallowSelector((state) => state.user.email);
  const { enqueueSnackbar } = useSnackbar();
  const [, setCaseCode] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);

  return useMutation({
    mutationKey: ['log-case-code'],
    mutationFn: ({ formData, flowType }: TCaseCodeData) => {
      const userData = { email, sql_query: true };

      const body = {
        case_data: { ...prepareCaseCodeData(formData), work_stream: flowType },
        ...userData,
      };

      return post({ apiName: 'CDPAPI', path: '/log_user_data', options: { body } }).response.then((res) =>
        res.body.json(),
      );
    },
    onSuccess: (_, { formData, onSuccess, flowType, step }) => {
      if (!isEmpty(formData) && !IS_DEV) {
        pendo.track('User Case Code registration', {
          workStream: flowType,
          step,
          caseCode: formData?.caseCode,
          partnerEmail: formData?.partnerEmail,
          projectType: formData?.projectType,
          projectName: formData?.projectName,
          otherInfo: formData?.otherInfo,
          otherEmail: formData?.otherEmail,
          module: 'HelixFindModule',
        });

        if (formData?.caseCode) {
          pendo.identify({
            account: {
              id: formData?.caseCode,
            },
          });
        }
      }

      setCaseCode({ formData, flowType, step });

      onSuccess?.();
      dispatch(modalActions.closeModal({ id: MODALS.CASE_CODE, data: {} }));
    },
    onError: () =>
      enqueueSnackbar(<NotificationMessage title="Failed to log case code. Please try again or contact support." />, {
        variant: 'error',
      }),
  });
};
