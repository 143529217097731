import React, { useState, useEffect, useCallback } from 'react';
import { union } from 'lodash';
import { useSnackbar } from 'notistack';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

import { Loader } from '../../Loader/Loader';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { useMutationUpdateCompaniesList } from '@/hooks/queries/companies-list/use-mutation-update-companies-list';
import { useModal } from '@/hooks/use-modal';
import { MODALS, MAX_COMPANIES_ON_THE_LIST } from '@/constants';
import { useQueryCompaniesListById } from '@/hooks/queries/companies-list/use-query-companies-list-by-id';

export const FIND_SIMILAR_BUTTON_TEXT = 'Find similar';

export const AddToListDropdown = ({
  isOpen,
  anchorEl,
  handleClose,
  selectedCompanies,
  companiesLists,
  isCompanyProfile,
}) => {
  const [stateCompaniesListIdForUpdate, setStateCompaniesListIdForUpdate] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { handleOpen: handleOpenNewCompaniesListDialog } = useModal(MODALS.CREATE_COMPANIES_LIST);
  const updateCompaniesListMutation = useMutationUpdateCompaniesList(handleClose);

  const continueCompaniesListUpdate = useCallback(
    (data) => {
      const currentListIds = data.pinned_company_ids ?? [];
      const pinned_company_ids = union(currentListIds, selectedCompanies);

      if (pinned_company_ids.length > MAX_COMPANIES_ON_THE_LIST) {
        enqueueSnackbar(
          <NotificationMessage
            title={`You can only add up to ${MAX_COMPANIES_ON_THE_LIST.toLocaleString(
              'en-US',
            )} companies to a list. Please narrow your selection. `}
          />,
          { variant: 'error' },
        );

        return;
      }

      updateCompaniesListMutation.mutate({
        id: data.list_id,
        fieldsToUpdate: { pinned_company_ids, pinned_company_ids_count: pinned_company_ids.length },
        showDefaultNotification: true,
        invalidateResponse: true,
      });
      setStateCompaniesListIdForUpdate('');
    },
    [enqueueSnackbar, selectedCompanies, updateCompaniesListMutation],
  );

  const listQueryUpdate = useQueryCompaniesListById(stateCompaniesListIdForUpdate, undefined, {
    enabled: false,
    onSuccess: (data) => continueCompaniesListUpdate(data),
  });

  const handleOpenDialogFromMenu = () => {
    handleOpenNewCompaniesListDialog(
      isCompanyProfile ? { companyProfileSelectedCompanies: selectedCompanies } : undefined,
    );
    handleClose();
  };

  useEffect(() => {
    if (stateCompaniesListIdForUpdate) listQueryUpdate.refetch();
  }, [listQueryUpdate, stateCompaniesListIdForUpdate]);

  return (
    <Menu
      className="p-0"
      classes={{
        list: 'p-0',
      }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
    >
      <MenuItem
        className="flex gap-2 font-medium text-sm py-0 px-4"
        onClick={handleOpenDialogFromMenu}
      >
        <AddIcon fontSize="small" />
        <span>Create new list</span>
      </MenuItem>

      {companiesLists.map(({ list_id, title, pinned_company_ids_count }) => (
        <MenuItem
          key={list_id}
          className="py-0 px-4 min-w-[174px]"
          onClick={() => setStateCompaniesListIdForUpdate(list_id)}
        >
          <Tooltip title={title?.length > 40 ? title : ''}>
            <span className="mr-2 flex gap-1 max-w-[300px]">
              <span className="truncate">{title}</span>
              <span>-</span>
              <span>{pinned_company_ids_count}</span>
            </span>
          </Tooltip>

          {stateCompaniesListIdForUpdate === list_id ||
          (updateCompaniesListMutation.isLoading && updateCompaniesListMutation.variables?.id === list_id) ? (
            <Loader />
          ) : (
            <div className="w-[20px]" />
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};
