import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GenericTable } from '../GenericTableTab/GenericTable';
import { ITransaction } from '@/types';
import { useQueryColumnMapperDataset } from '@/hooks/queries/column-mapper/use-query-column-mapper-dataset';
import { CommonCell } from '@/Components/Shared/ScreenerTable/CommonCell';

interface MnATableProps {
  tableData: ITransaction[];
  title: string;
}

const MnATable = ({ tableData, title }: MnATableProps) => {
  const columnMapper = useQueryColumnMapperDataset('master_mna');
  const rows: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }[] = [];

  const columns: GridColDef[] =
    columnMapper.data?.map((colMapper) => {
      return {
        field: colMapper['Backend Name'],
        headerName: colMapper['Display Name'],
        width: 200,
        headerClassName: 'pl-6',
        renderCell: (params: GridRenderCellParams) => (
          <CommonCell
            displayName={colMapper['Display Name']}
            filterType={colMapper['NUMERIC_TYPE'] ?? null}
            numericPrecision={colMapper['NUMERIC_PRECISION'] ?? 0}
            {...params}
          />
        ),
        sortable: colMapper['SORTABLE_COLUMN'],
      };
    }) ?? [];

  tableData.forEach((rowData, index) => {
    //@ts-ignore
    if (rowData['bain_id_buyer'] !== '') {
      if (!rows.includes(rowData)) {
        rows.push({
          id: index + 1,
          ...rowData,
        });
      }
    }
  });

  const sortColumn = columns.find((column) => column.sortable);

  return (
    <GenericTable
      tableName={title}
      columns={columns}
      rows={rows}
      sortModel={sortColumn ? [{ field: sortColumn.field, sort: 'desc' }] : undefined}
    />
  );
};

export default MnATable;
