import React from 'react';

export const IconLinkIsNotValid = () => (
  <svg
    width="600"
    height="446"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M552.759 108.338C484.66 13.576 340.805-24.864 199.328 16.536 124.533 38.423 52.417 82.278 16.678 167.194-47.21 318.991 86.93 367.471 174.642 382.922c42.413 7.471 82.359 20.022 118.862 37.109 65.672 30.741 181.916 57.981 269.571-70.071 63.533-92.812 35.045-178.501-10.316-241.622Z"
      fill="url(#a)"
    />
    <path
      d="m324.172 131.609-11.193 3.306 7.713 26.118 11.193-3.306-7.713-26.118Z"
      fill="#C00"
    />
    <path
      d="m261.274 142.947.24-15.964-9.845-6.136-10.704 13.094 20.309 9.006Z"
      fill="#FFB6B6"
    />
    <path
      opacity=".1"
      d="m261.274 142.947.638-15.566-10.243-6.534 3.48 19.263 6.125 2.837Z"
      fill="#000"
    />
    <path
      d="M256.002 128.574c9.67 0 17.508-7.838 17.508-17.508 0-9.669-7.838-17.508-17.508-17.508-9.669 0-17.507 7.839-17.507 17.508 0 9.67 7.838 17.508 17.507 17.508Z"
      fill="#FFB6B6"
    />
    <path
      d="m285.183 248.508-3.085 20.969-1.55 10.56-6.458 43.94a902.907 902.907 0 0 1-24.11-1.084l1.151-26.755.694-16.101.456-10.56.283-6.647-4.151-15.856s-1.367-2.54-2.757-5.925a64 64 0 0 1-.734-1.873c-1.722-4.607-3.09-10.093-1.372-12.961.083-.133.166-.261.261-.383.778-1.006 2.795-1.756 5.535-2.312 1.673-.345 3.624-.611 5.725-.817 6.358-.628 14.122-.723 20.064-.661 5.374.044 9.253.211 9.253.211l.056 1.773.005.172.039 1.345.095 3.212.239 7.937.244 8.064.117 3.752Z"
      fill="#2F2E41"
    />
    <path
      d="m285.066 244.756-8.653 17.357-5.475 7.364-7.842 10.56-11.965 16.101-18.975 25.538a830.397 830.397 0 0 1-26.805-2.529l23.226-39.11 6.269-10.56 6.053-10.187 3.846-15.934s0-.851.061-2.212c.028-.573.067-1.24.117-1.968.339-4.863 1.367-12.616 4.424-15.656a4.542 4.542 0 0 1 1.35-.962c.917-.411 2.445-.311 4.374.145 4.769 1.139 11.971 4.496 18.441 7.831a318.676 318.676 0 0 1 11.31 6.158l.244 8.064Z"
      fill="#2F2E41"
    />
    <path
      d="m243.215 130.564 19.89 9.546s21.083 62.453 21.083 65.636c0 3.182 5.967 5.171 2.386 8.751-3.58 3.58 6.664 6.612 1.144 9.472-5.519 2.86-39.951 18.502-43.78 17.091-10.56-3.89.447-18.186.447-18.186s-7.932-6.388-6.739-9.57c1.194-3.183-7.16-64.044-7.16-64.044l12.729-18.696Z"
      fill="#C00"
    />
    <path
      d="m257.476 115.135 4.394 2.459s-2.695-7.97-.728-11.486c1.967-3.515.82-6.003.82-6.003s7.016 2.31 8.864-.58c1.848-2.89 2.459-4.394-3.559-6.838-6.018-2.444-3.754-.716-3.754-.716s-5.139-1.952-4.007-1.088c1.132.865-4.261-1.46-3.501-.343.76 1.117-3.381-.969-2.622.148.76 1.118-9.594 2.71-7.464 3.44 2.13.73-8.73 1.578-8.404 10.069.327 8.492-5.587 10.9-1.223 13.369 4.364 2.468 10.455 13.031 10.455 13.031l5.137-3.492s-1.544-13.654 5.592-11.97Z"
      fill="#2F2E41"
    />
    <path
      d="M220.178 206.115c5.105-.661 8.901-3.831 8.48-7.079-.422-3.248-4.901-5.345-10.007-4.683-2.043.237-4 .959-5.707 2.107l-21.592 3.075 1.659 10.102 21.11-4.104c1.943.675 4.021.875 6.057.582Z"
      fill="#A0616A"
    />
    <path
      d="m154.168 161.787 5.923 44.089s1.629 9.482 16.458 8.086c14.829-1.396 34.316-7.147 34.316-7.147l-1.268-10.296-16.563 1.795s-7.731-1.876-9.266-.611c-1.535 1.265-.83 2.746-2.348.904-1.518-1.842-.756-2.986-2.085-1.375-1.329 1.611-1.599 4.411-2.395.943-.796-3.468-2.277-2.763-2.277-2.763l-.842-32.296s-4.449-15.405-12.576-10.255c-8.126 5.151-7.077 8.926-7.077 8.926Z"
      fill="#E6E6E6"
    />
    <path
      d="M180.126 126.611h-29.332v43.341h29.332v-43.341Z"
      fill="#2F2E41"
    />
    <path
      d="m157.379 156.737 1.027-12.414h13.658l3.028 13.152-17.713-.738Z"
      fill="#A0616A"
    />
    <path
      opacity=".1"
      d="m157.379 156.737 1.027-12.414h13.658l3.028 13.152-17.713-.738Z"
      fill="#000"
    />
    <path
      d="M182.122 135.735c1.321-8.139-4.206-15.808-12.345-17.129-8.139-1.32-15.807 4.207-17.128 12.346-1.321 8.139 4.206 15.807 12.345 17.128 8.139 1.321 15.808-4.206 17.128-12.345Z"
      fill="#A0616A"
    />
    <path
      d="M171.152 117.636s0-6.129-5.692-4.815c-5.691 1.313-7.442 3.502-7.442 3.502s-2.189 3.502-5.691 3.94c-3.503.438-6.567 10.507-6.129 12.696.437 2.189 2.626 7.88-.438 10.507-3.065 2.627-7.443 6.567-6.129 10.507 1.313 3.94 5.253 14.009 5.253 14.009s-10.989 1.606-10.091 8.465c0 0 5.276-4.087 12.28-4.087 7.005 0 6.129-12.708 6.129-12.708s.438-7.868 1.751-10.057c1.314-2.189-.437-14.447 6.129-21.014 6.567-6.567 10.07-10.945 10.07-10.945Z"
      fill="#2F2E41"
    />
    <path
      d="M169.4 116.323s8.318 1.751 11.383 10.069c3.065 8.318 1.517 9.866 1.517 9.866s.677 5.009 4.396 6.765c3.718 1.756.654 9.199.654 9.199s.875 7.442 12.696 10.069c11.82 2.627-14.823 22.77-14.823 22.77s1.915-9.507-10.343-9.945c-12.258-.438 2.401-22.894 2.401-22.894l1.313-14.447s-2.627-16.636-10.507-18.825 1.313-2.627 1.313-2.627Zm21.195 132.585-3.023 20.569-1.551 10.56-2.629 17.863-2.662 18.101a658.171 658.171 0 0 1-9.337-1.417 629.105 629.105 0 0 1-15.506-2.657l.984-22.97.383-8.92.456-10.56.267-6.252-4.158-15.857s-8.575-15.967-4.596-21.136c.184-.233.428-.456.739-.661 1.012-.684 2.679-1.229 4.775-1.651 4.952-1.011 12.288-1.378 18.974-1.473 8.581-.122 16.084.206 16.084.206l.044 1.306.084 2.835.672 22.114Z"
      fill="#2F2E41"
    />
    <path
      d="m190.595 248.908-3.023 20.569-1.551 10.56-2.629 17.863-11.999 16.684a629.105 629.105 0 0 1-15.506-2.657 573.448 573.448 0 0 1-10.965-2.128l11.949-20.842 5.113-8.92 6.047-10.56 2.906-5.068 2.529-4.413 1.851-8.226 1.423-6.308.328-1.456s-.034-2.29.183-5.363c.334-4.658 1.251-11.127 3.774-14.167.489-.6 1.045-1.062 1.673-1.356 1.311-.617 3.901-.195 7.141.839l.084 2.835.672 22.114Z"
      fill="#2F2E41"
    />
    <path
      d="m176.074 156.818-.365-.103-17.934-5.069-14.32 19.492 9.547 40.574-3.58 16.31s29.038-7.558 40.574-1.194c0 0 .796-3.627 0-5.393-.795-1.767-1.989-9.118-.795-12.404 1.193-3.285-2.387-18.799-2.387-18.799s1.193-10.343-3.978-15.514c-5.171-5.171-6.762-17.9-6.762-17.9Z"
      fill="#E6E6E6"
    />
    <path
      d="M171.096 265.056c1.286 4.983 4.9 8.36 8.071 7.541 3.172-.819 4.7-5.523 3.413-10.508a12.889 12.889 0 0 0-2.795-5.404l-5.716-21.048-9.82 2.893 6.677 20.442a12.881 12.881 0 0 0 .17 6.084Z"
      fill="#A0616A"
    />
    <path
      d="M173.7 174.733s-5.562-15.031-11.658-14.053c-6.096.979-11.654 10.427-11.654 10.427l4.407 53.47 12.104 30.513 12.84-5.062-6.669-30.495.63-44.8Z"
      fill="#E6E6E6"
    />
    <path
      opacity=".7"
      d="M451.794 81.902v230.442a8.381 8.381 0 0 1-.556.1 550.213 550.213 0 0 1-8.564 1.479c-3.435.567-6.92 1.117-10.449 1.645-2.935.433-5.897.856-8.892 1.261a750.024 750.024 0 0 1-26.867 3.179c-2.94.301-5.908.584-8.892.851-26.094 2.345-53.827 3.612-82.583 3.612-4.074 0-8.12-.027-12.149-.077-.189 0-.373 0-.556-.006V81.902h159.508Z"
      fill="#fff"
    />
    <path
      d="M451.238 157.927v.684l.072-.145a3.001 3.001 0 0 0-.072-.539Zm-158.952 72.607-.556-.45v49.953h.556v44.351c.183.005.367.005.556.005v-127.55c-.184.1-.367.194-.556.289v33.402Zm-.556-149.187v243.03c.183.005.367.011.556.011.183.005.367.005.556.005V82.458h158.396v229.986c.189-.027.372-.061.556-.1.183-.028.372-.061.556-.1V81.347H291.73Z"
      fill="#3F3D56"
    />
    <path
      d="M305.9 256.141c5.832 0 10.56-4.728 10.56-10.56 0-5.832-4.728-10.559-10.56-10.559-5.832 0-10.559 4.727-10.559 10.559 0 5.832 4.727 10.56 10.559 10.56Z"
      fill="#3F3D56"
    />
    <path
      d="M304.533 242.655c3.791 3.482 8.66 4.349 10.876 1.936 2.215-2.413.938-7.19-2.855-10.673a12.875 12.875 0 0 0-5.285-3.013l-16.247-14.55-6.665 7.77 16.724 13.519a12.888 12.888 0 0 0 3.452 5.011Z"
      fill="#FFB6B6"
    />
    <path
      d="M257.574 165.455s-12.845-9.585-17.427-5.447c-4.582 4.138-4.105 15.09-4.105 15.09l32.79 42.464 26.758 19.015 8.019-11.233-22.188-21.957-23.847-37.932Z"
      fill="#C00"
    />
    <path
      d="M396.466 261.141v58.867c-2.94.301-5.908.584-8.892.851v-59.718h8.892Zm-324.574 0v26.732A175.065 175.065 0 0 1 63 283.65v-22.509h8.892Z"
      fill="#3F3D56"
    />
    <path
      d="M393.129 269.478H69.111v10.56h324.018v-10.56Z"
      fill="#C00"
    />
    <path
      d="M464.928 113.4s-5.928 3.477-3.984 6.915 4.655 8.122 2.816 9.597c-1.839 1.475-4.875 7.978-4.875 7.978s-12.958-1.81-11.741 8.255c1.216 10.066 7.304 11.438 2.086 15.867-5.218 4.428 5.423 9.724 5.423 9.724s7.345-2.289 9.021-9.329c1.676-7.039-1.494 5.383-.43 6.273 1.063.891 5.191 6.762 8.111-1.044 2.921-7.805.029-10.832 3.889-15.438 3.859-4.607 6.483-12.058 6.368-13.361-.115-1.302 4.415-13.188-2.557-17.823-6.973-4.635-14.127-7.614-14.127-7.614Z"
      fill="#2F2E41"
    />
    <path
      d="M414.608 223.169c-2.972 3.349-7.109 4.53-9.24 2.638-2.13-1.892-1.448-6.139 1.525-9.488a11.205 11.205 0 0 1 4.307-3.076l12.771-14.029 6.456 6.134-13.276 13.179a11.21 11.21 0 0 1-2.543 4.642Z"
      fill="#FFB6B6"
    />
    <path
      d="M451.954 157.162s7.746-7.115 11.013-4.72c3.268 2.396 3.688 9.577 3.688 9.577l-18.571 29.913-26.784 22.576-5.985-6.798 23.604-24.191 13.035-26.357Z"
      fill="#3F3D56"
    />
    <path
      d="m467.651 141.041-4.196 14.914h23.03l-7.584-18.377-11.25 3.463Z"
      fill="#FFB6B6"
    />
    <path
      opacity=".1"
      d="m467.651 141.041-4.196 14.914h23.03l-7.584-18.377-11.25 3.463Z"
      fill="#000"
    />
    <path
      d="m479.329 190.258 13.504 31.901-45.7-2.257 3.106-29.644h29.09Z"
      fill="#FFB6B6"
    />
    <path
      d="m466.119 148.319 19.055-.794 12.704 22.231-11.116 35.729-38.905 3.97 4.213-34.457 2.139-19.534 11.91-7.145Z"
      fill="#3F3D56"
    />
    <path
      d="M497.879 252.087c-.917 4.441-1.912 10.526-2.885 17.123a864.164 864.164 0 0 0-1.483 10.56 1580.293 1580.293 0 0 0-3.063 24.177 485.711 485.711 0 0 1-18.841 4.474l-3.362-28.651-1.24-10.56-1.283-10.926-.578 10.926-.562 10.56-1.611 30.451a549.825 549.825 0 0 1-10.621 2.023 9.146 9.146 0 0 1-.556.1 8.381 8.381 0 0 1-.556.1 550.213 550.213 0 0 1-8.564 1.479l-.478-34.153-.15-10.56-.54-38.315 4.697-15.517 1.417-.128 3.618-.328.556-.05.556-.055 35.992-3.268 1.2-.112s.856 1.673 2.034 4.458c.1.233.206.478.312.728a104.27 104.27 0 0 1 3.334 9.203c1.462 4.713 2.746 10.138 3.174 15.534.289 3.663.189 7.309-.517 10.727Z"
      fill="#2F2E41"
    />
    <path
      d="M499.284 237.601c-.334 4.464-2.91 7.911-5.751 7.697-2.841-.213-4.872-4.005-4.537-8.472a11.21 11.21 0 0 1 1.562-5.056l1.658-18.899 8.852.967-2.574 18.528c.678 1.657.949 3.452.79 5.235Z"
      fill="#FFB6B6"
    />
    <path
      d="M488.991 162.463s1.849-10.354 5.9-10.429c4.051-.075 8.737 5.383 8.737 5.383l3.355 35.049-7.624 34.189-8.881-1.78 4.116-33.547-5.603-28.865Z"
      fill="#3F3D56"
    />
    <path
      d="M473.122 144.282c7.66 0 13.869-6.21 13.869-13.869 0-7.66-6.209-13.869-13.869-13.869-7.659 0-13.868 6.209-13.868 13.869 0 7.659 6.209 13.869 13.868 13.869Z"
      fill="#FFB6B6"
    />
    <path
      d="M467.806 114.926s-2.311 6.472 1.387 7.859c3.698 1.387 8.784 3.236 8.321 5.547-.462 2.312 1.387 9.246 1.387 9.246s-11.095 6.935-3.698 13.869c7.397 6.935 12.944 4.077 11.788 10.822-1.155 6.745 10.402 3.972 10.402 3.972s4.161-6.473.925-12.945c-3.236-6.472 2.311 5.086 3.698 5.086s8.321 1.849 5.548-6.01c-2.774-7.859-6.935-8.322-6.935-14.331 0-6.01-2.774-13.407-3.698-14.332-.925-.924-5.085-12.944-13.407-12.019-8.321.924-15.718 3.236-15.718 3.236Z"
      fill="#2F2E41"
    />
    <path
      d="m405.707 203.913-11.194 3.305 7.714 26.118 11.193-3.305-7.713-26.118ZM222.3 181.682l-11.193 3.305 7.713 26.118 11.194-3.305-7.714-26.118Z"
      fill="#C00"
    />
    <path
      d="M432.225 260.874v54.694c-2.935.433-5.897.856-8.892 1.261v-55.955h8.892Z"
      fill="#3F3D56"
    />
    <path
      d="M543 269.209H429.447v10.56H543v-10.56Z"
      fill="#C00"
    />
    <path
      d="M314.382 149.35c3.19-4.04 7.859-5.669 10.43-3.639 2.571 2.03 2.069 6.95-1.122 10.991a12.889 12.889 0 0 1-4.739 3.815l-13.73 16.946-7.815-6.612 14.364-16.004a12.89 12.89 0 0 1 2.612-5.497Z"
      fill="#FFB6B6"
    />
    <path
      d="m263.789 142.099 20.032 34.043 24.251-16.751 9.754 3.891s-17.233 37.508-32.093 35.069c-14.859-2.44-44.426-36.004-41.032-47.725 3.394-11.721 19.088-8.527 19.088-8.527Z"
      fill="#C00"
    />
    <defs>
      <linearGradient
        id="a"
        x1="300"
        y1="0"
        x2="300"
        y2="445.678"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#FCE3E3"
          stopOpacity=".9"
        />
        <stop
          offset="1"
          stopColor="#FCE3E3"
          stopOpacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
);
