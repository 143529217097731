import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import { useSessionStorage } from 'usehooks-ts';
import classNames from 'classnames';

import mainLogo from '../../assets/helix_find_logo.svg';
import { CardIconList } from '../Shared/Card/CardIcons/CardIconList';
import { CardIconSearch } from '../Shared/Card/CardIcons/CardIconSearch';
import { createCompaniesListPath } from '../Shared/CompaniesList/ShareCompaniesListLink';
import { createSavedSearchPath } from '../Shared/SavedSearch/ShareSavedSearchButton';
import { ProfileDropdown } from './ProfileDropdown';
import { HelixAppsDropdown } from './HelixAppsDropdown';
import { HeaderSearch } from './HeaderSearch';
import { ROUTES, HEADER_HEIGHT, HOME_PAGE_SLUG, LOCAL_STORAGE_KEYS, HELIX_LANDING_PAGE_URL } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useTableLoading } from '@/hooks/table/use-table-loading';
import { addCaseCodeQueryParamsToUrl } from '@/Utils/url-utils';
import { CASE_CODE_INITIAL_STATE } from '@/hooks/queries/use-mutation-log-case-code';

const shouldCurrencyShown = (path: string) =>
  path.includes(ROUTES.CUSTOM_SEARCH) ||
  path.includes(ROUTES.CUSTOM_SEARCH.toLowerCase()) ||
  path.includes(ROUTES.COMPANIES_LIST) ||
  path.includes(ROUTES.SAVED_SEARCH) ||
  path.includes(ROUTES.COMPANY_PROFILE);

const Header = () => {
  const location = useLocation();
  const isLoading = useTableLoading();
  const { currentCompaniesList, currentSavedSearch } = useShallowSelector((state) => state.navigationTabs);
  const [caseCodeData] = useSessionStorage(LOCAL_STORAGE_KEYS.CASE_CODE, CASE_CODE_INITIAL_STATE);
  const isLoginPage = location.pathname === ROUTES.ROOT;
  const isCompanyProfilePage = location.pathname.startsWith(ROUTES.COMPANY_PROFILE);

  const handleLogoClick = () => {
    //@ts-ignore
    window.location = addCaseCodeQueryParamsToUrl(HELIX_LANDING_PAGE_URL, caseCodeData);
  };

  const isHomePageTabVisible = !isLoginPage;
  const isHomeSearch = location.pathname === ROUTES.SEARCH_LISTS;
  const isCompanySearchTabVisible = !isLoginPage && !isCompanyProfilePage;
  const isSavedSearchTabVisible = !isLoginPage && !!currentSavedSearch && !isCompanyProfilePage;
  const isCompaniesListTabVisible = !isLoginPage && !!currentCompaniesList && !isCompanyProfilePage;
  const isSavedSearchTabActive = location.pathname.startsWith(`${ROUTES.SAVED_SEARCH}/`);
  const isCompaniesListTabActive = location.pathname.startsWith(`${ROUTES.COMPANIES_LIST}/`);

  return (
    <div
      data-testid="headerComponent"
      style={{
        height: HEADER_HEIGHT,
      }}
    >
      <Box className="flex items-center">
        <Box className="flex w-full">
          <div className="w-full flex justify-between h-12">
            <div className="flex">
              <button
                className="flex items-center justify-center cursor-pointer border-r border-b border-b-[#dddddd] border-r-[#dddddd] w-[54px]"
                onClick={handleLogoClick}
                data-testid="helix-find-logo"
              >
                <img
                  src={mainLogo}
                  style={{ width: 24, height: 24 }}
                  alt="logo"
                />
              </button>

              {isHomePageTabVisible ? (
                <div className="w-[54px] border-r border-r-[#dddddd] ">
                  <NavLink
                    to={HOME_PAGE_SLUG}
                    className="w-full h-full flex items-center justify-center border-b border-b-[#f8f9fa] bg-[#f8f9fa] [&:not(.active)]:border-b-[#dddddd] [&:not(.active)]:bg-[white]"
                  >
                    <HomeIcon
                      fontSize="medium"
                      className="fill-[#666]"
                    />
                  </NavLink>
                </div>
              ) : null}

              {isCompanySearchTabVisible ? (
                <div
                  className={classNames('border-r border-r-[#dddddd] max-w-[320px]', {
                    'cursor-wait': isLoading,
                  })}
                >
                  <NavLink
                    to={ROUTES.CUSTOM_SEARCH}
                    className={classNames(
                      'w-[54px] h-full flex items-center justify-center border-b border-b-[white] [&:not(.active)]:border-b-[#dddddd]',
                      {
                        'pointer-events-none': isLoading,
                      },
                    )}
                  >
                    <SearchIcon
                      fontSize="medium"
                      className="fill-[#666]"
                    />
                  </NavLink>
                </div>
              ) : null}

              {isSavedSearchTabVisible ? (
                <div
                  className={classNames('border-r border-r-[#dddddd] max-w-[320px]', {
                    'cursor-wait': isLoading,
                  })}
                >
                  <NavLink
                    to={createSavedSearchPath(currentSavedSearch.title, currentSavedSearch.list_id)}
                    className={classNames(
                      'w-full h-full grid grid-cols-[40px_1fr] items-center px-4 border-b border-b-[white] [&:not(.active)]:border-b-[#dddddd]',
                      {
                        active: isSavedSearchTabActive,
                        'pointer-events-none': isLoading,
                      },
                    )}
                  >
                    <CardIconSearch active={isSavedSearchTabActive} />
                    <span className="text-sm font-semibold text-[#666] truncate">{currentSavedSearch.title}</span>
                  </NavLink>
                </div>
              ) : null}

              {isCompaniesListTabVisible ? (
                <div
                  className={classNames('border-r border-r-[#dddddd] max-w-[320px]', {
                    'cursor-wait': isLoading,
                  })}
                >
                  <NavLink
                    to={createCompaniesListPath(currentCompaniesList.title, currentCompaniesList.list_id)}
                    className={classNames(
                      'w-full h-full grid grid-cols-[40px_1fr] bg-[#fafafa] items-center px-4 border-b border-b-[#fafafa] [&:not(.active)]:border-b-[#dddddd]',
                      {
                        active: isCompaniesListTabActive,
                        'pointer-events-none': isLoading,
                      },
                    )}
                  >
                    <CardIconList active={isCompaniesListTabActive} />
                    <span className="text-sm font-semibold text-[#666] truncate">{currentCompaniesList.title}</span>
                  </NavLink>
                </div>
              ) : null}
            </div>

            <div className="pr-6 m-0 grow gap-4 flex items-center justify-end border-b border-b-[#dddddd]">
              {isHomeSearch && <HeaderSearch />}

              <HelixAppsDropdown />

              <span className="text-[#2E3F4C] font-normal text-sm cursor-pointer">What’s new?</span>
              <span
                className="cursor-pointer w-6 h-6 block"
                id="help-center-placeholder"
              ></span>

              {shouldCurrencyShown(location.pathname) && <ProfileDropdown />}
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Header;
