import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useTableLoading } from '@/hooks/table/use-table-loading';

interface IProps {
  headerName: string;
}

export const CommonHeader = ({ headerName }: IProps) => {
  const isLoading = useTableLoading();

  return isLoading ? (
    <Skeleton className="w-full h-full bg-[#0000000f]" />
  ) : (
    <span className="ml-4 w-full">{headerName}</span>
  );
};
