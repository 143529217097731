import { difference } from 'lodash';

import { useDispatch } from 'react-redux';
import { useMutationSimplifyCustomAggregationString } from './use-mutation-simplify-custom-aggregation-string';
import {
  ELEMENT_ROLES,
  checkIfAggregationIsCustom,
  useFiltersToDisplay,
} from '@/Components/CompanyResearch/SavedFilters/use-filters-to-display';
import { actions as filtersActions } from '@/slices/filters';

export const useSimplifyCustomAggregationString = () => {
  const dispatch = useDispatch();

  const { mutate: simplifyCustomAggregationStringMutate, isLoading: isSimplifying } =
    useMutationSimplifyCustomAggregationString();

  const { getFiltersAggregation, filtersToDisplay } = useFiltersToDisplay();

  const simplifyCustomAggregationString = ({
    customAggregationStringToSimplify,
    onSuccess,
    onError,
  }: {
    customAggregationStringToSimplify: string;
    onSuccess: () => void;
    onError?: () => void;
  }) => {
    simplifyCustomAggregationStringMutate({
      customAggregationStringToSimplify,
      onSuccess: (newCustomAggregationString) => {
        const newFiltersAggregation = getFiltersAggregation(newCustomAggregationString) || [];

        const filtersInCurrentAggregation = newFiltersAggregation
          .filter((item) => item.role === ELEMENT_ROLES.filter)
          .map((item) => item.backendName);

        const filtersInInitialAggregation = filtersToDisplay.map((item) => item.backendName);

        const removedFilters = difference(filtersInInitialAggregation, filtersInCurrentAggregation);

        removedFilters.forEach((item) => {
          dispatch(filtersActions.removeFilter({ filter: item }));
        });

        dispatch(
          filtersActions.setCustomAggregationString(
            checkIfAggregationIsCustom(newFiltersAggregation) ? newCustomAggregationString : undefined,
          ),
        );

        onSuccess();
      },
      onError,
    });
  };

  return { simplifyCustomAggregationString, isSimplifying };
};
