import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CreateUpdateSharedElementDialog } from '../Shared/CreateUpdateSharedElementDialog';
import { useModal } from '@/hooks/use-modal';
import { useMutationUpdateSavedSearch } from '@/hooks/queries/saved-search/use-mutation-update-saved-search';
import { MODALS } from '@/constants';

export const UpdateSavedSearchDialog = () => {
  const { isOpen, handleClose: handleCloseModal, data } = useModal(MODALS.UPDATE_SAVED_SEARCH);
  const savedSearchData = data?.savedSearch;
  const { register, handleSubmit, reset, formState } = useForm();
  const isSharedWithUserList = data?.sharedWithUser;

  const handleClose = () => {
    handleCloseModal();
    data?.handleCloseMenu();
  };

  const mutation = useMutationUpdateSavedSearch(handleClose);

  useEffect(() => {
    reset();
  }, [savedSearchData, reset]);

  const onSubmit = (formData) => {
    mutation.mutate({
      id: savedSearchData?.list_id,
      fieldsToUpdate: formData,
      showDefaultNotification: true,
      invalidateResponse: true,
    });
  };

  return (
    <CreateUpdateSharedElementDialog
      title="View details"
      buttonLabel="Done"
      register={register}
      formState={formState}
      isOpen={isOpen}
      handleClose={handleClose}
      handleButtonClick={handleSubmit(onSubmit)}
      isLoading={mutation.isLoading}
      disabled={mutation.isLoading || isSharedWithUserList}
      elementData={savedSearchData}
    />
  );
};
