import React from 'react';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { MODALS } from '@/constants';

import { useModal } from '@/hooks/use-modal';

export const AdvancedSearchButton = () => {
  const { handleOpen: openAdvancedSearchDialog } = useModal(MODALS.ADVANCED_SEARCH);
  const customAggregationString = useShallowSelector((state) => state.filters.customAggregationString);

  const isAdvancedMode = !!customAggregationString;

  return (
    <Button
      className={classnames(
        'px-3 py-2 border-bluegray-900 border border-solid hover:bg-bluegray-900 hover:text-white font-graphik text-xs h-[36px]',
        {
          'bg-[#fff] text-bluegray-900': !isAdvancedMode,
          'bg-bluegray-900 text-white': isAdvancedMode,
        },
      )}
      onClick={() => openAdvancedSearchDialog()}
      data-testid="advanced-button"
    >
      Advanced
    </Button>
  );
};
