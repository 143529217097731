import React from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import { SimilarCompanyCardTitle } from './SimilarCompanyCardTitle';
import { SimilarCompanyType } from '@/types';
import { IconSimilarCompany } from '@/Components/Icons/IconSimilarCompany';
import { AddToListDropdown } from '@/Components/Shared/CompaniesList/SelectCompaniesControls/AddToListDropdown';
import { useMenu } from '@/hooks/use-menu';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { useModal } from '@/hooks/use-modal';
import { MODALS } from '@/constants';
import { createCompanyProfileLink } from '@/pages/company-profile';

interface IProps {
  item: SimilarCompanyType;
}

export const SimilarCompanyCard = ({ item }: IProps) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const { handleOpen: handleOpenNewCompaniesListDialog } = useModal(MODALS.CREATE_COMPANIES_LIST);
  const query = useQueryCompaniesLists();
  const companiesLists = query.data ?? [];

  const stringBainId = item.bain_id.toString();

  const handleOpenDialog = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    companiesLists.length > 0
      ? handleClick(event)
      : handleOpenNewCompaniesListDialog({ companyProfileSelectedCompanies: [stringBainId] });
  };

  const handleOpenCompanyProfile = () => {
    window.open(createCompanyProfileLink(stringBainId), '_blank');
  };

  return (
    <div className="group flex flex-col bg-white p-4 rounded border border-solid border-[#ddd] relative">
      <button
        className="flex items-center justify-start gap-4 mr-6 group/title"
        onClick={handleOpenCompanyProfile}
      >
        <span className="border border-[#ddd] p-1 rounded">
          {item?.self_logourl ? (
            <div
              className="w-[40px] h-[40px] bg-no-repeat bg-contain bg-center"
              style={{ backgroundImage: `url(${item.self_logourl})` }}
            />
          ) : (
            <div className="w-[40px] h-[40px] flex items-center justify-center">
              <IconSimilarCompany />
            </div>
          )}
        </span>

        <SimilarCompanyCardTitle label={item.self_firmo_name___} />
      </button>

      <IconButton
        className="absolute top-0.5 right-0.5 opacity-0 group-hover:opacity-100 transition-opacity duration-100"
        size="small"
        onClick={handleOpenDialog}
      >
        <AddIcon fontSize="small" />
      </IconButton>

      <div className="flex items-center gap-2 text-[#666] text-sm mt-3">
        {item.self_firmo_webpage___ && (
          <>
            <a
              href={`${'https://' + item.self_firmo_webpage___}`}
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {item.self_firmo_webpage___}
            </a>

            <Divider
              className="text-[#ddd]"
              orientation="vertical"
              variant="middle"
            />
          </>
        )}

        {item.self_location_country___ && (
          <>
            <span>{item.self_location_country___}</span>

            <Divider
              className="text-[#ddd]"
              orientation="vertical"
              variant="middle"
            />
          </>
        )}

        <span>{item.self_hierarchy_parentSubsStandalone___}</span>
      </div>

      <AddToListDropdown
        isOpen={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedCompanies={[item.bain_id.toString()]}
        companiesLists={companiesLists}
        isCompanyProfile
      />
    </div>
  );
};
