import React, { FC } from 'react';
import { capitalize } from 'lodash';

import { SkeletonLoader } from '../../common/SkeletonLoader';
import { CompanyProfileWidget } from '../../common/CompanyProfileWidget/CompanyProfileWidget';
import { OverviewCards } from './OverviewCards';
import { OverviewChart } from './OverviewChart';
import { SubsidiariesTable } from './SubsidiariesTable';
import { useOverviewTabData } from './use-overview-tab-data';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { formatCompanyDescription } from '@/Utils/text';
import { useQueryCompanySubsidiaries } from '@/hooks/queries/company-profile/use-query-company-subsidiaries';
import { ChartConfigType, DatumDatum } from '@/types';
import { getConfidenceLevelData, getShouldIconShowed } from '@/Components/Shared/ScreenerTable/CommonCell/utils';
import { useQueriesChartData } from '@/hooks/queries';

interface ConfidenceScoreProps {
  showConfidenceScore: boolean;
  params: { row: DatumDatum };
  field: string;
}

export interface OverviewTabProps {
  params?: {
    row: DatumDatum;
  };
  bainId: string;
  isCompanyProfileLoading: boolean;
  showConfidenceScore: boolean;
  chartConfigs: ChartConfigType[];
  handlePushToFinancials: () => void;
}

const ConfidenceScore = ({ params, showConfidenceScore, field }: ConfidenceScoreProps) => {
  if (!showConfidenceScore) return null;

  //@ts-ignore
  const returnValue = params.row[field];
  //@ts-ignore
  const resultConfidence = params.row[`${field}confidence`];
  const confidenceLevelData = getConfidenceLevelData(resultConfidence as number);
  const IconComponent = confidenceLevelData?.Icon;

  const shouldIconShowed = getShouldIconShowed({
    isConfidenceLevelChecked: showConfidenceScore,
    IconComponent,
    returnValue,
  });

  const Icon = shouldIconShowed ? (
    <IconComponent
      className="w-[15px] left-[5px]"
      style={{ fill: confidenceLevelData?.color }}
    />
  ) : null;

  if (!Icon) return null;

  return <span>{Icon}</span>;
};

const prepareChartConfig = (configs: ChartConfigType[]) => {
  return configs.map((config) => {
    const isLineOverBar = config.CHART_TYPE === 'LineOverBar';

    return {
      title: config.TITLE,
      slug: config.SLUG,
      yAxis: isLineOverBar
        ? config.METADATA?.Y_AXIS.map((item) => ({
            chartType: item.CHART_TYPE,
            field: item.FIELD_NAME,
            label: item.TITLE,
            yAxisType: item.FORMAT,
          })) ?? []
        : [{ chartType: config.CHART_TYPE, field: config.YAXIS, label: config.TITLE, yAxisType: config.YAXIS_FORMAT }],
      xAxis: isLineOverBar
        ? config.METADATA?.X_AXIS.map((item) => ({ field: item.FIELD_NAME })) ?? []
        : [{ field: config.XAXIS }],
    };
  });
};

export const OverviewTab: FC<OverviewTabProps> = ({
  params,
  bainId,
  chartConfigs,
  isCompanyProfileLoading,
  showConfidenceScore,
  handlePushToFinancials,
}) => {
  const queries = useQueriesChartData(chartConfigs, bainId, 'Overview');
  const currency = useShallowSelector((state) => state.config.currency);
  const { data: subsidiaries, isLoading: isSubsidiariesLoading } = useQueryCompanySubsidiaries(bainId);
  const { topLevelParentCompany } = useOverviewTabData({ row: params?.row, currency });
  const preparedChartConfigs = prepareChartConfig(chartConfigs);

  if (isCompanyProfileLoading) {
    return <SkeletonLoader />;
  }

  if (!params) {
    return null;
  }

  return (
    <div className="flex flex-col mb-4">
      <OverviewCards params={params} />

      <div className="grid grid-cols-3 gap-4">
        <div className=" self-start">
          <CompanyProfileWidget
            title={{ text: 'About', id: 'overview-header' }}
            description={{
              text: formatCompanyDescription(params.row.self_firmo_description___)?.formattedDescription,
              id: 'overview-description',
            }}
            entries={[
              {
                label: 'Top-Level Parent Company',
                value: topLevelParentCompany,
                id: 'overview-company',
                row: params.row,
                field: 'parent_firmo_name___',
                confidenceScore: (
                  <ConfidenceScore
                    showConfidenceScore={showConfidenceScore}
                    params={params}
                    field="parent_firmo_name___"
                  />
                ),
              },
              {
                label: 'Revenue profile',
                value: capitalize(params.row.self_financials_revenueProfile___),
                id: 'overview-profile-growth',
                row: params.row,
                field: 'self_financials_revenueProfile___',
                confidenceScore: (
                  <ConfidenceScore
                    showConfidenceScore={showConfidenceScore}
                    params={params}
                    field="self_financials_revenueProfile___"
                  />
                ),
              },
              {
                label: 'Known subsidiaries count',
                id: 'overview-subsidiaries-count',
                value: subsidiaries?.length,
              },
              {
                label: 'Subsidiaries',
                id: 'overview-subsidiaries',
                value: subsidiaries?.length ? <SubsidiariesTable subsidiaries={subsidiaries} /> : undefined,
                isLoading: isSubsidiariesLoading,
              },
            ]}
          />
        </div>

        <div className="grid grid-cols-2 gap-4 col-span-2 self-start">
          <CompanyProfileWidget
            title={{ text: 'Industry', id: 'industry-header' }}
            entries={[
              {
                label: 'Sector',
                value: params.row.self_sector_sector___,
                id: 'industry-sector',
                row: params.row,
                field: 'self_sector_sector___',
                confidenceScore: (
                  <ConfidenceScore
                    showConfidenceScore={showConfidenceScore}
                    params={params}
                    field="self_sector_sector___"
                  />
                ),
              },
              {
                label: 'Industry Group',
                value: params.row.self_sector_industryGroup___,
                id: 'industry-group',
                row: params.row,
                field: 'self_sector_industryGroup___',
                confidenceScore: (
                  <ConfidenceScore
                    showConfidenceScore={showConfidenceScore}
                    params={params}
                    field="self_sector_industryGroup___"
                  />
                ),
              },
              {
                label: 'Industry',
                value: params.row.self_sector_industry___,
                id: 'industry',
                row: params.row,
                field: 'self_sector_industry___',
                confidenceScore: (
                  <ConfidenceScore
                    showConfidenceScore={showConfidenceScore}
                    params={params}
                    field="self_sector_industry___"
                  />
                ),
              },
              {
                label: 'Sub Industry',
                value: params.row.self_sector_subIndustry___,
                id: 'industry-sub',
                row: params.row,
                field: 'self_sector_subIndustry___',
                confidenceScore: (
                  <ConfidenceScore
                    showConfidenceScore={showConfidenceScore}
                    params={params}
                    field="self_sector_subIndustry___"
                  />
                ),
              },
            ]}
          />

          {preparedChartConfigs.map((config, idx) => (
            <OverviewChart
              key={config.title}
              chartConfig={config}
              data={queries[idx]?.data ?? []}
              isLoading={queries[idx].isFetching}
              handlePushToFinancials={handlePushToFinancials}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
