import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { actions as searchActions } from '../../../slices/search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const DescriptionChip = () => {
  const dispatch = useDispatch();
  const { semanticSearch: semanticSearchState } = useShallowSelector((state) => state.search);
  const descriptionChecked = semanticSearchState?.applyDescription;

  const handleDisableDescription = () => {
    if (!semanticSearchState) return;

    dispatch(searchActions.setSemanticSearch({ ...semanticSearchState, applyDescription: false }));
    dispatch(searchActions.setIsElasticEnabled(true));
  };

  if (!descriptionChecked) return null;

  return (
    <div className="w-fit flex items-center p-1 rounded text-xs bg-[#DCE2E8] mr-2 mt-2">
      <p className="text-left">Description</p>

      <IconButton
        className="w-4 h-4 ml-1"
        onClick={handleDisableDescription}
      >
        <CloseIcon className="w-4	h-4" />
      </IconButton>
    </div>
  );
};
