import { useMemo } from 'react';
import { keyBy } from 'lodash';

import { useQueryTable } from '@/hooks/queries/table/use-query-table';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getRows } from '@/Utils/table';
import { useTableLoading } from '@/hooks/table/use-table-loading';

export const useIndexedROws = () => {
  const { pageNumber } = useShallowSelector((state) => state.tableNotPersist);
  const selectedCompanies = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);
  const query = useQueryTable();
  const isLoading = useTableLoading();

  const tableRows = useMemo(() => query?.data?.pages[pageNumber]?.data ?? [], [query?.data?.pages, pageNumber]);

  const rows = useMemo(() => getRows({ rows: tableRows, isLoading }), [tableRows, isLoading]);
  const indexedRows = useMemo(() => keyBy(rows, 'bain_id'), [rows]);

  const areAllSelectedCompaniesOnCurrentPage = selectedCompanies.every((id) => indexedRows[id]);

  return {
    indexedRows,
    rows,
    areAllSelectedCompaniesOnCurrentPage,
  };
};
