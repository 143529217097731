import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import { useParams } from 'react-router';
import { useModal } from '../use-modal';
import { getUuidFromStringEnd } from '@/Utils/url-utils';
import { MODALS } from '@/constants';

export const useTableLoading = () => {
  const { id: idWithTitle } = useParams<{ id: string }>();
  const id = getUuidFromStringEnd(idWithTitle || '');

  const { isOpen: isWizardLoadingDialogOpen } = useModal(MODALS.WIZARD_LOADING_DIALOG);
  const { isOpen: isSemanticSearchLoadingDialogOpen } = useModal(MODALS.SEMANTIC_SEARCH_LOADING_DIALOG);

  const isCompaniesListLoading = useIsFetching({ queryKey: ['companies-list', id] }) > 0;
  const isSavedSearchLoading = useIsFetching({ queryKey: ['saved-search', id] }) > 0;
  const isTableLoading = useIsFetching({ queryKey: ['table-elastic'] }) > 0;
  const isColumnMapperLoading = useIsFetching({ queryKey: ['column-mapper'] }) > 0;
  const isSmartSearchLoading = useIsMutating({ mutationKey: ['smart-search'] }) > 0;
  const isSemanticSearchLoading = useIsFetching({ queryKey: ['generate-semantic-based-search-payload'] }) > 0;

  const isLoading =
    isTableLoading ||
    isCompaniesListLoading ||
    isSavedSearchLoading ||
    isColumnMapperLoading ||
    isSmartSearchLoading ||
    isSemanticSearchLoading ||
    isSemanticSearchLoadingDialogOpen ||
    isWizardLoadingDialogOpen;

  return isLoading;
};
