import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { isNil } from 'lodash';
import { actions as selectedCompaniesActions } from '@/slices/selected-companies';
import { actions as navigationTabsActions } from '@/slices/navigation-tabs';
import { actions as searchActions } from '@/slices/search';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { updateCompaniesList } from '@/services/api/companies-list';
import { IUpdateCompaniesListVariables } from '@/types';
import { createCompaniesListPath } from '@/Components/Shared/CompaniesList/ShareCompaniesListLink';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const useMutationUpdateCompaniesList = (onSuccess: () => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { currentCompaniesList } = useShallowSelector((state) => state.navigationTabs);
  const { pinnedCompanyIds } = useShallowSelector((state) => state.search);

  return useMutation({
    mutationKey: ['update-companies-list'],
    mutationFn: ({ id, fieldsToUpdate }: IUpdateCompaniesListVariables) =>
      updateCompaniesList({ id, body: fieldsToUpdate }),
    onSuccess: (response, { showDefaultNotification, invalidateResponse }) => {
      dispatch(selectedCompaniesActions.reset());

      if (currentCompaniesList?.list_id === response?.list_id) {
        dispatch(navigationTabsActions.setCurrentCompaniesList(response));
      }

      if (invalidateResponse) {
        queryClient.invalidateQueries({ queryKey: ['companies-list', response.list_id] });
        queryClient.invalidateQueries({ queryKey: ['companies-lists'] });
      }

      if (!isNil(pinnedCompanyIds)) {
        dispatch(searchActions.setPinnedCompanyIds(response.pinned_company_ids.map(Number)));
      }

      if (showDefaultNotification) {
        enqueueSnackbar(
          <NotificationMessage
            title={`The ${response.title} list has been successfully updated`}
            href={{
              to: createCompaniesListPath(response.title, response.list_id),
              name: 'Go to the list',
            }}
          />,
          { variant: 'success' },
        );
      }

      onSuccess?.();
    },
    onError: () => {
      enqueueSnackbar(
        <NotificationMessage title="Failed to update companies list. Please try again or contact support." />,
        { variant: 'error' },
      );
    },
  });
};
