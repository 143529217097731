import React, { useRef } from 'react';

import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { StepProps } from '../types';

import { WorkflowDialogHeader } from '../../Common/WorkflowDialogHeader';
import { WorkflowDialogFooter } from '../../Common/WorkflowDialogFooter';
import { actions as searchActions } from '../../../../slices/search';
import { useSemanticSearchValueValidation } from './useSemanticSearchValueValidation';
import { useModal } from '@/hooks/use-modal';
import { MODALS, ROUTES } from '@/constants';

export const ProvideSemanticSearchDescriptionStep = ({ handleClose, setStep, isFirstStep, progress }: StepProps) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { handleOpen: openLoadingModal } = useModal(MODALS.SEMANTIC_SEARCH_LOADING_DIALOG);
  const { validate } = useSemanticSearchValueValidation();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const onNext = () => {
    const value = inputRef.current?.value;

    if (!validate(value) || !value) return;

    dispatch(searchActions.setSemanticSearch({ userDescription: value, applyDescription: false }));
    handleClose();
    push(ROUTES.CUSTOM_SEARCH_SEMANTIC);
    openLoadingModal();
  };

  return (
    <>
      <WorkflowDialogHeader title="What are you looking for?" />
      <div className="mx-auto w-1/3 text-sm font-normal text-[#484848] flex flex-col gap-6 flex-grow">
        <span>
          Add a description of companies you want to find using at least two sentences and we&apos;ll use AI to craft a
          summary description, draw out industries & keywords, which you can adjust.
        </span>
        <span>Focus on what companies do, not what they don&apos;t do.</span>

        <TextField
          inputRef={inputRef}
          placeholder="Add a few sentences about what a company does for us to work with...
e.g. manufacturers of plankton based biofuels etc...
Try not to use locations or financials..."
          variant="outlined"
          size="small"
          multiline
          rows={5}
          InputLabelProps={{
            shrink: true,
          }}
          id="provide-semantic-search-description-step-textarea"
        />
      </div>
      <WorkflowDialogFooter
        onPrimaryButtonClick={onNext}
        onBackButtonClick={isFirstStep ? handleClose : () => setStep((prevState) => prevState - 1)}
        isLoading={false}
        progress={progress}
        primaryButtonId="provide-semantic-search-description-step-next-button"
      />
    </>
  );
};
