import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { CaseCodeForm } from './CaseCodeForm';
import { CaseCodeSubmitButton } from './CaseCodeSubmitButton';
import { CASE_CODE_FORM_STEPS } from '@/constants';

export const CaseCodeSecondStep = ({ isLoading, isEditMode, onSubmit, setStep, flowData, defaultValues }) => {
  const form = useForm({ defaultValues });

  return (
    <>
      <CaseCodeForm
        inputs={flowData?.[CASE_CODE_FORM_STEPS.SECOND]}
        form={form}
        onSubmit={onSubmit}
      />

      <div className="flex gap-4 items-center">
        <CaseCodeSubmitButton
          isLoading={isLoading}
          isEditMode={isEditMode}
          onSubmit={form.handleSubmit(onSubmit)}
        />

        <Button
          size="large"
          className="text-[#2E3F4C] mt-7 px-3 h-10"
          disabled={isLoading}
          onClick={() => setStep(CASE_CODE_FORM_STEPS.FIRST)}
        >
          <span data-testid="back-button">Back</span>
        </Button>
      </div>
    </>
  );
};
