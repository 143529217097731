import { useMutation } from '@tanstack/react-query';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getAllBainIds } from '@/services/api/elastic-search';

export const useMutationValidateBainIds = () => {
  const currency = useShallowSelector((state) => state.config.currency);

  return useMutation({
    mutationKey: ['validate-bain-ids'],
    mutationFn: (bainIds: string[]) =>
      getAllBainIds({
        currency,
        body: {
          company_search: '',
          filters: {
            bain_id: bainIds.map(Number),
          },
          is_lucene: true,
        },
      }),
  });
};
