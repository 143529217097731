import { useQuery } from '@tanstack/react-query';
import { get } from 'aws-amplify/api';
import { useShallowSelector } from '../../use-shallow-selector';
import { FiltersAvailableData } from '@/types';

export const useQueryCustomAvailableFiltersById = (id?: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.config.currency);

  return useQuery(
    ['custom-available-filters-by-id', currency, id],
    (): Promise<FiltersAvailableData[]> =>
      get({ apiName: 'CDPAPI', path: `/filters/available/${id}?currency=${currency}` })
        .response.then((res) => res.body.json())
        // @ts-ignore
        .then((response) => response.data),
    {
      enabled: !!currency && isAuth && !!id,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: 1,
    },
  );
};
