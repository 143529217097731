import React, { ReactElement } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { isArray, isNil } from 'lodash';

import { getConfidenceLevelData } from './utils';
import { MultiValueCellChip } from './cells/multi-value-cell';
import { IElasticTableData } from '@/types';
import { formatCompanyDescription, formatTooltipMetadata, formatTooltipValue } from '@/Utils/text';

export type TTooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export interface IProps {
  displayName: string;
  row?: IElasticTableData;
  field?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any, any>;
  placement?: TTooltipPlacement;
}

export const CommonCellTooltip = ({ displayName, field, row, children, placement }: IProps) => {
  if (!row || !field) return children;

  const returnValue = row[field];
  const resultConfidence = row[`${field}confidence`];
  const resultMetadata = formatTooltipMetadata((row[`${field}metadata`] ?? '') as string);
  const confidenceLevelData = getConfidenceLevelData(resultConfidence as number);
  const IconComponent = confidenceLevelData?.Icon;
  const hasUnknownValue = isNil(returnValue) || returnValue === 'UNKNOWN';
  const hideCellTooltip = (!resultMetadata && !row[field]) || hasUnknownValue;

  const confidenceScore = !!resultConfidence && (
    <>
      <div className="flex gap-2">
        <b>{`Confidence score: (${resultConfidence}) ${confidenceLevelData?.text}`} </b>
        {IconComponent ? (
          <span>
            <IconComponent style={{ width: 15, fill: '#fff' }} />
          </span>
        ) : null}
      </div>
      <br />
    </>
  );

  const isCompanyDescriptionField = field === 'self_firmo_description___';

  const value = row[field];

  const getDisplayValue = () => {
    if (isArray(value)) {
      return (
        <div className="mt-2 inline-block">
          {value.map((item) => (
            <MultiValueCellChip
              key={item}
              label={item}
              inTooltip
            />
          ))}
        </div>
      );
    }

    return formatTooltipValue(value);
  };

  let mainTooltipContent = (
    <>
      <b className="font-black text-xs">{displayName}: </b> {getDisplayValue()}
      <br />
      {resultMetadata ? (
        <>
          <br />
          <b className="font-black text-xs">Origin: </b>
          <span dangerouslySetInnerHTML={{ __html: resultMetadata }}></span>
        </>
      ) : null}
    </>
  );

  if (isCompanyDescriptionField && row[field]) {
    const description = formatCompanyDescription(row[field] as string);
    const source = description?.source;

    mainTooltipContent = (
      <>
        <b className="font-black text-xs">{displayName}: </b> {description?.description}
        <br />
        {source ? (
          <>
            <br />
            <b className="font-black text-xs">Origin: </b>
            {source}
          </>
        ) : null}
      </>
    );
  }

  const toolTipWindow = hideCellTooltip ? null : (
    <div>
      {confidenceScore}
      {mainTooltipContent}
    </div>
  );

  return (
    <Tooltip
      title={toolTipWindow}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
