import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import classnames from 'classnames';

import { useModal } from '../../../hooks/use-modal';
import { useShallowSelector } from '../../../hooks/use-shallow-selector';
import { MODALS } from '../../../constants';
import { useCaseCodePopupConditionally } from '../CaseCodeDialog/use-show-case-code-popup-conditionally';
import { useAllFetchedTableRows } from '@/hooks/table/use-all-fetched-table-rows';
import { useMutationTriggerCompanyOverviewOrComparisonExport } from '@/hooks/queries/export/use-mutation-trigger-company-overview-or-comparison-export';

export const ComparisonExportDialog = () => {
  const { isOpen: isModalOpen, handleClose: handleModalClose } = useModal(MODALS.COMPARISON_EXPORT);
  const { getRowByBainId } = useAllFetchedTableRows();
  const selectedCompanies = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);
  const [mainCompanyId, setMainCompanyId] = useState('');
  const triggerComparisonExportMutation = useMutationTriggerCompanyOverviewOrComparisonExport();
  const { shouldShowCaseCodePopup, handleCaseCodeModalOpen } = useCaseCodePopupConditionally();

  const handleSetMainCompanyId = (_: React.ChangeEvent<HTMLInputElement>, id: string) => {
    setMainCompanyId(id);
  };

  const triggerComparisonExport = () => {
    triggerComparisonExportMutation.mutate(
      { target_id: mainCompanyId, competitor_ids: selectedCompanies },
      { onSettled: handleModalClose },
    );
  };

  const onDownloadClick = () => {
    if (shouldShowCaseCodePopup) {
      handleCaseCodeModalOpen({ onSuccess: triggerComparisonExport });

      return;
    }

    triggerComparisonExport();
  };

  useEffect(() => {
    setMainCompanyId(selectedCompanies[0]);
  }, [selectedCompanies]);

  const isExporting = triggerComparisonExportMutation.isLoading;

  return (
    <div>
      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        classes={{ paper: 'w-1/3' }}
      >
        <DialogTitle classes={{ root: 'font-medium text-base' }}>Create company overview</DialogTitle>

        <CloseIcon
          fontSize="small"
          className="absolute right-3 top-4 cursor-pointer"
          onClick={handleModalClose}
        />

        <DialogContent className="pt-0">
          <DialogContentText className="flex flex-col text-sm">
            <div className="pt-2">
              We`ll provide a slide deck with an overview of the companies you`ve selected, including financial data. An
              excel with data used will be provided for analysis.
            </div>
            <div className="pt-2">
              If multiple companies have been selected, financial metrics will be compared against selected target.
            </div>

            <FormControl className="mt-4">
              <FormLabel
                className="mb-2 font-medium text-base"
                focused={false}
              >
                Select target company
              </FormLabel>
              <RadioGroup
                name="mainCompany"
                value={mainCompanyId}
                onChange={handleSetMainCompanyId}
              >
                {selectedCompanies.map((selectedCompany) => (
                  <FormControlLabel
                    key={selectedCompany}
                    value={selectedCompany}
                    control={
                      <Radio
                        color="info"
                        inputProps={{ title: selectedCompany }}
                      />
                    }
                    label={getRowByBainId(selectedCompany)?.['self_firmo_name___']}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <div className="flex justify-end mt-4">
              <Button
                className={classnames('bg-cred-50 text-white px-4 py-2.5', {
                  'opacity-50': isExporting,
                })}
                onClick={onDownloadClick}
                disabled={isExporting}
              >
                Download
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
