import React, { useEffect, useMemo, useState } from 'react';
import { Select, FormControl, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { createPortal } from 'react-dom';

import { useQueriesChartData } from '@/hooks/queries';
import { ChartConfigType, DatumDatum } from '@/types';
import { ChartCommon } from '@/Components/Shared/Charts';

export interface IMultipleChartsProps {
  bainId: string;
  section: string;
  params?: { row: DatumDatum };
  chartConfigs: ChartConfigType[];
}

export const MultipleCharts = ({ bainId, section, params, chartConfigs }: IMultipleChartsProps) => {
  const queries = useQueriesChartData(chartConfigs, bainId, section);
  const isAllDataFetched = useMemo(() => queries.every((query) => query.status === 'success'), [queries]);
  const isAllDataLoading = useMemo(() => queries.some((query) => query.status === 'loading'), [queries]);
  const hasError = useMemo(() => queries.some((query) => query.status === 'error'), [queries]);

  const firstDataAvailableIndex = useMemo(() => {
    const index = queries.findIndex((query) => !!query.data?.length);

    if (index === -1) return 0;

    return index;
  }, [queries]);

  const [currentChart, setCurrentChart] = useState(firstDataAvailableIndex);

  const config = chartConfigs[currentChart];
  const data = queries?.[currentChart]?.data ?? [];

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentChart(+event.target.value);
  };

  useEffect(() => {
    if (isAllDataFetched) return;

    setCurrentChart(firstDataAvailableIndex);
  }, [firstDataAvailableIndex, isAllDataFetched, queries]);

  const hasMorThanOneChartData = queries.filter((query) => !!query.data?.length).length > 1;

  return (
    <div className="mb-4">
      {!!config && !hasError && (
        <>
          {!!data.length && (
            createPortal(
              <div className="flex gap-2 justify-end">
                <FormControl
                  sx={{ maxWidth: 250 }}
                  fullWidth
                  size="small"
                  disabled={!hasMorThanOneChartData}
                  data-testid="chartDropdown"
                >
                  <InputLabel>Chart</InputLabel>
                  <Select
                    value={currentChart.toString()}
                    label="Chart"
                    onChange={handleChange}
                  >
                    {chartConfigs.map((item, idx) => {
                      const hasData = !!queries?.[idx]?.data?.length;

                      return (
                        <MenuItem
                          key={item.ID}
                          value={idx}
                          className={hasData ? undefined : 'hidden'}
                        >
                          {item.TITLE}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>,
              document.getElementById('chart-switcher') as HTMLElement,
            )
          )}

          <ChartCommon
            data={data}
            params={params}
            section={section}
            isLoading={isAllDataLoading}
            config={config}
          />
        </>
      )}
    </div>
  );
};
