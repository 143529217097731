import React from 'react';
import { range } from 'lodash';
import { Link } from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';

import classNames from 'classnames';
import { BE_LIST_TYPE, StarterPackData } from './types';
import { FAIL_FETCH_TEXT } from '.';
import { useQueryConfig } from '@/hooks/queries/config/use-query-config';
import { CardIconSearch } from '@/Components/Shared/Card/CardIcons/CardIconSearch';
import { CardIconList } from '@/Components/Shared/Card/CardIcons/CardIconList';
import { createSavedSearchPath } from '@/Components/Shared/SavedSearch/ShareSavedSearchButton';
import { createCompaniesListPath } from '@/Components/Shared/CompaniesList/ShareCompaniesListLink';
import { MODALS } from '@/constants';
import { useModal } from '@/hooks/use-modal';
import { StarterPackWizardModalData } from '@/Components/Dialogs/StarterPackWizardDialog';

export const StarterPackSection = () => {
  const { data, error, isLoading } = useQueryConfig<StarterPackData>('starter_pack');
  const { handleOpen: handleOpenStarterPackWizardDialog } = useModal<StarterPackWizardModalData>(
    MODALS.STARTER_PACK_WIZARD,
  );

  const starterItems = data?.tiles ?? [];

  const renderCards = () =>
    starterItems.map(({ link_type, link_id, title, backgroundColor, description, icon, slug }) => {
      const isList = link_type === BE_LIST_TYPE.companiesList;
      const isWizard = link_type === BE_LIST_TYPE.wizard;

      const iconRight = isList ? <CardIconList /> : <CardIconSearch />;

      const containerClasses = classNames('grid rounded-lg pl-3 pr-1.5 py-[12px] gap-4 cursor-pointer min-h-[90px]', {
        'grid-cols-[60px_1fr_36px]': !!icon,
        'grid-cols-[1fr_36px]': !icon,
      });

      const cardContent = (
        <>
          {icon ? (
            <img
              className="w-full"
              src={icon}
              alt={`${title} tile`}
            />
          ) : null}
          <div className="overflow-auto text-left">
            <p className="text-sm text-[#484848] font-medium leading-6">{title}</p>
            <p className="text-sm text-[#666] font-normal leading-5">{description}</p>
          </div>

          <div>{iconRight}</div>
        </>
      );

      if (!link_id)
        return (
          <div
            key={link_id ?? title}
            style={{ backgroundColor }}
            className={containerClasses}
            id={slug}
          >
            {cardContent}
          </div>
        );

      if (isWizard) {
        return (
          <button
            key={link_id}
            style={{ backgroundColor }}
            className={containerClasses}
            id={slug}
            onClick={() => handleOpenStarterPackWizardDialog({ wizardId: link_id, iconLink: icon ?? undefined })}
          >
            {cardContent}
          </button>
        );
      }

      const url = isList ? createCompaniesListPath(title, link_id) : createSavedSearchPath(title, link_id);

      return (
        <Link
          key={link_id}
          to={url}
          style={{ backgroundColor }}
          className={containerClasses}
          id={slug}
        >
          {cardContent}
        </Link>
      );
    });

  return (
    <div className="flex flex-col mt-8">
      <span className="text-[#2E3F4C] font-medium text-sm w-max">Quick starts</span>
      <div className="grid gap-4 grid-cols-3 mt-6">
        {error ? <span className="text-[#666] italic text-sm col-span-3">{FAIL_FETCH_TEXT}</span> : null}

        {!isLoading && starterItems.length === 0 && !error ? (
          <span className="text-[#666] italic text-sm col-span-3">No starter pack config provided</span>
        ) : null}

        {isLoading
          ? range(3).map((idx) => (
              <Skeleton
                key={idx}
                variant="rounded"
                className="bg-[#0000000f] rounded-2xl"
                height={90}
              />
            ))
          : renderCards()}
      </div>
    </div>
  );
};
