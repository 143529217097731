import React from 'react';
import classnames from 'classnames';
import { Tabs, Tab } from '@mui/material';

import { HOME_PAGE_TABS } from '.';

interface IProps {
  currentTabIndex: number;
  handleCurrentTabIndexChange: (_: React.SyntheticEvent<Element, Event>, newIndex: number) => void;
}

export const CtaButtons = ({ currentTabIndex, handleCurrentTabIndexChange }: IProps) => {
  return (
    <div className="pt-2 flex justify-between items-center">
      <span className="text-[#2E3F4C] font-medium text-sm w-max">Saved work</span>
      <div className="flex justify-center items-center gap-6">
        <Tabs
          value={currentTabIndex}
          onChange={handleCurrentTabIndexChange}
          className="min-h-[auto] self-end"
          classes={{
            indicator: 'bg-[#2E3F4C]',
          }}
        >
          {HOME_PAGE_TABS.map((label, index) => (
            <Tab
              key={index}
              label={label}
              id={label}
              className={classnames('text-sm text-[#2E3F4C] p-0 mb-2 min-w-[auto] min-h-[auto] tracking-wide', {
                'font-medium': index === currentTabIndex,
                'font-normal': index !== currentTabIndex,
                'mr-6': index !== HOME_PAGE_TABS.length - 1,
              })}
            />
          ))}
        </Tabs>
        {/* TODO: add tooltip text
        <div className="border-l-[1px] border-l-[#DDD]  h-[24px]" />
        <Tooltip title="Tooltip text">
          <div>
            <IconHelp />
          </div>
        </Tooltip> */}
      </div>
    </div>
  );
};
