import { useLocalStorage } from 'usehooks-ts';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { CardMenuItem } from '../../Card/CardMenu';
import { useModal } from '@/hooks/use-modal';
import { useMutationRemoveCompaniesList } from '@/hooks/queries/companies-list/use-mutation-remove-companies-list';
import { LOCAL_STORAGE_KEYS, MODALS } from '@/constants';
import { ICompaniesListSnippet } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions } from '@/slices/navigation-tabs';

export const useCompaniesListMenuItems = (
  companiesList?: ICompaniesListSnippet,
  sharedWithUser = false,
  removeWithRedirect = false,
  hideDelete = false,
  readOnlyMode = false,
): CardMenuItem[] => {
  const { handleOpen } = useModal(MODALS.UPDATE_COMPANIES_LIST);
  const mutation = useMutationRemoveCompaniesList(removeWithRedirect);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { currentCompaniesList } = useShallowSelector((state) => state.navigationTabs);
  const [companiesListsSharedWithUser, setCompaniesListsSharedWithUser] = useLocalStorage<ICompaniesListSnippet[]>(
    LOCAL_STORAGE_KEYS.COMPANIES_LISTS_SHARED_WITH_USER,
    [],
  );

  const handleRemoveSharedWithUserItem = () => {
    setCompaniesListsSharedWithUser(
      companiesListsSharedWithUser.filter(
        (sharedCompaniesList) => sharedCompaniesList.list_id !== companiesList?.list_id,
      ),
    );

    if (currentCompaniesList?.list_id === companiesList?.list_id) {
      dispatch(actions.setCurrentCompaniesList(null));
      queryClient.invalidateQueries({ queryKey: ['companies-lists'] });
    }
  };

  const handleRemoveUserItem = () => {
    if (!companiesList?.list_id) return;

    mutation.mutate(companiesList.list_id);
  };

  const handleOpenMenu = (handleCloseMenu?: () => void) => {
    handleOpen({ companiesList, handleCloseMenu, sharedWithUser, readOnlyMode });
  };

  return [
    {
      name: 'View details',
      onClick: handleOpenMenu,
      disabled: false,
      loading: false,
    },
    ...(hideDelete || companiesList?.shared_with_me
      ? []
      : [
          {
            name: 'Remove',
            onClick: sharedWithUser ? handleRemoveSharedWithUserItem : handleRemoveUserItem,
            disabled: mutation.isLoading,
            loading: mutation.isLoading,
          },
        ]),
  ];
};
