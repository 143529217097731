import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useShallowSelector } from '../../use-shallow-selector';
import { getCompanyProfileDatasets } from '@/services/api/company-profile';

export const useQueryCompanyProfileDatasets = (bainId: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.config.currency);
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(['company-profile-datasets', bainId, currency], () => getCompanyProfileDatasets(bainId, currency), {
    enabled: isAuth && !!bainId,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
    onError: () =>
      enqueueSnackbar('Failed to fetch datasets. Please try again or contact support.', {
        variant: 'error',
      }),
  });
};
