import React from 'react';

interface TabPanelProps {
  value: number;
  index: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className="px-6 pt-4"
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};
