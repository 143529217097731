export const COMPANY_PROFILE_MA = {
  data: [
    {
      buyer_summary: [
        {
          id_ciq_buyer: '313055',
          current_portfolio_count: 16,
          current_portfolio:
            'Alert Innovation Inc. | Bonobos, Inc. | Certain Assets of Botmock | Delivery Drivers, Inc. | Jet.com, Inc. | Massmart Holdings Limited | MemoMi Inc. | PaperG, Inc. | Parcel Inc. | Volt Systems LLC | Walmart.com USA, LLC | Zeekit Online Shopping Ltd.\nAlert Innovation | Bompreço | Botmock | CareZone | Cifra | Distribucion y Servicio | Flipkart | Jet | JoyRun | Massmart | MeMD | Memomi | Parcel | Polymorph | Reclip.It | Thunder | VOLT Systems | Walmart Aspectiva | Wim Yogurt | Zeekit (a Walmart company)',
          exited_portfolio_count: 0,
          exited_portfolio: null,
          acquisitions_last_1yr: 0,
          acquisitions_last_3yr: 0,
          acquisitions_last_5yr: 0,
          sales_last_1yr: 0,
          sales_last_3yr: 0,
          sales_last_5yr: 0,
          most_recent_transaction: '2017-10-03',
          yrs_since_most_recent_transaction: 6.12,
          second_most_recent_transaction: '2017-06-16',
          yrs_since_second_most_recent_transaction: 6.42,
          last_acquisition_date: '2022-10-06',
          second_to_last_acquisition_date: '2022-08-05',
          last_divestiture_date: null,
          second_to_last_divestiture_date: null,
          holding_period_avg: null,
          holding_period_std: null,
          bain_id_buyer: 23617408,
        },
      ],
    },
    {
      target_transactions: [
        {
          bain_id: '',
          bain_id_buyer: '',
          company_name: '',
          buyer_name: '',
          entry_date: '',
          exit_date: '',
          ownership_pct: '',
          transaction_value: '',
          currently_in_portfolio: '',
          data_source: '',
          data_priority: '',
        },
      ],
    },
    {
      buyer_transactions: [
        {
          bain_id: 1701036,
          bain_id_buyer: 23617408,
          company_name: 'Parcel Inc.',
          buyer_name: 'Walmart Inc.',
          entry_date: '2017-10-03',
          exit_date: null,
          ownership_pct: 100,
          transaction_value: null,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 21521555,
          bain_id_buyer: 23617408,
          company_name: 'Zeekit Online Shopping Ltd.',
          buyer_name: 'Walmart Inc.',
          entry_date: null,
          exit_date: null,
          ownership_pct: 100,
          transaction_value: null,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 2867595,
          bain_id_buyer: 23617408,
          company_name: 'Massmart Holdings Limited',
          buyer_name: 'Walmart Inc.',
          entry_date: null,
          exit_date: null,
          ownership_pct: 47.20000076293945,
          transaction_value: null,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 31478531,
          bain_id_buyer: 23617408,
          company_name: 'Walmart.com USA, LLC',
          buyer_name: 'Walmart Inc.',
          entry_date: null,
          exit_date: null,
          ownership_pct: 100,
          transaction_value: null,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 22487085,
          bain_id_buyer: 23617408,
          company_name: 'Jet.com, Inc.',
          buyer_name: 'Walmart Inc.',
          entry_date: '2016-08-08',
          exit_date: null,
          ownership_pct: 100,
          transaction_value: 3300000000,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 42711202,
          bain_id_buyer: 23617408,
          company_name: 'MemoMi Inc.',
          buyer_name: 'Walmart Inc.',
          entry_date: null,
          exit_date: null,
          ownership_pct: 100,
          transaction_value: null,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 26900128,
          bain_id_buyer: 23617408,
          company_name: 'Delivery Drivers, Inc.',
          buyer_name: 'Walmart Inc.',
          entry_date: null,
          exit_date: null,
          ownership_pct: 100,
          transaction_value: null,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 2608276,
          bain_id_buyer: 23617408,
          company_name: 'PaperG, Inc.',
          buyer_name: 'Walmart Inc.',
          entry_date: null,
          exit_date: null,
          ownership_pct: 100,
          transaction_value: null,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 26822791,
          bain_id_buyer: 23617408,
          company_name: 'Volt Systems LLC',
          buyer_name: 'Walmart Inc.',
          entry_date: null,
          exit_date: null,
          ownership_pct: 100,
          transaction_value: null,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 22824013,
          bain_id_buyer: 23617408,
          company_name: 'Bonobos, Inc.',
          buyer_name: 'Walmart Inc.',
          entry_date: '2017-06-16',
          exit_date: null,
          ownership_pct: 100,
          transaction_value: 310000000,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
        {
          bain_id: 18382314,
          bain_id_buyer: 23617408,
          company_name: 'Alert Innovation Inc.',
          buyer_name: 'Walmart Inc.',
          entry_date: null,
          exit_date: null,
          ownership_pct: 100,
          transaction_value: null,
          currently_in_portfolio: 'Y',
          data_source: 'Capital IQ',
          data_priority: 1,
        },
      ],
    },
  ],
};