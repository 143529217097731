import { useQuery } from '@tanstack/react-query';

import { useSnackbar } from 'notistack';
import { getSavedSearchById } from '@/services/api/saved-search';
import { ISavedSearch } from '@/types';

export const useQuerySavedSearchById = ({
  id,
  onSuccess,
}: {
  id: string;
  onSuccess?: (data: ISavedSearch) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(['saved-search', id], () => getSavedSearchById(id), {
    enabled: !!id,
    cacheTime: Infinity,
    staleTime: Infinity,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () =>
      enqueueSnackbar('Failed to fetch saved search. Please try again or contact support.', { variant: 'error' }),
  });
};
