import React, { useState, KeyboardEvent } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { useSearchCompanies } from '@/hooks/use-search-companies';
import useAutocompleteCompanySearch from '@/Components/CompanyResearch/Search/hooks/use-autocomplete-company-search';
import { QueryType, actions } from '@/slices/search';
import { IResponseCompanySearchItem } from '@/types';
import { ROUTES } from '@/constants';

export const HeaderSearch = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const searchCompanies = useSearchCompanies();

  const { isMatchCompanySearchLoading, matchCompanyTypeAheadList } = useAutocompleteCompanySearch({
    isFocused: true,
    searchText,
    queryTypeParam: QueryType.MATCH,
  });

  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    if (event) {
      setSearchText(value);
    }
  };

  const handleOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | IResponseCompanySearchItem | null,
  ) => {
    if (value === null) {
      return;
    }

    if (event.type === 'click') {
      dispatch(actions.setQueryType(QueryType.MATCH));

      searchCompanies({
        searchText: typeof value === 'string' ? value : value.self_firmo_name___,
        queryType: QueryType.MATCH,
        isUniq: true,
      });

      push(ROUTES.CUSTOM_SEARCH);
    }
  };

  const handleEnter = (event: KeyboardEvent) => {
    const isEnter = event?.key === 'Enter';

    if (isEnter) {
      event.preventDefault();
    }

    if (isEnter && (event.target as HTMLInputElement).value.length > 0) {
      dispatch(actions.setQueryType(QueryType.MATCH));
      searchCompanies({ searchText, queryType: QueryType.MATCH });
      push(ROUTES.CUSTOM_SEARCH);
    }
  };

  return (
    <Autocomplete
      id="HomeSearch"
      size="small"
      disablePortal
      inputValue={searchText}
      freeSolo={true}
      loading={isMatchCompanySearchLoading}
      options={matchCompanyTypeAheadList}
      className="w-[320px]"
      onInputChange={handleInputChange}
      onKeyDown={handleEnter}
      onChange={handleOnChange}
      getOptionKey={(option) => (typeof option === 'string' ? option : option.self_firmo_name___)}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.self_firmo_name___)}
      renderOption={(props, option) => <li {...props}>{option.self_firmo_name___}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          className="border border-[#ddd] text-[13px]"
          placeholder="Enter company name or website..."
          InputProps={{
            ...params.InputProps,
            classes: {
              notchedOutline: 'border-[#ddd]',
            },
          }}
          inputProps={{
            ...params.inputProps,
            style: { fontSize: 12 },
            autoComplete: 'off',
          }}
        />
      )}
    />
  );
};
