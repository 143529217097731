export const copyToClipboard = (payload, onSuccess, onError) => {
  navigator.clipboard
    .writeText(payload)
    ?.then(() => {
      onSuccess?.();
    })
    ?.catch(() => {
      onError?.();
    });
};
